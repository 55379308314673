<template>
  <div class="db-ChromeMainContent">
    <left-nav :leftNavItems="leftNavItems" title="Report" />
    <div class="w-full h-full mt-32">
      <router-view />
    </div>
    <!-- <report-detail :selectedNavItem="selectedNavItem">
    </report-detail> -->
  </div>
</template>
<script>
import LeftNav from "../../components/LeftNav.vue";
import { uuid, v4 } from "uuidv4";
import ReportDetail from "../../components/ReportDetail.vue";
export default {
  components: { LeftNav, ReportDetail },
  name: "configuration",
  data() {
    return {
      leftNavItems: [
        // {
        //   id: uuid(),
        //   title: "Test",
        //   showChildren: true,
        //   selected: false,
        //   children: [
        //     {
        //       id: uuid(),
        //       title: "Test Summary",
        //       selected: true,
        //       pathName: "TestSummaryReport",
        //     },
        //     {
        //       id: uuid(),
        //       title: "Test Usage",
        //       selected: false,
        //       pathName: "TestUsageReport",
        //     },
        //   ],
        // },
        // {
        //   id: uuid(),
        //   title: "Panel",
        //   showChildren: false,
        //   selected: false,
        //   children: [
        //     {
        //       id: uuid(),
        //       title: "Panel Summary",
        //       selected: false,
        //       pathName: "PanelSummaryReport",
        //     },
        //     {
        //       id: uuid(),
        //       title: "Panel Usage",
        //       selected: false,
        //       pathName: "PanelUsageReport",
        //     },
        //   ],
        // },
        // {
        //   id: uuid(),
        //   title: "Unreleased/Pending Panels",
        //   showChildren: false,
        //   selected: false,
        //   pathName: "UnreleasedPanelsReport",
        //   children: [],
        // },
        // {
        //   id: uuid(),
        //   title: "Test Results",
        //   showChildren: false,
        //   selected: false,
        //   pathName: "TestResultReport",
        //   children: [],
        // },
        {
          id: uuid(),
          title: "Breast cancer report Per Date Range",
          showChildren: false,
          selected: false,
          pathName: "WeeklyBreastCancerReport",
          children: [],
        },
        {
          id: uuid(),
          title: "Cervical cancer report Per Date Range",
          showChildren: false,
          selected: false,
          pathName: "WeeklyCervicalCancerReport",
          children: [],
        },
      ],
    };
  },
  computed: {
    selectedNavItem() {
      var title = "none";
      this.leftNavItems.forEach((leftNavItem) => {
        if (leftNavItem.selected) {
          title = leftNavItem.title;
        }
        leftNavItem.children.forEach((leftNav) => {
          if (leftNav.selected) {
            title = leftNav.title;
          }
        });
      });
      return title;
    },
  },
};
</script>
