<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation v-if="requestData">
      <v-text-field
        dense
        label="Field Lable"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.prompt"
      ></v-text-field>

      <v-textarea
        dense
        label="Response Title"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.responseTitle"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.required"
        label="Is Required"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.required"
        dense
        label="Required Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.requiredMessage"
      ></v-text-field>

      <v-checkbox
        v-model="requestData.hasValidation"
        label="Has Validation"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpression"
      ></v-text-field>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpressionMessage"
      ></v-text-field>
      <div>Orientation</div>
      <v-btn-toggle v-model="text" tile color="deep-purple accent-3" group>
        <v-btn value="horizontal"> Horizontal </v-btn>
        <v-btn value="vertical"> Vertical </v-btn>
      </v-btn-toggle>
      <!-- <v-combobox
        v-model="requestData.Options"
        :items="requestData.Options"
        :search-input.sync="search"
        hide-selected
        hint="Maximum of 5 tags"
        label="Edit some tags"
        multiple
        persistent-hint
        small-chips
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                "<strong>{{ search }}</strong
                >" Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox> -->
      <div>
        <div v-for="(option, index) in requestData.Options" :key="index">
          <div class="d-flex">
            <!-- <v-checkbox dense /> -->
            <v-text-field
              label="Option"
              outlined
              @change="setIsEdited(option)"
              dense
              v-model="option.value"
            ></v-text-field>
            <!-- <div>{{ option }}</div> -->
            <!-- <v-spacer></v-spacer> -->
            <v-btn icon @click="removeItem(option)">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
        <v-text-field
          label="New Option"
          outlined
          dense
          @keyup.enter="addOption()"
          v-model="newOption"
        ></v-text-field>
      </div>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.ordinal"
        type="number"
      ></v-text-field>
      <v-btn color="warning" @click="disable()" depressed small>Disable</v-btn>
    </v-form>
  </edit-page>
</template>
<script>
import { mapGetters } from "vuex";
import apiClient from "../../../API/apiClient";
import EditPage from "../../EditPage.vue";
export default {
  components: { EditPage },
  props: [
    "selectedNavItem",
    "toggleForm",
    "addFormField",
    "dataTypes",
    "formField",
    "removeOption",
  ],
  created() {
    this.formField.Options.forEach((option) => {
      option.isEdited = false;
    });
    this.requestData = this.formField;
  },
  data() {
    return {
      text: "vertical",
      newOption: "",
      valid: false,
      NoEmptyRule: [(v) => !!v || "*required"],
      search: null,
      requestData: null,
    };
  },
  computed: {
    ...mapGetters(["currentCollectionProtocol", "currentInstitution"]),
  },
  methods: {
    disable() {
      this.addFormField(this.requestData, false, true);
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (this.text == "vertical") {
          this.requestData.verticalView = true;
          this.requestData.horizontalView = false;
        } else {
          this.requestData.verticalView = false;
          this.requestData.horizontalView = true;
        }
        // console.log(this.requestData);
        this.addFormField(this.requestData, false);
      }
    },
    setIsEdited(item) {
      this.requestData.Options.forEach((option) => {
        if (option.id == item.id) {
          option.isEdited = true;
        }
      });
    },
    addOption() {
      console.log("add Option", this.requestData);
      var institutionId = this.currentInstitution.id;
      var collectionProtocolId = this.currentCollectionProtocol.id;
      var collectionProtocolFormId = this.requestData.collectionProtocolFormID;
      var collectionProtocolFormFieldId = this.requestData.id;
      var newOption = {
        value: this.newOption,
        ordinal: this.requestData.Options.length,
      };
      // return;
      apiClient
        .call(
          "POST",
          `institutions/${institutionId}/collectionProtocols/${collectionProtocolId}/collectionProtocolForms/${collectionProtocolFormId}/collectionProtocolFormFields/${collectionProtocolFormFieldId}/collectionProtocolFormFieldOptions`,
          newOption
        )
        .then((option) => {
          option.isEdited = false;
          this.requestData.Options.push(option);
          console.log(option);
        })
        .catch((err) => console.log(err));
      this.newOption = "";
    },
    removeItem(item) {
      console.log("remove");
      this.removeOption(item);
      this.requestData.Options.forEach((option) => {
        if (option.id == item.id) {
          this.requestData.Options.splice(
            this.requestData.Options.indexOf(option),
            1
          );
        }
      });
    },
  },
};
</script>
