<template>
  <div>
    <div>
      <router-link
        :to="
          '/collection-protocols/' + currentCollectionProtocol.id + '/overview'
        "
        >{{ currentCollectionProtocol.title }}</router-link
      ><span style="padding: 5px">/</span
      ><router-link
        :to="
          '/collection-protocols/' +
            currentCollectionProtocol.id +
            '/participants'
        "
        >Participants</router-link
      ><span style="padding: 5px">/</span
      >{{ currentCollectionProtocolParticipant.patient.firstName }}
      {{ currentCollectionProtocolParticipant.patient.fatherName
      }}<span style="padding: 5px">/</span>questioners
    </div>
    <v-app>
      <div class="Box-root">
        <div
          class="
            Card-root Card--radius--all
            Section
            Box-root
            Box-background--white
          "
          style="overflow: visible"
        >
          <div class="ListView ListView--withoutFooter">
            <div
              class="
                Box-root
                Padding-top--16
                Padding-bottom--8
                Flex-flex
                Flex-alignItems--center
                Flex-justifyContent--spaceBetween
              "
            >
              <span
                class="
                  Text-color--dark
                  Text-fontSize--28
                  Text-fontWeight--bold
                  Text-lineHeight--32
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Questioners</span></span
              >
              <v-btn color="primary" elevation="0" small @click="toggleForm()"
                ><v-icon style="margin-right: 5px" right dark>
                  mdi-plus
                </v-icon>
                Fill Form</v-btn
              >
            </div>
            <v-data-table
              style="margin-top: 15px"
              :headers="headers"
              @click:row="handleClick"
              :loading="isLoading"
              :items="submittedCollectionProtocolForms"
              ><template v-slot:item.enabledDate="{ item }">
                {{ item.enabledDate | moment("YYYY-MM-DD") }}
              </template></v-data-table
            >
            <v-dialog v-model="dialog" max-width="600">
              <v-card v-if="selectedCollectionProtocolParticipantForm">
                <v-card-title class="text-h5">
                  <span>
                    {{
                      selectedCollectionProtocolParticipantForm
                        .collectionProtocolForm.name
                    }}
                  </span>
                </v-card-title>
                <hr />

                <v-card-text>
                  <div
                    class="my-2"
                    v-if="
                      selectedCollectionProtocolParticipantForm.submittedCollectionProtocolFormValues
                    "
                  >
                    <div
                      class="py-2"
                      v-for="(submittedCollectionProtocolFormValue,
                      index) in selectedCollectionProtocolParticipantForm.submittedCollectionProtocolFormValues"
                      :key="index"
                    >
                      <div class="font-bold">
                        {{
                          submittedCollectionProtocolFormValue
                            .collectionProtocolFormField.prompt
                        }}
                      </div>
                      <div>
                        {{ submittedCollectionProtocolFormValue.value }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <div class="d-flex justify-center"></div>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <div
        v-if="showAddForm"
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <add-collection-protocol-participant-form
          selectedNavItem="Form"
          :toggleForm="toggleForm"
        />
      </div>
    </v-app>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AddCollectionProtocolParticipantForm from "../../components/AddForms/AddCollectionProtocolParticipantForm.vue";
export default {
  components: { AddCollectionProtocolParticipantForm },
  name: "collection-protocol-participant-form",
  created() {
    console.log("this.getSubmittedCollectionProtocolForms() fired!");
    this.getSubmittedCollectionProtocolForms();
  },
  computed: {
    ...mapGetters([
      "currentCollectionProtocol",
      "currentCollectionProtocolParticipant",
      "submittedCollectionProtocolForms",
      "isLoading",
    ]),
  },
  data() {
    return {
      showAddForm: false,
      dialog: false,
      selectedCollectionProtocolParticipantForm: null,
      headers: [
        {
          text: "Form",
          align: "start",
          value: "collectionProtocolForm.name",
        },
        {
          text: "Filled Date",
          value: "enabledDate",
        },
        {
          text: "Sample ID",
          value: "sampleID",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getSubmittedCollectionProtocolForms"]),
    toggleForm: function() {
      this.showAddForm = !this.showAddForm;
    },
    handleClick(value) {
      this.selectedCollectionProtocolParticipantForm = value;
      this.dialog = true;
    },
  },
};
</script>
