<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Participants</span></span
                >
                <!-- <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Participant</v-btn
                > -->
              </div>
              <div class="p-2 d-flex">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  @keyup.enter="searchPatients()"
                  style="width: 400px"
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn
                  color="primary"
                  dark
                  depressed
                  small
                  @click="searchPatients()"
                  style="margin-left: 10px"
                >
                  Search
                </v-btn>
              </div>

              <v-data-table
                style="margin-top: 15px"
                :headers="headers"
                :loading="isLoading"
                :items="patients"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.firstName }} {{ item.fatherName }}
                  {{ item.grandFatherName }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:item.birthDate="{ item }">
                  {{ item.birthDate | moment("YYYY-MM-DD hh:mm A") }}
                </template></v-data-table
              >
            </div>
          </div>
        </div>
      </v-app>
    </div>
    <edit-patient
      :patient="selectedPatient"
      :toggleForm="toggleForm"
      v-if="showAddForm"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EditPatient from "../components/EditForms/EditPatient.vue";
export default {
  components: { EditPatient },
  name: "patients",
  created() {
    this.getPatients("");
  },
  data() {
    return {
      search: "",
      showAddForm: false,
      selectedPatient: null,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        // {
        //   text: "Father Name",
        //   align: "start",
        //   value: "fatherName",
        // },
        {
          text: "Birth Date",
          align: "start",
          value: "birthDate",
        },
        {
          text: "Gender",
          align: "start",
          value: "gender.title",
        },
        {
          text: "Marital Status",
          align: "start",
          value: "maritalStatus.title",
        },
        {
          text: "Vital Status",
          align: "start",
          value: "vitalStatus.title",
        },
        {
          text: "Patient Type",
          align: "start",
          value: "patientType.title",
        },
        {
          text: "External Subject ID",
          align: "start",
          value: "externalSubjectID",
        },
        {
          text: "",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getPatients"]),
    searchPatients() {
      this.getPatients(this.search);
    },
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    editItem: function (item) {
      this.selectedPatient = item;
      this.showAddForm = true;
      // console.log("item:::", item);
    },
    // handleClick(value) {
    //   this.$store.commit("setSelectedPatient", value);
    //   // this.setSelectedCollectionProtocolForm(setSelectedPatient);
    //   this.$router
    //     .push({
    //       name: "PatientOverview",
    //       params: { collectionProtocolParticipantId: value.id },
    //     })
    //     .catch(() => {});
    //   console.log(value.name);
    // },
  },
  computed: {
    ...mapGetters(["patients", "currentCollectionProtocol", "isLoading"]),
  },
};
</script>