<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <!-- <v-text-field
        dense
        label="Code"
        :rules="[
          (v) => (v || '').length <= 2 || 'Code must be 2 characters or less',
        ]"
        counter="2"
        filled
        required
        v-model="requestData.Code"
      ></v-text-field> -->

      <div class="d-flex">
        <v-text-field
          dense
          label="Rows"
          :rules="NoEmptyRule"
          filled
          type="number"
          required
          v-model="requestData.Rows"
        ></v-text-field>
        <v-text-field
          dense
          class="ml-4"
          type="number"
          label="Columns"
          :rules="NoEmptyRule"
          filled
          required
          v-model="requestData.Columns"
        ></v-text-field>
      </div>
      <!-- <v-select
        :items="storageContainerRacks"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedStorageContainerRack"
        :rules="[(v) => !!v || '*Required']"
        label="Storage Container Rack"
      ></v-select> -->
      <v-select
        :items="positionLabelingOptions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedPositionLabelingOption"
        :rules="[(v) => !!v || '*Required']"
        label="Position Labeling Option"
      ></v-select>
      <v-select
        v-if="
          requestData.SelectedPositionLabelingOption &&
          requestData.SelectedPositionLabelingOption.title.toLowerCase() ==
            'linear'
        "
        :items="labelingSchemas"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedRowLabelingSchema"
        :rules="[(v) => !!v || '*Required']"
        label="Labeling Schema"
      ></v-select>
      <div v-else>
        <v-select
          :items="labelingSchemas"
          item-text="title"
          filled
          return-object
          v-model="requestData.SelectedRowLabelingSchema"
          :rules="[(v) => !!v || '*Required']"
          label="Row Labeling Schema"
        ></v-select>
        <v-select
          :items="labelingSchemas"
          item-text="title"
          filled
          return-object
          v-model="requestData.SelectedColumnLabelingSchema"
          :rules="[(v) => !!v || '*Required']"
          label="Column Labeling Schema"
        ></v-select>
      </div>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-race",
  props: ["selectedNavItem", "toggleForm", "selectedStorage"],
  created() {
    // this.getStorageContainerRacks();
    this.getPositionLabelingOptions();
    this.getLabelingSchemas();
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        Title: "",
        Code: "",
        Rows: 0,
        Columns: 0,
        SelectedPositionLabelingOption: null,
        PositionLabelingOptionId: null,
        SelectedRowLabelingSchema: null,
        RowLabelingSchemaId: null,
        SelectedColumnLabelingSchema: null,
        ColumnLabelingSchemaId: null,
        SelectedStorageContainerRack: null,
        StorageContainerRackId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions([
      "getStorageContainerRacks",
      "getPositionLabelingOptions",
      "getLabelingSchemas",
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        // this.requestData.StorageContainerRackId =
        //   this.requestData.SelectedStorageContainerRack.id;
        if (
          this.requestData.SelectedPositionLabelingOption.title.toLowerCase() !=
          "linear"
        ) {
          this.requestData.ColumnLabelingSchemaId =
            this.requestData.SelectedColumnLabelingSchema.id;
        }
        // this.requestData.ColumnLabelingSchemaId =
        //   this.requestData.SelectedColumnLabelingSchema.id;
        this.requestData.PositionLabelingOptionId =
          this.requestData.SelectedPositionLabelingOption.id;
        this.requestData.RowLabelingSchemaId =
          this.requestData.SelectedRowLabelingSchema.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/storageContainers/" +
              this.selectedStorage.ids.containerId +
              "/storageContainerShelfs/" +
              this.selectedStorage.ids.shelfId +
              "/storageContainerRacks/" +
              this.selectedStorage.ids.rackId +
              "/storageContainerDrawers",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            // this.$store.dispatch("getStorageContainerDrawers");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "storageContainerRacks",
      "positionLabelingOptions",
      "labelingSchemas",
    ]),
  },
};
</script>