var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"contextmenu":_vm.show}},[_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[(
          _vm.orderedTest.testResultRelease
            ? !_vm.orderedTest.testResultRelease.isReleased
            : false
        )?_c('v-list-item',{on:{"click":function($event){return _vm.releaseTest(_vm.orderedPanel, _vm.orderedTest)}}},[_vm._v(" Release ")]):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.commentTest(_vm.orderedPanel, _vm.orderedTest)}}},[_vm._v(" Comment ")])],1)],1),_c('div',{class:[
      (
        _vm.orderedTest.testResultRelease
          ? !_vm.orderedTest.testResultRelease.isReleased
          : false
      )
        ? 'unreleased'
        : '' ],staticStyle:{"min-height":"40px","height":"40px","display":"flex","margin-top":"10px"}},[_c('div',{staticStyle:{"height":"40px","width":"30%","text-align":"center","vertical-align":"middle","line-height":"40px"}},[_vm._v(" "+_vm._s(_vm.orderedTest.test.title)+" ")]),_c('div',{staticStyle:{"min-height":"40px","min-width":"25%","text-align":"center","vertical-align":"middle","line-height":"40px"}},[(_vm.orderedTest.testAcceptedValues)?_c('span',[(_vm.orderedTest.testAcceptedValues.length > 0)?_c('v-select',{staticStyle:{"width":"100px"},attrs:{"dense":"","items":_vm.orderedTest.testAcceptedValues,"item-text":"title","filled":"","disabled":_vm.orderedTest.testResultRelease
              ? _vm.orderedTest.testResultRelease.isReleased
              : false,"rules":[function (v) { return !!v || '*Required'; }]},model:{value:(_vm.orderedTest.testResult.result),callback:function ($$v) {_vm.$set(_vm.orderedTest.testResult, "result", $$v)},expression:"orderedTest.testResult.result"}}):_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"disabled":_vm.orderedTest.testResultRelease
              ? _vm.orderedTest.testResultRelease.isReleased
              : false,"dense":"","filled":""},model:{value:(_vm.orderedTest.testResult.result),callback:function ($$v) {_vm.$set(_vm.orderedTest.testResult, "result", $$v)},expression:"orderedTest.testResult.result"}})],1):_vm._e()]),_c('div',{staticStyle:{"min-height":"40px","width":"45%","text-align":"center","vertical-align":"middle","line-height":"40px"}},[(_vm.orderedTest.testResultRange)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.orderedTest.testResultRange.minimumValue)+" - "+_vm._s(_vm.orderedTest.testResultRange.maximumValue))])]):_vm._e(),(_vm.orderedTest.test)?_c('span',[(_vm.orderedTest.test.unitOfMeasurement)?_c('span',[_vm._v(_vm._s(_vm.orderedTest.test.unitOfMeasurement.unitSymbol)+" ")]):_vm._e()]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }