<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Field Lable"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.prompt"
      ></v-text-field>

      <v-textarea
        dense
        label="Response Title"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.responseTitle"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.required"
        label="Is Required"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.required"
        dense
        label="Required Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.requiredMessage"
      ></v-text-field>

      <v-checkbox
        v-model="requestData.hasValidation"
        label="Has Validation"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpression"
      ></v-text-field>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpressionMessage"
      ></v-text-field>
      <div>Orientation</div>
      <v-btn-toggle v-model="text" tile color="deep-purple accent-3" group>
        <v-btn value="horizontal"> Horizontal </v-btn>
        <v-btn value="vertical"> Vertical </v-btn>
      </v-btn-toggle>
      <v-combobox
        v-model="requestData.Options"
        :items="requestData.Options"
        :search-input.sync="search"
        hide-selected
        hint="Maximum of 5 tags"
        label="Add some tags"
        multiple
        persistent-hint
        small-chips
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                "<strong>{{ search }}</strong
                >" Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.ordinal"
        type="number"
      ></v-text-field>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  props: ["selectedNavItem", "toggleForm", "addFormField", "dataTypes"],
  created() {
    this.requestData.ordinal = this.currentOrdinal;
    //console.log("dataTypes", this.dataTypes);
    this.dataTypes.forEach((DT) => {
      //console.log(DT.abbreviation);
      if (DT.abbreviation.toLowerCase() === "rl") {
        this.requestData.dataType = DT;
        //console.log(this.requestData.dataType.title);
      }
    });
  },
  data() {
    return {
      text: "vertical",
      valid: false,
      NoEmptyRule: [(v) => !!v || "*required"],
      search: null,
      requestData: {
        prompt: "",
        placeholder: "none",
        responseTitle: "",
        ordinal: 0,
        onNewLine: true,
        required: false,
        requiredMessage: "",
        verticalView: false,
        horizontalView: false,
        hasValidation: false,
        regularExpression: "",
        regularExpressionMessage: "",
        dataType: {
          dataTypeName: "RadioList",
          dataTypeAbbreviation: "RL",
          dataTypeDescription: "RadioList",
          hasValidation: false,
          dataTypeValidation: "",
        },
        Options: [],
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (this.text == "vertical") {
          this.requestData.verticalView = true;
          this.requestData.horizontalView = false;
        } else {
          this.requestData.verticalView = false;
          this.requestData.horizontalView = true;
        }
        // console.log(this.requestData);
        this.addFormField(this.requestData);
      }
    },
  },
  computed: { ...mapGetters(["currentOrdinal"]) },
};
</script>