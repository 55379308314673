<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.description"
      ></v-textarea>
      <v-select
        :items="specimenTypeCategories"
        item-text="title"
        filled
        return-object
        v-model="requestData.selectedSpecimenTypeCategory"
        :rules="[(v) => !!v || '*Required']"
        label="Specimen Type Category"
      ></v-select>
      <v-select
        :items="unitOfMeasurementTypes"
        item-text="title"
        filled
        return-object
        @change="getUnitOfMeasurements()"
        v-model="requestData.SelectedUnitOfMeasurementType"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement Type"
      ></v-select>
      <v-select
        :items="unitOfMeasurements"
        item-text="unitName"
        filled
        return-object
        v-model="requestData.SelectedUnitOfMeasurement"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement"
      ></v-select>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        type="number"
        required
        v-model="requestData.ordinal"
      ></v-text-field>
      <v-checkbox
        v-model="requestData.isDefault"
        label="Is Default"
      ></v-checkbox>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-specimen-type",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.requestData = this.selectedEditObject;
    console.log("this.requestData", this.requestData);
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/unitOfMeasurements/" +
          this.requestData.unitOfMeasurementID,
        null
      )
      .then((response) => {
        console.log("unitOfMeasurements: " + this.currentInstitution.id);
        this.requestData.SelectedUnitOfMeasurement = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/specimenTypeCategories/" +
          this.requestData.specimenTypeCategoryID,
        null
      )
      .then((response) => {
        this.requestData.SelectedSpecimenTypeCategory = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/specimenTypeCategories",
        null
      )
      .then((response) => {
        this.specimenTypeCategories = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/unitOfMeasurementTypes",
        null
      )
      .then((response) => {
        this.unitOfMeasurementTypes = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      specimenTypeCategories: [],
      unitOfMeasurementTypes: [],
      unitOfMeasurements: [],
      requestData: {
        title: "",
        abbreviation: "",
        description: "",
        ordinal: 0,
        isDefault: false,
        selectedInstitution: null,
        institutionId: null,
        SelectedSpecimenTypeCategory: null,
        specimenTypeCategoryId: null,
        SelectedUnitOfMeasurementType: null,
        unitOfMeasurementId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.UnitOfMeasurementId =
          this.requestData.SelectedUnitOfMeasurement.id;
        this.requestData.SpecimenTypeCategoryId =
          this.requestData.SelectedSpecimenTypeCategory.id;
        apiClient
          .call(
            "PUT",
            "institutions/" +
              this.currentInstitution.id +
              "/specimenTypeCategories/" +
              this.requestData.SelectedSpecimenTypeCategory.id +
              "/specimenTypes",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getAllSpecimenTypes");
          })
          .catch((err) => console.log(err));
      }
    },
    getUnitOfMeasurements() {
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/unitOfMeasurementTypes/" +
            this.requestData.SelectedUnitOfMeasurementType.id +
            "/unitOfMeasurements",
          null
        )
        .then((response) => {
          this.unitOfMeasurements = response;
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "selectedEditObject"]),
  },
};
</script>