<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form
      @submit.prevent
      novalidate="novalidate"
      style="width: 100%; height: 100%"
    >
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <div>
            <span
              class="
                Text-color--default
                Text-fontSize--14
                Text-fontWeight--medium
                Text-lineHeight--20
                Text-numericSpacing--proportional
                Text-typeface--base
                Text-wrap--wrap
                Text-display--inline
              "
              ><span>{{ selectedCollectionProtocolShipment.name }}</span></span
            >
            <div>
              <span style="font-weight: bold">From:</span>
              {{ selectedCollectionProtocolShipment.sendingInstitution.title }}
            </div>
          </div>
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <v-btn
                        v-if="!isSent"
                        color="primary mr-2"
                        small
                        @click="rejectCollectionProtocolShipmentItems()"
                        elevation="0"
                        >Reject
                      </v-btn>
                      <div
                        v-if="!isSent"
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="reciveCollectionProtocolShipmentItems()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Accept
                          </v-btn>
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <div>
                <span class="font-weight-bold">Shipped At: </span>
                {{
                  selectedCollectionProtocolShipment.shippedDate
                    | moment("calendar")
                }}
              </div>
              <div>
                <span class="font-weight-bold">Tracking Number: </span>
                {{ selectedCollectionProtocolShipment.trackingNumber }}
              </div>
              <div>
                <span class="font-weight-bold">Tracking URL: </span>
                {{ selectedCollectionProtocolShipment.trackingURL }}
              </div>
              <v-data-table
                style="padding-top: 10px"
                v-model="selectedResults"
                :headers="resultHeaders"
                :items="pendingCollectionProtocolShipmentItems"
                :single-select="false"
                item-key="id"
                :show-select="!isSent"
                hide-default-footer
                class="elevation-1"
              >
              </v-data-table>
              <div class="m-3">Received/Rejected</div>
              <v-data-table
                style="padding-top: 10px"
                v-model="selectedResults"
                :headers="resultHeaders"
                :items="receivedCollectionProtocolShipmentItems"
                :single-select="false"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "collection-protocol-shipment-detail",
  props: ["toggleForm", "selectedCollectionProtocolShipment", "isSent"],
  created() {
    // console.log("The id is: " + this.$route.params.id);
    this.getCollectionProtocolShipmentItemsByCollectionProtocolShipmentId();
  },
  data() {
    return {
      pendingCollectionProtocolShipmentItems: [],
      receivedCollectionProtocolShipmentItems: [],
      selectedResults: [],
      collectionProtocolShipmentItems: [],
      resultHeaders: [
        {
          text: "Sample ID",
          align: "start",
          value: "collectedSample.sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "collectedSample.specimenType.title",
        },
        {
          text: "Received",
          align: "start",
          value: "received",
        },
        {
          text: "Rejected",
          align: "start",
          value: "rejected",
        },
        // {
        //   text: "Actions",
        //   align: "start",
        //   value: "actions",
        // },
      ],
    };
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocol"]),
    ...mapActions([]),
    getCollectionProtocolShipmentItemsByCollectionProtocolShipmentId() {
      console.log(
        `institutions/${this.currentInstitution.id}/collectedSampleShipments/${this.selectedCollectionProtocolShipment.id}/collectedSampleShipmentItems`
      );
      apiClient
        .call(
          "GET",
          `institutions/${this.currentInstitution.id}/collectedSampleShipments/${this.selectedCollectionProtocolShipment.id}/collectedSampleShipmentItems`,
          null
        )
        .then((response) => {
          this.pendingCollectionProtocolShipmentItems = [];
          this.receivedCollectionProtocolShipmentItems = [];
          response.forEach((collectionProtocolShipmentItem) => {
            console.log(
              "collectionProtocolShipmentItem: " +
                collectionProtocolShipmentItem
            );
            if (
              !collectionProtocolShipmentItem.received &&
              !collectionProtocolShipmentItem.rejected
            ) {
              console.log("pushing....");
              this.pendingCollectionProtocolShipmentItems.push(
                collectionProtocolShipmentItem
              );
            } else {
              this.receivedCollectionProtocolShipmentItems.push(
                collectionProtocolShipmentItem
              );
            }
          });
          // this.collectionProtocolShipmentItems = response;
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    AddSelectedResults() {},
    reciveCollectionProtocolShipmentItems() {
      this.selectedResults.forEach((result) => {
        console.log("result::", result);
        // return;
        var reciveCollectionProtocolShipmentItem = {
          collectedSampleShipmentID: result.collectedSampleShipmentID,
          collectedSampleID: result.collectedSampleID,
          received: true,
          rejected: false,
          receivedDate: new Date().toISOString(),
        };
        apiClient
          .call(
            "POST",
            `institutions/${this.currentInstitution.id}/collectedSampleShipments/${this.selectedCollectionProtocolShipment.id}/collectedSampleShipmentItems/${result.id}/receive`,
            reciveCollectionProtocolShipmentItem
          )
          .then((response) => {
            console.log("received", response);
            this.getCollectionProtocolShipmentItemsByCollectionProtocolShipmentId();
          })
          .catch((err) => console.log(err));
      });
    },
    rejectCollectionProtocolShipmentItems() {
      this.selectedResults.forEach((result) => {
        console.log("result::", result);
        // return;
        var rejectCollectionProtocolShipmentItem = {
          collectedSampleShipmentID: result.collectedSampleShipmentID,
          collectedSampleID: result.collectedSampleID,
          received: false,
          rejected: true,
          receivedDate: new Date().toISOString(),
        };
        apiClient
          .call(
            "POST",
            `institutions/${this.currentInstitution.id}/collectedSampleShipments/${this.selectedCollectionProtocolShipment.id}/collectedSampleShipmentItems/${result.id}/reject`,
            rejectCollectionProtocolShipmentItem
          )
          .then((response) => {
            console.log("received", response);
            this.getCollectionProtocolShipmentItemsByCollectionProtocolShipmentId();
          })
          .catch((err) => console.log(err));
      });
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "currentCollectionProtocol",
      "collectionProtocolEventUnitOfMeasurements",
    ]),
    // receivedCollectionProtocolShipmentItems() {
    //   var tempreceivedCollectionProtocolShipmentItems = [];
    //   if (this.collectionProtocolShipmentItems) {
    //     this.collectionProtocolShipmentItems.forEach(
    //       (collectionProtocolShipmentItem) => {
    //         if (collectionProtocolShipmentItem.received) {
    //           tempreceivedCollectionProtocolShipmentItems.push(
    //             receivedCollectionProtocolShipmentItem
    //           );
    //         }
    //       }
    //     );
    //   }
    //   return tempreceivedCollectionProtocolShipmentItems;
    // },
    // pendingCollectionProtocolShipmentItems() {
    //   var temppendingCollectionProtocolShipmentItems = [];
    //   if (this.collectionProtocolShipmentItems) {
    //     this.collectionProtocolShipmentItems.forEach(
    //       (collectionProtocolShipmentItem) => {
    //         if (collectionProtocolShipmentItem.received) {
    //           temppendingCollectionProtocolShipmentItems.push(
    //             receivedCollectionProtocolShipmentItem
    //           );
    //         }
    //       }
    //     );
    //   }
    //   return temppendingCollectionProtocolShipmentItems;
    // },
  },
};
</script>