<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.title"
      ></v-text-field>

      <v-text-field
        dense
        label="QuantitySymbol"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.quantitySymbol"
      ></v-text-field>
      <v-text-field
        dense
        label="DimensionSymbol"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.dimensionSymbol"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.description"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.isDefault"
        label="Is Default"
      ></v-checkbox>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../EditPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { EditPage },
  name: "edit-unit-of-measurement-type",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.requestData = this.selectedEditObject;
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        title: "",
        quantitySymbol: "",
        dimensionSymbol: "",
        description: "",
        abbreviation: "Not right!",
        SelectedInstitution: null,
        institutionId: null,
        isDefault: false,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.institutionId = this.currentInstitution.id;
        apiClient
          .call(
            "PUT",
            "institutions/" +
              this.currentInstitution.id +
              "/unitOfMeasurementTypes/" +
              this.requestData.id,
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getUnitOfMeasurementTypes");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "selectedEditObject"]),
  },
};
</script>