<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Consents
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Consents</span></span
                >
                <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Consent</v-btn
                >
              </div>
              <v-data-table
                style="margin-top: 15px"
                :headers="headers"
                :loading="isLoading"
                :items="collectionProtocolConsents"
                ><template v-slot:item.actions="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="editItem(item)"
                        style="cursor: pointer"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="handleClick(item)"
                        style="cursor: pointer"
                      >
                        <v-list-item-title>View</v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title>Disable</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> </template
                ><template v-slot:item.enabledDate="{ item }">
                  {{ item.enabledDate | moment("YYYY-MM-DD") }} </template
                ><template v-slot:item.enablerAccount="{ item }">
                  <span v-if="item.enablerAccount"
                    >{{ item.enablerAccount.firstName }}
                    {{ item.enablerAccount.lastName }}</span
                  ></template
                ></v-data-table
              >
              <v-dialog v-model="dialog" max-width="600">
                <v-card v-if="selectedCollectionProtocolConsent">
                  <v-card-title class="text-h5">
                    <span>
                      {{ selectedCollectionProtocolConsent.title }}
                    </span>
                  </v-card-title>
                  <hr />

                  <v-card-text
                    v-html="selectedCollectionProtocolConsent.consent"
                  >
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-collection-protocol-consent
            selectedNavItem="Consent"
            :toggleForm="toggleForm"
          />
        </div>
        <div
          v-if="showEditForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <edit-collection-protocol-consent
            selectedNavItem="Consent"
            :collectionProtocolConsent="selectedCollectionProtocolConsent"
            :toggleForm="toggleEditForm"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolConsent from "../../components/AddForms/AddCollectionProtocolConsent.vue";
import { mapGetters, mapActions } from "vuex";
import EditCollectionProtocolConsent from "../../components/EditForms/EditCollectionProtocolConsent.vue";
export default {
  components: { AddCollectionProtocolConsent, EditCollectionProtocolConsent },
  name: "collection-protocol-consents",
  created() {
    this.getCollectionProtocolConsents();
  },
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
      dialog: false,
      selectedCollectionProtocolConsent: null,
      headers: [
        {
          text: "Consent",
          align: "start",
          value: "title",
        },
        { text: "Created Date", value: "enabledDate" },
        { text: "Created By", value: "enablerAccount" },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolConsents"]),
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    toggleEditForm: function () {
      this.showEditForm = !this.showEditForm;
    },
    editItem: function (item) {
      this.selectedCollectionProtocolConsent = item;
      this.showEditForm = true;
      // console.log("item:::", item);
    },
    handleClick(value) {
      this.selectedCollectionProtocolConsent = value;
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolConsents",
      "currentCollectionProtocol",
      "isLoading",
    ]),
  },
};
</script>