<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="unitOfMeasurementTypes"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedUnitOfMeasurementType"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement Type"
      ></v-select>
      <v-text-field
        dense
        label="Unit Name"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.UnitName"
      ></v-text-field>

      <v-text-field
        dense
        label="Unit Symbol"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.UnitSymbol"
      ></v-text-field>
      <v-text-field
        dense
        label="Code"
        :rules="[
          (v) => (v || '').length <= 2 || 'Code must be 2 characters or less',
        ]"
        counter="2"
        filled
        required
        v-model="requestData.Code"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>

      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-unit-of-measurement",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    // apiClient
    //   .call(
    //     "GET",
    //     "institutions/" +
    //       this.currentInstitution.id +
    //       "/unitOfMeasurementTypes",
    //     null
    //   )
    //   .then((response) => {
    //     console.log("unitOfMeasurementTypes: " + this.currentInstitution.id);
    //     this.unitOfMeasurementTypes = response;
    //   })
    //   .catch((err) => console.log(err));
    this.getUnitOfMeasurementTypes();
  },
  data() {
    return {
      valid: false,
      requestData: {
        UnitName: "",
        UnitSymbol: "",
        Code: "",
        Description: "",
        SelectedInstitution: null,
        InstitutionId: null,
        SelectedUnitOfMeasurementType: null,
        UnitOfMeasurementTypeId: null,
      },
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getUnitOfMeasurementTypes"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.UnitOfMeasurementTypeId =
          this.requestData.SelectedUnitOfMeasurementType.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/UnitOfMeasurementTypes/" +
              this.requestData.SelectedUnitOfMeasurementType.id +
              "/unitOfMeasurements",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getUnitOfMeasurements");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "unitOfMeasurementTypes"]),
  },
};
</script>