<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        label="Abbreviation"
        :rules="[
          (v) => !!v || '*required',
          (v) => (v || '').length <= 4 || 'Code must be 4 characters or less',
        ]"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        label="Address"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.Address"
      ></v-textarea>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
export default {
  components: { AddPage },
  name: "add-institution",
  props: ["selectedNavItem", "toggleForm"],
  data() {
    return {
      valid: false,
      requestData: {
        Title: "",
        Abbreviation: "",
        Address: "",
      },

      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log("seems about right");
        // console.log(this.requestData);
        apiClient
          .call("POST", "institutions", this.requestData)
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getInstitutions");
          })
          .catch((err) => console.log(err));
      }
    },
    validate() {
      this.$refs.form.validate();
      console.log("this.$refs.form.validate(): " + this.$refs.form.validate());
      console.log("Valid: " + this.valid);
      if (this.valid) {
        console.log("seems about right");
        // console.log(this.requestData);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>