<template>
  <div style="margin-top: 15px">
    <!-- Search result page -->
    <v-data-table
      :headers="headers"
      :items="limsOrders"
      class="elevation-0"
      :loading="isLoading"
      @click:row="handleClick"
      ><template v-slot:item.patient="{ item }">
        {{ item.patient.firstName }} {{ item.patient.fatherName }}
      </template>
      <template v-slot:item.collectedSampleID="{ item }">
        <!-- {{ item }} -->
        <!-- {{ item.collectedSampleID }} -->
        {{ item.collectedSampleID }}
        <!-- {{ item.patient.firstName }} {{ item.patient.fatherName }} -->
      </template>
    </v-data-table>
    <!-- <div>{{ limsOrders }}</div> -->
  </div>
</template></v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TablePage from "../../components/TablePage.vue";
export default {
  components: { TablePage },
  name: "orders-search-result",
  created() {
    console.log("this.limsOrders", this.limsOrders);
  },
  data() {
    return {
      headers: [
        {
          text: "Patient",
          align: "start",
          value: "patient",
        },
        {
          text: "Patient MRN",
          align: "start",
          value: "patientMRN",
        },
        { text: "Sample ID", value: "sampleID" },
        { text: "Collected Sample ID", value: "collectedSample.sampleID" },
        { text: "Ordered Date", value: "createdDate" },
        { text: "Ordered Panels", value: "collectedSample.sampleID" },
      ],
      items: [],
    };
  },
  methods: {
    handleClick(value) {
      this.$router
        .push({
          name: "OrderTestResult",
          query: { sampleId: value.sampleID },
        })
        .catch(() => {});
      console.log(value.name);
    },
  },
  computed: {
    ...mapGetters(["limsOrders", "isLoading"]),
  },
};
</script>