<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Unit Name"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.UnitName"
      ></v-text-field>

      <v-text-field
        dense
        label="Unit Symbol"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.UnitSymbol"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>
      <v-text-field
        dense
        label="To Minute Conversion Rate"
        :rules="NoEmptyRule"
        type="number"
        filled
        required
        v-model="requestData.ToMinuteConversionRate"
      ></v-text-field>
      <!-- <v-select
        :items="institutions"
        item-text="UnitName"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-collection-protocol-event-uniti-of-measurement",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        UnitName: "",
        UnitSymbol: "",
        Description: "",
        ToMinuteConversionRate: 0,
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocolEventUnitOfMeasurements",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch(
              "getCollectionProtocolEventUnitOfMeasurements"
            );
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>