var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"page":1,"pageCount":180,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
    },"hide-default-footer":_vm.hasActions},on:{"update:options":_vm.updateOptions,"update:pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mt-4",staticStyle:{"width":"450px"},attrs:{"label":"Search","outlined":"","hint":"press 'Enter' to search","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})]},proxy:true},{key:"item.createdDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdDate,"YYYY-MM-DD"))+" ")]}},{key:"item.enabledDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.enabledDate,"YYYY-MM-DD"))+" ")]}},{key:"item.creatorAccount",fn:function(ref){
    var item = ref.item;
return [(item.creatorAccount)?_c('span',[_vm._v(_vm._s(item.creatorAccount.firstName)+" "+_vm._s(item.creatorAccount.lastName))]):_vm._e()]}},{key:"item.enablerAccount",fn:function(ref){
    var item = ref.item;
return [(item.enablerAccount)?_c('span',[_vm._v(_vm._s(item.enablerAccount.firstName)+" "+_vm._s(item.enablerAccount.lastName))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.dblclickg(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Disable")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }