<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="formfield.value"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formfield.value"
        outlined
        dense
        label="Picker in menu"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="formfield.value"
      full-width
      @click:minute="$refs.menu.save(formfield.value)"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  name: "custom-time-picker",
  props: ["formfield"],
  created() {
    console.log("this.formfield", this.formfield);
  },
  data() {
    return {
      menu2: false,
    };
  },
};
</script>