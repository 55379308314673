<template>
  <div class="db-ChromeMainContent">
    <div tabindex="-1" class="db-ChromeMainContent-content">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><router-link
          :to="
            '/collection-protocols/' +
            currentCollectionProtocol.id +
            '/collectio-protocol-forms'
          "
          >Forms</router-link
        ><span style="padding: 5px">/</span
        >{{ currentCollectionProtocolabc.name }}
      </div>
      <v-app>
        <v-main>
          <div class="db-CardlessUIPageWrapper db-CustomerDetailPage">
            <div style="display: flex">
              <div
                class="db-TwoColumnLayout--leftColumn"
                style="min-width: 240px; max-width: 320px; flex: 0 0 30%"
              >
                <div style="margin-top: 0px"></div>
                <div style="align-self: flex-start; position: sticky">
                  <div class="Box-root Padding-bottom--48">
                    <div class="Box-root Padding-bottom--20">
                      <div
                        class="Card-root Card--radius--all Section Box-root Box-background--white"
                        style="overflow: visible"
                      >
                        <div
                          class="Box-root Padding-top--20 Padding-bottom--12 Padding-horizontal--0"
                        >
                          <div
                            class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                            style="position: relative; min-width: 0px"
                          >
                            <div
                              class="TextAligner Box-root"
                              style="
                                line-height: 32px;
                                font-size: 28px;
                                flex: 0 0 auto;
                              "
                            ></div>
                            <div
                              class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--spaceBetween"
                              style="
                                min-width: 0px;
                                line-height: 0;
                                flex: 1 1 auto;
                              "
                            >
                              <div
                                class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-wrap--wrap"
                                style="flex: 1 1 auto; min-width: 0px"
                              >
                                <div
                                  class="Box-root Padding-right--8 Padding-bottom--8"
                                  style="pointer-events: none; min-width: 0px"
                                >
                                  <div
                                    class="Box-root Flex-flex Flex-direction--column Flex-justifyContent--flexStart Flex-wrap--nowrap"
                                    style="margin-left: -4px; margin-top: -4px"
                                  >
                                    <div
                                      class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4"
                                      style="pointer-events: auto"
                                    >
                                      <div
                                        class="Box-root"
                                        style="pointer-events: none"
                                      >
                                        <div
                                          class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                                          style="
                                            margin-left: -8px;
                                            margin-top: -8px;
                                          "
                                        >
                                          <div
                                            class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                                            style="pointer-events: auto"
                                          >
                                            <span
                                              class="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wordBreak--word Text-wrap--wrap Text-display--block"
                                              style="margin-top: -1px"
                                            >
                                              {{
                                                currentCollectionProtocolabc.name
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4"
                                      style="pointer-events: auto"
                                    >
                                      <span
                                        class="Text-color--gray Text-fontSize--16 Text-fontWeight--regular Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block"
                                        ><span
                                          class="Text-color--disabled Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                                          ><span
                                            >{{
                                              currentCollectionProtocolabc.description
                                            }}
                                          </span></span
                                        ></span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <v-btn color="primary" @click="populate"
                          >Populate</v-btn
                        > -->
                        <div
                          class="Box-root Padding-top--16 Flex-flex Flex-direction--row"
                          style="min-height: 150px"
                        >
                          <div>
                            <v-btn
                              rounded
                              @click="toggleForm('SingleLine')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Single Line</v-btn
                            ><v-btn
                              rounded
                              dense
                              @click="toggleForm('MultiLine')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Multi Line</v-btn
                            ><v-btn
                              rounded
                              dense
                              @click="toggleForm('TimePicker')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Time Picker</v-btn
                            ><v-btn
                              rounded
                              @click="toggleForm('DatePicker')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Date Picker</v-btn
                            ><v-btn
                              rounded
                              @click="toggleForm('DateTimePicker')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Date & Time Picker</v-btn
                            ><v-btn
                              rounded
                              dense
                              @click="toggleForm('Checkbox')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Checkbox</v-btn
                            ><v-btn
                              rounded
                              dense
                              elevation="0"
                              small
                              @click="toggleForm('CheckboxList')"
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Checkbox List</v-btn
                            ><v-btn
                              rounded
                              dense
                              elevation="0"
                              small
                              @click="toggleForm('RadioList')"
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Radio List</v-btn
                            ><v-btn
                              rounded
                              dense
                              @click="toggleForm('Select')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Select</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="db-TwoColumnLayout--rightColumn Box-root"
                style="flex-grow: 1"
              >
                <div class="Box-root Padding-top--24 Padding-bottom--48">
                  <div
                    class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween"
                  >
                    <div>
                      <!-- {{ collectionProtocolFormFields }} -->
                      <v-tabs v-model="tab" style="width: 600px">
                        <v-tab> Editor </v-tab>
                        <v-tab> Preview </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tab" style="margin-top: 20px">
                        <v-tab-item>
                          <div
                            style="
                              min-height: 300px;
                              width: 600px;
                              padding: 0px;
                            "
                          >
                            <div
                              v-for="(
                                formfield, index
                              ) in collectionProtocolFormFields"
                              style="
                                border-left-width: 5px;
                                border-top-width: 0px;
                                border-right-width: 0px;
                                border-bottom-width: 1px;
                                border-color: #bbdefb;
                                border-style: solid;
                              "
                              class="outer"
                              :key="index"
                            >
                              <div
                                style="
                                  height: 25px;
                                  width: 25px;
                                  vertical-align: middle;
                                  text-align: center;
                                  background: #bbdefb;
                                  line-height: 25px;
                                "
                              >
                                {{ formfield.ordinal }}
                              </div>
                              <!-- {{ formfield }} -->
                              <div
                                v-if="formfield.dataType"
                                class="top"
                                style="
                                  min-height: 120px;
                                  margin: 0px 20px;
                                  padding: 0px 15px;
                                "
                              >
                                <!-- isEnabled: {{ formfield.isEnabled }} -->
                                <div style="padding: 10px 0px">
                                  {{ formfield.prompt }}
                                </div>
                                <v-text-field
                                  dense
                                  v-if="formfield.dataType.abbreviation == 'TB'"
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  outlined
                                ></v-text-field>
                                <v-textarea
                                  outlined
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  rows="2"
                                  v-if="formfield.dataType.abbreviation == 'TA'"
                                ></v-textarea>
                                <custom-time-picker
                                  v-if="formfield.dataType.abbreviation == 'TP'"
                                  :formfield="formfield"
                                />
                                <custom-date-picker
                                  v-if="formfield.dataType.abbreviation == 'DP'"
                                  :formfield="formfield"
                                />
                                <custom-date-time-picker
                                  v-if="
                                    formfield.dataType.abbreviation == 'DTP'
                                  "
                                  :formfield="formfield"
                                >
                                </custom-date-time-picker>
                                <!-- <custom-date-picker
                                  v-if="
                                    formfield.dataType.abbreviation == 'DTP'
                                  "
                                  :formfield="formfield"
                                /> -->

                                <v-container
                                  class="px-0"
                                  fluid
                                  v-if="formfield.dataType.abbreviation == 'CB'"
                                >
                                  <v-checkbox
                                    v-if="
                                      formfield.dataType.abbreviation == 'CB'
                                    "
                                    :label="formfield.prompt"
                                  ></v-checkbox>
                                </v-container>

                                <div
                                  v-if="
                                    formfield.dataType.abbreviation == 'CBL'
                                  "
                                  :class="{
                                    'd-flex': formfield.horizontalView,
                                  }"
                                >
                                  <v-checkbox
                                    class="ml-2"
                                    style="margin: 0px; padding: 0px"
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                    :label="option.value"
                                  ></v-checkbox>
                                </div>
                                <div
                                  v-if="formfield.dataType.abbreviation == 'RL'"
                                >
                                  <v-radio-group>
                                    <v-radio
                                      v-for="(
                                        option, index
                                      ) in formfield.Options"
                                      :key="index"
                                      :label="option.value"
                                    ></v-radio>
                                  </v-radio-group>
                                  <!-- <a-radio-group :options="formfield.Options" /> -->
                                </div>
                                <v-select
                                  v-if="formfield.dataType.abbreviation == 'SL'"
                                  :items="formfield.Options"
                                  item-text="value"
                                  :label="formfield.prompt"
                                  outlined
                                  dense
                                ></v-select>
                              </div>
                              <div
                                @click="editFormField(formfield)"
                                class="below"
                                style="
                                  background: white;
                                  min-height: 120px;
                                  width: 100%;
                                  opacity: 0.1;
                                "
                              ></div>
                            </div>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <!-- editor preview -->
                          <div
                            style="
                              min-height: 300px;
                              width: 600px;
                              padding: 10px;
                            "
                          >
                            <div
                              v-for="(
                                formfield, index
                              ) in collectionProtocolFormFields"
                              style=""
                              :key="index"
                            >
                              <div
                                v-if="formfield.dataType"
                                class="top"
                                style="margin: 0px 20px; padding: 0px 15px"
                              >
                                <div style="padding: 10px 0px">
                                  {{ formfield.prompt }}
                                </div>

                                <v-text-field
                                  dense
                                  v-if="formfield.dataType.abbreviation == 'TB'"
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  outlined
                                ></v-text-field>
                                <v-textarea
                                  outlined
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  rows="2"
                                  v-if="formfield.dataType.abbreviation == 'TA'"
                                ></v-textarea>
                                <custom-time-picker
                                  v-if="formfield.dataType.abbreviation == 'TP'"
                                  :formfield="formfield"
                                />

                                <custom-date-picker
                                  v-if="formfield.dataType.abbreviation == 'DP'"
                                  :formfield="formfield"
                                />

                                <!-- <custom-date-picker
                                  v-if="
                                    formfield.dataType.abbreviation == 'DTP'
                                  "
                                  :formfield="formfield"
                                /> -->
                                <custom-date-time-picker
                                  v-if="
                                    formfield.dataType.abbreviation == 'DTP'
                                  "
                                  :formfield="formfield"
                                >
                                </custom-date-time-picker>
                                <v-container
                                  class="px-0"
                                  fluid
                                  v-if="formfield.dataType.abbreviation == 'CB'"
                                >
                                  <v-checkbox
                                    v-if="
                                      formfield.dataType.abbreviation == 'CB'
                                    "
                                    label="Checkbox 1"
                                  ></v-checkbox>
                                </v-container>
                                <div
                                  v-if="
                                    formfield.dataType.abbreviation == 'CBL'
                                  "
                                  :class="{
                                    'd-flex': formfield.horizontalView,
                                  }"
                                >
                                  <v-checkbox
                                    style="margin: 0px; padding: 0px"
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                    :label="option.value"
                                  ></v-checkbox>
                                </div>
                                <div
                                  v-if="formfield.dataType.abbreviation == 'RL'"
                                >
                                  <v-radio-group>
                                    <v-radio
                                      v-for="(
                                        option, index
                                      ) in formfield.Options"
                                      :key="index"
                                      :label="option.value"
                                    ></v-radio>
                                  </v-radio-group>
                                  <!-- <a-radio-group :options="formfield.Options" /> -->
                                </div>
                                <!-- <div v-if="formfield.Options">
                                  <div
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                  >
                                    {{ option.value }}
                                  </div>
                                </div> -->
                                <v-select
                                  v-if="formfield.dataType.abbreviation == 'SL'"
                                  :items="formfield.Options"
                                  :label="formfield.prompt"
                                  item-text="value"
                                  outlined
                                  dense
                                ></v-select>
                              </div>

                              <!-- <div>{{ formfield }}</div> -->
                              <!-- <div>{{ index }}</div> -->
                            </div>
                          </div>
                        </v-tab-item>
                      </v-tabs-items>
                    </div>

                    <div class="Box-root Padding-bottom--4">
                      <div class="Box-root Flex-flex">
                        <div class="Box-root Flex-flex">
                          <div
                            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                            style="background-color: rgb(84, 105, 212)"
                          >
                            <div class="PressableCore-base Box-root"></div>
                            <div
                              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showAddForm"
            class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
          >
            <component
              :is="component"
              :dataTypes="dataTypes"
              :selectedNavItem="selectedFormField"
              :toggleForm="toggleForm"
              :addFormField="addFormField"
            />
          </div>
          <div
            v-if="showEditForm"
            class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
          >
            <component
              :is="component"
              :dataTypes="dataTypes"
              :selectedNavItem="selectedFormField"
              :toggleForm="toggleEditForm"
              :addFormField="addFormField"
              :formField="selectedFormFieldForEdit"
              :removeOption="removeOption"
            />
          </div>
        </v-main>
      </v-app>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CustomDatePicker from "../../components/CustomComponents/CustomDatePicker.vue";
import CustomDateTimePicker from "../../components/CustomComponents/CustomDateTimePicker.vue";
import CustomTimePicker from "../../components/CustomComponents/CustomTimePicker.vue";
import AddCheckbox from "../../components/DynamicFormFields/AddCheckbox.vue";
import AddCheckboxList from "../../components/DynamicFormFields/AddCheckboxList.vue";
import AddDatePicker from "../../components/DynamicFormFields/AddDatePicker.vue";
import AddDateTimePicker from "../../components/DynamicFormFields/AddDateTimePicker.vue";
import AddMultiLine from "../../components/DynamicFormFields/AddMultiLine.vue";
import AddRadioList from "../../components/DynamicFormFields/AddRadioList.vue";
import AddSelect from "../../components/DynamicFormFields/AddSelect.vue";
import AddSingleLine from "../../components/DynamicFormFields/AddSingleLine.vue";
import AddTimePicker from "../../components/DynamicFormFields/AddTimePicker.vue";
import EditCheckbox from "../../components/DynamicFormFields/Edit/EditCheckbox.vue";
import EditCheckboxList from "../../components/DynamicFormFields/Edit/EditCheckboxList.vue";
import EditDatePicker from "../../components/DynamicFormFields/Edit/EditDatePicker.vue";
import EditDateTimePicker from "../../components/DynamicFormFields/Edit/EditDateTimePicker.vue";
import EditMultiLine from "../../components/DynamicFormFields/Edit/EditMultiLine.vue";
import EditRadioList from "../../components/DynamicFormFields/Edit/EditRadioList.vue";
import EditSelect from "../../components/DynamicFormFields/Edit/EditSelect.vue";
import EditSingleLine from "../../components/DynamicFormFields/Edit/EditSingleLine.vue";
import EditTimePicker from "../../components/DynamicFormFields/Edit/EditTimePicker.vue";
import apiClient from "../../API/apiClient";
export default {
  components: {
    CustomTimePicker,
    CustomDatePicker,
    CustomDateTimePicker,
    AddSingleLine,
    AddCheckbox,
    AddCheckboxList,
    AddDatePicker,
    AddDateTimePicker,
    AddTimePicker,
    AddMultiLine,
    AddRadioList,
    AddSelect,
    EditSingleLine,
    EditCheckbox,
    EditCheckboxList,
    EditDatePicker,
    EditDateTimePicker,
    EditTimePicker,
    EditMultiLine,
    EditRadioList,
    EditSelect,
  },
  created() {
    this.getCollectionProtocolFormByFormId(this.$route.params.formId);
    this.getCollectionProtocolFormFields(this.$route.params.formId);
    // console.log(
    //   "currentCollectionProtocolabc",
    //   this.currentCollectionProtocolabc
    // );
    // console.log("editor-loaded: ", this.collectionProtocolFormFields);
    apiClient
      .call(
        "GET",
        "institutions/" + this.currentInstitution.id + "/dataTypes",
        null
      )
      .then((response) => {
        console.log(response);
        this.dataTypes = response;
        response.forEach((res) => {
          console.log("res.title:::", res.abbreviation, res.title);
        });
      })
      .catch((err) => console.log(err));
    // console.log("____________________________________________________");
    // console.log(this.currentCollectionProtocolabc);
    // console.log(this.$route.params.formId);
    // console.log("____________________________________________________");
  },
  name: "collection-protocol-form-editor",
  data() {
    return {
      dataTypes: [],
      component: "",
      selectedFormField: "",
      tab: null,
      showAddForm: false,
      showEditForm: false,
      selectedFormFieldForEdit: null,
      form: {
        title: "Form Title 1",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        formfields: [],
      },
    };
  },
  methods: {
    ...mapActions([
      "getCollectionProtocolFormByFormId",
      "getCollectionProtocolFormFields",
    ]),
    toggleForm: function (formName = "none") {
      // console.log("toggleForm", formName);
      this.component = "Add" + formName;
      if (this.collectionProtocolFormFields) {
        // console.log(
        //   "set collectionProtocolFormFields",
        //   this.collectionProtocolFormFields.length
        // );
        this.$store.commit(
          "setCurrentOrdinal",
          this.collectionProtocolFormFields.length + 1
        );
      }
      // console.log(this.se);
      const text = formName;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      this.selectedFormField = finalResult;
      this.showAddForm = !this.showAddForm;
    },
    addFormField: function (requestData, isAdd = true, disable = false) {
      console.log("requestData, isAdd:", requestData, isAdd);
      // return;
      if (isAdd) {
        requestData.dataTypeId = requestData.dataType.id;
        apiClient
          .call(
            "POST",
            `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${this.currentCollectionProtocolabc.id}/collectionProtocolFormFields`,
            requestData
          )
          .then((response) => {
            console.log(response);
            console.log(requestData);
            console.log(requestData.dataType.abbreviation);
            if (
              requestData.dataType.abbreviation.toLowerCase() == "cbl" ||
              requestData.dataType.abbreviation.toLowerCase() == "rl" ||
              requestData.dataType.abbreviation.toLowerCase() == "sl"
            ) {
              var count = 0;
              var optionsCount = requestData.Options.length;
              for (const index in requestData.Options) {
                var newFormFieldField = {
                  value: requestData.Options[index],
                  ordinal: index,
                };
                // console.log();
                apiClient
                  .call(
                    "POST",
                    `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${this.currentCollectionProtocolabc.id}/collectionProtocolFormFields/${response.id}/collectionProtocolFormFieldOptions`,
                    newFormFieldField
                  )
                  .then((response1) => {
                    count = count + 1;
                    console.log(response1);
                    if (count == optionsCount) {
                      this.showAddForm = !this.showAddForm;
                      this.$store.dispatch(
                        "getCollectionProtocolFormFields",
                        this.$route.params.formId
                      );
                    }
                  })
                  .catch((err) => console.log(err));
              }
            } else {
              this.showAddForm = !this.showAddForm;
              this.$store.dispatch(
                "getCollectionProtocolFormFields",
                this.$route.params.formId
              );
            }
          })
          .catch((err) => console.log(err));
      } else {
        console.log("saving the edit....");
        if (disable) {
          console.log("disabling...............");
          apiClient
            .call(
              "POST",
              `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${this.currentCollectionProtocolabc.id}/collectionProtocolFormFields/` +
                this.selectedFormFieldForEdit.id +
                "/disable",
              requestData
            )
            .then((response) => {
              console.log("disabled!!");
              this.showEditForm = !this.showEditForm;
              this.$store.dispatch(
                "getCollectionProtocolFormFields",
                this.$route.params.formId
              );
            })
            .catch((err) => console.log(err));
        } else {
          // return;
          apiClient
            .call(
              "PUT",
              `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${this.currentCollectionProtocolabc.id}/collectionProtocolFormFields/` +
                this.selectedFormFieldForEdit.id,
              requestData
            )
            .then((response) => {
              console.log("Updated::::::::____________", response);
              console.log("this.requestData.Options", requestData.Options);
              requestData.Options.forEach((option) => {
                console.log("option:::::::", option);
                if (option.isEdited) {
                  apiClient
                    .call(
                      "PUT",
                      `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${this.currentCollectionProtocolabc.id}/collectionProtocolFormFields/` +
                        this.selectedFormFieldForEdit.id +
                        "/collectionProtocolFormFieldOptions/" +
                        option.id,
                      option
                    )
                    .then((updatedOption) => {
                      console.log("updated option....", updatedOption);
                    })
                    .catch((err) => console.log(err));
                }
              });

              this.showEditForm = false;
              this.$store.dispatch(
                "getCollectionProtocolFormFields",
                this.$route.params.formId
              );
            })
            .catch((err) => console.log(err));
        }
      }
    },
    editFormField(item) {
      var formName = "";
      if (item.dataType.abbreviation == "TB") {
        formName = "SingleLine";
      } else if (item.dataType.abbreviation == "TA") {
        formName = "MultiLine";
      } else if (item.dataType.abbreviation == "DP") {
        formName = "DatePicker";
      } else if (item.dataType.abbreviation == "DTP") {
        formName = "DateTimePicker";
      } else if (item.dataType.abbreviation == "CB") {
        formName = "Checkbox";
      } else if (item.dataType.abbreviation == "CBL") {
        formName = "CheckboxList";
      } else if (item.dataType.abbreviation == "RL") {
        formName = "RadioList";
      } else if (item.dataType.abbreviation == "TP") {
        formName = "TimePicker";
      } else if (item.dataType.abbreviation == "SL") {
        formName = "Select";
      }
      this.selectedFormFieldForEdit = item;
      this.component = "Edit" + formName;
      // console.log(this.se);
      const text = formName;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      this.selectedFormField = finalResult;
      this.showEditForm = !this.showEditForm;
      console.log(":::::::::item:::::::::", item);
    },
    toggleEditForm() {
      this.showEditForm = false;
    },
    removeOption(option) {
      console.log("option::::", option);
      console.log(this.currentCollectionProtocolabc);
      // return;
      option.collectionProtocolFormId = this.currentCollectionProtocolabc.id;
      console.log(
        "option.collectionProtocolFormId::",
        option.collectionProtocolFormId
      );
      // return;
      // return;
      apiClient
        .call(
          "POST",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolForms/${option.collectionProtocolFormId}/collectionProtocolFormFields/${this.selectedFormFieldForEdit.id}/collectionProtocolFormFieldOptions/${option.id}/disable`,
          option
        )
        .then((response) => {
          console.log("disable", response);
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    populate() {
      let questions = [
        // "Fullness (Right breast inspection)",
        // "Abnormal discoloration (Right breast inspection)",
        "Venous distention (Right breast inspection)",
        "Rashes (Right breast inspection)",
        "Visible lump (Right breast inspection)",
        "Retraction (Right breast inspection)",
        "Edema (Right breast inspection)",
        "Axillary mass (Right breast inspection)",
        "Scaly nipple (Right breast inspection)",
        "Fullness (Left breast inspection)",
        "Abnormal discoloration (Left breast inspection)",
        "Venous distention (Left breast inspection)",
        "Rashes (Left breast inspection)",
        "Visible lump (Left breast inspection)",
        "Retraction (Left breast inspection)",
        "Edema (Left breast inspection)",
        "Axillary mass (Left breast inspection)",
        "Scaly nipple (Left breast inspection)",
      ];

      const datas = questions.map((question, index) => {
        return {
          prompt: question,
          placeholder: "none",
          responseTitle: question,
          ordinal: 50,
          onNewLine: true,
          required: false,
          requiredMessage: "",
          verticalView: true,
          horizontalView: false,
          hasValidation: false,
          regularExpression: "",
          regularExpressionMessage: "",
          dataType: {
            id: "9a9ad905-77bd-4a26-953f-3f388b67fb93",
            institutionID: "8aa9ca85-c800-4d8f-aceb-9fdc683c13c3",
            institution: null,
            title: "RadioList",
            abbreviation: "RL",
            description: "RadioList",
            hasValidation: false,
            dataTypeValidation: "",
            informationUpdated: false,
            createdDate: "0001-01-01T00:00:00",
            creatorAccountID: "00000000-0000-0000-0000-000000000000",
            creatorAccount: null,
            createReason: null,
            concurrencyStamp: "cc7de96b-0617-486b-b923-90a74a392cba",
          },
          Options: ["Yes", "No"],
          dataTypeId: "9a9ad905-77bd-4a26-953f-3f388b67fb93",
        };
      });
      datas.forEach((data) => {
        this.addFormField(data);
      });
    },
  },
  computed: {
    ...mapGetters([
      "currentCollectionProtocolabc",
      "currentInstitution",
      "currentCollectionProtocol",
      "collectionProtocolFormFields",
    ]),
  },
};
</script>
<style>
.stacked {
  position: relative;
  min-height: 80px;
}

.outer {
  display: grid;
  grid-template: 1fr / 1fr;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .below {
  z-index: 2;
}
.outer .top {
  z-index: 1;
}
</style>
