<template>
  <div class="db-ChromeMainContent-content" tabindex="-1">
    <div class="Box-root">
      <div
        class="
          Card-root Card--radius--all
          Section
          Box-root
          Box-background--white
        "
        style="overflow: visible"
      >
        <div class="ListView ListView--withoutFooter">
          <div
            class="
              Box-root
              Padding-top--16
              Padding-bottom--8
              Flex-flex
              Flex-alignItems--center
              Flex-justifyContent--spaceBetween
            "
          >
            <span
              class="
                Text-color--dark
                Text-fontSize--28
                Text-fontWeight--bold
                Text-lineHeight--32
                Text-numericSpacing--proportional
                Text-typeface--base
                Text-wrap--wrap
                Text-display--inline
              "
              ><span>{{ selectedNavItem }}</span></span
            >
            <div class="Box-root" style="pointer-events: none">
              <div
                class="
                  Box-root
                  Flex-flex
                  Flex-direction--row
                  Flex-justifyContent--flexStart
                  Flex-wrap--nowrap
                "
                style="margin-left: -8px; margin-top: -8px"
              >
                <div
                  class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                  style="pointer-events: auto"
                >
                  <div
                    class="
                      PressableCore
                      PressableCore--cursor--pointer
                      PressableCore--height--medium
                      PressableCore--radius--all
                      PressableCore--width
                      PressableCore--width--auto
                      PressableButton
                      Button Button--color--blue
                      Box-root
                      Flex-inlineFlex
                    "
                    style="
                      background-color: rgb(84, 105, 212);
                      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
                    "
                  >
                    <div class="PressableCore-base Box-root">
                      <a
                        href="#"
                        class="
                          UnstyledLink
                          Button-element
                          PressableContext
                          Padding-horizontal--8
                          Padding-vertical--4
                          PressableContext--cursor--pointer
                          PressableContext--display--inlineFlex
                          PressableContext--fontLineHeight--20
                          PressableContext--fontSize--14
                          PressableContext--fontWeight--medium
                          PressableContext--height
                          PressableContext--height--medium
                          PressableContext--radius--all
                          PressableContext--width
                          PressableContext--width--auto
                        "
                        style="color: rgb(255, 255, 255)"
                        ><div
                          class="
                            Button-align
                            Box-root
                            Flex-flex
                            Flex-alignItems--baseline
                            Flex-direction--row
                          "
                          style="position: relative"
                        >
                          <div
                            class="TextAligner Box-root"
                            style="
                              line-height: 20px;
                              font-size: 14px;
                              flex: 0 0 auto;
                            "
                          ></div>
                          <div
                            class="
                              Box-root
                              Flex-flex
                              Flex-alignItems--baseline
                              Flex-direction--row
                              Flex-justifyContent--center
                            "
                            style="width: 100%; line-height: 0; flex: 1 1 auto"
                          >
                            <span
                              class="
                                Button-label
                                Text-color--white
                                Text-fontSize--14
                                Text-fontWeight--medium
                                Text-lineHeight--20
                                Text-numericSpacing--proportional
                                Text-typeface--base
                                Text-wrap--noWrap
                                Text-display--block
                              "
                              style="margin-top: -1px"
                              ><span>Generate</span></span
                            >
                          </div>
                        </div></a
                      >
                    </div>
                    <div
                      class="
                        PressableCore-overlay PressableCore-overlay--extendBy1
                        Box-root
                        Box-background--white
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <v-app style="min-height: unset !important">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="max-height: 40px; width: 250px"
                    v-model="dateRangeText"
                    label="Date Range"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker range v-model="dates" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <div style="margin-top: 10px">
                <slot />
              </div>
            </v-app>
          </div>

          <!-- <table class="tablex">
            <tbody class="tablex-body"></tbody>
          </table>
          <div
            class="
              db-DashboardEmptyState
              Box-root
              Flex-flex
              Flex-alignItems--center
              Flex-justifyContent--center
            "
          >
            <div class="db-DashboardEmptyState--inner Box-root">
              <div
                class="
                  Card-root Card--radius--all
                  Box-root Box-hideIfEmpty
                  Box-background--white
                "
                style="display: inline-block"
              >
                <div class="Box-root Box-background--offset Padding-all--12">
                  <div
                    aria-hidden="true"
                    class="
                      SVGInline SVGInline--cleaned
                      SVG
                      Icon Icon--configurationItem Icon-color Icon-color--gray
                      Box-root
                      Flex-flex
                    "
                  >
                    <svg
                      aria-hidden="true"
                      height="24"
                      width="24"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      class="
                        SVGInline-svg SVGInline--cleaned-svg
                        SVG-svg
                        Icon-svg Icon--configurationItem-svg Icon-color-svg
                        Icon-color--gray-svg
                      "
                    >
                      <path
                        d="M2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm9.57 4.5h.68a.75.75 0 1 0 0-1.5h-3.5a.75.75 0 0 0-.75.75v2.5a.75.75 0 0 0 1.5 0V4.837a3.5 3.5 0 0 1 .5 6.036v1.711A5.001 5.001 0 0 0 11.57 4.5zm-7.14 7.084h-.68a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-1.5 0v1.413A3.5 3.5 0 0 1 6 5.211V3.5a5.001 5.001 0 0 0-1.57 8.084z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="Box-root Padding-top--24">
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--20
                    Text-fontWeight--bold
                    Text-lineHeight--28
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Generate {{ selectedNavItem }} Report</span></span
                >
              </div>
              <div class="Box-root Padding-top--4">
                <span
                  class="
                    Text-color--gray
                    Text-fontSize--14
                    Text-lineHeight--20
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><div class="Box-root" style="pointer-events: none">
                    <div
                      class="
                        Box-root
                        Flex-flex
                        Flex-direction--column
                        Flex-justifyContent--flexStart
                        Flex-wrap--nowrap
                      "
                      style="margin-left: -4px; margin-top: -4px"
                    >
                      <div
                        class="
                          Box-root Box-hideIfEmpty
                          Margin-top--4
                          Margin-left--4
                        "
                        style="pointer-events: auto"
                      >
                        <span><span>Report description here</span></span>
                      </div>
                    </div>
                  </div></span
                >
              </div>
              <div class="Box-root Padding-top--24">
                <span
                  class="
                    Text-color--default
                    Text-fontSize--14
                    Text-lineHeight--20
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><div
                    class="
                      PressableCore
                      PressableCore--cursor--pointer
                      PressableCore--height--medium
                      PressableCore--radius--all
                      PressableCore--width
                      PressableCore--width--auto
                      PressableButton
                      Button Button--color--blue
                      Box-root
                      Flex-inlineFlex
                    "
                    style="
                      background-color: rgb(84, 105, 212);
                      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
                    "
                  >
                    <div class="PressableCore-base Box-root">
                      <a
                        data-db-analytics-name="billing_configurationItem_create_button"
                        href="#"
                        class="
                          UnstyledLink
                          Button-element
                          PressableContext
                          Padding-horizontal--8
                          Padding-vertical--4
                          PressableContext--cursor--pointer
                          PressableContext--display--inlineFlex
                          PressableContext--fontLineHeight--20
                          PressableContext--fontSize--14
                          PressableContext--fontWeight--medium
                          PressableContext--height
                          PressableContext--height--medium
                          PressableContext--radius--all
                          PressableContext--width
                          PressableContext--width--auto
                        "
                        style="color: rgb(255, 255, 255)"
                        ><div
                          class="
                            Button-align
                            Box-root
                            Flex-flex
                            Flex-alignItems--baseline
                            Flex-direction--row
                          "
                          style="position: relative"
                        >
                          <div
                            class="TextAligner Box-root"
                            style="
                              line-height: 20px;
                              font-size: 14px;
                              flex: 0 0 auto;
                            "
                          ></div>
                          <div
                            class="
                              Box-root
                              Flex-flex
                              Flex-alignItems--baseline
                              Flex-direction--row
                              Flex-justifyContent--center
                            "
                            style="width: 100%; line-height: 0; flex: 1 1 auto"
                          >
                            <span
                              class="
                                Button-label
                                Text-color--white
                                Text-fontSize--14
                                Text-fontWeight--medium
                                Text-lineHeight--20
                                Text-numericSpacing--proportional
                                Text-typeface--base
                                Text-wrap--noWrap
                                Text-display--block
                              "
                              style="margin-top: -1px"
                              ><span>Generate</span></span
                            >
                          </div>
                        </div></a
                      >
                    </div>
                    <div
                      class="
                        PressableCore-overlay PressableCore-overlay--extendBy1
                        Box-root
                        Box-background--white
                      "
                    ></div></div
                ></span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["selectedNavItem"],
  name: "configuration-detail",
  data() {
    return {
      showAddForm: false,
      dates: ["2019-09-10", "2012-09-20"],
      modal: false,
    };
  },
  methods: {
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
  },
  computed: {
    component() {
      console.log(
        "component: " + "Add" + this.selectedNavItem.replaceAll(" ", "")
      );
      return "Add" + this.selectedNavItem.replaceAll(" ", "");
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>