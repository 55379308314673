<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <span
            class="
              Text-color--default
              Text-fontSize--14
              Text-fontWeight--medium
              Text-lineHeight--20
              Text-numericSpacing--proportional
              Text-typeface--base
              Text-wrap--wrap
              Text-display--inline
            "
            ><span
              >Create a Collection Protocol Event Specimen Requirement</span
            ></span
          >
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  label="Title"
                  :rules="NoEmptyRule"
                  filled
                  required
                  v-model="requestData.Title"
                ></v-text-field>

                <v-text-field
                  dense
                  label="Code"
                  :rules="[
                    (v) => !!v || '*required',
                    (v) =>
                      (v || '').length <= 2 ||
                      'Code must be 2 characters or less',
                  ]"
                  counter="2"
                  filled
                  required
                  v-model="requestData.Code"
                ></v-text-field>
                <div class="d-flex">
                  <v-text-field
                    label="Initial Quantity"
                    :rules="NoEmptyRule"
                    filled
                    style="width: 180px"
                    type="number"
                    required
                    v-model="requestData.InitialQuantity"
                  ></v-text-field>
                  <v-autocomplete
                    label="Unit Of Measurement"
                    :items="unitOfMeasurements"
                    :search-input.sync="SearchUnitOfMeasurement"
                    return-object
                    filled
                    class="ml-3"
                    v-model="requestData.SelectedUnitOfMeasurement"
                    item-text="unitName"
                    :rules="[(v) => !!v || '*Required']"
                    clearable
                  ></v-autocomplete>
                  <!-- <v-select
                    :items="unitOfMeasurements"
                    item-text="unitSymbol"
                    filled
                    class="ml-3"
                    return-object
                    v-model="requestData.SelectedUnitOfMeasurement"
                    label="Unit Of Measurement"
                  ></v-select> -->
                </div>
                <v-select
                  :items="anatomicSites"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedAnatomicSite"
                  label="Anatomic Site"
                ></v-select>
                <v-autocomplete
                  label="Specimen Type"
                  :items="specimenTypes"
                  :search-input.sync="SearchSpecimenType"
                  return-object
                  filled
                  v-model="requestData.SelectedSpecimenType"
                  item-text="title"
                  :rules="[(v) => !!v || '*Required']"
                  clearable
                ></v-autocomplete>
                <!-- <v-select
                  :rules="[(v) => !!v || '*Required']"
                  :items="specimenTypes"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedSpecimenType"
                  label="Specimen Type"
                ></v-select> -->
                <v-select
                  :items="pathologicalStatuses"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedPathologicalStatus"
                  label="Pathological Status"
                ></v-select>

                <v-select
                  :items="specimenCollectionProcedures"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedSpecimenCollectionProcedure"
                  label="Specimen Collection Procedure"
                ></v-select>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-collection-protocol-event-specimen-requirement",
  props: ["toggleForm"],
  created() {
    console.log("The id is: " + this.$route.params.id);
    // this.getAllSpecimenTypes();
    this.$store.commit("setSpecimenTypes", []);
    this.$store.commit("setUnitOfMeasurements", []);
    this.getAnatomicSites();
    this.getPathologicalStatuses();
    // this.getAllUnitOfMeasurements();
    this.getSpecimenCollectionProcedures();
  },
  data() {
    return {
      valid: false,
      departments: [],
      menu: false,
      menu1: false,
      SearchedUnitOfMeasurement: "",
      SearchUnitOfMeasurement: null,
      SearchedSpecimenType: "",
      SearchSpecimenType: null,
      requestData: {
        Title: "",
        Code: "",
        InitialQuantity: 0,
        SelectedAnatomicSite: null,
        AnatomicSiteId: null,
        SelectedSpecimenType: null,
        SpecimenTypeId: null,
        SelectedPathologicalStatus: null,
        PathologicalStatusId: null,
        SelectedUnitOfMeasurement: null,
        UnitOfMeasurementId: null,
        SelectedSpecimenCollectionProcedure: null,
        SpecimenCollectionProcedureId: null,
        CollectionProtocolEventId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  watch: {
    SearchUnitOfMeasurement(val) {
      if (val) {
        if (this.SearchedUnitOfMeasurement != val) {
          this.$store.commit("setUnitOfMeasurements", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/unitOfMeasurements?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedUnitOfMeasurement = val;
              this.$store.commit("setUnitOfMeasurements", response);
            })
            .catch((err) => {});
        }
      }
    },
    SearchSpecimenType(val) {
      if (val) {
        if (this.SearchedSpecimenType != val) {
          this.$store.commit("setSpecimenTypes", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/specimenTypes?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedSpecimenType = val;
              this.$store.commit("setSpecimenTypes", response);
            })
            .catch((err) => {});
        }
      }
    },
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocol"]),
    ...mapActions([
      "getAnatomicSites",
      "getAllSpecimenTypes",
      "getPathologicalStatuses",
      "getAllUnitOfMeasurements",
      "getSpecimenCollectionProcedures",
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        // console.log(
        //   "SelectedAnatomicSite",
        //   this.requestData.SelectedAnatomicSite
        // );
        // this.requestData.InstitutionId = this.currentInstitution.id;
        if (!!this.requestData.SelectedAnatomicSite) {
          this.requestData.AnatomicSiteId =
            this.requestData.SelectedAnatomicSite.id;
        }

        this.requestData.SpecimenTypeId =
          this.requestData.SelectedSpecimenType.id;

        if (!!this.requestData.SelectedPathologicalStatus) {
          this.requestData.PathologicalStatusId =
            this.requestData.SelectedPathologicalStatus.id;
        }

        if (this.requestData.SelectedUnitOfMeasurement) {
          this.requestData.UnitOfMeasurementId =
            this.requestData.SelectedUnitOfMeasurement.id;
        }

        if (this.requestData.SelectedSpecimenCollectionProcedure) {
          this.requestData.SpecimenCollectionProcedureId =
            this.requestData.SelectedSpecimenCollectionProcedure.id;
        }

        this.requestData.CollectionProtocolEventId =
          this.selectedCollectionProtocolEvent.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocols/" +
              this.currentCollectionProtocol.id +
              "/collectionProtocolEvents/" +
              this.selectedCollectionProtocolEvent.id +
              "/collectionProtocolEventSpecimenRequirements",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.$store.dispatch(
              "getCollectionProtocolEventSpecimenRequirements"
            );
            // this.$router
            //   .push({
            //     name: "CollectionProtocolEvents",
            //     params: { id: response.id },
            //   })
            //   .catch(() => {});
            this.toggleForm();
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "anatomicSites",
      "currentCollectionProtocol",
      "selectedCollectionProtocolEvent",
      "specimenTypes",
      "pathologicalStatuses",
      "unitOfMeasurements",
      "specimenCollectionProcedures",
    ]),
  },
};
</script>