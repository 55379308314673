<template>
  <div id="app">
    <v-app dark="dark">
      <v-main>
        <v-container fill-height="fill-height">
          <v-layout align-center="align-center" justify-center="justify-center">
            <v-flex
              class="login-form text-xs-center flex flex-col"
              style="max-width: 400px"
            >
              <div class="d-flex align-center mb-3 justify-center">
                <img
                  src="../assets/bb_logo.png"
                  alt=""
                  @click="selectNav(0)"
                  srcset=""
                  width="50"
                  height="50"
                  style="margin-right: 10px"
                />

                <div>
                  <div class="h3" style="margin-bottom: 0px">
                    Biobanking LIMS
                  </div>
                  Log in to your portal
                </div>
              </div>

              <v-card light="light">
                <v-card-text>
                  <!-- <div class="subheading">
                    <template>Log in to your portal</template>
                  </div> -->
                  <v-form class="mt-4">
                    <v-text-field
                      v-model="user.userName"
                      light="light"
                      outlined
                      dense
                      prepend-icon="mdi-email"
                      label="Email"
                      type="email"
                    ></v-text-field>
                    <v-text-field
                      dense
                      v-model="user.password"
                      light="light"
                      outlined
                      prepend-icon="mdi-lock"
                      label="Password"
                      type="password"
                    ></v-text-field>
                    <v-checkbox
                      v-if="options.isLoggingIn"
                      v-model="options.shouldStayLoggedIn"
                      light="light"
                      label="Stay logged in?"
                      hide-details="hide-details"
                    ></v-checkbox>
                    <v-btn
                      color="primary"
                      rounded
                      class="mt-3"
                      @click.prevent="login"
                      block="block"
                      :loading="isLoading"
                      type="submit"
                      >Sign in</v-btn
                    >
                  </v-form>
                </v-card-text>
              </v-card>
              <v-alert
                dense
                outlined
                type="error"
                class="mt-3"
                v-if="loginError"
              >
                {{ errorMessage }}
              </v-alert>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
      <v-footer app="app">
        <v-flex class="text-xs-center">© 2021. All rights reserved.</v-flex>
      </v-footer>
    </v-app>
  </div>
</template>
<script>
import apiClient from "../API/apiClient";
export default {
  name: "login",
  data() {
    return {
      isLoading: false,
      loginError: false,
      errorMessage: "",
      user: {
        userName: "",
        password: "",
      },
      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },
    };
  },
  methods: {
    login: function() {
      this.isLoading = true;
      apiClient
        .call("POST", "Authentication/login", this.user)
        .then((response) => {
          console.log(response);
          localStorage.setItem("auth_token", response.token);
          this.$router
            .push({
              name: "Home",
            })
            .catch(() => {});
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.status === 401) {
            this.loginError = true;
            this.errorMessage = "Incorrect username or password.";
          } else if (err.status === 500) {
            this.loginError = true;
            this.errorMessage = "Internal server error.";
          }
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
