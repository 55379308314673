<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Participants
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="Card-root Card--radius--all Section Box-root Box-background--white"
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween"
              >
                <span
                  class="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                  ><span>Participants</span></span
                >
                <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Participant</v-btn
                >
              </div>
              <v-data-table
                @click:row="handleClick"
                style="margin-top: 15px"
                :headers="headers"
                :loading="isLoading"
                :items="collectionProtocolParticipants"
                @update:options="updateOptions"
                class="elevation-0"
                :page="1"
                :pageCount="180"
                :server-items-length="totalCount"
                :options="options"
                @update:pagination="updatePagination"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
                }"
                ><template v-slot:item.name="{ item }">
                  {{ item.patient.firstName }} {{ item.patient.fatherName }}
                  {{ item.patient.grandFatherName }} </template
                ><template v-slot:item.birthDate="{ item }">
                  {{ item.patient.birthDate | moment("YYYY-MM-DD") }}
                </template></v-data-table
              >
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
        >
          <add-collection-protocol-Participant
            selectedNavItem="Participant"
            :toggleForm="toggleForm"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolParticipant from "../../components/AddForms/AddCollectionProtocolParticipant.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: { AddCollectionProtocolParticipant },
  name: "collection-protocol-Participants",
  created() {
    this.getCollectionProtocolParticipants();
  },
  data() {
    return {
      showAddForm: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Birth Date",
          align: "start",
          value: "birthDate",
        },
        {
          text: "Gender",
          align: "start",
          value: "patient.gender.title",
        },
        {
          text: "Marital Status",
          align: "start",
          value: "patient.maritalStatus.title",
        },
        {
          text: "Vital Status",
          align: "start",
          value: "patient.vitalStatus.title",
        },
        {
          text: "Patient Type",
          align: "start",
          value: "patient.patientType.title",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolParticipants"]),
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    handleClick(value) {
      this.$store.commit("setSelectedCollectionProtocolParticipant", value);
      // this.setSelectedCollectionProtocolForm(setSelectedCollectionProtocolParticipant);
      this.$router
        .push({
          name: "CollectionProtocolParticipantOverview",
          params: { collectionProtocolParticipantId: value.id },
        })
        .catch(() => {});
      console.log(value.name);
    },
    updateOptions(val) {
      // console.log("Options changed to: ", val);
      this.$store.commit("setOptions", val);
      this.getCollectionProtocolParticipants();
      // console.log("this.selectedConfiguration", this.selectedConfiguration);
      // if (!this.isFirstTime) {
      //   this.$store.dispatch(this.selectedConfiguration);
      // } else {
      //   this.isFirstTime = false;
      // }
    },
    updatePagination(val) {
      console.log("Pagination changed to: ", val);
      // this.$store.commit("setPagination", val);
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolParticipants",
      "currentCollectionProtocol",
      "isLoading",
      "totalCount",
    ]),
    ...mapState(["options"]),
  },
  unmounted() {
    console.log("unmounted");
  },
};
</script>
