<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <div>
            <div>
              <span
                class="
                  Text-color--default
                  Text-fontSize--14
                  Text-fontWeight--medium
                  Text-lineHeight--20
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Collect Specimen</span></span
              >
            </div>
            <div>{{ currentCollectionProtocol.title }}</div>
          </div>
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            ><span v-if="e1 == 2">Submit</span
                            ><span v-else>Next</span>
                          </v-btn>
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                min-width: 700px;
                max-width: 1200px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Select Specimen Requirements
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">
                    Collect Samples
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <div style="display: flex">
                      <div style="width: 320px">
                        <div class="d-flex pt-2 pb-1">
                          <div style="font-weight: bold">Events</div>
                          <v-spacer />
                          <div>
                            <!-- <v-btn small elevation="0" @click="toggleForm()"
                        >Create Event
                      </v-btn> -->
                          </div>
                        </div>
                        <div
                          v-for="(collectionProtocolEvent,
                          index) in collectionProtocolEvents"
                          :key="index"
                          @click="selectEvent(collectionProtocolEvent.id)"
                          :class="{
                            selected: collectionProtocolEvent.selected,
                            'not-selected': !collectionProtocolEvent.selected,
                          }"
                        >
                          <span style="font-weight: bold"
                            >{{ collectionProtocolEvent.label }}
                          </span>
                          <div style="display: flex">
                            <span style="font-weight: bold">Code:</span
                            ><span style="padding-left: 10px">{{
                              collectionProtocolEvent.eventCode
                            }}</span>
                          </div>
                        </div>
                        <!-- <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
            "
          >
            <v-btn small elevation="0" @click="toggleForm()"
              >Create Event
            </v-btn>
          </div> -->
                      </div>
                      <div
                        style="width: 100%; margin: 10px; margin-top: 15px"
                        v-if="selectedCollectionProtocolEvent"
                      >
                        <div style="display: flex">
                          <span
                            >{{
                              selectedCollectionProtocolEvent.label
                            }}
                            Specimen Requirement</span
                          ><v-spacer />
                        </div>
                        <!-- <v-data-table
            style="margin-top: 15px"
            :headers="headers"
            :items="collectionProtocolEventSpecimenRequirements"
          ></v-data-table> -->
                        <div
                          class="d-flex align-center justify-center"
                          v-if="isLoading"
                        >
                          <v-progress-circular indeterminate color="primary" />
                        </div>
                        <v-treeview
                          v-else
                          open-all
                          v-model="tree"
                          return-object
                          :open="initiallyOpen"
                          :items="collectionProtocolEventSpecimenRequirements"
                          activatable
                          item-key="id"
                          open-on-click
                        >
                          <template slot="label" slot-scope="{ item, open }">
                            <div>
                              <div
                                class="d-flex align-center"
                                style="
                                  min-width: 600px;
                                  max-width: 800px;
                                  border-bottom: 1px solid #e3e3e3;
                                "
                              >
                                <div
                                  class="mr-3"
                                  style="
                                    height: 10px;
                                    width: 10px;
                                    border-radius: 5px;
                                    background: #afb42b;
                                  "
                                ></div>
                                <div
                                  style="
                                    width: 150px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                  "
                                >
                                  <!-- @click="onclicka(item)" -->
                                  <span v-if="item.collectionProtocolEventID">
                                    {{ item.title }}
                                  </span>
                                  <span v-else
                                    ><span
                                      v-if="
                                        item.subSampleLineage.title == 'Derived'
                                      "
                                      >{{ item.subSampleLineage.title }}
                                      {{ item.specimenType.title }}</span
                                    >
                                    <span v-else>{{
                                      item.subSampleLineage.title
                                    }}</span>
                                  </span>
                                </div>
                                <v-spacer />
                                <div
                                  style="
                                    width: 250px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                  "
                                >
                                  <!-- @click="onclicka(item)" -->
                                  {{ item.specimenType.title }}
                                </div>

                                <div
                                  style="
                                    width: 150px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                  "
                                >
                                  <!-- @click="onclicka(item)" -->
                                  {{
                                    item.initialQuantity +
                                      "" +
                                      item.unitOfMeasurement.unitSymbol
                                  }}
                                </div>

                                <div
                                  style="
                                    width: 150px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                  "
                                >
                                  <!-- @click="onclicka(item)" -->
                                  {{ item.createdDate }}
                                </div>
                                <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                                <div>
                                  <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-treeview>
                      </div>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <div class="p-2">
                        <div class="d-flex align-start">
                          <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                dense
                                v-model="CollectedDate"
                                label="Collected Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                :rules="NoEmptyRule"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="CollectedDate"
                              @input="menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="CollectedTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                dense
                                class="ml-3"
                                v-model="CollectedTime"
                                label="Collected Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                :rules="NoEmptyRule"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="menu2"
                              v-model="requestData.BirthTime"
                              full-width
                              @click:minute="
                                $refs.menu.save(requestData.BirthTime)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </div>
                      </div>
                      <v-treeview
                        v-if="
                          filteredCollectionProtocolEventSpecimenRequirements.length >
                            0
                        "
                        return-object
                        :items="
                          filteredCollectionProtocolEventSpecimenRequirements
                        "
                        open-all
                        item-key="id"
                      >
                        <template slot="label" slot-scope="{ item, open }">
                          <div>
                            <div
                              class="d-flex align-center"
                              style="
                                min-width: 600px;
                                max-width: 800px;
                                border-bottom: 1px solid #e3e3e3;
                              "
                            >
                              <div
                                class="mr-3"
                                style="
                                  height: 10px;
                                  width: 10px;
                                  border-radius: 5px;
                                  background: #afb42b;
                                "
                              ></div>
                              <div
                                style="
                                  width: 150px;
                                  height: 40px;
                                  display: flex;
                                  align-items: center;
                                "
                              >
                                <!-- @click="onclicka(item)" -->
                                <span v-if="item.collectionProtocolEventID">
                                  {{ item.title }}
                                </span>
                                <span v-else
                                  ><span
                                    v-if="
                                      item.subSampleLineage.title == 'Derived'
                                    "
                                    >{{ item.subSampleLineage.title }}
                                    {{ item.specimenType.title }}</span
                                  >
                                  <span v-else>{{
                                    item.subSampleLineage.title
                                  }}</span>
                                </span>
                              </div>
                              <v-spacer />
                              <div
                                style="
                                  width: 250px;
                                  height: 70px;
                                  display: flex;
                                  align-items: center;
                                "
                              >
                                <!-- @click="onclicka(item)" -->
                                {{ item.specimenType.title }}
                              </div>

                              <div
                                style="
                                  width: 150px;
                                  height: 40px;
                                  display: flex;
                                  align-items: center;
                                "
                              >
                                <v-text-field
                                  :rules="NoEmptyRule"
                                  v-model="item.initialQuantity"
                                  style="max-width: 60px"
                                ></v-text-field>
                                <!-- @click="onclicka(item)" -->
                                {{ item.unitOfMeasurement.unitSymbol }}
                              </div>
                              <v-text-field
                                class="ml-2"
                                label="Sample ID"
                                v-model="item.sampleID"
                              ></v-text-field>
                              <div
                                style="
                                  width: 150px;
                                  height: 40px;
                                  display: flex;
                                  align-items: center;
                                "
                              >
                                <!-- @click="onclicka(item)" -->
                                {{ item.createdDate }}
                              </div>
                              <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                              <div>
                                <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                              </div>
                            </div>
                          </div>
                        </template>
                      </v-treeview>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card
                      class="mb-12"
                      color="grey lighten-1"
                      height="200px"
                    ></v-card>

                    <v-btn color="primary" @click="e1 = 1"> Continue </v-btn>

                    <v-btn text> Cancel </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import AddCollectionProtocolEvent from "../../components/AddForms/AddCollectionProtocolEvent.vue";
import { mapGetters, mapActions } from "vuex";
import AddCollectionProtocolEventSpecimenRequirement from "../../components/AddForms/AddCollectionProtocolEventSpecimenRequirement.vue";
import apiClient from "../../API/apiClient";
export default {
  components: {
    AddCollectionProtocolEvent,
    AddCollectionProtocolEventSpecimenRequirement,
  },
  name: "add-planned-collected-sample",
  props: ["toggleForm"],
  created() {
    // this.getCollectionProtocolEvents();
    console.log(
      "collectionProtocolEventSpecimenRequirements_______",
      this.collectionProtocolEventSpecimenRequirements
    );
    this.filteredSpecimenRequirements;
  },
  data() {
    return {
      filteredSpecimenRequirements: [],
      filteredCollectionProtocolEventSpecimenRequirements: [],
      e1: 1,
      valid: false,
      menu1: false,
      menu2: false,
      tree: [],
      initiallyOpen: [],
      showAddForm: false,
      showAddSpecimenRequirementForm: false,
      events: [],
      requestData: null,
      NoEmptyRule: [(v) => !!v || "*required"],
      CollectedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      CollectedTime: this.getTodaysTime(),
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Code", value: "code" },
        { text: "Anatomic Site", value: "anatomicSite.title" },
        { text: "Pathological Status", value: "pathologicalStatus.title" },
        { text: "InitialQuantity", value: "initialQuantity" },
        { text: "Unit Of Measurement", value: "unitOfMeasurement.unitName" },
        {
          text: "Specimen Collection Procedure",
          value: "specimenCollectionProcedure.title",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "collectionProtocolEvents",
      "collectionProtocolEventSpecimenRequirements",
      "selectedCollectionProtocolEvent",
      "currentCollectionProtocol",
      "isLoading",
      "subSampleLineages",
      "specimenTypes",
      "currentInstitution",
      "currentCollectionProtocolParticipant",
    ]),
    allEvents() {
      this.events.forEach((event) => {
        console.log("Event: " + event.label + event.selected);
      });

      return this.events;
    },
  },
  methods: {
    ...mapActions([
      "getCollectionProtocolEvents",
      "getCollectionProtocolEventSpecimenRequirements",
      "getSubSampleLineages",
      "getAllSpecimenTypes",
    ]),
    getTodaysTime() {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes();
      return time;
    },
    toggleSpecimenRequirementForm() {
      this.showAddSpecimenRequirementForm = !this
        .showAddSpecimenRequirementForm;
    },
    selectEvent(id) {
      this.$store.commit("setSelectedCollectionProtocolEvent", id);
      this.getCollectionProtocolEventSpecimenRequirements();
    },
    toggleAddView(item) {
      console.log("toggleAddView", item);
      item.addView = !item.addView;
      if (item.addView) {
        this.requestData = {
          collectionProtocolEventSpecimenRequirement:
            item.CollectionProtocolEventID == null
              ? item
              : item.collectionProtocolEventSpecimenRequirement,
          parentCollectionProtocolEventSpecimenRequirementItem:
            item.CollectionProtocolEventID != null ? null : item,
          initialQuantity: "",
          specimenType: item.specimenType,
          subSampleLineage: item.subSampleLineage,
          unitOfMeasurement: item.unitOfMeasurement,
        };
        // this.$set(this.requestData, "subSampleLineage", true);
        // this.collectedSample.subSampleLineage = null;
      } else {
        this.requestData = null;
      }
    },
    createSpecimenRequirementItem(item) {
      console.log("Create Specimen Requirement Item", item);
      if (this.$refs.specimenRequirementItemForm.validate()) {
        this.requestData.specimenTypeID = this.requestData.specimenType.id;
        this.requestData.subSampleLineageID = this.requestData.subSampleLineage.id;
        if (
          this.requestData.parentCollectionProtocolEventSpecimenRequirementItem
        ) {
          this.requestData.parentCollectionProtocolEventSpecimenRequirementItemID = this.requestData.parentCollectionProtocolEventSpecimenRequirementItem.id;
        }

        if (this.requestData.collectionProtocolEventSpecimenRequirement) {
          this.requestData.collectionProtocolEventSpecimenRequirementID = this.requestData.collectionProtocolEventSpecimenRequirement.id;
        }
        if (this.requestData.unitOfMeasurement) {
          this.requestData.unitOfMeasurementID = this.requestData.unitOfMeasurement.id;
        }
        console.log("__________________________________");
        console.log(this.requestData);
        // return;
        // console.log(
        //   "this.selectedCollectionProtocolEvent",
        //   this.selectedCollectionProtocolEvent
        // );
        console.log(
          "endpoint",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${this.selectedCollectionProtocolEvent.id}/collectionProtocolEventSpecimenRequirements/${this.requestData.collectionProtocolEventSpecimenRequirementID}/collectionProtocolEventSpecimenRequirementItems`
        );
        // return;
        apiClient
          .call(
            "POST",

            `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${this.selectedCollectionProtocolEvent.id}/collectionProtocolEventSpecimenRequirements/${this.requestData.collectionProtocolEventSpecimenRequirementID}/collectionProtocolEventSpecimenRequirementItems`,

            this.requestData
          )
          .then((response) => {
            console.log(response);
            // this.$store.dispatch("getCollectionProtocolCollectedSamples");
            // this.toggleForm();
            item.addView = false;
          })
          .catch((err) => console.log(err));
      }
    },
    submit() {
      console.log("submit clicked", this.e1);
      if (this.e1 == 1) {
        // console.log(this.tree);
        // this.collectionProtocolEventSpecimenRequirements.forEach(
        //   (collectionProtocolEventSpecimenRequirement) => {
        //     this.tree.forEach((treeItem) => {
        //       if (
        //         treeItem.id == collectionProtocolEventSpecimenRequirement.id
        //       ) {
        //         console.log("got it:___________");
        //       }
        //     });
        //     this.checkIfExist(collectionProtocolEventSpecimenRequirement);
        //   }
        // );
        this.filteredCollectionProtocolEventSpecimenRequirements = this.collectionProtocolEventSpecimenRequirements;
        this.e1 = 2;
      } else {
        if (this.$refs.form.validate()) {
          //   console.log("it worked, it is insane!");
          this.filteredCollectionProtocolEventSpecimenRequirements.forEach(
            (collectionProtocolEventSpecimenRequirement) => {
              // console.log(
              //   "treeItem.initialQuantity",
              //   collectionProtocolEventSpecimenRequirement.initialQuantity
              // );
              var newCollectedSample = {
                InstitutionSiteId: this.selectedCollectionProtocolEvent
                  .institutionSiteID,
                CollectionProtocolEventId: this.selectedCollectionProtocolEvent
                  .id,
                PatientId: this.currentCollectionProtocolParticipant.patientID,
                SampleID: collectionProtocolEventSpecimenRequirement.sampleID,
                SpecimenTypeId:
                  collectionProtocolEventSpecimenRequirement.specimenType.id,
                CollectedAmount:
                  collectionProtocolEventSpecimenRequirement.initialQuantity,
                RemainingAmount:
                  collectionProtocolEventSpecimenRequirement.initialQuantity,
                UnitOfMeasurementId:
                  collectionProtocolEventSpecimenRequirement.unitOfMeasurement
                    .id,
                CollectedDate: this.CollectedDate,
                CollectedTime: this.CollectedTime,
                CollectionProtocolId: this.currentCollectionProtocol.id,
              };
              console.log(
                "Parent CollectedSample before create:: ",
                newCollectedSample
              );
              apiClient
                .call(
                  "POST",
                  "institutions/" +
                    this.currentInstitution.id +
                    "/collectionProtocols/" +
                    this.currentCollectionProtocol.id +
                    "/collectedSamples",
                  newCollectedSample
                )
                .then((response) => {
                  console.log("::::::::::::--", response);
                  // let that = this;
                  this.checkIfExist(
                    collectionProtocolEventSpecimenRequirement,
                    response
                  );
                })
                .catch((err) => console.log(err));
            }
          );
          this.toggleForm();
        } else {
          console.log("it did not work, it is insane!");
        }
        // this.e1 = 1;
      }
    },
    checkIfExist(collectionProtocolEventSpecimenRequirement, parent) {
      if (collectionProtocolEventSpecimenRequirement.children.length > 0) {
        // for (
        //   let index = 0;
        //   index < collectionProtocolEventSpecimenRequirement.children.length;

        // ) {
        // const child =
        //   collectionProtocolEventSpecimenRequirement.children[index];
        // console.log("child:", child);
        // var newCollectedSample = {
        //   InstitutionSiteId:
        //     this.selectedCollectionProtocolEvent.institutionSiteID,
        //   CollectionProtocolEventId: this.selectedCollectionProtocolEvent.id,
        //   PatientId: this.currentCollectionProtocolParticipant.patientID,
        //   SampleID: child.sampleID,
        //   SpecimenTypeId: child.specimenType.id,
        //   subSampleLineageId: child.subSampleLineage.id,
        //   CollectedAmount: child.initialQuantity,
        //   RemainingAmount: child.initialQuantity,
        //   UnitOfMeasurementId: child.unitOfMeasurement.id,
        //   CollectedDate: this.CollectedDate,
        //   CollectedTime: this.CollectedTime,
        //   CollectionProtocolId: this.currentCollectionProtocol.id,
        //   ParentSampleID: parent.id,
        //   IsSubSample: true,
        // };
        // // console.log("newCollectedSample", newCollectedSample);
        // // console.log("parent:::::", parent);
        // console.log(
        //   "Adding:::" + child.specimenType.title,
        //   child.initialQuantity,
        //   child.unitOfMeasurement.unitSymbol
        // );

        // apiClient
        //   .call(
        //     "POST",
        //     "institutions/" +
        //       this.currentInstitution.id +
        //       "/collectionProtocols/" +
        //       this.currentCollectionProtocol.id +
        //       "/collectedSamples",
        //     newCollectedSample
        //   )
        //   .then((response) => {
        //     console.log("::::::::::::-", response);
        //     that.checkIfExist(child, response);
        //     index++;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     index++;
        //   });
        // }
        // return;
        collectionProtocolEventSpecimenRequirement.children.forEach((child) => {
          // console.log(
          //   "collectionProtocolEventSpecimenRequirement_______::::::",
          //   child
          // );
          // console.log("treeItem.initialQuantity", child.initialQuantity);
          var newCollectedSample = {
            InstitutionSiteId: this.selectedCollectionProtocolEvent
              .institutionSiteID,
            CollectionProtocolEventId: this.selectedCollectionProtocolEvent.id,
            PatientId: this.currentCollectionProtocolParticipant.patientID,
            SampleID: child.sampleID,
            SpecimenTypeId: child.specimenType.id,
            subSampleLineageId: child.subSampleLineage.id,
            CollectedAmount: child.initialQuantity,
            RemainingAmount: child.initialQuantity,
            UnitOfMeasurementId: child.unitOfMeasurement.id,
            CollectedDate: this.CollectedDate,
            CollectedTime: this.CollectedTime,
            CollectionProtocolId: this.currentCollectionProtocol.id,
            ParentSampleID: parent.id,
            IsSubSample: true,
          };
          console.log("newCollectedSample", newCollectedSample);
          // console.log("parent:::::", parent);
          // console.log(
          //   "Adding:::" + child.specimenType.title,
          //   child.initialQuantity,
          //   child.unitOfMeasurement.unitSymbol
          // );

          apiClient
            .call(
              "POST",
              "institutions/" +
                this.currentInstitution.id +
                "/collectionProtocols/" +
                this.currentCollectionProtocol.id +
                "/collectedSamples",
              newCollectedSample
            )
            .then((response) => {
              console.log("::::::::::::-", response);
              this.checkIfExist(child, response);
            })
            .catch((err) => console.log(err));
        });
        this.$store.dispatch("getCollectionProtocolCollectedSamples");
      }
    },
  },
};
</script>
<style scoped>
.selected {
  padding: 10px;
  background: #5469d4;
  min-height: 60px;
  border-radius: 7.5px;
  color: white;
  margin-top: 10px;
}
.not-selected {
  padding: 10px;
  border: 1px solid #5469d4;
  min-height: 60px;
  border-radius: 7.5px;
  margin-top: 10px;
}
</style>
