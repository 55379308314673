<template>
  <div class="w-full h-full b px-10 pt-32 overflow-hidden">
    <div class="w-full h-full flex flex-col overflow-hidden">
      <!-- {{ collectionProtocolForms }} -->
      <div class="w-full flex justify-between px-3 py-2">
        <div class="w-full flex">
          <v-select
            v-model="selectedForm"
            :items="collectionProtocolForms"
            item-text="name"
            item-value="id"
            label="Select Form"
            outlined
            class="w-full max-w-md"
            hide-details
          >
          </v-select>
        </div>
        <div class="flex gap-3">
          <v-btn
            color="primary"
            elevation="0"
            @click="generate()"
            :loading="loading"
          >
            Generate
          </v-btn>
          <v-btn color="primary" elevation="0" @click="exportData()">
            Export
          </v-btn>
        </div>
      </div>
      <div class="w-full h-full overflow-auto">
        <v-simple-table class="w-full" hide-default-footer>
          <template v-slot:default>
            <thead>
              <!-- <tr>
              <th class="text-left">Institution</th>
              <th class="text-left">Institution Site</th>
              <th class="text-left">Patient Name</th>
              <th class="text-left">Patient Date of Birth</th>
              <th class="text-left">
                Patient Gender
              </th>
                <th class="text-left">
                    Patient Marital Status
                </th>
                <th class="text-left">
                    Patient Vital Status
                </th>
                <th
                    v-for="(formField, index) in formFields"
                    :key="index"
                    class="text-left"
                >
                    {{ formField.prompt }}
                </th>
                <th class="text-left">Date Collected</th>
            </tr> -->
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  class="text-left"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in tableData" :key="index">
                <td v-for="(column, index) in row" :key="index">
                  <v-btn
                    @click="showDisableConfirmation(column)"
                    v-if="index === 0"
                    icon
                  >
                    <!-- {{ column }} -->
                    <v-icon color="red" size="16">mdi-delete</v-icon>
                  </v-btn>
                  <span v-else>
                    {{ column }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <v-dialog v-model="isDisableConfirmationDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Disable Confirmation</v-card-title>
        <v-card-text> Are you sure you want to disable this form? </v-card-text>
        <v-card-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Id</td>
                  <td>{{ selectedSubmittedForm.id }}</td>
                </tr>
                <tr>
                  <td>Institution</td>
                  <td>{{ selectedSubmittedForm.institution.title }}</td>
                </tr>
                <tr>
                  <td>Institution Site</td>
                  <td>{{ selectedSubmittedForm.institutionSite.title }}</td>
                </tr>
                <tr>
                  <td>Patient Name</td>
                  <td>
                    {{
                      selectedSubmittedForm.patient.firstName +
                      " " +
                      selectedSubmittedForm.patient.fatherName +
                      " " +
                      selectedSubmittedForm.patient.grandFatherName
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="isDisableConfirmationDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            @click="disableSubmittedForm(selectedSubmittedForm.id)"
            color="blue darken-1"
            text
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import fileDownload from "js-file-download";

import apiClient from "../../API/apiClient";

export default {
  name: "CollectionProtocolCollectedQuestioners",
  created() {
    console.log("CollectionProtocolCollectedQuestioners created >>>>>>>>>>>>");
    this.refresh();
  },
  components: {},
  data() {
    return {
      collectionProtocolForms: [],
      selectedForm: null,
      selectedFormFields: [],
      loading: false,
      submittedCollectionProtocolForms: [],
      formFields: [],
      headers: [],
      tableData: [],
      genders: [],
      maritalStatuses: [],
      vitalStatuses: [],
      isDisableConfirmationDialog: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions([
      "getCollectionProtocolForms",
      "getSubmittedCollectionProtocolFormsPerCollectionProtocolFormID",
      "getCollectionProtocolFormFields",
      "getVitalStatuses",
      "getMaritalStatuses",
      "getGenders",
    ]),
    refresh() {
      this.getCollectionProtocolForms()
        .then((res) => {
          console.log("getCollectionProtocolForms >>>>>>>>>>>>", res);
          this.collectionProtocolForms = res;
        })
        .catch((error) => {
          console.log("getCollectionProtocolForms error >>>>>>>>>>>>", error);
        });
    },
    generate() {
      console.log("generate >>>>>>>>>>>>", this.selectedForm);
      this.loading = true;
      let promises = [];
      promises.push(
        this.getSubmittedCollectionProtocolFormsPerCollectionProtocolFormID(
          this.selectedForm
        )
          .then((res) => {
            console.log(
              "getSubmittedCollectionProtocolFormsPerCollectionProtocolFormID >>>>>>>>>>>>",
              res
            );
            this.submittedCollectionProtocolForms = res;
          })
          .catch((error) => {
            console.log(
              "getSubmittedCollectionProtocolFormsPerCollectionProtocolFormID error >>>>>>>>>>>>",
              error
            );
          })
      );

      promises.push(
        this.getVitalStatuses()
          .then((res) => {
            console.log("getVitalStatuses >>>>>>>>>>>>", res);
            this.vitalStatuses = res;
          })
          .catch((error) => {
            console.log("getVitalStatuses error >>>>>>>>>>>>", error);
          })
      );

      promises.push(
        this.getMaritalStatuses()
          .then((res) => {
            console.log("getMaritalStatuses >>>>>>>>>>>>", res);
            this.maritalStatuses = res;
          })
          .catch((error) => {
            console.log("getMaritalStatuses error >>>>>>>>>>>>", error);
          })
      );

      promises.push(
        this.getGenders()
          .then((res) => {
            console.log("getGenders >>>>>>>>>>>>", res);
            this.genders = res;
          })
          .catch((error) => {
            console.log("getGenders error >>>>>>>>>>>>", error);
          })
      );

      //   promises.push(
      //     this.getCollectionProtocolFormFields(this.selectedForm)
      //       .then((res) => {
      //         console.log("getCollectionProtocolFormFields >>>>>>>>>>>>", res);
      //         this.selectedFormFields = res;
      //       })
      //       .catch((error) => {
      //         console.log(
      //           "getCollectionProtocolFormFields error >>>>>>>>>>>>",
      //           error
      //         );
      //       })
      //   );

      Promise.all(promises)
        .then(() => {
          console.log("Promise.all >>>>>>>>>>>>");
          let allFormFields = [];

          this.submittedCollectionProtocolForms.forEach((submittedForm) => {
            // console.log("submittedForm >>>>>>>>>>>>", submittedForm);
            let submittedCollectionProtocolFormValues =
              submittedForm.submittedCollectionProtocolFormValues;
            let formFields = submittedCollectionProtocolFormValues.map(
              (submittedCollectionProtocolFormValue) => {
                return submittedCollectionProtocolFormValue.collectionProtocolFormField;
              }
            );
            // console.log("formFields >>>>>>>>>>>>", formFields);
            allFormFields = allFormFields.concat(formFields);
          });

          const uniqueFormFields = allFormFields.filter(
            (value, index, self) =>
              self.findIndex(
                (t) => t.id === value.id && t.name === value.name
              ) === index
          );

          console.log("uniqueFormFields >>>>>>>>>>>>", uniqueFormFields);

          // sorted by ordinal
          this.formFields = uniqueFormFields.sort((a, b) =>
            a.ordinal > b.ordinal ? 1 : -1
          );

          this.headers = [
            "Id",
            "Institution",
            "Institution Site",
            "Patient Name",
            "Patient Date of Birth",
            "Patient Gender",
            "Patient Marital Status",
            "Patient Vital Status",
          ];

          this.formFields.forEach((formField) => {
            this.headers.push(formField.prompt);
          });

          this.headers.push("Date Collected");

          this.tableData = [];

          this.submittedCollectionProtocolForms.forEach((submittedForm) => {
            let columnData = [
              submittedForm.id,
              submittedForm.institution.title,
              submittedForm.institutionSite.title,
              submittedForm.patient.firstName +
                " " +
                submittedForm.patient.fatherName +
                " " +
                submittedForm.patient.grandFatherName,
              submittedForm.patient.birthDate,
              this.genders.find((x) => x.id === submittedForm.patient.genderID)
                .title,
              this.maritalStatuses.find(
                (x) => x.id === submittedForm.patient.maritalStatusID
              ).title,
              this.vitalStatuses.find(
                (x) => x.id === submittedForm.patient.vitalStatusID
              ).title,
            ];

            this.formFields.forEach((formField) => {
              const submittedCollectionProtocolFormValue =
                submittedForm.submittedCollectionProtocolFormValues.find(
                  (submittedCollectionProtocolFormValue) =>
                    submittedCollectionProtocolFormValue
                      .collectionProtocolFormField.id === formField.id
                );
              if (submittedCollectionProtocolFormValue) {
                columnData.push(submittedCollectionProtocolFormValue.value);
              } else {
                columnData.push("");
              }
            });

            columnData.push(submittedForm.enabledDate);
            this.tableData.push(columnData);
          });

          console.log(
            "this.headers >>>>>>>>>>>>",
            this.headers,
            this.tableData
          );

          this.loading = false;
        })
        .catch((error) => {
          console.log("Promise.all error >>>>>>>>>>>>", error);
          this.loading = false;
        });
    },
    exportData() {
      // console.log("export data >>>>>>>>>>>>");
      // console.log("this.headers: length >>>>>>>>>>>>", this.headers);

      // this.tableData.forEach((row) => {
      //   console.log("row: length >>>>>>>>>>>>", row.length);
      // });

      let csvString = "";
      let headerString = "";
      this.headers.forEach((header) => {
        if (header === "Id") {
          header = `"${header}"`;
        } else {
          header = `"${header}"`;
          headerString += header + ",";
        }
      });
      csvString += headerString + "\n";

      this.tableData.forEach((row) => {
        let rowString = "";
        row.forEach((column) => {
          // add double quotes to escape commas
          if (row.indexOf(column) === 0) {
            column = `"${column}"`;
          } else {
            column = `"${column}"`;
            rowString += column + ",";
          }
        });
        csvString += rowString + "\n";
      });
      console.log("csvString >>>>>>>>>>>>", csvString);
      //   this.downloadCSV(csvString, "data.csv");
      fileDownload(csvString, "data.csv");
    },
    downloadCSV(csvString, filename) {
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${blob}`);
      window.open(encodedUri, "_blank"); // Open in new tab (optional: '_self' for current)
    },
    // disableSubmittedForm(value) {
    //   console.log("disableSubmittedForm >>>>>>>>>>>>", value);

    //   // POST
    //   // /api/institutions/{institutionId}/submittedCollectionProtocolForms/{id}/disable

    //   const submittedCollectionProtocolForm =
    //     this.submittedCollectionProtocolForms.find((x) => x.id === value);

    //   console.log(
    //     "submittedCollectionProtocolForm >>>>>>>>>>>>",
    //     submittedCollectionProtocolForm
    //   );

    //   // return;

    //   if (submittedCollectionProtocolForm) {
    //     apiClient
    //       .call(
    //         "POST",
    //         `/institutions/${submittedCollectionProtocolForm.institution.id}/submittedCollectionProtocolForms/${value}/disable`,
    //         submittedCollectionProtocolForm
    //       )
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // },
    showDisableConfirmation(value) {
      console.log("showDisableConfirmation >>>>>>>>>>>>", value);
      this.isDisableConfirmationDialog = true;
      this.selectedSubmittedForm = this.submittedCollectionProtocolForms.find(
        (x) => x.id === value
      );
    },
  },
};
</script>
