<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <div>
            <div>
              <span
                class="
                  Text-color--default
                  Text-fontSize--14
                  Text-fontWeight--medium
                  Text-lineHeight--20
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Collect Specimen</span></span
              >
            </div>
            <div>{{ currentCollectionProtocol.title }}</div>
          </div>
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form
                v-if="requestData"
                ref="form"
                v-model="valid"
                lazy-validation
                v-on:submit.prevent
              >
                <v-text-field
                  dense
                  disabled
                  label="Parent Sample Id"
                  :rules="NoEmptyRule"
                  filled
                  required
                  v-on:keyup.enter="searchPatient()"
                  v-model="requestData.sampleID"
                ></v-text-field>
                <!-- <v-select
                  :items="institutionSites"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.institutionSite"
                  :rules="[(v) => !!v || '*Required']"
                  label="InstitutionSite"
                ></v-select> -->
                <!-- <v-select
                  :items="collectionProtocolEvents"
                  item-text="label"
                  filled
                  @change="eventChanged"
                  return-object
                  v-model="requestData.SelectedCollectionProtocolEvent"
                  label="Event"
                ></v-select> -->
                <v-select
                  :items="specimenTypes"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.specimenType"
                  :rules="[(v) => !!v || '*Required']"
                  label="SpecimenType"
                ></v-select>
                <v-text-field
                  dense
                  label="Remaining Amount"
                  :rules="NoEmptyRule"
                  filled
                  disabled
                  type="number"
                  required
                  v-model="requestData.remainingAmount"
                ></v-text-field>
                <v-select
                  :items="subSampleLineages"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.subSampleLineage"
                  :rules="[(v) => !!v || '*Required']"
                  label="Sub Sample Lineage"
                ></v-select>
                <v-text-field
                  dense
                  label="Collected Amount"
                  :rules="NoEmptyRule"
                  filled
                  type="number"
                  required
                  v-model="requestData.collectedAmount"
                ></v-text-field>

                <v-select
                  disabled
                  :items="unitOfMeasurements"
                  item-text="unitName"
                  filled
                  return-object
                  v-model="requestData.unitOfMeasurement"
                  :rules="[(v) => !!v || '*Required']"
                  label="Unit Of Measurement"
                ></v-select>
                <!-- <v-menu
                  disabled
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="requestData.collectedDate"
                      label="Collected Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      disabled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="requestData.collectedDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  disabled
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="requestData.collectedTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      disabled
                      v-model="requestData.collectedTime"
                      label="Collected Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="requestData.collectedTime"
                    full-width
                    @click:minute="$refs.menu.save(requestData.collectedTime)"
                  ></v-time-picker>
                </v-menu> -->
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-sub-sample-form",
  props: ["toggleForm", "hasParticipant", "collectedSample"],
  created() {
    // console.log("The id is: " + this.$route.params.id);
    this.getInstitutionSites();
    this.getCollectionProtocolEvents();
    this.getAllSpecimenTypes();
    this.getAllUnitOfMeasurements();
    this.getSubSampleLineages();
    this.collectedSample.subSampleLineage = null;
    this.requestData = this.collectedSample;
    console.log("this.requestData", this.requestData);
  },
  data() {
    return {
      valid: false,
      departments: [],
      menu1: false,
      menu2: false,
      requestData: null,
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions([
      "getInstitutionSites",
      "getCollectionProtocolEvents",
      "getAllSpecimenTypes",
      "getAllUnitOfMeasurements",
      "getSubSampleLineages",
    ]),
    eventChanged(event) {
      console.log("event", event.label);
      apiClient
        .call(
          "GET",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${event.id}`,
          null
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    searchPatient() {
      console.log("searching now...");
      apiClient
        .call(
          "GET",
          "institutions/PatientMRNs?institutionId=" +
            this.currentInstitution.id +
            "&mrn=" +
            this.requestData.PatientMRN,
          null
        )
        .then((response) => {
          if (response.length > 0) {
            console.log(response[0].patient);
            this.requestData.SelectedPatient = response[0].patient;
          } else {
            this.requestData.SelectedPatient = null;
          }

          // this.toggleForm();
          // this.$store.dispatch("getDepartments");
        })
        .catch((err) => console.log(err));
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.parentSampleID = this.requestData.id;
        this.requestData.isSubsample = true;
        this.requestData.subSampleLineageID =
          this.requestData.subSampleLineage.id;
        // return;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocols/" +
              this.currentCollectionProtocol.id +
              "/collectedSamples",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.$store.dispatch("getCollectionProtocolCollectedSamples");
            this.toggleForm();
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "currentCollectionProtocol",
      "institutionSites",
      "collectionProtocolEvents",
      "specimenTypes",
      "unitOfMeasurements",
      "currentCollectionProtocolParticipant",
      "subSampleLineages",
    ]),
  },
};
</script>