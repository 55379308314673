<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div style="margin: 10px 0px">
        <span
          class="
            Text-color--dark
            Text-fontSize--16
            Text-fontWeight--bold
            Text-lineHeight--24
            Text-numericSpacing--proportional
            Text-typeface--base
            Text-wrap--wrap
            Text-display--inline
          "
          ><span>Collection Protocols</span></span
        >
      </div>
      <div style="display: flex; align-items: center">
        <v-text-field
          outlined
          style="max-width: 400px"
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn @click="toggleForm()" color="primary" elevation="0" small
          >Create</v-btn
        >
      </div>
      <v-data-table
        style="margin-top: 15px"
        :headers="headers"
        loading-text="Loading Collection Protocols..."
        :loading="isLoading"
        :items="collectionProtocols"
        @click:row="handleClick"
        :search="search"
        ><template v-slot:item.startDate="{ item }">
          {{ item.startDate | moment("YYYY-MM-DD") }}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{ item.endDate | moment("YYYY-MM-DD") }}
        </template></v-data-table
      >
    </div>
    <add-collection-protocol
      :toggleForm="toggleForm"
      v-if="showAddForm"
      :collectionProtocol="currentCollectionProtocol"
    />
  </div>
</template>
<script>
import AddCollectionProtocol from "../../components/AddForms/AddCollectionProtocol.vue";
import { v4 as uuid_v4 } from "uuid";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      search: "",
      showAddForm: false,
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Code", value: "code" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Expected Participants", value: "expectedParticipants" },
      ],
    };
  },
  created() {
    this.getCollectionProtocols();
  },
  computed: {
    ...mapGetters([
      "collectionProtocols",
      "currentCollectionProtocol",
      "isLoading",
    ]),
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocol"]),
    ...mapActions(["getCollectionProtocols"]),
    handleClick(value) {
      this.setSelectedCollectionProtocol(value);
      console.log(
        "currentCollectionProtocol: list clicked",
        this.currentCollectionProtocol
      );
      this.$router
        .push({
          name: "CollectionProtocolOverview",
          params: { collectionProtocolId: value.id },
        })
        .catch(() => {});
      console.log(value);
    },
    toggleForm() {
      this.showAddForm = !this.showAddForm;
    },
  },
  components: {
    AddCollectionProtocol,
  },
};
</script>