<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation v-if="requestData">
      <v-text-field
        dense
        label="Field Lable"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.prompt"
      ></v-text-field>

      <v-textarea
        dense
        label="Response Title"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.responseTitle"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.required"
        label="Is Required"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.required"
        dense
        label="Required Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.requiredMessage"
      ></v-text-field>

      <v-checkbox
        v-model="requestData.hasValidation"
        label="Has Validation"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpression"
      ></v-text-field>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpressionMessage"
      ></v-text-field>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.ordinal"
        type="number"
      ></v-text-field>
      <v-btn color="warning" @click="disable()" depressed small>Disable</v-btn>
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../../EditPage.vue";
export default {
  components: { EditPage },
  props: [
    "selectedNavItem",
    "toggleForm",
    "addFormField",
    "dataTypes",
    "formField",
  ],
  created() {
    this.requestData = this.formField;
  },
  data() {
    return {
      text: "vertical",
      valid: false,
      NoEmptyRule: [(v) => !!v || "*required"],
      search: null,
      requestData: null,
    };
  },
  methods: {
    disable() {
      this.addFormField(this.requestData, false, true);
    },
    submit() {
      if (this.$refs.form.validate()) {
        // console.log(this.requestData);
        this.addFormField(this.requestData, false);
      }
    },
  },
};
</script>
