<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Questioners
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Questioners</span></span
                >
                <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Questioner</v-btn
                >
              </div>
              <v-data-table
                @click:row="handleClick"
                :loading="isLoading"
                style="margin-top: 15px"
                :headers="headers"
                :items="collectionProtocolForms"
              ></v-data-table>
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-collection-protocol-Form
            selectedNavItem="Questioners"
            :toggleForm="toggleForm"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolForm from "../../components/AddForms/AddCollectionProtocolForm.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: { AddCollectionProtocolForm },
  name: "collection-protocol-forms-list",
  created() {
    this.getCollectionProtocolForms();
  },
  data() {
    return {
      showAddForm: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Description",
          value: "description",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocolabc"]),
    ...mapActions(["getCollectionProtocolForms"]),
    handleClick(value) {
      this.$store.commit("setSelectedCollectionProtocolabc", value);
      // this.setSelectedCollectionProtocolForm(setSelectedCollectionProtocolabc);
      this.$router
        .push({
          name: "CollectionProtocolFormEditor",
          params: { formId: value.id },
        })
        .catch(() => {});
      console.log(value.name);
    },
    toggleForm: function() {
      this.showAddForm = !this.showAddForm;
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolForms",
      "currentCollectionProtocol",
      "isLoading",
    ]),
  },
};
</script>
