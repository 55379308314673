<template>
  <div class="db-ChromeMainContent">
    <left-nav :leftNavItems="leftNavItems" title="Configurations" />
    <configuration-detail :selectedNavItem="selectedNavItem">
      <router-view />
    </configuration-detail>
  </div>
</template>
<script>
import LeftNav from "../../components/LeftNav.vue";
import ConfigurationDetail from "../../components/ConfigurationDetail.vue";
import { v4 as uuid_v4 } from "uuid";
export default {
  components: { LeftNav, ConfigurationDetail },
  name: "configuration",
  data() {
    return {
      leftNavItems: [
        {
          id: uuid_v4(),
          title: " Institution Management",
          showChildren: false,
          selected: false,
          pathName: "Institutions",
          children: [
            {
              id: uuid_v4(),
              title: "Institution",
              selected: false,
              pathName: "Institution",
              getterName: "getInstitutions",
            },
            {
              id: uuid_v4(),
              title: "Institution Site",
              selected: false,
              pathName: "InstitutionSite",
              getterName: "getInstitutionSites",
            },
          ],
        },
        {
          id: uuid_v4(),
          title: "LIMS Configuration",
          showChildren: true,
          selected: false,
          children: [
            {
              id: uuid_v4(),
              title: "Panel",
              selected: true,
              pathName: "Panel",
              getterName: "getPanels",
            },
            {
              id: uuid_v4(),
              title: "Test",
              selected: false,
              pathName: "Test",
              getterName: "getTests",
            },
            {
              id: uuid_v4(),
              title: "Panel Test",
              selected: false,
              pathName: "PanelTest",
              getterName: "getPanelTests",
            },
            {
              id: uuid_v4(),
              title: "Test Accepted Value",
              selected: false,
              pathName: "TestAcceptedValue",
              getterName: "getTestAcceptedValues",
            },
            {
              id: uuid_v4(),
              title: "Test Result Range",
              selected: false,
              pathName: "TestResultRange",
              getterName: "getTestResultRanges",
            },
            {
              id: uuid_v4(),
              title: "Order Comment Type",
              selected: false,
              pathName: "OrderCommentType",
              getterName: "getOrderCommentTypes",
            },
          ],
        },

        {
          id: uuid_v4(),
          title: "Storage Container",
          showChildren: false,
          selected: false,
          children: [
            {
              id: uuid_v4(),
              title: "Storage Container",
              selected: false,
              pathName: "StorageContainer",
            },
          ],
        },
        {
          id: uuid_v4(),
          title: "Advanced",
          showChildren: false,
          selected: false,
          children: [
            {
              id: uuid_v4(),
              title: "Department",
              selected: false,
              pathName: "Department",
              getterName: "getDepartments",
            },
            {
              id: uuid_v4(),
              title: "Ethnicity",
              selected: false,
              pathName: "Ethnicity",
              getterName: "getEthnicities",
            },
            {
              id: uuid_v4(),
              title: "Gender",
              selected: false,
              pathName: "Gender",
              getterName: "getGenders",
            },
            {
              id: uuid_v4(),
              title: "Sub Sample Lineage",
              selected: false,
              pathName: "SubSampleLineage",
              getterName: "getSubSampleLineages",
            },
            {
              id: uuid_v4(),
              title: "Labeling Schema",
              selected: false,
              pathName: "LabelingSchema",
              getterName: "getLabelingSchemas",
            },
            {
              id: uuid_v4(),
              title: "Marital Status",
              selected: false,
              pathName: "maritalStatuses",
              getterName: "getMaritalStatuses",
            },
            {
              id: uuid_v4(),
              title: "Patient Type",
              selected: false,
              pathName: "PatientType",
              getterName: "getPatientTypes",
            },
            {
              id: uuid_v4(),
              title: "Position Labeling Option",
              selected: false,
              pathName: "PositionLabelingOption",
              getterName: "getPositionLabelingOptions",
            },
            {
              id: uuid_v4(),
              title: "Priority",
              selected: false,
              pathName: "Priority",
              getterName: "getPriorities",
            },
            {
              id: uuid_v4(),
              title: "Race",
              selected: false,
              pathName: "Race",
              getterName: "getRaces",
            },
            {
              id: uuid_v4(),
              title: "Specimen Type Category",
              selected: false,
              pathName: "SpecimenTypeCategory",
              getterName: "getSpecimenTypeCategories",
            },
            {
              id: uuid_v4(),
              title: "Specimen Type",
              selected: false,
              pathName: "SpecimenType",
              getterName: "getSpecimenTypes",
            },
            {
              id: uuid_v4(),
              title: "Unit Of Measurement",
              selected: false,
              pathName: "UnitOfMeasurement",
              getterName: "getUnitOfMeasurements",
            },
            {
              id: uuid_v4(),
              title: "Unit Of Measurement Type",
              selected: false,
              pathName: "UnitOfMeasurementType",
              getterName: "getUnitOfMeasurementTypes",
            },
            {
              id: uuid_v4(),
              title: "Anatomic Site",
              selected: false,
              pathName: "AnatomicSite",
              getterName: "getAnatomicSites",
            },
            {
              id: uuid_v4(),
              title: "Pathological Status",
              selected: false,
              pathName: "PathologicalStatus",
              getterName: "getPathologicalStatuses",
            },
            {
              id: uuid_v4(),
              title: "Specimen Collection Procedure",
              selected: false,
              pathName: "SpecimenCollectionProcedure",
              getterName: "getSpecimenCollectionProcedures",
            },

            {
              id: uuid_v4(),
              title: "Collection Protocol Event Unit Of Measurement",
              selected: false,
              pathName: "CollectionProtocolEventUnitOfMeasurement",
              getterName: "getCollectionProtocolEventUnitOfMeasurements",
            },
          ],
        },
      ],
    };
  },
  computed: {
    selectedNavItem() {
      var title = "none";
      this.leftNavItems.forEach((leftNavItem) => {
        if (leftNavItem.selected) {
          title = leftNavItem.title;
        }
        leftNavItem.children.forEach((leftNav) => {
          if (leftNav.selected) {
            title = leftNav.title;
          }
        });
      });
      return title;
    },
  },
};
</script>