<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Collected Samples
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Collected Samples</span></span
                >
                <div>
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    class="mr-2"
                    @click="allocateCollectedSamples()"
                  >
                    Allocate</v-btn
                  >
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    @click="toggleForm()"
                    ><v-icon style="margin-right: 5px" right dark>
                      mdi-plus
                    </v-icon>
                    Collect Sample</v-btn
                  >
                </div>
              </div>
              <div class="d-flex align-center justify-center" v-if="isLoading">
                <v-progress-circular indeterminate color="primary" />
              </div>
              <v-treeview
                v-else
                v-model="tree"
                :open="initiallyOpen"
                :items="collectionProtocolCollectedSamples"
                activatable
                item-key="id"
                open-on-click
              >
                <template slot="label" slot-scope="{ item, open }">
                  <div>
                    <div
                      v-if="!item.addView"
                      class="d-flex align-center"
                      style="
                        min-width: 600px;
                        max-width: 800px;
                        border-bottom: 1px solid #e3e3e3;
                      "
                    >
                      <div
                        class="mr-3"
                        style="
                          height: 10px;
                          width: 10px;
                          border-radius: 5px;
                          background: #afb42b;
                        "
                      ></div>
                      <div
                        style="
                          width: 150px;
                          height: 40px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <!-- @click="onclicka(item)" -->
                        {{ item.sampleID }}
                      </div>
                      <v-spacer />
                      <div
                        style="
                          width: 250px;
                          height: 40px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <!-- @click="onclicka(item)" -->
                        {{ item.specimenType.title }}
                      </div>

                      <div
                        style="
                          width: 150px;
                          height: 40px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <!-- @click="onclicka(item)" -->
                        {{
                          item.collectedAmount +
                          "" +
                          item.unitOfMeasurement.unitSymbol
                        }}
                      </div>

                      <div
                        style="
                          width: 150px;
                          height: 40px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <!-- @click="onclicka(item)" -->
                        {{ item.createdDate | moment("YYYY-MM-DD") }}
                      </div>
                      <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                      <div>
                        <v-btn @click="toggleAddView(item)" icon small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                        <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                      </div>
                    </div>
                    <div v-else style="border: 1px solid #e3e3e3">
                      <div
                        class="d-flex align-center"
                        style="
                          min-width: 600px;
                          max-width: 800px;
                          background: #e3e3e3;
                        "
                      >
                        <div
                          class="mr-3"
                          style="
                            height: 10px;
                            width: 10px;
                            border-radius: 5px;
                            background: #afb42b;
                          "
                        ></div>
                        <div
                          style="
                            width: 150px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <!-- @click="onclicka(item)" -->
                          {{ item.sampleID }}
                        </div>
                        <v-spacer />
                        <div
                          style="
                            width: 250px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <!-- @click="onclicka(item)" -->
                          {{ item.specimenType.title }}
                        </div>

                        <div
                          style="
                            width: 150px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <!-- @click="onclicka(item)" -->
                          {{
                            item.collectedAmount +
                            "" +
                            item.unitOfMeasurement.unitSymbol
                          }}
                        </div>

                        <div
                          style="
                            width: 150px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <!-- @click="onclicka(item)" -->
                          {{ item.createdDate | moment("YYYY-MM-DD") }}
                        </div>
                        <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                        <div>
                          <v-btn @click="toggleAddView(item)" icon small
                            ><v-icon>mdi-plus</v-icon></v-btn
                          >
                          <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                        </div>
                      </div>
                      <div>
                        <div v-if="requestData">
                          <v-form
                            v-if="requestData"
                            ref="subSampleForm"
                            v-model="valid"
                            lazy-validation
                            style="min-height: 50px; max-width: 800px"
                            class="d-flex p-2 pb-0"
                            v-on:submit.prevent
                          >
                            <v-autocomplete
                              label="Specimen Type"
                              :items="specimenTypes"
                              :search-input.sync="SearchSpecimenType"
                              return-object
                              filled
                              v-model="requestData.specimenType"
                              item-text="title"
                              :rules="[(v) => !!v || '*Required']"
                              clearable
                            ></v-autocomplete>
                            <!-- <v-select
                              :items="specimenTypes"
                              item-text="title"
                              filled
                              return-object
                              v-model="requestData.specimenType"
                              :rules="[(v) => !!v || '*Required']"
                              label="SpecimenType"
                            ></v-select> -->
                            <v-select
                              :items="subSampleLineages"
                              item-text="title"
                              filled
                              class="ml-2"
                              return-object
                              v-model="requestData.subSampleLineage"
                              :rules="[(v) => !!v || '*Required']"
                              label="Sub Sample Lineage"
                            ></v-select>
                            <v-text-field
                              class="ml-2"
                              label="Collected Amount"
                              :rules="NoEmptyRule"
                              filled
                              type="number"
                              required
                              v-model="requestData.collectedAmount"
                            ></v-text-field>
                            <v-text-field
                              class="ml-2"
                              label="Sample ID"
                              filled
                              required
                              v-model="requestData.sampleId"
                            ></v-text-field>
                          </v-form>
                        </div>
                        <v-btn
                          @click="createSubSample(item)"
                          class="ml-2"
                          color="primary"
                          depressed
                          small
                          >Create</v-btn
                        >
                        <v-btn
                          @click="closeAdder(item)"
                          class="ml-2"
                          color="secondary"
                          depressed
                          small
                          >Cancel</v-btn
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </v-treeview>
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-collection-protocol-CollectedSample
            selectedNavItem="CollectedSample"
            :toggleForm="toggleForm"
          />
        </div>
        <div
          v-if="showAllocateCollectedSample"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <allocate-collected-sample
            selectedNavItem="CollectedSample"
            :toggleForm="toggleAllocateCollectedSample"
          />
        </div>

        <div
          v-if="showAddSubSampleForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-sub-sample-form
            selectedNavItem="Create Sub Collected Sample"
            :toggleForm="createSubCollectionProtocolCollectedSample"
            :collectedSample="selectedCollectedSample"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolCollectedSample from "../../components/AddForms/AddCollectionProtocolCollectedSample.vue";
import { mapGetters, mapActions } from "vuex";
import AllocateCollectedSample from "../../components/AllocateCollectedSample.vue";
import AddSubSampleForm from "../../components/AddForms/AddSubSampleForm.vue";
import apiClient from "../../API/apiClient";
export default {
  components: {
    AddCollectionProtocolCollectedSample,
    AllocateCollectedSample,
    AddSubSampleForm,
  },
  name: "collection-protocol-CollectedSamples",
  created() {
    this.getCollectionProtocolCollectedSamples();
    this.getSubSampleLineages();
  },
  data() {
    return {
      valid: false,
      tree: [],
      items: [],
      SearchSpecimenType: null,
      SearchUnitOfMeasurement: null,
      NoEmptyRule: [(v) => !!v || "*required"],
      selectedCollectedSample: null,
      initiallyOpen: ["f7c1640b-f7aa-46b9-724a-08d99dfb1dc9"],
      showAddForm: false,
      showAddSubSampleForm: false,
      showAllocateCollectedSample: false,
      headers: [
        {
          text: "Event",
          align: "start",
          value: "collectionProtocolEvent.label",
        },
        {
          text: "Patient",
          align: "start",
          value: "patient",
        },
        {
          text: "Sample Id",
          align: "start",
          value: "sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "specimenType.title",
        },
        {
          text: "Quantity",
          align: "start",
          value: "quantity",
        },
        {
          text: "",
          align: "start",
          value: "actions",
        },
        // {
        //   text: "Collected Amount",
        //   align: "start",
        //   value: "collectedAmount",
        // },
        // {
        //   text: "Remaining Amount",
        //   align: "start",
        //   value: "remainingAmount",
        // },
        // {
        //   text: "Unit of Measurment",
        //   align: "start",
        //   value: "unitOfMeasurement.unitName",
        // },
      ],
      childheaders: [
        // {
        //   text: "Event",
        //   align: "start",
        //   value: "collectionProtocolEvent.label",
        // },
        // {
        //   text: "Patient",
        //   align: "start",
        //   value: "patient",
        // },
        {
          text: "Sample Id",
          align: "start",
          value: "sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "specimenType.title",
        },
        {
          text: "Quantity",
          align: "start",
          value: "quantity",
        },
        {
          text: "",
          align: "start",
          value: "actions",
        },
        // {
        //   text: "Collected Amount",
        //   align: "start",
        //   value: "collectedAmount",
        // },
        // {
        //   text: "Remaining Amount",
        //   align: "start",
        //   value: "remainingAmount",
        // },
        // {
        //   text: "Unit of Measurment",
        //   align: "start",
        //   value: "unitOfMeasurement.unitName",
        // },
      ],
      requestData: null,
    };
  },
  methods: {
    ...mapActions([
      "getCollectionProtocolCollectedSamples",
      "getSubSampleLineages",
    ]),
    createSubSample(item) {
      if (this.$refs.subSampleForm.validate()) {
        this.requestData.parentSampleID = this.requestData.id;
        this.requestData.sampleID = "";
        this.requestData.isSubsample = true;
        this.requestData.subSampleLineageID =
          this.requestData.subSampleLineage.id;
        this.requestData.specimenTypeID = this.requestData.specimenType.id;
        // return;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocols/" +
              this.currentCollectionProtocol.id +
              "/collectedSamples",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.$store.dispatch("getCollectionProtocolCollectedSamples");
            // this.toggleForm();
            item.addView = false;
          })
          .catch((err) => console.log(err));
      }
    },
    closeAdder(item) {
      item.addView = false;
    },
    getChildCollectionProtocolCollectedSample() {
      var newCollectionProtocolCollectedSamples = [];
      this.collectionProtocolCollectedSamples.forEach(
        (collectionProtocolCollectedSample) => {
          collectionProtocolCollectedSample.children = [];
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/collectionProtocols/" +
                this.currentCollectionProtocol.id +
                "/collectedSamples?IsSubsample=true",
              null
            )
            .then((collectedSamples) => {
              console.log(collectedSamples);
              collectedSamples.forEach((collectedSample) => {
                if (
                  collectionProtocolCollectedSample.id ==
                  collectedSample.parentSampleID
                ) {
                  this.getChildCollectionProtocolCollectedSample(
                    collectedSample
                  );
                  collectionProtocolCollectedSample.children.push(
                    collectedSample
                  );
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
          newCollectionProtocolCollectedSamples.push(
            collectionProtocolCollectedSample
          );
          console.log(
            "newCollectionProtocolCollectedSamples",
            newCollectionProtocolCollectedSamples
          );
          this.$$store.commit("setCollectionProtocolCollectedSamples", {
            collectionProtocolCollectedSamples:
              newCollectionProtocolCollectedSamples,
          });
        }
      );
    },
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    toggleAllocateCollectedSample() {
      this.showAllocateCollectedSample = false;
    },
    allocateCollectedSamples: function () {
      this.showAllocateCollectedSample = !this.showAllocateCollectedSample;
    },
    createSubCollectionProtocolCollectedSample: function (item) {
      this.selectedCollectedSample = item;
      this.showAddSubSampleForm = !this.showAddSubSampleForm;
    },
    toggleAddView(item) {
      item.addView = !item.addView;
      if (item.addView) {
        this.requestData = { ...item };
        this.$set(this.requestData, "subSampleLineage", null);
        this.$set(this.requestData, "sampleID", "");
        // this.collectedSample.subSampleLineage = null;
      } else {
        this.requestData = null;
      }
    },
  },
  watch: {
    SearchUnitOfMeasurement(val) {
      if (val) {
        if (this.SearchedUnitOfMeasurement != val) {
          this.$store.commit("setUnitOfMeasurements", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/unitOfMeasurements?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedUnitOfMeasurement = val;
              this.$store.commit("setUnitOfMeasurements", response);
            })
            .catch((err) => {});
        }
      }
    },
    SearchSpecimenType(val) {
      if (val) {
        if (this.SearchedSpecimenType != val) {
          this.$store.commit("setSpecimenTypes", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/specimenTypes?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedSpecimenType = val;
              this.$store.commit("setSpecimenTypes", response);
            })
            .catch((err) => {});
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolCollectedSamples",
      "currentCollectionProtocol",
      "currentInstitution",
      "isLoading",
      "specimenTypes",
      "subSampleLineages",
    ]),
  },
};
</script>