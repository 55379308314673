<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="unitOfMeasurementTypes"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedUnitOfMeasurementType"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement Type"
      ></v-select>
      <v-text-field
        dense
        label="Unit Name"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.unitName"
      ></v-text-field>

      <v-text-field
        dense
        label="Unit Symbol"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.unitSymbol"
      ></v-text-field>
      <v-text-field
        dense
        label="Code"
        :rules="[
          (v) => (v || '').length <= 2 || 'Code must be 2 characters or less',
        ]"
        counter="2"
        filled
        required
        v-model="requestData.code"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.description"
      ></v-textarea>

      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../EditPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { EditPage },
  name: "edit-unit-of-measurement",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    console.log("this.selectedEditObject");
    this.requestData = this.selectedEditObject;
    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/unitOfMeasurementTypes/" +
          this.requestData.unitOfMeasurementTypeID,
        null
      )
      .then((response) => {
        console.log("unitOfMeasurementTypes: " + this.currentInstitution.id);
        this.requestData.SelectedUnitOfMeasurementType = response;
      })
      .catch((err) => console.log(err));
    this.getUnitOfMeasurementTypes();
  },
  data() {
    return {
      valid: false,
      requestData: {
        unitName: "",
        unitSymbol: "",
        code: "",
        description: "",
        SelectedInstitution: null,
        institutionID: null,
        SelectedUnitOfMeasurementType: null,
        unitOfMeasurementTypeID: null,
      },
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getUnitOfMeasurementTypes"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.institutionId = this.currentInstitution.id;
        this.requestData.unitOfMeasurementTypeId =
          this.requestData.SelectedUnitOfMeasurementType.id;
        apiClient
          .call(
            "PUT",
            "institutions/" +
              this.currentInstitution.id +
              "/UnitOfMeasurementTypes/" +
              this.requestData.SelectedUnitOfMeasurementType.id +
              "/unitOfMeasurements/" +
              this.requestData.id,
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getUnitOfMeasurements");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "unitOfMeasurementTypes",
      "selectedEditObject",
    ]),
  },
};
</script>