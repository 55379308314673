<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.description"
      ></v-textarea>
      <v-text-field
        dense
        label="ICD Code"
        filled
        v-model="requestData.ICDCode"
      ></v-text-field>
      <v-select
        :items="specimenTypeCategories"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedSpecimenTypeCategory"
        @change="getSpecimenTypes()"
        :rules="[(v) => !!v || '*Required']"
        label="Specimen Type Category"
      ></v-select>
      <v-select
        :items="specimenTypes"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedSpecimenType"
        :rules="[(v) => !!v || '*Required']"
        label="Specimen Type"
      ></v-select>
      <v-select
        :items="departments"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedDepartment"
        :rules="[(v) => !!v || '*Required']"
        label="Department"
      ></v-select>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../EditPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { EditPage },
  name: "edit-panel",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.getDepartments();
    this.getSpecimenTypeCategories();
    this.requestData = this.selectedEditObject;
  },
  data() {
    return {
      valid: false,
      requestData: {
        title: "",
        abbreviation: "",
        description: "",
        ICDCode: "",
        SelectedInstitution: null,
        InstitutionId: null,
        SelectedSpecimenTypeCategory: null,
        SpecimenTypeCategoryId: null,
        SelectedSpecimenType: null,
        SpecimenTypeId: null,
        SelectedDepartment: null,
        DepartmentId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions([
      "getDepartments",
      "getSpecimenTypes",
      "getSpecimenTypeCategories",
      "getSpecimenTypesBySpecimenTypeCategory",
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.SpecimenTypeId =
          this.requestData.SelectedSpecimenType.id;
        this.requestData.DepartmentId = this.requestData.SelectedDepartment.id;
        apiClient
          .call(
            "PUT",
            "institutions/" +
              this.currentInstitution.id +
              "/panels/" +
              this.selectedEditObject.id,
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getPanels");
          })
          .catch((err) => console.log(err));
      }
    },
    getSpecimenTypes() {
      this.getSpecimenTypesBySpecimenTypeCategory(
        this.requestData.SelectedSpecimenTypeCategory
      );
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "specimenTypeCategories",
      "departments",
      "specimenTypes",
      "selectedEditObject",
    ]),
  },
};
</script>