<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="First Name"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.FirstName"
      ></v-text-field>

      <v-text-field
        dense
        label="Last Name"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.LastName"
      ></v-text-field>

      <v-text-field
        dense
        label="Email"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Email"
      ></v-text-field>

      <v-text-field
        dense
        autocomplete="false"
        label="Password"
        :rules="NoEmptyRule"
        filled
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show1 = !show1"
        :type="show1 ? 'text' : 'password'"
        required
        v-model="requestData.Password"
      ></v-text-field>

      <v-text-field
        dense
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show2 = !show2"
        :type="show2 ? 'text' : 'password'"
        label="Confirm Password"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.ConfirmPassword"
      ></v-text-field>
      <v-text-field
        dense
        label="PhoneNumber"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.PhoneNumber"
      ></v-text-field>
      <v-autocomplete
        label="Roles"
        :items="requestData.AllRoles"
        chips
        filled
        return-object
        v-model="requestData.Roles"
        multiple
        item-text="title"
        :rules="[(v) => !!v || '*Required']"
        clearable
        deletable-chips
      ></v-autocomplete>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-account",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      show1: false,
      show2: true,
      departments: [],
      requestData: {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        ConfirmPassword: "",
        PhoneNumber: "",
        AllRoles: [
          "Administrator",
          "Bench Scientist",
          "Clinician",
          "Coordinator",
          "Institution Administrator",
          "Principal Investigator",
          "Researcher",
          "Site Administrator",
          "Super Administrator",
          "Technician",
          "Tissue Banker",
        ],
        Roles: [],
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log("this.requestData", this.requestData);
        // return;
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call("POST", "users", this.requestData)
          .then((response) => {
            console.log(response);
            console.log(
              "created: ",
              this.requestData.Email,
              this.requestData.password
            );
            this.toggleForm();
            this.$store.dispatch("getAccounts");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>
