<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <div>
            <div>
              <span
                class="
                  Text-color--default
                  Text-fontSize--14
                  Text-fontWeight--medium
                  Text-lineHeight--20
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Create a Collection Protocol Participant</span></span
              >
            </div>
            <div>{{ currentCollectionProtocol.title }}</div>
          </div>
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-on:submit.prevent
              >
                <div>
                  <!-- <div>Search</div> -->
                  <div class="d-flex">
                    <v-text-field
                      dense
                      label="MRN"
                      required
                      outlined
                      v-on:keyup.enter="searchPatient()"
                      v-model="PatientMRN"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      class="ml-3"
                      label="Phone Number"
                      required
                      v-on:keyup.enter="searchPatient()"
                      v-model="PhoneNumber"
                    ></v-text-field>
                  </div>
                  <v-data-table
                    style="padding-top: 10px"
                    v-model="selectedResults"
                    :headers="resultHeaders"
                    :items="requestData.SelectedPatients"
                    :single-select="false"
                    item-key="id"
                    show-select
                    hide-default-footer
                    class="elevation-1"
                    ><template v-slot:item.name="{ item }">
                      <div>
                        {{ item.firstName }}
                        {{ item.fatherName }}
                        {{ item.grandFatherName }}
                      </div>
                    </template>
                    <template v-slot:item.birthDate="{ item }">
                      <div>
                        {{ item.birthDate | moment("from", true) }}
                      </div>
                    </template></v-data-table
                  >
                </div>
                <div
                  v-if="requestData.SelectedPatient"
                  style="
                    min-height: 60px;
                    border-radius: 5px;
                    border: 2px solid #5469d4;
                    border-left: 5px solid #5469d4;
                    margin-bottom: 10px;
                    padding: 10px 20px;
                  "
                >
                  <div class="h3">
                    {{ requestData.SelectedPatient.firstName }}
                    {{ requestData.SelectedPatient.fatherName }}
                    {{ requestData.SelectedPatient.grandFatherName }}
                  </div>
                  <div class="d-flex">
                    <div>Date of Birth:</div>
                    <div>{{ requestData.SelectedPatient.birthDate }}</div>
                  </div>
                  <div class="d-flex">
                    <div>Gender:</div>
                    <div>
                      {{ requestData.SelectedPatient.gender }}
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-collection-protocol-Participant",
  props: ["toggleForm"],
  created() {
    console.log("The id is: " + this.$route.params.id);
  },
  data() {
    return {
      valid: false,
      departments: [],
      selectedResults: [],
      menu: false,
      menu1: false,
      resultHeaders: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Age",
          align: "start",
          value: "birthDate",
        },
        {
          text: "Phone Number",
          align: "start",
          value: "phoneNumber",
        },
      ],
      PatientMRN: "",
      PhoneNumber: "",
      requestData: {
        SelectedPatient: null,
        SelectedPatients: [],

        InstitutionId: null,
        CollectionProtocolId: null,
        PatientId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  watch: {
    PhoneNumber(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PatientMRN = "";
      }
    },
    PatientMRN(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PhoneNumber = "";
      }
    },
  },
  methods: {
    searchPatient() {
      console.log("searching now...");
      this.requestData.SelectedPatients = [];
      if (this.PatientMRN || this.PatientMRN.length != 0) {
        apiClient
          .call(
            "GET",
            "institutions/PatientMRNs?institutionId=" +
              this.currentInstitution.id +
              "&mrn=" +
              this.PatientMRN,
            null
          )
          .then((response) => {
            if (response.length > 0) {
              response.forEach((patientMRN) => {
                this.requestData.SelectedPatients.push(patientMRN.patient);
                console.log("patientMRN.patient", patientMRN.patient);
              });
              // console.log(response[0].patient);
              // this.requestData.SelectedPatient = response[0].patient;
            } else {
              this.requestData.SelectedPatients = [];
            }

            // this.toggleForm();
            // this.$store.dispatch("getDepartments");
          })
          .catch((err) => console.log(err));
      } else if (this.PhoneNumber || this.PhoneNumber.length != 0) {
        console.log("searching with phone number::", this.PhoneNumber);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/patients?PhoneNumber=" +
              this.PhoneNumber,
            null
          )
          .then((response) => {
            if (response.length > 0) {
              response.forEach((patient) => {
                this.requestData.SelectedPatients.push(patient);
                console.log("patientMRN.patient", patient);
              });
              // console.log(response[0].patient);
              // this.requestData.SelectedPatient = response[0].patient;
            } else {
              this.requestData.SelectedPatients = [];
            }

            // this.toggleForm();
            // this.$store.dispatch("getDepartments");
          })
          .catch((err) => console.log(err));
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        console.log(this.selectedResults);
        // return;
        // console.log(this.currentInstitution, this.currentCollectionProtocol);
        this.selectedResults.forEach((patient) => {
          this.requestData.InstitutionId = this.currentInstitution.id;
          this.requestData.CollectionProtocolId =
            this.currentCollectionProtocol.id;
          this.requestData.PatientId = patient.id;
          apiClient
            .call(
              "POST",
              "institutions/" +
                this.currentInstitution.id +
                "/collectionProtocols/" +
                this.currentCollectionProtocol.id +
                "/collectionProtocolParticipants",
              this.requestData
            )
            .then((response) => {
              console.log(response);
              this.$store.dispatch("getCollectionProtocolParticipants");
              this.toggleForm();
            })
            .catch((err) => console.log(err));
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "institutionSites",
      "currentCollectionProtocol",
    ]),
  },
};
</script>