<template>
  <div>
    <div>
      <router-link
        :to="
          '/collection-protocols/' + currentCollectionProtocol.id + '/overview'
        "
        >{{ currentCollectionProtocol.title }}</router-link
      ><span style="padding: 5px">/</span
      ><router-link
        :to="
          '/collection-protocols/' +
          currentCollectionProtocol.id +
          '/participants'
        "
        >Participants</router-link
      ><span style="padding: 5px">/</span
      >{{ currentCollectionProtocolParticipant.patient.firstName }}
      {{ currentCollectionProtocolParticipant.patient.fatherName }}
    </div>

    <div>
      <div class="d-flex p-3 pl-7">
        <div class="d-flex flex-column justify-start">
          <div class="mt-1 font-weight-bold text-right">MRNs</div>
          <div class="mt-1 font-weight-bold text-right">First Name</div>
          <div class="mt-1 font-weight-bold text-right">Father Name</div>
          <div class="mt-1 font-weight-bold text-right">Grand Father Name</div>
          <div class="mt-1 font-weight-bold text-right">Birth Date</div>
          <div class="mt-1 font-weight-bold text-right">Gender</div>
          <div class="mt-1 font-weight-bold text-right">Race</div>
          <div class="mt-1 font-weight-bold text-right">Ethnicities</div>
          <div class="mt-1 font-weight-bold text-right">Vital Status</div>
          <!-- <div class="mt-1 font-weight-bold text-right">Gender</div> -->
        </div>
        <div class="d-flex flex-column justify-start ml-1">
          <div class="ml-2 mt-1 font-weight-light text-left">
            <div class="d-flex" style="height: 22px">
              <v-chip
                label
                small
                outlined
                v-for="patientMRN in patientMRNs"
                :key="patientMRN.id"
                >{{ patientMRN.mrn }}</v-chip
              >
            </div>
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{ currentCollectionProtocolParticipant.patient.firstName }}
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{ currentCollectionProtocolParticipant.patient.fatherName }}
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{ currentCollectionProtocolParticipant.patient.grandFatherName }}
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{
              currentCollectionProtocolParticipant.patient.birthDate
                | moment("YYYY-MM-DD")
            }}
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{ currentCollectionProtocolParticipant.patient.gender.title }}
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            <div class="d-flex" style="height: 22px">
              <v-chip
                label
                small
                outlined
                v-for="patientRace in patientRaces"
                :key="patientRace.id"
                >{{ patientRace.race.title }}</v-chip
              >
            </div>
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            <div class="d-flex" style="height: 22px">
              <v-chip
                label
                small
                outlined
                v-for="patientEthnicity in patientEthnicities"
                :key="patientEthnicity.id"
                >{{ patientEthnicity.ethnicity.title }}</v-chip
              >
            </div>
          </div>
          <div class="ml-2 mt-1 font-weight-light text-left">
            {{ currentCollectionProtocolParticipant.patient.vitalStatus.title }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-btn
        color="primary"
        @click="showDisableDialog"
        style="margin-top: 15px"
        size="small"
        >Disable</v-btn
      >
    </div>
    <div class="mt-4 h5">Collected Samples</div>
    <div>
      <div class="d-flex align-center justify-center" v-if="isLoading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <v-treeview
        v-else
        v-model="tree"
        :open="initiallyOpen"
        :items="collectionProtocolCollectedSamples"
        activatable
        item-key="id"
        open-on-click
      >
        <template slot="label" slot-scope="{ item, open }">
          <div>
            <div
              class="d-flex align-center"
              style="
                min-width: 600px;
                max-width: 800px;
                border-bottom: 1px solid #e3e3e3;
              "
            >
              <div
                class="mr-3"
                style="
                  height: 10px;
                  width: 10px;
                  border-radius: 5px;
                  background: #afb42b;
                "
              ></div>
              <div
                style="
                  width: 150px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                <!-- @click="onclicka(item)" -->
                {{ item.sampleID }}
              </div>
              <v-spacer />
              <div
                style="
                  width: 250px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                <!-- @click="onclicka(item)" -->
                {{ item.specimenType.title }}
              </div>

              <div
                style="
                  width: 150px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                <!-- @click="onclicka(item)" -->
                {{
                  item.collectedAmount + "" + item.unitOfMeasurement.unitSymbol
                }}
              </div>

              <div
                style="
                  width: 150px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                {{ item.createdDate | moment("YYYY-MM-DD") }}
              </div>
            </div>
          </div>
        </template>
      </v-treeview>
      <div>
        <div class="mt-4 h5">Events</div>
        <v-data-table
          style="margin-top: 15px"
          :headers="headers"
          @click:row="handleClick"
          :items="collectionProtocolEvents"
        ></v-data-table>
      </div>
    </div>
    <add-planned-collected-sample :toggleForm="toggleForm" v-if="showAddForm" />
    <v-dialog v-model="isDisableDialogVisible" max-width="290">
      <v-card>
        <v-card-title class="headline">Disable Participant</v-card-title>
        <v-card-text>
          Are you sure you want to disable this participant?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="isDisableDialogVisible = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="disableParticipant"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import apiClient from "../../API/apiClient";
import AddPlannedCollectedSample from "../../components/AddForms/AddPlannedCollectedSample.vue";
export default {
  components: { AddPlannedCollectedSample },
  name: "collection-protocol-participant-overview",
  data() {
    return {
      tree: [],
      showAddForm: false,
      patientMRNs: [],
      patientRaces: [],
      patientEthnicities: [],
      initiallyOpen: [],
      headers: [
        {
          text: "Label",
          align: "start",
          value: "label",
        },
        {
          text: "Code",
          align: "start",
          value: "eventCode",
        },
      ],
      isDisableDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentCollectionProtocol",
      "currentCollectionProtocolParticipant",
      "collectionProtocolCollectedSamples",
      "collectionProtocolEvents",
      "isLoading",
      "currentInstitution",
    ]),
  },
  created() {
    console.log("created.....");
    this.getCollectionProtocolCollectedSamples();
    this.getCollectionProtocolEvents();

    apiClient
      .call(
        "GET",
        `institutions/${this.currentInstitution.id}/patients/${this.currentCollectionProtocolParticipant.patientID}/patientMRNs`,
        null
      )
      .then((response) => {
        this.patientMRNs = response;
      })
      .catch((err) => {
        console.log(err);
      });
    apiClient
      .call(
        "GET",
        `institutions/${this.currentInstitution.id}/patients/${this.currentCollectionProtocolParticipant.patientID}/patientRaces`,
        null
      )
      .then((response) => {
        this.patientRaces = response;
      })
      .catch((err) => {
        console.log(err);
      });

    apiClient
      .call(
        "GET",
        `institutions/${this.currentInstitution.id}/patients/${this.currentCollectionProtocolParticipant.patientID}/patientEthnicities`,
        null
      )
      .then((response) => {
        this.patientEthnicities = response;
        console.log("_+_+_+_+_+_+_+_+_+_+:::::::::::");
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    ...mapActions([
      "getCollectionProtocolCollectedSamples",
      "getCollectionProtocolEvents",
    ]),
    showDisableDialog() {
      this.isDisableDialogVisible = true;
    },
    disableParticipant() {
      console.log("disableParticipant");

      // POST;
      // /api/institutions/{institutionId}/collectionProtocols/{collectionProtocolId}/collectionProtocolParticipants/{id}/disable

      apiClient
        .call(
          "POST",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolParticipants/${this.currentCollectionProtocolParticipant.id}/disable`,
          this.currentCollectionProtocolParticipant
        )
        .then((response) => {
          console.log(response);
          this.isDisableDialogVisible = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(item) {
      console.log(item);
      this.showAddForm = true;
    },
    toggleForm() {
      this.showAddForm = !this.showAddForm;
    },
  },
};
</script>
