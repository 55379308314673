<template>
  <div class="db-ChromeMainContent">
    <div tabindex="-1" class="db-ChromeMainContent-content">
      <v-app>
        <v-main>
          <div class="db-CardlessUIPageWrapper db-CustomerDetailPage">
            <div style="display: flex">
              <div
                class="db-TwoColumnLayout--leftColumn"
                style="min-width: 240px; max-width: 320px; flex: 0 0 30%"
              >
                <div style="margin-top: 0px"></div>
                <div style="align-self: flex-start; position: sticky">
                  <div class="Box-root Padding-bottom--48">
                    <div class="Box-root Padding-bottom--20">
                      <div
                        class="
                          Card-root Card--radius--all
                          Section
                          Box-root
                          Box-background--white
                        "
                        style="overflow: visible"
                      >
                        <div
                          class="
                            Box-root
                            Padding-top--20
                            Padding-bottom--12
                            Padding-horizontal--0
                          "
                        >
                          <div
                            class="
                              Box-root
                              Flex-flex
                              Flex-alignItems--baseline
                              Flex-direction--row
                            "
                            style="position: relative; min-width: 0px"
                          >
                            <div
                              class="TextAligner Box-root"
                              style="
                                line-height: 32px;
                                font-size: 28px;
                                flex: 0 0 auto;
                              "
                            ></div>
                            <div
                              class="
                                Box-root
                                Flex-flex
                                Flex-alignItems--baseline
                                Flex-direction--row
                                Flex-justifyContent--spaceBetween
                              "
                              style="
                                min-width: 0px;
                                line-height: 0;
                                flex: 1 1 auto;
                              "
                            >
                              <div
                                class="
                                  Box-root
                                  Flex-flex
                                  Flex-alignItems--baseline
                                  Flex-direction--row
                                  Flex-wrap--wrap
                                "
                                style="flex: 1 1 auto; min-width: 0px"
                              >
                                <div
                                  class="
                                    Box-root
                                    Padding-right--8
                                    Padding-bottom--8
                                  "
                                  style="pointer-events: none; min-width: 0px"
                                >
                                  <div
                                    class="
                                      Box-root
                                      Flex-flex
                                      Flex-direction--column
                                      Flex-justifyContent--flexStart
                                      Flex-wrap--nowrap
                                    "
                                    style="margin-left: -4px; margin-top: -4px"
                                  >
                                    <div
                                      class="
                                        Box-root Box-hideIfEmpty
                                        Margin-top--4
                                        Margin-left--4
                                      "
                                      style="pointer-events: auto"
                                    >
                                      <div
                                        class="Box-root"
                                        style="pointer-events: none"
                                      >
                                        <div
                                          class="
                                            Box-root
                                            Flex-flex
                                            Flex-alignItems--baseline
                                            Flex-direction--row
                                            Flex-justifyContent--flexStart
                                            Flex-wrap--nowrap
                                          "
                                          style="
                                            margin-left: -8px;
                                            margin-top: -8px;
                                          "
                                        >
                                          <div
                                            class="
                                              Box-root Box-hideIfEmpty
                                              Margin-top--8
                                              Margin-left--8
                                            "
                                            style="pointer-events: auto"
                                          >
                                            <span
                                              class="
                                                Text-color--dark
                                                Text-fontSize--28
                                                Text-fontWeight--bold
                                                Text-lineHeight--32
                                                Text-numericSpacing--proportional
                                                Text-typeface--base
                                                Text-wordBreak--word
                                                Text-wrap--wrap
                                                Text-display--block
                                              "
                                              style="margin-top: -1px"
                                              >{{ form.title }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="
                                        Box-root Box-hideIfEmpty
                                        Margin-top--4
                                        Margin-left--4
                                      "
                                      style="pointer-events: auto"
                                    >
                                      <span
                                        class="
                                          Text-color--gray
                                          Text-fontSize--16
                                          Text-fontWeight--regular
                                          Text-lineHeight--24
                                          Text-numericSpacing--proportional
                                          Text-typeface--base
                                          Text-wrap--noWrap
                                          Text-display--block
                                        "
                                        ><span
                                          class="
                                            Text-color--disabled
                                            Text-numericSpacing--proportional
                                            Text-typeface--base
                                            Text-wrap--wrap
                                            Text-display--inline
                                          "
                                          ><span
                                            >{{ form.description }}
                                          </span></span
                                        ></span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            Box-root
                            Padding-top--16
                            Flex-flex
                            Flex-direction--row
                          "
                          style="min-height: 150px"
                        >
                          <div>
                            <v-btn
                              @click="toggleForm('SingleLine')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Single Line</v-btn
                            ><v-btn
                              dense
                              @click="toggleForm('MultiLine')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Multi Line</v-btn
                            ><v-btn
                              dense
                              @click="toggleForm('TimePicker')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Time Picker</v-btn
                            ><v-btn
                              @click="toggleForm('DatePicker')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Date Picker</v-btn
                            ><v-btn
                              @click="toggleForm('DateTimePicker')"
                              dense
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Date & Time Picker</v-btn
                            ><v-btn
                              dense
                              @click="toggleForm('Checkbox')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Checkbox</v-btn
                            ><v-btn
                              dense
                              elevation="0"
                              small
                              @click="toggleForm('CheckboxList')"
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Checkbox List</v-btn
                            ><v-btn
                              dense
                              elevation="0"
                              small
                              @click="toggleForm('RadioList')"
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Radio List</v-btn
                            ><v-btn
                              dense
                              @click="toggleForm('Select')"
                              elevation="0"
                              small
                              style="margin-left: 10px; margin-top: 10px"
                              color="primary"
                              >Select</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="db-TwoColumnLayout--rightColumn Box-root"
                style="flex-grow: 1"
              >
                <div class="Box-root Padding-top--24 Padding-bottom--48">
                  <div
                    class="
                      Box-root
                      Box-divider--light-bottom-1
                      Flex-flex
                      Flex-direction--row
                      Flex-justifyContent--spaceBetween
                    "
                  >
                    <div>
                      <v-tabs v-model="tab" style="width: 600px">
                        <v-tab> Editor </v-tab>
                        <v-tab> Preview </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tab" style="margin-top: 20px">
                        <v-tab-item>
                          <div
                            style="
                              min-height: 300px;
                              width: 600px;
                              padding: 0px;
                            "
                          >
                            <div
                              v-for="(formfield, index) in form.formfields"
                              style="
                                border-left-width: 5px;
                                border-top-width: 0px;
                                border-right-width: 0px;
                                border-bottom-width: 1px;
                                border-color: #bbdefb;
                                border-style: solid;
                              "
                              class="outer"
                              :key="index"
                            >
                              <div
                                class="top"
                                style="
                                  min-height: 120px;
                                  margin: 0px 20px;
                                  padding: 0px 15px;
                                "
                              >
                                <div style="padding: 10px 0px">
                                  {{ formfield.prompt }}
                                </div>
                                <v-text-field
                                  dense
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TB'
                                  "
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  outlined
                                ></v-text-field>
                                <v-textarea
                                  outlined
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  rows="2"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TA'
                                  "
                                ></v-textarea>
                                <custom-time-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                  :time="formfield.prompt"
                                />
                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                  ref="menu"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="time"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="time"
                                      outlined
                                      dense
                                      label="Picker in menu"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu2"
                                    v-model="time"
                                    full-width
                                    @click:minute="$refs.menu.save(time)"
                                  ></v-time-picker>
                                </v-menu> -->
                                <!-- <a-time-picker
                                  use12-hours
                                  format="h:mm a"
                                  :placeholder="formfield.placeholder"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                /> -->
                                <custom-date-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                  :formfield="formfield"
                                />

                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Picker in menu"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu> -->
                                <custom-date-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DTP'
                                  "
                                  :formfield="formfield"
                                />

                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DTP'
                                  "
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Picker in menu"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu> -->
                                <!-- <a-date-picker
                                  :placeholder="formfield.placeholder"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                /> -->
                                <!-- <a-date-picker
                          show-time="true"
                          :placeholder="formfield.placeholder"
                          v-if="
                            formfield.dataType.dataTypeAbbreviation == 'DTP'
                          "
                        /> -->
                                <v-container
                                  class="px-0"
                                  fluid
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'CB'
                                  "
                                >
                                  <v-checkbox
                                    v-if="
                                      formfield.dataType.dataTypeAbbreviation ==
                                      'CB'
                                    "
                                    label="Checkbox 1"
                                  ></v-checkbox>
                                </v-container>
                                <!-- <a-checkbox
                                  :placeholder="formfield.placeholder"
                                >
                                  Checkbox
                                </a-checkbox> -->
                                <div
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'CBL'
                                  "
                                >
                                  <v-checkbox
                                    style="margin: 0px; padding: 0px"
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                    :label="option"
                                  ></v-checkbox>
                                </div>
                                <div
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'RL'
                                  "
                                >
                                  <v-radio-group>
                                    <v-radio
                                      v-for="(
                                        option, index
                                      ) in formfield.Options"
                                      :key="index"
                                      :label="option"
                                    ></v-radio>
                                  </v-radio-group>
                                  <!-- <a-radio-group :options="formfield.Options" /> -->
                                </div>
                                <v-select
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'SL'
                                  "
                                  :items="formfield.Options"
                                  :label="formfield.prompt"
                                  outlined
                                  dense
                                ></v-select>
                                <!-- <a-select
                                  style="width: 120px"
                                  
                                >
                                  <a-select-option
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                    style="width: 200px"
                                    :value="option"
                                  >
                                    {{ option }}
                                  </a-select-option>
                                </a-select> -->
                              </div>
                              <div
                                class="below"
                                style="
                                  background: white;
                                  min-height: 120px;
                                  width: 100%;
                                  opacity: 0.1;
                                "
                              ></div>
                              <!-- <div>{{ formfield }}</div> -->
                              <!-- <div>{{ index }}</div> -->
                            </div>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <div
                            style="
                              min-height: 300px;
                              width: 600px;
                              padding: 10px;
                            "
                          >
                            <div
                              v-for="(formfield, index) in form.formfields"
                              style=""
                              :key="index"
                            >
                              <div
                                class="top"
                                style="margin: 0px 20px; padding: 0px 15px"
                              >
                                <div style="padding: 10px 0px">
                                  {{ formfield.prompt }}
                                </div>

                                <v-text-field
                                  dense
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TB'
                                  "
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  outlined
                                ></v-text-field>
                                <v-textarea
                                  outlined
                                  :placeholder="formfield.placeholder"
                                  :label="formfield.prompt"
                                  rows="2"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TA'
                                  "
                                ></v-textarea>
                                <custom-time-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                  :time="formfield.prompt"
                                />
                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                  ref="menu"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="time"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="time"
                                      outlined
                                      dense
                                      label="Picker in menu"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu2"
                                    v-model="time"
                                    full-width
                                    @click:minute="$refs.menu.save(time)"
                                  ></v-time-picker>
                                </v-menu> -->
                                <!-- <a-time-picker
                                  use12-hours
                                  format="h:mm a"
                                  :placeholder="formfield.placeholder"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'TP'
                                  "
                                /> -->
                                <custom-date-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                  :formfield="formfield"
                                />

                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Picker in menu"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu> -->
                                <custom-date-picker
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DTP'
                                  "
                                  :formfield="formfield"
                                />

                                <!-- <v-menu
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DTP'
                                  "
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Picker in menu"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu> -->
                                <!-- <a-date-picker
                                  :placeholder="formfield.placeholder"
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'DP'
                                  "
                                /> -->
                                <!-- <a-date-picker
                          show-time="true"
                          :placeholder="formfield.placeholder"
                          v-if="
                            formfield.dataType.dataTypeAbbreviation == 'DTP'
                          "
                        /> -->
                                <v-container
                                  class="px-0"
                                  fluid
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'CB'
                                  "
                                >
                                  <v-checkbox
                                    v-if="
                                      formfield.dataType.dataTypeAbbreviation ==
                                      'CB'
                                    "
                                    label="Checkbox 1"
                                  ></v-checkbox>
                                </v-container>
                                <!-- <a-checkbox
                                  :placeholder="formfield.placeholder"
                                >
                                  Checkbox
                                </a-checkbox> -->
                                <div
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'CBL'
                                  "
                                >
                                  <v-checkbox
                                    style="margin: 0px; padding: 0px"
                                    v-for="(option, index) in formfield.Options"
                                    :key="index"
                                    :label="option"
                                  ></v-checkbox>
                                </div>
                                <div
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'RL'
                                  "
                                >
                                  <v-radio-group>
                                    <v-radio
                                      v-for="(
                                        option, index
                                      ) in formfield.Options"
                                      :key="index"
                                      :label="option"
                                    ></v-radio>
                                  </v-radio-group>
                                  <!-- <a-radio-group :options="formfield.Options" /> -->
                                </div>
                                <v-select
                                  v-if="
                                    formfield.dataType.dataTypeAbbreviation ==
                                    'SL'
                                  "
                                  :items="formfield.Options"
                                  :label="formfield.prompt"
                                  outlined
                                  dense
                                ></v-select>
                              </div>

                              <!-- <div>{{ formfield }}</div> -->
                              <!-- <div>{{ index }}</div> -->
                            </div>
                          </div>
                        </v-tab-item>
                      </v-tabs-items>
                    </div>

                    <div class="Box-root Padding-bottom--4">
                      <div class="Box-root Flex-flex">
                        <div class="Box-root Flex-flex">
                          <div
                            class="
                              PressableCore
                              PressableCore--cursor--pointer
                              PressableCore--height--medium
                              PressableCore--radius--all
                              PressableCore--width
                              PressableCore--width--auto
                              PressableButton
                              Button Button--color--blue
                              Box-root
                              Flex-inlineFlex
                            "
                            style="background-color: rgb(84, 105, 212)"
                          >
                            <div class="PressableCore-base Box-root">
                              <button
                                aria-controls="menu48"
                                aria-haspopup="menu"
                                id="menu48-button"
                                type="button"
                                class="
                                  UnstyledLink
                                  Button-element
                                  PressableContext
                                  Padding-horizontal--8
                                  Padding-vertical--4
                                  PressableContext--cursor--pointer
                                  PressableContext--display--inlineFlex
                                  PressableContext--fontLineHeight--20
                                  PressableContext--fontSize--14
                                  PressableContext--fontWeight--medium
                                  PressableContext--height
                                  PressableContext--height--medium
                                  PressableContext--radius--all
                                  PressableContext--width
                                  PressableContext--width--auto
                                "
                                style="color: rgb(255, 255, 255)"
                              >
                                <div
                                  class="
                                    Button-align
                                    Box-root
                                    Flex-flex
                                    Flex-alignItems--baseline
                                    Flex-direction--rowReversed
                                  "
                                  style="position: relative"
                                >
                                  <div
                                    class="TextAligner Box-root"
                                    style="
                                      line-height: 20px;
                                      font-size: 14px;
                                      flex: 0 0 auto;
                                    "
                                  ></div>
                                  <div
                                    class="
                                      Box-root
                                      Flex-flex
                                      Flex-alignItems--baseline
                                      Flex-direction--rowReversed
                                      Flex-justifyContent--center
                                    "
                                    style="
                                      width: 100%;
                                      line-height: 0;
                                      flex: 1 1 auto;
                                    "
                                  >
                                    <div class="Box-root Padding-left--8">
                                      <div
                                        aria-hidden="true"
                                        class="
                                          SVGInline SVGInline--cleaned
                                          SVG
                                          Icon Icon--chevronDown
                                          Button-icon
                                          Icon-color Icon-color--white
                                          Box-root
                                          Flex-flex
                                        "
                                        style="transform: translateY(1.1px)"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          height="12"
                                          width="12"
                                          viewBox="0 0 16 16"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="
                                            SVGInline-svg SVGInline--cleaned-svg
                                            SVG-svg
                                            Icon-svg Icon--chevronDown-svg
                                            Button-icon-svg
                                            Icon-color-svg
                                            Icon-color--white-svg
                                          "
                                        >
                                          <path
                                            d="M13.591 5.293a1 1 0 0 1 1.416 1.416l-6.3 6.3a1 1 0 0 1-1.414 0l-6.3-6.3A1 1 0 0 1 2.41 5.293L8 10.884z"
                                            fill-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                    <span
                                      class="
                                        Button-label
                                        Text-color--white
                                        Text-fontSize--14
                                        Text-fontWeight--medium
                                        Text-lineHeight--20
                                        Text-numericSpacing--proportional
                                        Text-typeface--base
                                        Text-wrap--noWrap
                                        Text-display--block
                                      "
                                      style="margin-top: -1px"
                                      ><span>Actions</span></span
                                    >
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div
                              class="
                                PressableCore-overlay
                                PressableCore-overlay--extendBy1
                                Box-root
                                Box-background--white
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showAddForm"
            class="
              FullscreenView FullscreenView--isLayer
              Box-root
              Box-background--white
              Flex-flex
              Flex-alignItems--center
              Flex-direction--column
              Flex-justifyContent--center
            "
          >
            <component
              :is="component"
              :selectedNavItem="selectedFormField"
              :toggleForm="toggleForm"
              :addFormField="addFormField"
            />
          </div>
        </v-main>
      </v-app>
    </div>
  </div>
</template>
<script>
import CustomDatePicker from "../../components/CustomComponents/CustomDatePicker.vue";
import CustomTimePicker from "../../components/CustomComponents/CustomTimePicker.vue";
import AddCheckbox from "../../components/DynamicFormFields/AddCheckbox.vue";
import AddCheckboxList from "../../components/DynamicFormFields/AddCheckboxList.vue";
import AddDatePicker from "../../components/DynamicFormFields/AddDatePicker.vue";
import AddDateTimePicker from "../../components/DynamicFormFields/AddDateTimePicker.vue";
import AddMultiLine from "../../components/DynamicFormFields/AddMultiLine.vue";
import AddRadioList from "../../components/DynamicFormFields/AddRadioList.vue";
import AddSelect from "../../components/DynamicFormFields/AddSelect.vue";
import AddSingleLine from "../../components/DynamicFormFields/AddSingleLine.vue";
import AddTimePicker from "../../components/DynamicFormFields/AddTimePicker.vue";
export default {
  components: {
    CustomTimePicker,
    CustomDatePicker,
    AddSingleLine,
    AddCheckbox,
    AddCheckboxList,
    AddDatePicker,
    AddDateTimePicker,
    AddTimePicker,
    AddMultiLine,
    AddRadioList,
    AddSelect,
  },
  name: "test-result",
  data() {
    return {
      component: "",
      selectedFormField: "",
      tab: null,
      showAddForm: false,
      form: {
        title: "Form Title 1",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        formfields: [],
      },
    };
  },
  methods: {
    toggleForm: function (formName = "none") {
      // console.log(formName);
      this.component = "Add" + formName;
      // console.log(this.se);
      const text = formName;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      this.selectedFormField = finalResult;
      this.showAddForm = !this.showAddForm;
    },
    addFormField: function (requestData) {
      this.form.formfields.push(requestData);
      this.showAddForm = !this.showAddForm;
    },
  },
};
</script>
<style>
.stacked {
  position: relative;
  min-height: 80px;
}

.outer {
  display: grid;
  grid-template: 1fr / 1fr;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .below {
  z-index: 2;
}
.outer .top {
  z-index: 1;
}
</style>