var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"db-ChromeMainContent-nav"},[_c('div',{staticClass:"db-ChromeMainContent-nav-fixedContainer"},[_c('div',{staticClass:"Box-root Padding-vertical--16"},[_c('span',{staticClass:"\n          Text-color--dark\n          Text-fontSize--16\n          Text-fontWeight--bold\n          Text-lineHeight--24\n          Text-numericSpacing--proportional\n          Text-typeface--base\n          Text-wrap--wrap\n          Text-display--inline\n        "},[_c('span',[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"db-SideNav-navSections"},[_c('div',{staticClass:"db-SideNav-navSections-links-wrapper"},[_c('ul',_vm._l((_vm.leftNavItems),function(leftNavItem,index){return _c('li',{key:index,staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"UnstyledLink db-SideNav-link",attrs:{"data-db-analytics-sidenav":"allPayments","data-db-analytics-sidenav-path":"/test/payments","tabindex":"2"}},[_c('div',{staticClass:"\n                  db-SideNav-itemContent\n                  NavItem\n                  Box-root\n                  Padding-horizontal--4\n                  Padding-vertical--4\n                ",class:[
                  leftNavItem.selected
                    ? 'db-SideNav-itemContent--selected'
                    : '' ]},[_c('div',{staticClass:"Box-root Flex-flex Flex-alignItems--flexStart",on:{"click":function($event){return _vm.selectNav(leftNavItem.id)}}},[_c('span',{staticClass:"\n                      Text-fontSize--14\n                      Text-lineHeight--20\n                      Text-numericSpacing--proportional\n                      Text-typeface--base\n                      Text-wrap--wrap\n                      Text-display--inline\n                    ",class:[
                      leftNavItem.selected
                        ? 'Text-color--blue Text-fontWeight--bold'
                        : 'Text-color--default Text-fontWeight--regular' ]},[_c('span',[_vm._v(_vm._s(leftNavItem.title))])])])])]),(leftNavItem.showChildren)?_c('div',{staticClass:"db-SideNav--expandable",staticStyle:{"overflow":"hidden","margin-bottom":"8px"}},[_c('ul',_vm._l((leftNavItem.children),function(navItem,index){return _c('li',{key:index,staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.selectNav(navItem.id)}}},[_c('span',{staticClass:"UnstyledLink db-SideNav-link",attrs:{"data-db-analytics-sidenav":"reviews","data-db-analytics-sidenav-path":"/test/radar/reviews","tabindex":"2"}},[_c('div',{staticClass:"\n                        db-SideNav-itemContent\n                        NavItem\n                        Box-root\n                        Padding-horizontal--4\n                        Padding-vertical--2\n                      ",class:[
                        navItem.selected
                          ? 'db-SideNav-itemContent--selected'
                          : '' ]},[_c('div',{staticClass:"\n                          Box-root\n                          Padding-left--8\n                          Flex-flex\n                          Flex-alignItems--flexStart\n                        "},[_c('span',{staticClass:"\n                            Text-fontSize--14\n                            Text-lineHeight--20\n                            Text-numericSpacing--proportional\n                            Text-typeface--base\n                            Text-wrap--wrap\n                            Text-display--inline\n                          ",class:[
                            navItem.selected
                              ? 'Text-color--blue Text-fontWeight--bold'
                              : 'Text-color--default Text-fontWeight--regular' ]},[_c('span',[_vm._v(_vm._s(navItem.title))])])])])]),_c('div',{staticClass:"db-SideNav--expandable",staticStyle:{"height":"0px","overflow":"hidden"}})])}),0)]):_vm._e()])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }