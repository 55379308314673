<template>
  <v-app style="min-height: unset !important">
    <div class="w-full">
      <div class="text-3xl">Breast Cancer Report Per Date Range</div>
      <div class="w-full pt-3 flex flex-row justify-between">
        <div class="w-full max-w-xs">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
            class="max-w-32"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                style="max-height: 40px; width: 250px"
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="dates" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="flex flex-row gap-3 items-center justify-end">
          <v-btn color="primary" @click="generateReport()" elevation="0"
            >Generate Report</v-btn
          >
          <v-btn color="primary" @click="exportReport()" elevation="0" icon>
            <v-icon left>mdi-export</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        class="w-full flex flex-row items-center justify-center p-5"
        v-if="isLoading"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="w-full"
        ></v-progress-circular>
      </div>
      <div class="w-full h-full" v-else>
        <table-page
          :headers="headers"
          :items="reportDatas"
          :rowCount="25"
          :hasActions="true"
        />
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import fileDownload from "js-file-download";

import TablePage from "../../components/TablePage.vue";
export default {
  name: "WeeklyBreastCancerReport",
  components: {
    TablePage,
  },
  mounted() {
    this.refresh();
    var today = new Date();
    var last7Days = new Date(today.setDate(today.getDate() - 7));
    this.dates = [
      last7Days.toISOString().substr(0, 10),
      new Date().toISOString().substr(0, 10),
    ];
  },
  data: () => ({
    title: "Weekly CervicalCancer Report",
    dates: ["2019-09-10", "2012-09-20"],
    modal: false,
    headers: [
      { text: "S.no", value: "serialNumber" },
      {
        text: "Indicator",
        align: "start",
        value: "indicator",
      },
      { text: "Frequency", value: "frequency" },
      { text: "Remark", value: "remark" },
      // { text: "SampleId", value: "sampleId" },
      // { text: "Requested Date", value: "requested Date" },
      // { text: "Panel Name", value: "panelName" },
      // { text: "Test Name", value: "testName" },
      // { text: "Result", value: "result" },
      // { text: "Result Comment", value: "resultComment" },
    ],
    reportDatas: [],
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["collectionProtocols"]),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    ...mapActions([
      "getCollectionProtocols",
      "getCollectionProtocolForms",
      "getSubmittedCollectionProtocolFormsPerQuery",
      // "setSelectedCollectionProtocol",
    ]),
    ...mapMutations(["setSelectedCollectionProtocol"]),
    refresh() {
      // this.isLoading = true;
      // this.getCollectionProtocols()
      //   .then((res) => {
      //     console.log("getCollectionProtocols >>>>>>>>>>>>", res);
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     console.log("getCollectionProtocols error >>>>>>>>>>>>", error);
      //     this.isLoading = false;
      //   });
    },
    generateReport() {
      console.log("Generate Report");

      console.log("this.dates >>>>>>>>>>>>", this.dates);
      // return;

      this.isLoading = true;
      this.getCollectionProtocols()
        .then((res) => {
          console.log(
            "getCollectionProtocols >>>>>>>>>>>>",
            this.collectionProtocols
          );

          // const collectionProtocols = res;
          const cervicalCancerCollectionProtocol =
            this.collectionProtocols.find(
              (collectionProtocol) => collectionProtocol.abbreviation === "BCS"
            );

          if (!cervicalCancerCollectionProtocol) {
            this.isLoading = false;
            return;
          } else {
            console.log(
              "cervicalCancerCollectionProtocol >>>>>>>>>>>>",
              cervicalCancerCollectionProtocol
            );
            this.setSelectedCollectionProtocol(
              cervicalCancerCollectionProtocol
            );
          }

          // this.isLoading = false;
          this.getCollectionProtocolForms()
            .then((res) => {
              console.log("getCollectionProtocolForms >>>>>>>>>>>>", res);

              const collectionProtocolForms = res;

              if (
                !collectionProtocolForms ||
                collectionProtocolForms.length === 0
              ) {
                return;
              }

              console.log(
                "/////////////////////////////////////",
                new Date(this.dates[0]).toISOString(),
                new Date(this.dates[1]).toISOString()
              );

              this.getSubmittedCollectionProtocolFormsPerQuery({
                collectionProtocolFormID: collectionProtocolForms[0].id,
                EnabledStartDate: new Date(this.dates[0]).toISOString(),
                EnabledEndDate: new Date(this.dates[1]).toISOString(),
              })
                .then((res) => {
                  console.log(
                    "getSubmittedCollectionProtocolFormsPerQuery >>>>>>>>>>>>",
                    res
                  );
                  this.submittedCollectionProtocolForms = res;

                  const patientIds = Array.from(
                    new Set(
                      this.submittedCollectionProtocolForms.map(
                        (submittedCollectionProtocolForm) =>
                          submittedCollectionProtocolForm.patientID
                      )
                    )
                  );

                  // console.log(
                  //   "patientIds >>>>>>>>>>>>",
                  //   patientIds,
                  //   this.submittedCollectionProtocolForms.map(
                  //     (submittedCollectionProtocolForm) =>
                  //       submittedCollectionProtocolForm.patientID
                  //   )
                  // );

                  const allSubmittedCollectionProtocolFormValues =
                    this.submittedCollectionProtocolForms
                      .map(
                        (submittedCollectionProtocolForm) =>
                          submittedCollectionProtocolForm.submittedCollectionProtocolFormValues
                      )
                      .flat();

                  console.log(
                    "allSubmittedCollectionProtocolFormValues >>>>>>>>>>>>",
                    allSubmittedCollectionProtocolFormValues
                  );

                  const VIAScreeningMethodOffers =
                    allSubmittedCollectionProtocolFormValues.filter(
                      (submittedCollectionProtocolFormValue) =>
                        submittedCollectionProtocolFormValue
                          .collectionProtocolFormField.prompt ===
                        "VIA Screening Result"
                    );

                  this.reportDatas = [
                    {
                      serialNumber: 1,
                      indicator:
                        "The total number of women screened for breast cancer",
                      frequency: patientIds.length,
                      remark: "",
                    },
                    {
                      serialNumber: 2,
                      indicator:
                        "The total number of breast ultrasounds requested",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 3,
                      indicator:
                        "The total number of breast ultrasound results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 4,
                      indicator:
                        "The total number of breast ultrasound results pending",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 5,
                      indicator: "The total number of FNAC requests",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 6,
                      indicator: "The total number of FNAC results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 7,
                      indicator: "The total number of FNAC results pending",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 8,
                      indicator: "The total number of US-guided FNAC requests",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 9,
                      indicator:
                        "The total number of US-guided FNAC results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 10,
                      indicator:
                        "The total number of US-guided FNAC results pending",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 11,
                      indicator: "The total number of mammography requests",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 12,
                      indicator:
                        "The total number of mammography results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 13,
                      indicator:
                        "The total number of mammography results pending",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 14,
                      indicator:
                        "The total number of Core Needle Biopsy (CNB) requests",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 15,
                      indicator:
                        "The total number of Core Needle Biopsy (CNB) results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 16,
                      indicator:
                        "The total number of Core Needle Biopsy (CNB) results pending",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 17,
                      indicator:
                        "The total number of major surgeries scheduled",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 18,
                      indicator:
                        "The total number of major surgeries performed",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 19,
                      indicator: "The total number of major surgeries canceled",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 20,
                      indicator:
                        "The total number of minor surgeries scheduled",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 21,
                      indicator:
                        "The total number of minor surgeries performed",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 22,
                      indicator: "The total number of minor surgeries canceled",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 23,
                      indicator: "The total number of biopsy results collected",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 24,
                      indicator:
                        "The total number of clients referred to Tikur Anbessa",
                      frequency: 0,
                      remark: "",
                    },
                    {
                      serialNumber: 25,
                      indicator:
                        "The total number of clients referred to a private institution",
                      frequency: 0,
                      remark: "",
                    },
                  ];

                  this.isLoading = false;
                })
                .catch((error) => {
                  console.log(
                    "getSubmittedCollectionProtocolFormsPerQuery error >>>>>>>>>>>>",
                    error
                  );
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              console.log(
                "getCollectionProtocolForms error >>>>>>>>>>>>",
                error
              );
              this.isLoading = false;
            });
        })
        .catch((error) => {
          console.log("getCollectionProtocols error >>>>>>>>>>>>", error);
          this.isLoading = false;
        });
    },
    exportReport() {
      console.log("Export Report");
      let csvString = "";
      csvString += this.headers.map((x) => x.text).join(",") + "\n";
      this.reportDatas.forEach((row) => {
        let rowText = "";
        rowText += row.serialNumber + ",";
        rowText += row.indicator + ",";
        rowText += row.frequency + ",";
        rowText += row.remark + ",";

        csvString += rowText + "\n";
      });
      // console.log("csvString >>>>>>>>>>>>", csvString);
      //   this.downloadCSV(csvString, "data.csv");
      fileDownload(csvString, this.title + ".csv");
    },
  },
};
</script>
