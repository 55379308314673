<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Sites
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Sites</span></span
                >
                <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Site</v-btn
                >
              </div>
              <v-data-table
                style="margin-top: 15px"
                :headers="headers"
                :items="collectionProtocolSites"
              ></v-data-table>
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-collection-protocol-Site
            selectedNavItem="Site"
            :toggleForm="toggleForm"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolSite from "../../components/AddForms/AddCollectionProtocolSite.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { AddCollectionProtocolSite },
  name: "collection-protocol-sites",
  created() {
    this.getCollectionProtocolSites();
  },
  data() {
    return {
      showAddForm: false,
      headers: [
        {
          text: "Site",
          align: "start",
          value: "institutionSite.title",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolSites"]),
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
  },
  computed: {
    ...mapGetters(["collectionProtocolSites", "currentCollectionProtocol"]),
  },
};
</script>