<template>
  <div>
    <div class="db-ChromeHeader">
      <div
        class="db-ChromeHeader-toolbar"
        style="background: unset !important; box-shadow: unset !important"
      >
        <div class="db-ChromeHeader-toolbarContent">
          <div
            class="Box-root Flex-flex Flex-alignItems--center"
            style="flex-basis: 0px; flex-grow: 1"
          >
            <img
              src="../assets/bb_logo.png"
              alt=""
              @click="selectNav(0)"
              srcset=""
              width="30"
              style="margin-right: 10px"
            />
            <div>BioBanking LIMS</div>
            <!-- {{ isCollectionProtocolDetail }} -->
            <!-- <v-app>
            <v-main></v-main>
          </v-app> -->

            <div v-if="isCollectionProtocolDetail || isParticipantDetail">
              <span
                style="padding: 5px; font-size: 16px; color: rgb(200, 200, 200)"
                >|</span
              ><span>{{ currentCollectionProtocol.title }}</span>
              <span
                v-if="
                  isParticipantDetail && currentCollectionProtocolParticipant
                "
              >
                <span
                  style="
                    padding: 5px;
                    font-size: 16px;
                    color: rgb(200, 200, 200);
                  "
                  >/</span
                >
                <span
                  >{{ currentCollectionProtocolParticipant.patient.firstName }}
                  {{
                    currentCollectionProtocolParticipant.patient.fatherName
                  }}</span
                >
              </span>
            </div>
            <!-- <div>
              <v-breadcrumbs>
                <v-breadcrumbs-item to="/collection-protocols/">
                  sdfh
                </v-breadcrumbs-item>
              </v-breadcrumbs>
            </div>
            <div style="padding-left: 10px">
              {{ currentCollectionProtocol.title }}
            </div> -->
          </div>
          <div class="Box-root" style="flex-basis: 0px; flex-grow: 1">
            <div class="Box-root" style="pointer-events: none">
              <div
                class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexEnd Flex-wrap--nowrap"
                style="margin-left: -16px; margin-top: -16px"
              >
                <div
                  class="Box-root Box-hideIfEmpty Margin-top--16 Margin-left--16"
                  style="pointer-events: auto"
                >
                  <div>
                    <div class="Box-root Flex-flex">
                      <div class="Box-root Flex-flex">
                        <div
                          class="PressableCore PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
                          style="
                            box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                              rgba(0, 0, 0, 0) 0px 0px 0px 0px;
                            border-radius: 40px;
                          "
                        >
                          <div class="PressableCore-base Box-root">
                            <!-- {{ isParticipantDetail }} -->
                            <!-- <button
                              type="button"
                              class="UnstyledLink"
                              @click="showOrderPage"
                            >
                              <div
                                class="
                                  db-CreateButton
                                  Box-root
                                  Flex-flex
                                  Flex-alignItems--baseline
                                  Flex-direction--row
                                "
                                style="
                                  position: relative;
                                  border-radius: 40px;
                                  padding: 4px 12px;
                                "
                              >
                                <div
                                  class="TextAligner Box-root"
                                  style="
                                    line-height: 16px;
                                    font-size: 12px;
                                    flex: 0 0 auto;
                                  "
                                ></div>
                                <div
                                  class="
                                    Box-root
                                    Flex-flex
                                    Flex-alignItems--baseline
                                    Flex-direction--row
                                    Flex-justifyContent--flexStart
                                  "
                                  style="line-height: 0; flex: 1 1 auto"
                                >
                                  <span
                                    class="
                                      Text-color--inherit
                                      Text-fontSize--12
                                      Text-fontWeight--medium
                                      Text-lineHeight--16
                                      Text-numericSpacing--proportional
                                      Text-typeface--base
                                      Text-wrap--noWrap
                                      Text-display--block
                                    "
                                    style=""
                                    ><span>Orders</span></span
                                  >
                                </div>
                              </div>
                            </button> -->
                          </div>
                          <div
                            class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            style="border-radius: 40px"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="Box-root Box-hideIfEmpty Margin-top--16 Margin-left--16"
                  style="pointer-events: auto"
                >
                  <div class="Box-root" style="pointer-events: none">
                    <div
                      class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                      style="margin-left: -24px; margin-top: -24px"
                    >
                      <div
                        class="Box-root Box-hideIfEmpty Margin-top--24 Margin-left--24"
                        style="pointer-events: auto"
                      >
                        <!-- <v-btn icon color="pink">
                          <v-icon>mdi-cog-outline</v-icon>
                        </v-btn> -->
                      </div>

                      <div
                        class="Box-root Box-hideIfEmpty Margin-top--24"
                        style="pointer-events: auto"
                      >
                        <v-chip
                          @click="clearSelectedInstitution()"
                          class="mr-2"
                          link
                          color="indigo darken-3"
                          outlined
                        >
                          <v-icon left> mdi-domain </v-icon>
                          {{ currentInstitution.title }}
                        </v-chip>
                        <v-btn @click="logout" depressed> Logout </v-btn>
                        <!-- <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="pink" v-bind="attrs" v-on="on">
                              <v-icon>mdi-account-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="logout">
                              <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="db-ChromeHeader-tabs">
        <div
          class="db-ChromeHeader-tabsContent"
          v-if="!isCollectionProtocolDetail && !isParticipantDetail"
        >
          <!-- {{ currentRouteName }} -->
          <div
            class="Box-root Margin-right--4"
            v-for="(NavItem, index) in NavItems"
            :key="index"
          >
            <div
              class="PressableCore PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
              style="border-radius: 40px"
            >
              <!-- {{ currentRouteName }} -->
              <!-- {{ NavItem.pathName.toLowerCase() }} -->
              <div class="PressableCore-base Box-root">
                <span href="./dashboard" class="UnstyledLink"
                  ><div
                    @click="selectNav(NavItem.id)"
                    :class="[
                      isNavSelected(NavItem.pathName.toLowerCase())
                        ? 'db-ChromeHeader-tab--selected'
                        : '',
                    ]"
                    class="db-ChromeHeader-tab Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                    style="
                      position: relative;
                      border-radius: 40px;
                      padding: 2px 10px;
                    "
                  >
                    <div
                      class="TextAligner Box-root"
                      style="line-height: 20px; font-size: 14px; flex: 0 0 auto"
                    ></div>
                    <div
                      class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart"
                      style="line-height: 0; flex: 1 1 auto"
                    >
                      <span
                        class="Text-color--inherit Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block"
                        style="margin-top: -1px"
                        ><span>{{ NavItem.title }}</span></span
                      >
                    </div>
                  </div></span
                >
              </div>
              <div
                class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                style="border-radius: 40px"
              ></div>
            </div>
          </div>
          <v-spacer />
          <v-btn
            elevation="0"
            @click="toggleBarcodeForm('BarcodePrinter')"
            class="bg-blue-500 mr-2"
            rounded
            small
          >
            <v-icon> mdi-barcode </v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            @click="toggleForm('NewOrder')"
            color="primary"
            style="background: rgb(25, 118, 210)"
            rounded
            small
          >
            New Order
          </v-btn>
          <v-btn
            elevation="0"
            @click="toggleForm('Patient')"
            color="primary"
            style="background: rgb(25, 118, 210); margin-left: 10px"
            rounded
            small
          >
            New Participant
          </v-btn>
        </div>
        <div
          class="db-ChromeHeader-tabsContent"
          v-else-if="isCollectionProtocolDetail"
        >
          <!-- {{ currentRouteName }} -->
          <div
            class="Box-root Margin-right--4"
            v-for="(NavItem, index) in CollectionProtocolNavItems"
            :key="index"
          >
            <div
              class="PressableCore PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
              style="border-radius: 40px"
            >
              <!-- {{ currentRouteName }} -->
              <!-- {{ NavItem.pathName.toLowerCase() }} -->
              <div class="PressableCore-base Box-root">
                <span href="./dashboard" class="UnstyledLink"
                  ><div
                    @click="selectNav(NavItem.id)"
                    :class="[
                      isNavSelected(NavItem.pathName.toLowerCase())
                        ? 'db-ChromeHeader-tab--selected'
                        : '',
                    ]"
                    class="db-ChromeHeader-tab Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                    style="
                      position: relative;
                      border-radius: 40px;
                      padding: 2px 10px;
                    "
                  >
                    <div
                      class="TextAligner Box-root"
                      style="line-height: 20px; font-size: 14px; flex: 0 0 auto"
                    ></div>
                    <div
                      class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart"
                      style="line-height: 0; flex: 1 1 auto"
                    >
                      <span
                        class="Text-color--inherit Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block"
                        style="margin-top: -1px"
                        ><span>{{ NavItem.title }}</span></span
                      >
                    </div>
                  </div></span
                >
              </div>
              <div
                class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                style="border-radius: 40px"
              ></div>
            </div>
          </div>
          <div class="Box-root" style="flex-grow: 1"></div>
        </div>
        <div
          class="db-ChromeHeader-tabsContent"
          v-else-if="isParticipantDetail"
        >
          <!-- {{ currentRouteName }} -->
          <div
            class="Box-root Margin-right--4"
            v-for="(NavItem, index) in ParticipantNavItems"
            :key="index"
          >
            <div
              class="PressableCore PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
              style="border-radius: 40px"
            >
              <!-- {{ currentRouteName }} -->
              <!-- {{ NavItem.pathName.toLowerCase() }} -->
              <div class="PressableCore-base Box-root">
                <span href="./dashboard" class="UnstyledLink"
                  ><div
                    @click="selectNav(NavItem.id)"
                    :class="[
                      isNavSelected(NavItem.pathName.toLowerCase())
                        ? 'db-ChromeHeader-tab--selected'
                        : '',
                    ]"
                    class="db-ChromeHeader-tab Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                    style="
                      position: relative;
                      border-radius: 40px;
                      padding: 2px 10px;
                    "
                  >
                    <div
                      class="TextAligner Box-root"
                      style="line-height: 20px; font-size: 14px; flex: 0 0 auto"
                    ></div>
                    <div
                      class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart"
                      style="line-height: 0; flex: 1 1 auto"
                    >
                      <span
                        class="Text-color--inherit Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block"
                        style="margin-top: -1px"
                        ><span>{{ NavItem.title }}</span></span
                      >
                    </div>
                  </div></span
                >
              </div>
              <div
                class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                style="border-radius: 40px"
              ></div>
            </div>
          </div>
          <div class="Box-root" style="flex-grow: 1"></div>
        </div>
      </div>
    </div>
    <!-- <new-order v-if="showNewOrder" :toggleForm="toggleForm" /> -->
    <component :is="component" v-if="showNewOrder" :toggleForm="toggleForm" />
  </div>
</template>
<script>
import TablePage from "./TablePage.vue";
import apiClient from "../API/apiClient";
import { v4 as uuid_v4 } from "uuid";

import { mapGetters } from "vuex";
import AddNewOrder from "./AddNewOrder.vue";
import AddBarcodePrinter from "./BarcodePrinter.vue";
import AddPatient from "./AddForms/AddPatient.vue";
export default {
  components: {
    TablePage,
    AddNewOrder,
    AddPatient,
    AddBarcodePrinter,
  },
  created() {
    // console.log(
    //   "currentCollectionProtocolParticipant",
    //   this.currentCollectionProtocolParticipant
    // );
  },
  name: "nav-bar",
  data() {
    return {
      component: "",
      showNewOrder: false,
      selectedItem: 1,
      menu: false,
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Description", value: "description" },
        { text: "Created Date", value: "createdDate" },
        { text: "Created By", value: "creatorAccount" },
      ],
      items: [],
      NavItems: [
        { id: 0, title: "Home", pathName: "Home", selected: true },
        {
          id: uuid_v4(),
          title: "Collection Protocols",
          pathName: "CollectionProtocols",
          selected: false,
          children: [
            {
              id: uuid_v4(),
              title: "Overview",
              pathName: "CollectionProtocolOverview",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Consents",
              pathName: "CollectionProtocolConsents",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Sites",
              pathName: "CollectionProtocolSites",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Events",
              pathName: "CollectionProtocolEvents",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Participants",
              pathName: "CollectionProtocolParticipants",
              selected: false,
              children: [
                {
                  id: uuid_v4(),
                  title: "Overview",
                  pathName: "CollectionProtocolParticipantOverview",
                  selected: false,
                },
                {
                  id: uuid_v4(),
                  title: "Consents",
                  pathName: "CollectionProtocolParticipantConsents",
                  selected: false,
                },
                {
                  id: uuid_v4(),
                  title: "Questioners",
                  pathName: "CollectionProtocolParticipantForms",
                  selected: false,
                },
                {
                  id: uuid_v4(),
                  title: "Collected Samples",
                  pathName: "CollectionProtocolParticipantCollectedSamples",
                  selected: false,
                },
              ],
            },
            {
              id: uuid_v4(),
              title: "Collected Samples",
              pathName: "CollectionProtocolCollectedSamples",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Collected Questioners",
              pathName: "CollectionProtocolCollectedQuestioners",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Questioners",
              pathName: "CollectionProtocolForms",
              selected: false,
            },
            {
              id: uuid_v4(),
              title: "Shipments",
              pathName: "CollectionProtocolShipments",
              selected: false,
            },
            // {
            //   id: uuid_v4(),
            //   title: "Settings",
            //   pathName: "CollectionProtocolSettings",
            //   selected: false,
            // },
          ],
        },
        { id: uuid_v4(), title: "Orders", pathName: "Orders", selected: false },
        {
          id: uuid_v4(),
          title: "Configuration",
          pathName: "Configuration",
          selected: false,
        },
        {
          id: uuid_v4(),
          title: "Participants",
          pathName: "Patients",
          selected: false,
        },
        // {
        //   id: uuid_v4(),
        //   title: "Shipments",
        //   pathName: "Shipment",
        //   selected: false,
        // },
        { id: uuid_v4(), title: "Report", pathName: "Report", selected: false },
        {
          id: uuid_v4(),
          title: "Setting",
          pathName: "Setting",
          selected: false,
        },
        {
          id: uuid_v4(),
          title: "Accounts",
          pathName: "Accounts",
          selected: false,
        },
        // {
        //   id: uuid_v4(),
        //   title: "Settings",
        //   pathName: "Settings",
        //   selected: false,
        // },
      ],
    };
  },
  methods: {
    selectNav: function (id) {
      console.log("selectNav id", id);
      if (id == 0) {
        this.$router.push({ name: this.NavItems[0].pathName }).catch(() => {});
        return;
      }
      if (this.isCollectionProtocolDetail) {
        this.NavItems.forEach((navItem) => {
          if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
            navItem.children.forEach((navChild) => {
              if (navChild.id == id) {
                // console.log(navChild);
                this.$router.push({ name: navChild.pathName }).catch(() => {});
                return;
              }
            });
          }
        });
      }
      if (this.isParticipantDetail) {
        this.NavItems.forEach((navItem) => {
          if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
            navItem.children.forEach((navChild) => {
              if (navChild.pathName.toLowerCase().includes("participants")) {
                console.log(navChild.children);
                navChild.children.forEach((nvItem) => {
                  if (nvItem.id == id) {
                    // console.log(navChild);
                    this.$router
                      .push({ name: nvItem.pathName })
                      .catch(() => {});
                    return;
                  }
                });
              }
            });
          }
        });
      } else {
        // console.log("couldn't find id: " + id, this.isCollectionProtocolDetail);
        this.NavItems.forEach((navItem) => {
          if (navItem.id == id) {
            navItem.selected = true;
            this.$router.push({ name: navItem.pathName }).catch(() => {});
          } else {
            navItem.selected = false;
          }
        });
      }
    },
    isNavSelected: function (nav) {
      if (this.isCollectionProtocolDetail) {
        return this.currentRouteName
          .replaceAll("-", "")
          .includes(
            nav.toLowerCase().replace("collectionprotocol", "").replace(" ", "")
          );
      }
      if (this.isParticipantDetail) {
        if (true) {
          console.log(
            "this.currentRouteName",
            this.currentRouteName.replaceAll("-", "")
          );
          console.log(
            nav
              .toLowerCase()
              .replace("collectionprotocol", "")
              .replace(" ", "")
              .replace("participant", "")
          );
        }

        console.log(
          "selected_nav",
          this.currentRouteName
            .replaceAll("-", "")
            .includes(
              nav
                .toLowerCase()
                .replace("collectionprotocol", "")
                .replace(" ", "")
                .replace("participant", "")
            )
        );
        return this.currentRouteName
          .replaceAll("-", "")
          .includes(
            nav
              .toLowerCase()
              .replace("collectionprotocol", "")
              .replace(" ", "")
              .replace("participant", "")
          );
      }
      if (nav == "home" && this.currentRouteName == "/") {
        return true;
      } else {
        return this.currentRouteName
          .replace("-", "")
          .includes(nav.toLowerCase());
      }
    },
    clearSelectedInstitution() {
      console.log("clearSelectedInstitution");
      this.$store.commit("setSelectedInstitution", null);
    },
    insitutionSelected(value) {
      this.$store.commit("setSelectedInstitution", value);
      this.$router.push({ name: "Home" }).catch(() => {});
      this.menu = false;
    },
    toggleForm(formName = "none") {
      // console.log("toggle clicked", formName);
      if (formName == "none") {
        this.showNewOrder = !this.showNewOrder;
      } else {
        this.component = "Add" + formName;
        this.showNewOrder = !this.showNewOrder;
      }
    },
    toggleBarcodeForm(formName = "none") {
      // console.log("toggle clicked", formName);
      console.log("formName: ", formName);
      if (formName == "none") {
        this.showNewOrder = !this.showNewOrder;
      } else {
        this.component = "Add" + formName;
        this.showNewOrder = !this.showNewOrder;
      }
    },
    showOrderPage() {
      this.$router.push({ name: "TestResult" }).catch(() => {});
    },
    logout() {
      console.log("logging out...");
      localStorage.removeItem("auth_token");
      this.$store.commit("setSelectedInstitution", null);
      localStorage.removeItem("selectedInstitutionId");
      this.$router.push({ name: "Login" }).catch(() => {});
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "currentCollectionProtocol",
      "currentCollectionProtocolParticipant",
      // ...
    ]),
    currentRouteName() {
      // console.log(this.$route);
      return this.$route.path;
    },
    CollectionProtocolNavItems() {
      var navItems = [];
      this.NavItems.forEach((navItem) => {
        if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
          // console.log("found CollectionProtocols...");
          navItems = navItem.children;
        }
      });
      return navItems;
    },
    ParticipantNavItems() {
      var navItems = [];
      this.NavItems.forEach((navItem) => {
        if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
          navItem.children.forEach((nvItem) => {
            if (nvItem.pathName.toLowerCase().includes("participants")) {
              navItems = nvItem.children;
            }
          });
        }

        // if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
        //   // console.log("found CollectionProtocols...");
        //   navItems = navItem.children;
        // }
      });
      console.log("navItems", navItems);
      return navItems;
    },
    isParticipantDetail() {
      var result = false;
      console.log(
        "this.$route.params",
        !!this.$route.params.collectionProtocolParticipantId
      );
      if (
        this.$route.name
          .toLowerCase()
          .includes("collectionprotocolparticipant") &&
        !!this.$route.params.collectionProtocolParticipantId
      ) {
        // console.log("we got him....");
        result = true;
      }
      // CollectionProtocolParticipant
      return result;
    },
    isCollectionProtocolDetail() {
      var result = false;
      if (this.$route.name.toLowerCase() == "collectionprotocolformeditor") {
        result = true;
      }
      this.NavItems.forEach((navItem) => {
        if (navItem.pathName.toLowerCase().includes("collectionprotocols")) {
          // console.log(
          //   "navItem.pathName.toLowerCase()",
          //   navItem.pathName.toLowerCase()
          // );
          navItem.children.forEach((navChild) => {
            // console.log(
            //   "compare",
            //   this.$route.name.toLowerCase(),
            //   navChild.pathName.toLowerCase()
            // );
            if (
              this.$route.name
                .toLowerCase()
                .includes(navChild.pathName.replace(" ", "").toLowerCase())
            ) {
              // console.log(
              //   "this.$route.name.toLowerCase()",
              //   this.$route.name.toLowerCase().toLowerCase()
              // );
              navChild.selected = true;
              result = true;
            }
          });
        }
      });
      // console.log(this.$route);
      // if (this.$route.path.includes("overview")) {
      //   return true;
      // }
      // console.log("result", result);
      return result;
    },
  },
};
</script>
