<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="items" :rowCount="25" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../../components/TablePage.vue";
import { mapGetters } from "vuex";
import apiClient from "../../../API/apiClient";
export default {
  name: "panel-usage-report",
  components: {
    TablePage,
  },
  created() {},
  data() {
    return {
      headers: [
        {
          text: "Panel Name",
          align: "start",
          value: "panelName",
        },
        { text: "Out of Pocket", value: "outOfPocket" },
        { text: "Free", value: "free" },
        { text: "Half", value: "half" },
        { text: "Post Payment", value: "postPayment" },
        { text: "Insurance", value: "insurance" },
        { text: "Total", value: "total" },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>