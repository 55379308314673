<template>
  <div>
    <!-- <v-app id="inspire" v-if="!currentInstitution">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <div style="padding: 10px; margin: 10px; display: flex">
                <img
                  src="./assets/bb_logo.png"
                  alt=""
                  @click="selectNav(0)"
                  srcset=""
                  width="50"
                  height="50"
                  style="margin-right: 10px"
                />
                <div style="padding: 5px 10px">
                  <div style="font-size: 15px; font-weight: bold">
                    Bio-Bank LIMS
                  </div>
                  <div>
                    Remember, with great power comes great responsibility.
                  </div>
                </div>
              </div>
              <v-card style="padding: 10px" elevation="0">
                <div style="margin: 10px">INSTITUTIONS</div>
                <v-data-table
                  @click:row="insitutionSelected"
                  :headers="headers"
                  :items="institutions"
                  hide-default-header
                  hide-default-footer
                  class="elevation-0"
                ></v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app> -->
    <!-- <v-app> -->
    <router-view />
    <v-snackbar v-model="showErrorMessage" :timeout="timeout">
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="closeSnackbar()">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-dialog v-model="isLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- </v-app> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      snackbar: true,
      timeout: 3000,
      text: "Some ting never changes",
    };
  },
  computed: {
    showErrorMessage: {
      get() {
        return this.$store.state.showErrorMessage;
      },
      set(value) {
        this.$store.commit("setShowErrorMessage", value);
      },
    },
    ...mapGetters([
      "currentInstitution",
      "institutions",
      "isLoading",
      "errorMessage",
    ]),
  },
  methods: {
    closeSnackbar() {
      // this.showErrorMessage = false;
      this.$store.commit("setShowErrorMessage", false);
    },
  },
  watch: {
    // router param
    $route: {
      handler: function (val) {
        console.log("route changed", val);
        this.$store.commit("setOptions", {
          page: 1,
          itemsPerPage: 10,
          pageCount: 15,
        });
      },
      immediate: true,
    },
  },
};
</script>
<style>
.db-ChromeHeader {
  height: 100px !important;
  /* background: yellowgreen !important; */
  padding-top: 10px;
}
.db-ChromeMainContent {
  padding-top: 0px !important;
}
.db-ChromeHeader-toolbarContent {
  padding-top: 10px !important;
}
.db-ChromeHeader-tabs {
  height: unset !important;
}
.db-ChromeHeader-tabsContent {
  margin-top: 5px !important;
  padding-bottom: 0px !important;
}
/* remove outline */
.ProseMirror:focus {
  outline: none;
}
/* set */
.ProseMirror {
  min-height: 100px;
  max-height: 70vh;
  overflow-y: auto;
}
</style>
