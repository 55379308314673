<template>
  <div>
    <div>
      <router-link
        :to="
          '/collection-protocols/' + currentCollectionProtocol.id + '/overview'
        "
        >{{ currentCollectionProtocol.title }}</router-link
      ><span style="padding: 5px">/</span
      ><router-link
        :to="
          '/collection-protocols/' +
            currentCollectionProtocol.id +
            '/participants'
        "
        >Participants</router-link
      ><span style="padding: 5px">/</span
      >{{ currentCollectionProtocolParticipant.patient.firstName }}
      {{ currentCollectionProtocolParticipant.patient.fatherName
      }}<span style="padding: 5px">/</span>consents
    </div>
    <v-app>
      <div class="Box-root">
        <div
          class="
            Card-root Card--radius--all
            Section
            Box-root
            Box-background--white
          "
          style="overflow: visible"
        >
          <div class="ListView ListView--withoutFooter">
            <div
              class="
                Box-root
                Padding-top--16
                Padding-bottom--8
                Flex-flex
                Flex-alignItems--center
                Flex-justifyContent--spaceBetween
              "
            >
              <span
                class="
                  Text-color--dark
                  Text-fontSize--28
                  Text-fontWeight--bold
                  Text-lineHeight--32
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Consents</span></span
              >
              <v-btn color="primary" elevation="0" small @click="toggleForm()">
                Sign Consent</v-btn
              >
            </div>
            <v-data-table
              style="margin-top: 15px"
              :headers="headers"
              :loading="isLoading"
              @click:row="handleClick"
              :items="collectionProtocolParticipantConsents"
              ><template v-slot:item.signedAt="{ item }">
                {{ item.enabledDate | moment("YYYY-MM-DD") }}
              </template></v-data-table
            >
            <v-dialog v-model="dialog" max-width="600">
              <v-card v-if="selectedCollectionProtocolParticipantConsent">
                <v-card-title class="text-h5">
                  <span>
                    {{
                      selectedCollectionProtocolParticipantConsent
                        .collectionProtocolConsent.title
                    }}
                  </span>
                </v-card-title>
                <hr />

                <v-card-text
                  v-html="selectedCollectionProtocolParticipantConsent.consent"
                >
                </v-card-text>
                <div class="pl-10 h5" style="color: #1565c0">Signiture</div>
                <div class="d-flex justify-center">
                  <img
                    :src="
                      'data:image/png;base64,' +
                        selectedCollectionProtocolParticipantConsent.signature
                    "
                    alt=""
                    srcset=""
                  />
                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <div
        v-if="showAddForm"
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <add-collection-protocol-participant-consent
          selectedNavItem="Consent"
          :toggleForm="toggleForm"
        />
      </div>
    </v-app>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AddCollectionProtocolParticipantConsent from "../../components/AddForms/AddCollectionProtocolParticipantConsent.vue";
export default {
  components: { AddCollectionProtocolParticipantConsent },
  name: "collection-protocol-participant-consent",
  created() {
    this.getCollectionProtocolParticipantConsents();
  },
  computed: {
    ...mapGetters([
      "currentCollectionProtocol",
      "currentCollectionProtocolParticipant",
      "collectionProtocolParticipantConsents",
      "isLoading",
    ]),
  },
  data() {
    return {
      dialog: false,
      selectedCollectionProtocolParticipantConsent: null,
      showAddForm: false,
      headers: [
        {
          text: "Consent",
          align: "start",
          value: "collectionProtocolConsent.title",
        },
        {
          text: "Signed At",
          align: "start",
          value: "signedAt",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolParticipantConsents"]),
    toggleForm: function() {
      this.showAddForm = !this.showAddForm;
    },
    handleClick(value) {
      this.selectedCollectionProtocolParticipantConsent = value;
      this.dialog = true;
    },
  },
};
</script>
