<template>
  <div class="d-flex">
    <v-datetime-picker outline v-model="formfield.value"></v-datetime-picker>
    <!-- <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          label="Picker in menu"
          prepend-icon="mdi-calendar"
          readonly
          dense
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      ref="menu2"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          outlined
          dense
          label="Picker in menu"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu2"
        v-model="time"
        full-width
        @click:minute="$refs.menu2.save(time)"
      ></v-time-picker>
    </v-menu> -->
  </div>
</template>
<script>
export default {
  name: "custom-time-picker",
  props: ["formfield"],
  data() {
    return {
      time: "",
      date: "",
      menu: false,
      menu2: false,
    };
  },
  watch: {
    time(val) {
      this.formfield.value = this.formfield.value + " " + val;
      var newDate = new Date(this.formfield.value).toISOString();
      console.log("::time changed:: ", newDate);
      // newDate.setFullYear(this.formfield.value.split("-")[0]);
      // newDate.setMonth(this.formfield.value.split("-")[1] - 1);
      // newDate.setDate(this.formfield.value.split("-")[2]);
      // newDate.setHours(val.split(":")[0]);
      // newDate.setMinutes(val.split(":")[1]);
      // this.formfield.value = newDate;
      // console.log(newDate);
      // console.log("time changed", val);
    },
    date(val) {
      this.formfield.value = val + " " + this.time;
      var newDate = new Date(this.formfield.value).toISOString();
      console.log("::date changed:: ", newDate);
    },
  },
};
</script>