<template>
  <router-view v-if="currentCollectionProtocol" />
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import apiClient from "../../API/apiClient";
export default {
  created() {
    // console.log(this.$route.params.collectionProtocolId);
    // console.log("loading...");
    this.getCollectionProtocolById(this.$route.params.collectionProtocolId);
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocol"]),
    getCollectionProtocolById(id) {
      // console.log("getting CollectionProtocolById::", id);
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/collectionProtocols/" +
            id,
          null
          // patients%E2%80%8B/a274052a-9e22-4ca8-baf8-08d99c5355ea%E2%80%8B/patientMRNs
        )
        .then((response) => {
          // console.log(response);
          this.setSelectedCollectionProtocol(response);
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "currentCollectionProtocol"]),
  },
};
</script>

