var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"db-ChromeMainContent"},[_c('div',{staticClass:"db-ChromeMainContent-content",attrs:{"tabindex":"-1"}},[_c('div',[_c('router-link',{attrs:{"to":"/collection-protocols"}},[_vm._v("Collection Protocol")]),_c('span',{staticStyle:{"padding":"5px"}},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.currentCollectionProtocol.title))]),_c('span',{staticStyle:{"padding":"5px"}},[_vm._v("/")]),_vm._v("Consents ")],1),_c('v-app',[_c('div',{staticClass:"Box-root"},[_c('div',{staticClass:"\n            Card-root Card--radius--all\n            Section\n            Box-root\n            Box-background--white\n          ",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"ListView ListView--withoutFooter"},[_c('div',{staticClass:"\n                Box-root\n                Padding-top--16\n                Padding-bottom--8\n                Flex-flex\n                Flex-alignItems--center\n                Flex-justifyContent--spaceBetween\n              "},[_c('span',{staticClass:"\n                  Text-color--dark\n                  Text-fontSize--28\n                  Text-fontWeight--bold\n                  Text-lineHeight--32\n                  Text-numericSpacing--proportional\n                  Text-typeface--base\n                  Text-wrap--wrap\n                  Text-display--inline\n                "},[_c('span',[_vm._v("Consents")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"0","small":""},on:{"click":function($event){return _vm.toggleForm()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create Consent")],1)],1),_c('v-data-table',{staticStyle:{"margin-top":"15px"},attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.collectionProtocolConsents},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',[_vm._v("Disable")])],1)],1)],1)]}},{key:"item.enabledDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.enabledDate,"YYYY-MM-DD"))+" ")]}},{key:"item.enablerAccount",fn:function(ref){
var item = ref.item;
return [(item.enablerAccount)?_c('span',[_vm._v(_vm._s(item.enablerAccount.firstName)+" "+_vm._s(item.enablerAccount.lastName))]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedCollectionProtocolConsent)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('span',[_vm._v(" "+_vm._s(_vm.selectedCollectionProtocolConsent.title)+" ")])]),_c('hr'),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.selectedCollectionProtocolConsent.consent)}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1):_vm._e()],1)],1)])]),(_vm.showAddForm)?_c('div',{staticClass:"\n          FullscreenView FullscreenView--isLayer\n          Box-root\n          Box-background--white\n          Flex-flex\n          Flex-alignItems--center\n          Flex-direction--column\n          Flex-justifyContent--center\n        "},[_c('add-collection-protocol-consent',{attrs:{"selectedNavItem":"Consent","toggleForm":_vm.toggleForm}})],1):_vm._e(),(_vm.showEditForm)?_c('div',{staticClass:"\n          FullscreenView FullscreenView--isLayer\n          Box-root\n          Box-background--white\n          Flex-flex\n          Flex-alignItems--center\n          Flex-direction--column\n          Flex-justifyContent--center\n        "},[_c('edit-collection-protocol-consent',{attrs:{"selectedNavItem":"Consent","collectionProtocolConsent":_vm.selectedCollectionProtocolConsent,"toggleForm":_vm.toggleEditForm}})],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }