<template>
  <v-app>
    <v-main>
      <div
        class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
      >
        <form novalidate="novalidate" style="width: 100%; height: 100%">
          <div
            class="FullscreenHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-right--20 Padding-left--8 Padding-vertical--20 Flex-flex Flex-justifyContent--spaceBetween"
            style="flex: 0 0 auto"
          >
            <div class="Box-root Flex-flex Flex-alignItems--center">
              <div
                class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Box-root Flex-inlineFlex"
              >
                <div class="PressableCore-base Box-root">
                  <v-btn icon color="black" @click="toggleForm()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div
                  class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                ></div>
              </div>
              <div
                class="FullscreenHeader-separator Box-root Box-background--gray200 Margin-right--20 Margin-left--8"
              ></div>
              <span
                class="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                ><span>Create a New Order</span></span
              >
            </div>
            <div class="Box-root Flex-flex">
              <div class="Box-root" style="pointer-events: none">
                <div
                  class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                  style="margin-left: -20px; margin-top: -20px"
                >
                  <div
                    class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                    style="pointer-events: auto"
                  >
                    <div class="Box-root Margin-top--4">
                      <div class="Box-root">
                        <button
                          data-db-analytics-name="dashboard_create_payment_feedback_link"
                          type="button"
                          class="UnstyledLink ButtonLink Flex-flex"
                        >
                          <div
                            class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                            style="position: relative"
                          >
                            <div
                              class="TextAligner Box-root"
                              style="
                                line-height: 20px;
                                font-size: 14px;
                                flex: 0 0 auto;
                              "
                            ></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                    style="pointer-events: auto"
                  >
                    <div
                      class="ButtonGroup Box-root"
                      style="pointer-events: none"
                    >
                      <div
                        class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                        style="margin-left: -8px; margin-top: -8px"
                      >
                        <div
                          class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                          style="pointer-events: auto"
                        >
                          <div
                            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex"
                            style="background-color: rgb(255, 255, 255)"
                          >
                            <div class="PressableCore-base Box-root">
                              <v-btn small elevation="0"
                                >Submit and create another</v-btn
                              >
                            </div>
                            <div
                              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            ></div>
                          </div>
                        </div>
                        <div
                          class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                          style="pointer-events: auto"
                        >
                          <v-btn
                            color="primary mr-2"
                            v-if="e1 == 2"
                            small
                            @click="showFirstPage()"
                            elevation="0"
                            >Back
                          </v-btn>
                          <div
                            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                          >
                            <div
                              class="PressableCore-base Box-root"
                              @click="submit()"
                            >
                              <v-btn
                                v-if="e1 == 2"
                                color="primary"
                                small
                                :loading="isLoading"
                                elevation="0"
                                >Submit
                              </v-btn>
                              <v-btn
                                v-else
                                color="primary"
                                small
                                elevation="0"
                                :disabled="
                                  !(SelectedPatients.length > 0) &&
                                  !(selectedCollectedSampleResults.length > 0)
                                "
                                >Next
                              </v-btn>
                              <!-- <div>{{ SelectedPatients.length }}</div>
                              <div>
                                {{ selectedCollectedSampleResults.length }}
                              </div> -->
                            </div>
                            <div
                              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            ></div>
                          </div>
                          <!-- <div
                            class="
                              PressableCore
                              PressableCore--cursor--pointer
                              PressableCore--height--medium
                              PressableCore--radius--all
                              PressableCore--width
                              PressableCore--width--auto
                              PressableButton
                              Button Button--color--blue
                              Box-root
                              Flex-inlineFlex
                            "
                            style="background-color: rgb(84, 105, 212)"
                          >
                            <div
                              class="PressableCore-base Box-root"
                              @click="submit()"
                            >
                              <v-btn color="primary" dark small elevation="0"
                                >Submit</v-btn
                              >
                            </div>
                            <div
                              class="
                                PressableCore-overlay
                                PressableCore-overlay--extendBy1
                                Box-root
                                Box-background--white
                              "
                            ></div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="FullscreenContent FullscreenContent--scroll Box-root Box-background--white"
            style="flex: 1 1 100%; height: calc(100% - 76px)"
          >
            <div class="FormLayout Box-root Box-background--white">
              <div class="Box-root">
                <div
                  class="Box-root"
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    width: 500px;
                    margin-top: 20px;
                    min-height: 500px;
                  "
                >
                  <v-stepper v-model="e1">
                    <v-stepper-header>
                      <v-stepper-step :complete="e1 > 1" step="1">
                        Select Patients
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step :complete="e1 > 2" step="2">
                        Order
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-tabs
                          center-active
                          left
                          style="min-height: 100px"
                          v-model="selectedTab"
                        >
                          <v-tab
                            @click="tabSelected(1)"
                            key="patientMRN"
                            :disabled="
                              selectedCollectedSampleResults.length > 0
                            "
                            >Participant MRN</v-tab
                          >
                          <v-tab
                            @click="tabSelected(2)"
                            :disabled="SelectedPatients.length > 0"
                            key="collectedSampleId"
                            >COLLECTED SAMPLE ID</v-tab
                          >
                          <v-tab-item style="padding-top: 5px">
                            <div>
                              <div class="d-flex pt-1">
                                <v-text-field
                                  dense
                                  label="MRN"
                                  required
                                  outlined
                                  v-on:keyup.enter="searchPatient()"
                                  v-model="PatientMRN"
                                ></v-text-field>
                                <v-text-field
                                  dense
                                  outlined
                                  class="ml-3"
                                  label="Phone Number"
                                  required
                                  v-on:keyup.enter="searchPatient()"
                                  v-model="PhoneNumber"
                                ></v-text-field>
                              </div>
                              <div v-if="showResult">
                                <v-data-table
                                  style="padding-top: 10px"
                                  v-model="selectedResults"
                                  :headers="resultHeaders"
                                  :items="searchResults"
                                  :single-select="false"
                                  item-key="id"
                                  show-select
                                  hide-default-footer
                                  class="elevation-1"
                                  ><template v-slot:item.name="{ item }">
                                    <div>
                                      {{ item.firstName }}
                                      {{ item.fatherName }}
                                      {{ item.grandFatherName }}
                                    </div>
                                  </template>
                                  <template v-slot:item.birthDate="{ item }">
                                    <div>
                                      {{
                                        item.birthDate | moment("from", true)
                                      }}
                                    </div>
                                  </template></v-data-table
                                >
                                <v-btn
                                  color="primary"
                                  @click="AddPatients"
                                  class="mt-3"
                                  :disabled="selectedResults.length == 0"
                                >
                                  Add
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-data-table
                                  style="padding-top: 10px"
                                  :headers="resultHeaders"
                                  :items="SelectedPatients"
                                  item-key="id"
                                  hide-default-footer
                                  class="elevation-1"
                                  ><template v-slot:item.name="{ item }">
                                    <div>
                                      {{ item.firstName }}
                                      {{ item.fatherName }}
                                      {{ item.grandFatherName }}
                                    </div>
                                  </template>
                                  <template v-slot:item.birthDate="{ item }">
                                    <div>
                                      {{
                                        item.birthDate | moment("from", true)
                                      }}
                                    </div>
                                  </template>
                                  <template v-slot:item.actions="{ item }">
                                    <div>
                                      <v-btn
                                        icon
                                        small
                                        @click="removePatient(item)"
                                      >
                                        <v-icon color="red">mdi-delete</v-icon>
                                      </v-btn>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </div></v-tab-item
                          >
                          <v-tab-item style="padding-top: 5px">
                            <div>
                              <div class="d-flex pt-1">
                                <v-text-field
                                  v-model="SampleId"
                                  dense
                                  label="Collected Sample Id"
                                  @keyup.enter="searchCollectedSamples()"
                                  outlined
                                ></v-text-field>
                              </div>
                              <div v-if="showResult">
                                <v-data-table
                                  style="padding-top: 10px"
                                  v-model="selectedCollectedSampleResults"
                                  :headers="collectedResultHeaders"
                                  :items="collectedSampleSearchResults"
                                  :single-select="false"
                                  item-key="id"
                                  show-select
                                  hide-default-footer
                                  class="elevation-1"
                                ></v-data-table>
                                <v-btn
                                  color="primary"
                                  @click="AddPatients"
                                  class="mt-3"
                                  :disabled="
                                    selectedCollectedSampleResults.length == 0
                                  "
                                >
                                  Add
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-data-table
                                  style="padding-top: 10px"
                                  :headers="collectedResultHeaders"
                                  :items="selectedCollectedSampleResults"
                                  item-key="id"
                                  hide-default-footer
                                  class="elevation-1"
                                >
                                </v-data-table>
                              </div></div
                          ></v-tab-item>
                        </v-tabs>

                        <!-- <v-card
                          class="mb-12"
                          color="grey lighten-1"
                          height="200px"
                        ></v-card> -->
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <!-- <v-text-field
                            dense
                            label="Patient MRN"
                            :rules="[
                              requestData.SelectedPatient != null ||
                                '*Required',
                            ]"
                            filled
                            hint="Write patient MRN and hit enter to search for patient."
                            required
                            v-on:keyup.enter="searchPatient()"
                            v-model="requestData.PatientMRN"
                          ></v-text-field>
                          <div
                            v-if="requestData.SelectedPatient"
                            style="
                              min-height: 60px;
                              border-radius: 5px;
                              border: 2px solid #5469d4;
                              border-left: 5px solid #5469d4;
                              margin-bottom: 10px;
                              padding: 10px 20px;
                            "
                          >
                            <div class="h3">
                              {{ requestData.SelectedPatient.firstName }}
                              {{ requestData.SelectedPatient.fatherName }}
                              {{ requestData.SelectedPatient.grandFatherName }}
                            </div>
                            <div class="d-flex">
                              <div>Date of Birth:</div>
                              <div>
                                {{ requestData.SelectedPatient.birthDate }}
                              </div>
                            </div>
                            <div class="d-flex">
                              <div>Gender:</div>
                              <div>
                                {{ requestData.SelectedPatient.gender }}
                              </div>
                            </div>
                          </div>
                          <v-checkbox
                            v-model="requestData.HasSampleID"
                            label="Has Sample Id"
                          ></v-checkbox>
                          <v-text-field
                            dense
                            label="Sample Id"
                            :rules="NoEmptyRule"
                            filled
                            v-if="requestData.HasSampleID"
                            required
                            v-model="requestData.SampleId"
                          ></v-text-field> -->
                          <!-- <v-text-field
                      dense
                      label="Ordered By"
                      :rules="NoEmptyRule"
                      filled
                      required
                      v-model="requestData.OrderedBy"
                    ></v-text-field> -->

                          <v-select
                            :items="institutionSites"
                            item-text="title"
                            filled
                            clearable
                            return-object
                            v-model="requestData.SelectedInstitutionSite"
                            label="Institution Sites"
                          ></v-select>
                          <v-select
                            :items="priorities"
                            item-text="title"
                            filled
                            clearable
                            return-object
                            v-model="requestData.SelectedPriority"
                            :rules="[(v) => !!v || '*Required']"
                            label="Priority"
                          ></v-select>
                          <!-- <v-autocomplete
                            label="Panels"
                            :items="panels"
                            chips
                            filled
                            :search-input.sync="search"
                            return-object
                            v-model="requestData.SelectedPanels"
                            multiple
                            item-text="title"
                            :rules="[
                              requestData.SelectedPanels.length > 0 ||
                                '*Required',
                            ]"
                            clearable
                            deletable-chips
                          ></v-autocomplete> -->
                          <v-chip
                            close
                            close-icon="mdi-close"
                            v-for="panel in requestData.SelectedPanels"
                            :key="panel.id"
                            label
                            class="mr-2"
                            outlined
                            @click:close="removePanel(panel)"
                            >{{ panel.title }}</v-chip
                          >
                          <v-autocomplete
                            label="Panels"
                            class="mt-2"
                            :items="panels"
                            :search-input.sync="searchPanel"
                            return-object
                            v-model="requestData.SelectedPanel"
                            item-text="title"
                            outlined
                            :rules="[
                              requestData.SelectedPanels.length > 0 ||
                                '*Required',
                            ]"
                            clearable
                            @change="panelSelected()"
                          ></v-autocomplete>
                          <v-textarea
                            dense
                            label="Comment to Lab"
                            filled
                            rows="2"
                            required
                            v-model="requestData.CommentToLab"
                          ></v-textarea>

                          <!-- <v-checkbox
          v-model="requestData.IsSubDepartment"
          label="Is Sub Department"
        ></v-checkbox>
        <v-select
          v-if="requestData.IsSubDepartment"
          :items="departments"
          item-text="title"
          filled
          return-object
          v-model="requestData.SelectedDepartment"
          :rules="[(v) => (requestData.IsSubDepartment && !!v) || '*Required']"
          label="Parent Department"
        ></v-select> -->
                          <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
                        </v-form>

                        <!-- <v-btn color="primary" @click="e1 = 3">
                          Continue
                        </v-btn>

                        <v-btn text> Cancel </v-btn> -->
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <v-card
                          class="mb-12"
                          color="grey lighten-1"
                          height="200px"
                        ></v-card>

                        <v-btn color="primary" @click="e1 = 1">
                          Continue
                        </v-btn>

                        <v-btn text> Cancel </v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- <add-page
      selectedNavItem="Patient"
      :toggleForm="toggleForm"
      @form-submited="submit()"
    >
      
    </add-page> -->
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AddPage from "./AddPage.vue";
import apiClient from "../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-new-order",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.$store.commit("setPanels", []);
    console.log(this.$route);
    this.getInstitutionSites();
    this.getPriorities();
    // this.getPanels();
    this.getPatients();
    console.log(this.panels);
  },
  data() {
    return {
      e1: 1,
      valid: false,
      menu1: false,
      menu2: false,
      items: ["foo", "bar", "fizz", "buzz"],
      values: ["foo", "bar"],
      value: null,
      isLoading: false,
      orderedPanels: [],
      searchResults: [],
      selectedResults: [],
      SelectedPatients: [],
      searchPanel: null,
      searchedPanel: null,
      showResult: false,
      PatientMRN: "",
      PhoneNumber: "",
      SampleId: "",
      collectedSampleSearchResults: [],
      selectedCollectedSampleResults: [],
      collectedResultHeaders: [
        {
          text: "Sample ID",
          align: "start",
          value: "sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "specimenType.title",
        },
      ],
      resultHeaders: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Age",
          align: "start",
          value: "birthDate",
        },
        {
          text: "Phone Number",
          align: "start",
          value: "phoneNumber",
        },
        // {
        //   text: "Actions",
        //   align: "start",
        //   value: "actions",
        // },
      ],
      requestData: {
        PatientMRN: "",
        SelectedPatient: null,
        SelectedPanels: [],
        SelectedPanel: null,
        CommentToLab: "",
        SampleId: "",
        HasSampleID: false,
        OrderedBy: "",
        SocialSecurityNumber: "",
        BirthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        BirthTime: null,
        SelectedInstitutionSite: null,
        InstitutionSiteId: null,
        SelectedPriority: null,
        PriorityId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
      selectedTab: "",
    };
  },
  watch: {
    searchPanel(val) {
      if (this.searchedPanel != val) {
        this.$store.commit("setPanels", []);
        console.log(val);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/panels?SearchTerm=" +
              val,
            null
          )
          .then((response) => {
            this.$store.commit("setPanels", response);
            this.searchedPanel = val;
          })
          .catch((err) => {});
      }
    },
    PhoneNumber(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PatientMRN = "";
      }
    },
    PatientMRN(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PhoneNumber = "";
      }
    },
  },
  methods: {
    ...mapActions([
      "getInstitutionSites",
      "getPriorities",
      "getPanels",
      "getPatients",
    ]),
    searchPatient() {
      console.log("searching now...");
      this.searchResults = [];
      if (this.PatientMRN || this.PatientMRN.length != 0) {
        apiClient
          .call(
            "GET",
            "institutions/PatientMRNs?institutionId=" +
              this.currentInstitution.id +
              "&mrn=" +
              this.PatientMRN,
            null
          )
          .then((response) => {
            if (response.length > 0) {
              response.forEach((patientMRN) => {
                this.searchResults.push(patientMRN.patient);
                console.log("patientMRN.patient", patientMRN.patient);
              });
              // console.log(response[0].patient);
              // this.requestData.SelectedPatient = response[0].patient;
            } else {
              this.searchResults = [];
            }
            this.showResult = true;
            // this.toggleForm();
            // this.$store.dispatch("getDepartments");
          })
          .catch((err) => console.log(err));
      } else if (this.PhoneNumber || this.PhoneNumber.length != 0) {
        console.log("searching with phone number::", this.PhoneNumber);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/patients?PhoneNumber=" +
              this.PhoneNumber,
            null
          )
          .then((response) => {
            if (response.length > 0) {
              response.forEach((patient) => {
                this.searchResults.push(patient);
                console.log("patientMRN.patient", patient);
              });
              // console.log(response[0].patient);
              // this.requestData.SelectedPatient = response[0].patient;
            } else {
              this.searchResults = [];
            }

            // this.toggleForm();
            // this.$store.dispatch("getDepartments");
          })
          .catch((err) => console.log(err));
      }
    },
    searchCollectedSamples() {
      this.showResult = false;
      console.log("search...");
      // return;
      var patientId = null;
      var collectedSamplesEndpoint =
        "institutions/" + this.currentInstitution.id + "/collectedSamples";
      console.log("collectedSamplesEndpoint<><>", collectedSamplesEndpoint);
      console.log("searching by sample Id...");
      collectedSamplesEndpoint =
        collectedSamplesEndpoint + "?SampleID=" + this.SampleId;
      this.getCollectedSamples(collectedSamplesEndpoint);

      console.log("collectedSamplesEndpoint:", collectedSamplesEndpoint);
    },
    getCollectedSamples(CollectedSamplesEndpoint) {
      console.log("CollectedSamplesEndpoint::", CollectedSamplesEndpoint);
      apiClient
        .call("GET", CollectedSamplesEndpoint, null)
        .then((collectedSamples) => {
          console.log("collectedSamples", collectedSamples);
          this.collectedSampleSearchResults = collectedSamples;
          this.showResult = true;
        })
        .catch((err) => console.log(err));
    },
    // searchPatient() {
    //   console.log("searching now...");
    //   apiClient
    //     .call(
    //       "GET",
    //       "institutions/PatientMRNs?institutionId=" +
    //         this.currentInstitution.id +
    //         "&mrn=" +
    //         this.requestData.PatientMRN,
    //       null
    //     )
    //     .then((response) => {
    //       if (response.length > 0) {
    //         this.requestData.SelectedPatient = response[0].patient;
    //       } else {
    //         this.requestData.SelectedPatient = null;
    //       }

    //       // this.toggleForm();
    //       // this.$store.dispatch("getDepartments");
    //     })
    //     .catch((err) => console.log(err));
    // },
    submit() {
      console.log("submit clicked!", this.e1);

      // return;
      if (
        !(this.SelectedPatients.length > 0) &&
        !(this.selectedCollectedSampleResults.length > 0)
      ) {
        return;
      }
      if (this.e1 == 1) {
        this.e1 = 2;
      } else {
        this.isLoading = true;
        if (this.$refs.form.validate()) {
          console.log("loading...");
          // console.log(this.requestData.SelectedPatient.id);
          // console.log(this.requestData.SelectedPanels);
          this.requestData.InstitutionId = this.currentInstitution.id;
          // this.requestData.PatientId = this.requestData.SelectedPatient.id;
          this.requestData.PriorityId = this.requestData.SelectedPriority.id;
          if (this.requestData.SelectedInstitutionSite) {
            this.requestData.InstitutionSiteId =
              this.requestData.SelectedInstitutionSite.id;
          }

          this.SelectedPatients.forEach((patient) => {
            this.requestData.PatientId = patient.id;
            apiClient
              .call(
                "POST",
                "institutions/" + this.currentInstitution.id + "/LIMSOrders",
                this.requestData
              )
              .then((limsOrder) => {
                console.log(limsOrder);
                var lastPanel = 0;
                var panelsCount = this.requestData.SelectedPanels.length;
                this.requestData.SelectedPanels.forEach((panel) => {
                  // console.log(panel);
                  var newOrderPanel = {
                    limsOrderID: limsOrder.id,
                    panelID: panel.id,
                    comment: this.requestData.CommentToLab,
                  };
                  apiClient
                    .call(
                      "POST",
                      "institutions/" +
                        this.currentInstitution.id +
                        "/LIMSOrders/" +
                        limsOrder.id +
                        "/LIMSOrderedPanels",
                      newOrderPanel
                    )
                    .then((limsOrderedPanel) => {
                      console.log("limsOrderedPanel", limsOrderedPanel);
                      console.log("panelsCount::", lastPanel, panelsCount);
                      lastPanel = lastPanel + 1;
                    })
                    .catch((err) => console.log(err));
                });
                // this.$store.dispatch("getDepartments");
                this.toggleForm();
                this.isLoading = false;
              })
              .catch((err) => console.log(err));
          });

          this.selectedCollectedSampleResults.forEach(
            (selectedCollectedSampleResult) => {
              console.log(
                "selectedCollectedSampleResult",
                selectedCollectedSampleResult
              );
              this.requestData.PatientId =
                selectedCollectedSampleResult.patientID;
              this.requestData.CollectedSampleID =
                selectedCollectedSampleResult.id;
              apiClient
                .call(
                  "POST",
                  "institutions/" + this.currentInstitution.id + "/LIMSOrders",
                  this.requestData
                )
                .then((limsOrder) => {
                  console.log(limsOrder);
                  var lastPanel = 0;
                  var panelsCount = this.requestData.SelectedPanels.length;
                  this.requestData.SelectedPanels.forEach((panel) => {
                    // console.log(panel);
                    var newOrderPanel = {
                      limsOrderID: limsOrder.id,
                      panelID: panel.id,
                      comment: this.requestData.CommentToLab,
                    };
                    apiClient
                      .call(
                        "POST",
                        "institutions/" +
                          this.currentInstitution.id +
                          "/LIMSOrders/" +
                          limsOrder.id +
                          "/LIMSOrderedPanels",
                        newOrderPanel
                      )
                      .then((limsOrderedPanel) => {
                        console.log("limsOrderedPanel", limsOrderedPanel);
                        console.log("panelsCount::", lastPanel, panelsCount);
                        lastPanel = lastPanel + 1;
                      })
                      .catch((err) => console.log(err));
                  });
                  // this.$store.dispatch("getDepartments");
                  this.toggleForm();
                  this.isLoading = false;
                })
                .catch((err) => console.log(err));
            }
          );

          console.log("created...");
        }
      }
    },
    searchPanels() {
      console.log(this.search);
      // apiClient
      //   .call(
      //     "GET",
      //     "institutions/" + this.currentInstitution.id + "/panels?search=",
      //     null
      //   )
      //   .then((response) => {
      //     // console.log(response);
      //     this.setSelectedCollectionProtocolParticipant(response);
      //   })
      //   .catch((err) => {});
    },
    AddPatients() {
      console.log("this.selectedResults", this.selectedResults);
      console.log("this.requestData.SelectedPatients", this.SelectedPatients);
      // return;

      this.selectedResults.forEach((patient) => {
        if (this.SelectedPatients.indexOf(patient) == -1) {
          this.SelectedPatients.push(patient);
        }
        // this.SelectedPatients.push(patient);
      });
      this.showResult = false;
    },
    showFirstPage() {
      this.e1 = 1;
    },
    tabSelected(tabIndex) {
      this.selectedTab = tabIndex;
      console.log("tabSelected::::", this.selectedTab);
      // this.searchOrders();
      // this.goToOrders();
    },
    panelSelected() {
      if (this.requestData.SelectedPanel) {
        console.log(
          "this.requestData.SelectedPanel",
          this.requestData.SelectedPanel
        );
        this.requestData.SelectedPanels.push(this.requestData.SelectedPanel);
        this.requestData.SelectedPanel = null;
        console.log(
          "this.requestData.SelectedPanels",
          this.requestData.SelectedPanels
        );
      }
    },
    removePanel(item) {
      this.requestData.SelectedPanels.splice(
        this.requestData.SelectedPanels.indexOf(item),
        1
      );
      this.requestData.SelectedPanels = [...this.requestData.SelectedPanels];
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "institutionSites",
      "priorities",
      "panels",
      "patients",
    ]),
  },
};
</script>
