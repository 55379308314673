<template>
  <v-app>
    <v-main>
      <div class="db-ChromeMainContent">
        <div class="db-ChromeMainContent-content" tabindex="-1">
          <v-tabs
            center-active
            left
            style="max-height: 100px"
            v-model="selectedTab"
          >
            <v-tab @click="tabSelected(0)" key="sampleId">SAMPLE ID</v-tab>
            <v-tab @click="tabSelected(1)" key="patientMRN">PATIENT MRN</v-tab>
            <v-tab @click="tabSelected(2)" key="collectedSampleId"
              >COLLECTED SAMPLE ID</v-tab
            >
            <v-tab-item style="padding-top: 5px">
              <!-- <div>SAMPLE ID</div> -->
              <div class="d-flex">
                <v-text-field
                  v-model="SampleId"
                  dense
                  @keyup.enter="searchOrders()"
                  style="max-width: 550px"
                  outlined
                ></v-text-field>
                <v-btn
                  class="ml-2"
                  @click="searchOrders()"
                  color="primary"
                  depressed
                  >Search</v-btn
                >
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 5px">
              <!-- <div>PATIENT MRN</div> -->
              <div class="d-flex">
                <v-text-field
                  v-model="PatientMRN"
                  dense
                  @keyup.enter="searchOrders()"
                  style="max-width: 550px"
                  outlined
                ></v-text-field>
                <v-btn
                  class="ml-2"
                  @click="searchOrders()"
                  color="primary"
                  depressed
                  >Search</v-btn
                >
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 5px">
              <!-- <div>COLLECTED SAMPLE ID</div> -->
              <div class="d-flex">
                <v-text-field
                  v-model="CollectedSampleId"
                  dense
                  @keyup.enter="searchOrders()"
                  style="max-width: 550px"
                  outlined
                ></v-text-field>
                <v-btn
                  class="ml-2"
                  @click="searchOrders()"
                  color="primary"
                  depressed
                  >Search</v-btn
                >
              </div>
            </v-tab-item>
          </v-tabs>
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import apiClient from "../../API/apiClient";

export default {
  name: "orders",
  created() {
    this.$store.commit("setSelectedLimsOrders", []);
  },
  data() {
    return {
      selectedTab: 0,
      SampleId: "",
      PatientMRN: "",
      CollectedSampleId: "",
    };
  },
  methods: {
    tabSelected(tabIndex) {
      this.selectedTab = tabIndex;
      console.log("tabSelected::::", this.selectedTab);
      this.searchOrders();
      this.goToOrders();
    },
    goToOrders() {
      this.$router
        .push({
          name: "Orders",
        })
        .catch(() => {});
    },
    searchOrders() {
      var patientId = null;
      var ordersEndpoint =
        "institutions/" + this.currentInstitution.id + "/LIMSOrders";
      if (this.selectedTab == 0) {
        if (this.SampleId == "") {
          this.$store.commit("setSelectedLimsOrders", []);
        } else {
          console.log("searching by sample Id...");
          ordersEndpoint = ordersEndpoint + "?SampleID=" + this.SampleId;
          this.getOrders(ordersEndpoint);
        }
      } else if (this.selectedTab == 1) {
        if (this.PatientMRN == "") {
          console.log("this.PatientMRN", this.PatientMRN);
          this.$store.commit("setSelectedLimsOrders", []);
        } else {
          this.$store.commit("setIsLoading", true);
          apiClient
            .call(
              "GET",
              "institutions/patientMRNs?institutionId=" +
                this.currentInstitution.id +
                "&mrn=" +
                this.PatientMRN,
              null
              // patients%E2%80%8B/a274052a-9e22-4ca8-baf8-08d99c5355ea%E2%80%8B/patientMRNs
            )
            .then((patientMRNs) => {
              console.log("_________________________________________");
              console.log("patientMRNs:", patientMRNs);
              console.log("patientMRNs.length:", patientMRNs.length);
              if (patientMRNs.length > 0) {
                console.log(patientMRNs[0].patient.firstName);
                ordersEndpoint =
                  ordersEndpoint + "?patientId=" + patientMRNs[0].patientID;
                console.log("PatientMRNs::", patientMRNs);
                this.getOrders(ordersEndpoint);
              }
              console.log("_________________________________________");

              // response[0].patient.PatientMRNs = response;
            })
            .catch((err) => console.log(err));
        }

        console.log("searching by patient MRN...");
      } else {
        if (this.CollectedSampleId == "") {
          this.$store.commit("setSelectedLimsOrders", []);
        } else {
          console.log(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/collectedSamples?SpecimenTypeID=" +
              this.CollectedSampleId
          );
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/collectedSamples?SampleID=" +
                this.CollectedSampleId,
              null
            )
            .then((collectedSamples) => {
              console.log("_________________________________________");
              console.log("collectedSamples:", collectedSamples);
              console.log("collectedSamples.length:", collectedSamples.length);
              if (collectedSamples.length > 0) {
                console.log("collectedSamples[0]", collectedSamples[0]);

                ordersEndpoint =
                  ordersEndpoint +
                  "?CollectedSampleId=" +
                  collectedSamples[0].id;
                console.log("CollectedSamples::", collectedSamples);
                this.getOrders(ordersEndpoint);
              }
              // console.log("_________________________________________");

              // response[0].patient.PatientMRNs = response;
            })
            .catch((err) => console.log(err));

          console.log("searching by collected sample Id...");
        }
      }
      console.log("ordersEndpoint:", ordersEndpoint);
    },
    getOrders(ordersEndpoint) {
      this.$store.commit("setIsLoading", true);
      this.$store.commit("setSelectedLimsOrders", []);
      console.log("ordersEndpoint", ordersEndpoint);
      apiClient
        .call("GET", ordersEndpoint, null)
        .then((limsOrders) => {
          this.$store.commit("setSelectedLimsOrders", limsOrders);
          // console.log("this.limsOrders.length", this.limsOrders.length);
          // if (this.limsOrders.length == 1) {
          //   console.log("limsOrders[0]", limsOrders[0]);

          //   console.log("There is only one order");
          // }
          this.goToOrders();
          console.log(this.limsOrders);
          this.$store.commit("setIsLoading", false);
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "limsOrders"]),
  },
};
</script>
