<template>
  <div>
    <h2>Barcode Generator</h2>
    <div style="width: 400px; padding: 15px">
      <div style="display: flex">
        <div style="width: 50%">
          <v-text-field
            dense
            outlined
            v-model="prefix"
            label="Prefix"
            filled
            required
          ></v-text-field>
        </div>
        <div style="width: 50%">
          <v-text-field
            dense
            outlined
            label="Start Index"
            filled
            v-model="startIndex"
            type="number"
            required
          ></v-text-field>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 50%">
          <v-text-field
            dense
            type="number"
            outlined
            v-model="quantity"
            label="Quantity"
            filled
            required
          ></v-text-field>
        </div>
        <div style="width: 50%">
          <v-text-field
            dense
            type="number"
            outlined
            v-model="copies"
            label="Copies"
            filled
            required
          ></v-text-field>
        </div>
      </div>
      <v-btn @click="printZPL()">Print</v-btn>
    </div>
  </div>
  <!-- <div>
    <input v-model="barcodeValue" /><br />
    <barcode v-bind:value="barcodeValue" height="60" width="1">
      Show this if the rendering fails.
    </barcode>
    <div ref="printMe">
      <h1>Print me!</h1>
    </div>
    <div ref="outputHere"></div>
    <img :src="output" />
    <v-btn @click="print()">convert to html</v-btn>
    <v-btn @click="printBarcode()" class="ma-1" color="primary" dark
      >Print BarCode</v-btn
    >
  </div> -->
</template>
<script>
// import VueBarcode from "vue-barcode";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
// import html2canvas from "html2canvas";
export default {
  components: {
    // barcode: VueBarcode,
  },
  data() {
    return {
      barcodeValue: "",
      output: null,
      prefix: "",
      startIndex: 1,
      quantity: 1,
      copies: 1,
    };
  },
  methods: {
    printBarcode() {
      console.log("Print Barcode");
    },
    printZPL() {
      console.log("Printing...");
      // window.postMessage({
      //   type: "zpl",
      //   cmd: "print",
      //   printer: "http://10.21.3.241",
      //   raw: "^XA^FO50,50^B8N,100,Y,N^FD1234567^FS^XZ",
      // });

      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;

      var endIndex = this.startIndex + this.quantity;
      var ipAddress = localStorage.getItem("barcodePrinterIP");

      for (let i = 0; i < this.quantity; i++) {
        var sampleId = parseInt(this.startIndex) + i;
        var date = formattedDate;
        for (let j = 0; j < this.copies; j++) {
          console.log(this.prefix + "" + sampleId, date);
          window.postMessage(
            {
              type: "zebra_print_label",
              zpl: `^XA
          ^FX Second section with recipient address and permit information.
          ^CFA,20
          ^FO10,20^AQN^FD${this.prefix + "" + sampleId}^FS
          ^FO265,20^AQN^FD${date}^FS
          ^FX Third section with bar code.
          ^BY2,2,70
          ^FO10,45^BC,N,N,N,N^FD${sampleId}^FS
          ^XZ`,
              url: "http://" + ipAddress + "/pstprnt",
            },
            "*"
          );
        }
      }

      //       window.postMessage(
      //         {
      //           type: "zebra_print_label",
      //           zpl: `^XA
      // ^FX Second section with recipient address and permit information.
      // ^CFA,20
      // ^FO10,20^FD${sampleId}^FS
      // ^FO265,20^FD${date}^FS

      // ^FX Third section with bar code.
      // ^BY2,2,70
      // ^FO10,45^BC,N,N,N,N^FD${sampleId}^FS

      // ^CFA,20
      // ^FO10,125^FDMRN:${mrn}^FS
      // ^FO145,125^FD${Name}^FS

      // ^XZ`,
      //           url: "http://"+ipAddress+"/pstprnt",
      //         },
      //         "*"
      //       );
    },
    print() {
      const node = this.$refs.printMe;
      const outputHere = this.$refs.outputHere;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      console.log(node);
      // html2canvas(node).then(function (canvas) {
      //   console.log(canvas);
      //   outputHere.appendChild(canvas);
      // });

      window.postMessage(
        {
          type: "zebra_print_label",
          zpl: "^XA^PW400^LL200^FO20,20^A0N,30,30^FDThis is a TEST^FS^XZ",
          url: "http://192.168.37.36/pstprnt",
        },
        "*"
      );
    },
  },
};
</script>
