import axios from "axios";
import router from "@/router/index.js";
import store from "@/store/index.js";
const request = async (method, url, requestData) => {
  try {
    const { data, headers } = await axios({
      // baseURL: "https://127.0.0.1:6001/api/",
      baseURL: "https://api.chs-biobank.org/api/",
      method,
      url,
      data: requestData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
        accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    if (headers["x-pagination"] != undefined) {
      // console.log("headers", headers["x-pagination"]);
      // console.log('headers["x-pagination"]["TotalPages"]', headers["x-pagination"]["TotalPages"]);
      var parsed = JSON.parse(headers["x-pagination"]);
      // console.log("parsed", parsed);

      store.commit("setTotalCount", parsed.TotalCount);
    }

    return Promise.resolve(data);
  } catch (err) {
    // console.log("::::::::::err::::::::", err);
    const error = {
      status: err.response.status,
      data: err.response.data,
    };
    if (error.status == 401) {
      localStorage.removeItem("auth_token");
      router.push({ name: "Login" }).catch(() => {});
      store.commit("setShowErrorMessage", true);
      store.commit("setErrorMessage", error.data.title);
    } else {
      store.commit("setShowErrorMessage", true);
      store.commit("setErrorMessage", error.data.title);
    }
    // console.log("::::error:::::", error); wai
    return Promise.reject(error);
  }
};

export default {
  call(method, url, requestData) {
    return request(method, url, requestData);
  },
};
