<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.title"
      ></v-text-field>

      <v-text-field
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.abbreviation"
      ></v-text-field>

      <v-textarea
        label="Address"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.address"
      ></v-textarea>
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../EditPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { EditPage },
  name: "edit-institution",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.requestData = this.selectedEditObject;
  },
  data() {
    return {
      valid: false,
      requestData: {
        title: "",
        abbreviation: "",
        address: "",
      },

      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log("seems about right");
        // console.log(this.requestData);
        apiClient
          .call("PUT", "institutions", this.requestData)
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getInstitutions");
          })
          .catch((err) => console.log(err));
      }
    },
    validate() {
      this.$refs.form.validate();
      console.log("this.$refs.form.validate(): " + this.$refs.form.validate());
      console.log("Valid: " + this.valid);
      if (this.valid) {
        console.log("seems about right");
        // console.log(this.requestData);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapGetters(["selectedEditObject"]),
  },
};
</script>