<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>
      <v-select
        :items="specimenTypeCategories"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedSpecimenTypeCategory"
        :rules="[(v) => !!v || '*Required']"
        label="Specimen Type Category"
      ></v-select>
      <v-select
        :items="unitOfMeasurementTypes"
        item-text="title"
        filled
        return-object
        @change="getUnitOfMeasurements()"
        v-model="requestData.SelectedUnitOfMeasurementType"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement Type"
      ></v-select>
      <v-select
        :items="unitOfMeasurements"
        item-text="unitName"
        filled
        return-object
        v-model="requestData.SelectedUnitOfMeasurement"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement"
      ></v-select>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        type="number"
        required
        v-model="requestData.Ordinal"
      ></v-text-field>
      <v-checkbox
        v-model="requestData.IsDefault"
        label="Is Default"
      ></v-checkbox>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-specimen-type",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/specimenTypeCategories",
        null
      )
      .then((response) => {
        this.specimenTypeCategories = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/unitOfMeasurementTypes",
        null
      )
      .then((response) => {
        this.unitOfMeasurementTypes = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      specimenTypeCategories: [],
      unitOfMeasurementTypes: [],
      unitOfMeasurements: [],
      requestData: {
        Title: "",
        Abbreviation: "",
        Description: "",
        Ordinal: 0,
        IsDefault: false,
        SelectedInstitution: null,
        InstitutionId: null,
        SelectedSpecimenTypeCategory: null,
        SpecimenTypeCategoryId: null,
        SelectedUnitOfMeasurementType: null,
        UnitOfMeasurementId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.UnitOfMeasurementId =
          this.requestData.SelectedUnitOfMeasurement.id;
        this.requestData.SpecimenTypeCategoryId =
          this.requestData.SelectedSpecimenTypeCategory.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/specimenTypeCategories/" +
              this.requestData.SelectedSpecimenTypeCategory.id +
              "/specimenTypes",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getSpecimenTypes");
          })
          .catch((err) => console.log(err));
      }
    },
    getUnitOfMeasurements() {
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/unitOfMeasurementTypes/" +
            this.requestData.SelectedUnitOfMeasurementType.id +
            "/unitOfMeasurements",
          null
        )
        .then((response) => {
          this.unitOfMeasurements = response;
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>