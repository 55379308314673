<template>
  <div @contextmenu="show">
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="releaseTest(orderedPanel, orderedTest)"
          v-if="
            orderedTest.testResultRelease
              ? !orderedTest.testResultRelease.isReleased
              : false
          "
        >
          Release
        </v-list-item>
        <v-list-item @click="commentTest(orderedPanel, orderedTest)">
          Comment
        </v-list-item>
      </v-list>
    </v-menu>
    <div
      style="min-height: 40px; height: 40px; display: flex; margin-top: 10px"
      :class="[
        (
          orderedTest.testResultRelease
            ? !orderedTest.testResultRelease.isReleased
            : false
        )
          ? 'unreleased'
          : '',
      ]"
    >
      <div
        style="
          height: 40px;
          width: 30%;
          text-align: center;
          vertical-align: middle;
          line-height: 40px;
        "
      >
        {{ orderedTest.test.title }}
      </div>
      <div
        style="
          min-height: 40px;
          min-width: 25%;
          text-align: center;
          vertical-align: middle;
          line-height: 40px;
        "
      >
        <span v-if="orderedTest.testAcceptedValues">
          <v-select
            v-if="orderedTest.testAcceptedValues.length > 0"
            style="width: 100px"
            dense
            :items="orderedTest.testAcceptedValues"
            item-text="title"
            filled
            :disabled="
              orderedTest.testResultRelease
                ? orderedTest.testResultRelease.isReleased
                : false
            "
            v-model="orderedTest.testResult.result"
            :rules="[(v) => !!v || '*Required']"
          ></v-select>
          <v-text-field
            style="width: 100px"
            v-else
            :disabled="
              orderedTest.testResultRelease
                ? orderedTest.testResultRelease.isReleased
                : false
            "
            v-model="orderedTest.testResult.result"
            dense
            filled
          ></v-text-field>
        </span>
      </div>
      <div
        style="
          min-height: 40px;
          width: 45%;
          text-align: center;
          vertical-align: middle;
          line-height: 40px;
        "
      >
        <span v-if="orderedTest.testResultRange">
          <span
            >{{ orderedTest.testResultRange.minimumValue }}
            -
            {{ orderedTest.testResultRange.maximumValue }}</span
          >
        </span>
        <span v-if="orderedTest.test">
          <span v-if="orderedTest.test.unitOfMeasurement"
            >{{ orderedTest.test.unitOfMeasurement.unitSymbol }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "panel-test-row",
  props: ["orderedTest", "test", "releaseTest", "orderedPanel", "commentTest"],
  data() {
    return {
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
};
</script>
<style>
.unreleased {
  background: yellow;
}
</style>