<template>
  <div class="db-ChromeMainContent-nav">
    <div class="db-ChromeMainContent-nav-fixedContainer">
      <div class="Box-root Padding-vertical--16">
        <span
          class="
            Text-color--dark
            Text-fontSize--16
            Text-fontWeight--bold
            Text-lineHeight--24
            Text-numericSpacing--proportional
            Text-typeface--base
            Text-wrap--wrap
            Text-display--inline
          "
          ><span>{{ title }}</span></span
        >
      </div>
      <div class="db-SideNav-navSections">
        <div class="db-SideNav-navSections-links-wrapper">
          <ul>
            <li
              style="overflow: hidden"
              v-for="(leftNavItem, index) in leftNavItems"
              :key="index"
            >
              <span
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="allPayments"
                data-db-analytics-sidenav-path="/test/payments"
                tabindex="2"
                ><div
                  :class="[
                    leftNavItem.selected
                      ? 'db-SideNav-itemContent--selected'
                      : '',
                  ]"
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div
                    class="Box-root Flex-flex Flex-alignItems--flexStart"
                    @click="selectNav(leftNavItem.id)"
                  >
                    <span
                      :class="[
                        leftNavItem.selected
                          ? 'Text-color--blue Text-fontWeight--bold'
                          : 'Text-color--default Text-fontWeight--regular',
                      ]"
                      class="
                        Text-fontSize--14
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>{{ leftNavItem.title }}</span></span
                    >
                  </div>
                </div></span
              >
              <div
                class="db-SideNav--expandable"
                v-if="leftNavItem.showChildren"
                style="overflow: hidden; margin-bottom: 8px"
              >
                <ul>
                  <li
                    style="overflow: hidden"
                    v-for="(navItem, index) in leftNavItem.children"
                    :key="index"
                    @click="selectNav(navItem.id)"
                  >
                    <span
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="reviews"
                      data-db-analytics-sidenav-path="/test/radar/reviews"
                      tabindex="2"
                      ><div
                        :class="[
                          navItem.selected
                            ? 'db-SideNav-itemContent--selected'
                            : '',
                        ]"
                        class="
                          db-SideNav-itemContent
                          NavItem
                          Box-root
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <!-- Text-color--default
                              Text-fontSize--14
                              Text-fontWeight--regular
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline -->
                          <span
                            :class="[
                              navItem.selected
                                ? 'Text-color--blue Text-fontWeight--bold'
                                : 'Text-color--default Text-fontWeight--regular',
                            ]"
                            class="
                              Text-fontSize--14
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>{{ navItem.title }}</span></span
                          >
                        </div>
                      </div></span
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li>
                  <!-- <li style="overflow: hidden">
                    <a
                      href="/test/disputes?statuses%5B0%5D=needs_response&amp;statuses%5B1%5D=warning_needs_response&amp;evidence_due_by%5Bgt%5D=1633899600&amp;sort=evidence_due_by&amp;order=asc"
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="disputes"
                      data-db-analytics-sidenav-path="/test/disputes?statuses%5B0%5D=needs_response&amp;statuses%5B1%5D=warning_needs_response&amp;evidence_due_by%5Bgt%5D=1633899600&amp;sort=evidence_due_by&amp;order=asc"
                      tabindex="2"
                      ><div
                        class="
                          db-SideNav-itemContent
                          NavItem
                          Box-root
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <span
                            class="
                              Text-color--default
                              Text-fontSize--14
                              Text-fontWeight--regular
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>Disputes</span></span
                          >
                        </div>
                      </div></a
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li>
                  <li style="overflow: hidden">
                    <a
                      href="/test/balance"
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="balance"
                      data-db-analytics-sidenav-path="/test/balance"
                      tabindex="2"
                      ><div
                        class="
                          db-SideNav-itemContent
                          NavItem
                          Box-root
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <span
                            class="
                              Text-color--default
                              Text-fontSize--14
                              Text-fontWeight--regular
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>All transactions</span></span
                          >
                        </div>
                      </div></a
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li> -->
                </ul>
              </div>
            </li>
            <!-- <li style="overflow: hidden">
              <a
                href="/test/payments"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="allPayments"
                data-db-analytics-sidenav-path="/test/payments"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>All payments</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 84px; overflow: hidden; margin-bottom: 8px"
              >
                <ul>
                  <li style="overflow: hidden">
                    <a
                      href="/test/radar/reviews"
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="reviews"
                      data-db-analytics-sidenav-path="/test/radar/reviews"
                      tabindex="2"
                      ><div
                        class="
                          db-SideNav-itemContent
                          db-SideNav-itemContent--selected
                          NavItem
                          Box-root
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <span
                            class="
                              Text-color--blue
                              Text-fontSize--14
                              Text-fontWeight--bold
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>Reviews</span></span
                          >
                        </div>
                      </div></a
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li>
                  <li style="overflow: hidden">
                    <a
                      href="/test/disputes?statuses%5B0%5D=needs_response&amp;statuses%5B1%5D=warning_needs_response&amp;evidence_due_by%5Bgt%5D=1633899600&amp;sort=evidence_due_by&amp;order=asc"
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="disputes"
                      data-db-analytics-sidenav-path="/test/disputes?statuses%5B0%5D=needs_response&amp;statuses%5B1%5D=warning_needs_response&amp;evidence_due_by%5Bgt%5D=1633899600&amp;sort=evidence_due_by&amp;order=asc"
                      tabindex="2"
                      ><div
                        class="
                          db-SideNav-itemContent
                          NavItem
                          Box-root
                          db-SideNav-itemContent--selected
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <span
                            class="
                              Text-color--default
                              Text-fontSize--14
                              Text-fontWeight--regular
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>Disputes</span></span
                          >
                        </div>
                      </div></a
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li>
                  <li style="overflow: hidden">
                    <a
                      href="/test/balance"
                      class="UnstyledLink db-SideNav-link"
                      data-db-analytics-sidenav="balance"
                      data-db-analytics-sidenav-path="/test/balance"
                      tabindex="2"
                      ><div
                        class="
                          db-SideNav-itemContent
                          NavItem
                          Box-root
                          Padding-horizontal--4
                          Padding-vertical--2
                        "
                      >
                        <div
                          class="
                            Box-root
                            Padding-left--8
                            Flex-flex
                            Flex-alignItems--flexStart
                          "
                        >
                          <span
                            class="
                              Text-color--default
                              Text-fontSize--14
                              Text-fontWeight--regular
                              Text-lineHeight--20
                              Text-numericSpacing--proportional
                              Text-typeface--base
                              Text-wrap--wrap
                              Text-display--inline
                            "
                            ><span>All transactions</span></span
                          >
                        </div>
                      </div></a
                    >
                    <div
                      class="db-SideNav--expandable"
                      style="height: 0px; overflow: hidden"
                    ></div>
                  </li>
                </ul>
              </div>
            </li>
            <li aria-hidden="true" style="margin-bottom: 18px"></li>
            <li style="overflow: hidden">
              <a
                href="/test/invoices"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="invoices"
                data-db-analytics-sidenav-path="/test/invoices"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>Invoices</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 0px; overflow: hidden"
              ></div>
            </li>
            <li style="overflow: hidden">
              <a
                href="/test/subscriptions"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="subscriptions"
                data-db-analytics-sidenav-path="/test/subscriptions"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>Subscriptions</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 0px; overflow: hidden"
              ></div>
            </li>
            <li style="overflow: hidden">
              <a
                href="/test/quotes"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="quotes"
                data-db-analytics-sidenav-path="/test/quotes"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>Quotes</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 0px; overflow: hidden"
              ></div>
            </li>
            <li aria-hidden="true" style="margin-bottom: 18px"></li>
            <li style="overflow: hidden">
              <a
                href="/test/payment-links"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="paymentLinks"
                data-db-analytics-sidenav-path="/test/payment-links"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>Payment links</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 0px; overflow: hidden"
              ></div>
            </li>
            <li style="overflow: hidden">
              <a
                href="/test/terminal"
                class="UnstyledLink db-SideNav-link"
                data-db-analytics-sidenav="terminal"
                data-db-analytics-sidenav-path="/test/terminal"
                tabindex="2"
                ><div
                  class="
                    db-SideNav-itemContent
                    NavItem
                    Box-root
                    Padding-horizontal--4
                    Padding-vertical--4
                  "
                >
                  <div class="Box-root Flex-flex Flex-alignItems--flexStart">
                    <span
                      class="
                        Text-color--dark
                        Text-fontSize--14
                        Text-fontWeight--regular
                        Text-lineHeight--20
                        Text-numericSpacing--proportional
                        Text-typeface--base
                        Text-wrap--wrap
                        Text-display--inline
                      "
                      ><span>Readers</span></span
                    >
                  </div>
                </div></a
              >
              <div
                class="db-SideNav--expandable"
                style="height: 0px; overflow: hidden"
              ></div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uuid } from "uuidv4";
export default {
  props: ["leftNavItems", "title"],
  name: "left-nav",
  data() {
    return {
      // leftNavItems: [
      //   {
      //     id: 0,
      //     title: "Nav 1",
      //     selected: false,
      //     children: [
      //       { id: 1, title: "Nav 1 child 1", selected: true },
      //       { id: 2, title: "Nav 1 child 2", selected: false },
      //       { id: 3, title: "Nav 1 child 3", selected: false },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     title: "Nav 2",
      //     selected: false,
      //     children: [
      //       { id: 5, title: "Nav 2 child 1", selected: false },
      //       { id: 6, title: "Nav 2 child 2", selected: false },
      //     ],
      //   },
      //   {
      //     id: 7,
      //     title: "Nav 3",
      //     selected: false,
      //     children: [],
      //   },
      // ],
    };
  },
  mounted() {
    // this.leftNavItems.forEach((leftNavItem) => {
    //   console.log(leftNavItem.showChildren);
    // });
  },
  created() {
    var hasSelectedChild = false;
    var selectedNavId = null;
    console.log(this.$route.name);
    this.leftNavItems.forEach((leftNavItem) => {
      var childSelected = false;
      if (leftNavItem.pathName == this.$route.name) {
        selectedNavId = leftNavItem.id;
      }

      // console.log(":::::::::________________________________________");
      // console.log("childrens count: " + leftNavItem.children.length);
      // console.log("title: " + leftNavItem.title);
      // console.log(":::::::::________________________________________");
      leftNavItem.children.forEach((leftNav) => {
        // console.log("+        ________________________________________");
        // console.log("+        path name: " + leftNav.pathName);
        // console.log("+        route-name: " + this.$route.name);
        // console.log("+        ________________________________________");
        if (leftNav.pathName == this.$route.name) {
          // console.log(
          //   leftNav.pathName + "::::::::::::::::::::::::::::::::::::::::"
          // );
          leftNav.selected = true;
          hasSelectedChild = true;
          childSelected = true;
        } else {
          leftNav.selected = false;
        }
      });
      // console.log("hasSelectedChild:" + hasSelectedChild);
      leftNavItem.showChildren = childSelected;
    });
    // console.log("hasSelectedChild:" + hasSelectedChild);
    if (selectedNavId && !hasSelectedChild) {
      // console.log("it has no selected child but it will do!");
      this.selectNav(selectedNavId);
    } else if (!hasSelectedChild) {
      // console.log("it has no selected child!");
      this.selectNav(this.leftNavItems[0].id);
    }
  },
  methods: {
    selectNav: function (id) {
      // console.log(id);
      this.leftNavItems.forEach((leftNavItem) => {
        // console.log(leftNavItem.showChildren);
        var hasSelectedChild = false;
        if (leftNavItem.id == id) {
          if (leftNavItem.children.length > 0) {
            // console.log(leftNavItem.children[0].selected);
            // leftNavItem.children[0].selected = true;
            this.selectNav(leftNavItem.children[0].id);
            return;
          } else {
            leftNavItem.selected = true;
            this.$router.push({ name: leftNavItem.pathName }).catch(() => {});
            this.$store.commit(
              "setSelectedConfiguration",
              leftNavItem.getterName
            );
            console.log(
              "getterName:----------------::",
              leftNavItem.getterName
            );
          }
        } else {
          leftNavItem.selected = false;
        }
        hasSelectedChild = false;
        leftNavItem.children.forEach((leftNav) => {
          if (leftNav.id == id) {
            leftNav.selected = true;
            this.$router.push({ name: leftNav.pathName }).catch(() => {});
            this.$store.commit("setSelectedConfiguration", leftNav.getterName);
            console.log("getterName:----------------::", leftNav.getterName);
            hasSelectedChild = true;
          } else {
            leftNav.selected = false;
          }
        });
        leftNavItem.showChildren = hasSelectedChild;
      });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>