<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.IsSubDepartment"
        label="Is Sub Department"
      ></v-checkbox>
      <v-select
        v-if="requestData.IsSubDepartment"
        :items="departments"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedDepartment"
        :rules="[(v) => (requestData.IsSubDepartment && !!v) || '*Required']"
        label="Parent Department"
      ></v-select>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-department",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));

    apiClient
      .call(
        "GET",
        "institutions/" + this.currentInstitution.id + "/departments",
        null
      )
      .then((response) => {
        this.departments = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        Title: "",
        Abbreviation: "",
        Description: "",
        IsSubDepartment: false,
        SelectedDepartment: null,
        SelectedInstitution: null,
        InstitutionId: null,
        ParentDepartmentId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        if (this.requestData.IsSubDepartment) {
          this.requestData.ParentDepartmentId =
            this.requestData.SelectedDepartment.id;
        }
        let that = this;
        apiClient
          .call(
            "POST",
            "institutions/" + this.currentInstitution.id + "/departments",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getDepartments");
          })
          .catch((err) => console.log(err));
      }
    },
    validate() {
      this.$refs.form.validate();
      console.log("this.$refs.form.validate(): " + this.$refs.form.validate());
      console.log("Valid: " + this.valid);
      if (this.valid) {
        console.log("seems about right");
        // console.log(this.requestData);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>