<template>
  <edit-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.description"
      ></v-textarea>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </edit-page>
</template>
<script>
import EditPage from "../EditPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { EditPage },
  name: "edit-labeling-schema",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.requestData = this.selectedEditObject;
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        title: "",
        abbreviation: "",
        description: "",
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call(
            "PUT",
            "institutions/" +
              this.currentInstitution.id +
              "/labelingSchemas/" +
              this.selectedEditObject.id,
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getLabelingSchemas");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "selectedEditObject"]),
  },
};
</script>