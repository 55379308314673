<template>
  <v-app>
    <v-main>
      <table-page
        :headers="headers"
        :items="storageContainerRacks"
        :rowCount="25"
      />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../../components/TablePage.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TablePage,
  },
  methods: {
    ...mapActions(["getStorageContainerRacks"]),
  },
  created() {
    this.getStorageContainerRacks();
  },
  computed: {
    ...mapGetters(["currentInstitution", "storageContainerRacks"]),
  },
  data() {
    return {
      headers: [
        {
          text: "Storage Container Shelf",
          value: "storageContainerShelf.title",
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Code", value: "code" },
        { text: "Rows", value: "rows" },
        { text: "Columns", value: "columns" },
        {
          text: "Position Labeling Option",
          value: "positionLabelingOption.title",
        },
        {
          text: "Row Labeling Schema",
          value: "rowLabelingSchema.title",
        },
        {
          text: "Column Labeling Schema",
          value: "columnLabelingSchema.title",
        },
      ],
      items: [],
    };
  },
};
</script>