<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form
      @submit.prevent
      novalidate="novalidate"
      style="width: 100%; height: 100%"
    >
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <span
            class="
              Text-color--default
              Text-fontSize--14
              Text-fontWeight--medium
              Text-lineHeight--20
              Text-numericSpacing--proportional
              Text-typeface--base
              Text-wrap--wrap
              Text-display--inline
            "
            ><span>Create a Collected Sample Shipment</span></span
          >
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <v-btn
                        color="primary mr-2"
                        v-if="e1 == 2"
                        small
                        @click="showFirstPage()"
                        elevation="0"
                        >Back
                      </v-btn>
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            ><span v-if="e1 == 2">Submit</span
                            ><span v-else>Next</span>
                          </v-btn>
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Shipment Information
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">
                    Shipment Items
                  </v-stepper-step>

                  <!-- <v-divider></v-divider>

                  <v-stepper-step step="3"> Name of step 3 </v-stepper-step> -->
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        dense
                        label="Name"
                        :rules="NoEmptyRule"
                        filled
                        required
                        v-model="requestData.Name"
                      ></v-text-field>
                      <v-text-field
                        dense
                        label="TrackingNumber"
                        filled
                        v-model="requestData.TrackingNumber"
                      ></v-text-field>
                      <v-text-field
                        label="TrackingURL"
                        filled
                        required
                        v-model="requestData.TrackingURL"
                      ></v-text-field>
                      <!-- <v-select
                        clearable
                        :items="institutions"
                        item-text="title"
                        filled
                        return-object
                        v-model="requestData.SendingInstitution"
                        :rules="[(v) => !!v || '*Required']"
                        label="Sending Institution"
                      ></v-select> -->
                      <v-select
                        clearable
                        :items="institutionSites"
                        item-text="title"
                        filled
                        return-object
                        v-model="requestData.SendingInstitutionSite"
                        label="Sending Institution Sites"
                      ></v-select>
                      <v-select
                        clearable
                        :items="institutions"
                        item-text="title"
                        filled
                        @change="receivingInstitutionChanged"
                        return-object
                        v-model="requestData.ReceivingInstitution"
                        :rules="[(v) => !!v || '*Required']"
                        label="Receiving Institution"
                      ></v-select>
                      <v-select
                        clearable
                        :items="receivingInstitutionSites"
                        item-text="title"
                        filled
                        return-object
                        v-model="requestData.ReceivingInstitutionSite"
                        label="Receiving Institution Sites"
                      ></v-select>
                      <div class="d-flex">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="requestData.ShippedDate"
                              label="Shipped Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              :rules="NoEmptyRule"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestData.ShippedDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="requestData.ShippedTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="requestData.ShippedTime"
                              label="Shipped Time"
                              outlined
                              dense
                              :rules="NoEmptyRule"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu2"
                            v-model="requestData.ShippedTime"
                            full-width
                            @click:minute="
                              $refs.menu2.save(requestData.ShippedTime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </div>
                    </v-form>

                    <!-- <v-btn color="primary" @click="e1 = 2"> Continue </v-btn> -->

                    <!-- <v-btn text> Cancel </v-btn> -->
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-tabs
                      center-active
                      left
                      style="max-height: 100px"
                      v-model="selectedTab"
                    >
                      <v-tab @click="tabSelected(0)" key="collectedSampleId"
                        >COLLECTED SAMPLE ID</v-tab
                      >
                      <v-tab @click="tabSelected(1)" key="patientMRN"
                        >Participant MRN</v-tab
                      ><v-tab @click="tabSelected(2)" key="date">DATE</v-tab>

                      <v-tab-item style="padding-top: 5px">
                        <!-- <div>SAMPLE ID</div> -->
                        <v-text-field
                          v-model="SampleId"
                          dense
                          @keyup.enter="searchCollectedSamples()"
                          outlined
                        ></v-text-field>
                      </v-tab-item>
                      <v-tab-item style="padding-top: 5px">
                        <v-text-field
                          v-model="PatientMRN"
                          dense
                          @keyup.enter="searchCollectedSamples()"
                          outlined
                        ></v-text-field>
                      </v-tab-item>
                      <v-tab-item style="padding-top: 5px">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :return-value.sync="DateRange"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              v-model="DateRange"
                              dense
                              @change="searchCollectedSamples()"
                              label="Picker in menu"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="DateRange"
                            no-title
                            range
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu3 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="searchCollectedSamples()"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-tab-item>
                    </v-tabs>
                    <div v-if="!showResult">
                      <v-data-table
                        style="padding-top: 10px"
                        v-model="selectedResults"
                        :headers="resultHeaders"
                        :items="searchResults"
                        :single-select="false"
                        item-key="id"
                        show-select
                        hide-default-footer
                        class="elevation-1"
                      >
                      </v-data-table>

                      <v-btn
                        class="mt-4"
                        color="primary"
                        @click="AddSelectedResults()"
                      >
                        Add
                      </v-btn>

                      <v-btn class="mt-4" text> Cancel </v-btn>
                    </div>
                    <div v-else>
                      <v-data-table
                        style="padding-top: 10px"
                        v-model="selectedResults"
                        :headers="resultHeaders"
                        :items="selectedResults"
                        :single-select="false"
                        item-key="id"
                        hide-default-footer
                        class="elevation-1"
                      >
                      </v-data-table>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-collection-protocol-event",
  props: ["toggleForm"],
  created() {
    console.log("The id is: " + this.$route.params.id);
    this.getInstitutions();
    this.getInstitutionSites();
  },
  data() {
    return {
      selectedTab: null,
      e1: 1,
      valid: false,
      departments: [],
      menu: false,
      menu2: false,
      menu3: false,
      SampleId: "",
      PatientMRN: "",
      DateRange: null,
      searchResults: [],
      selectedResults: [],
      showResult: true,
      resultHeaders: [
        {
          text: "Sample ID",
          align: "start",
          value: "sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "specimenType.title",
        },
        // {
        //   text: "Actions",
        //   align: "start",
        //   value: "actions",
        // },
      ],
      selectedCollectedSamples: [],
      receivingInstitutionSites: [],
      requestData: {
        Name: "",
        TrackingNumber: "",
        TrackingURL: "",
        SendingInstitution: null,
        SendingInstitutionSite: null,
        SendingInstitutionId: null,
        SendingInstitutionSiteId: null,
        ReceivingInstitution: null,
        ReceivingInstitutionSite: null,
        ReceivingInstitutionId: null,
        ReceivingInstitutionSiteId: null,
        ShippedDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        ShippedTime: this.getTodaysTime(),
        // ShippedDate: null,
        // ShippedTime: null,
        CollectionProtocolId: null,
      },
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocol"]),
    ...mapActions([
      "getInstitutionSites",
      "getInstitutions",
      "getInstitutionSitesByInstitutionId",
    ]),
    AddSelectedResults() {
      this.selectedResults.forEach((result) => {
        console.log("result::", result);
        var isAlreadyExist = false;
        this.selectedResults.forEach((selectedResult) => {
          if (selectedResult.id == result.id) {
            isAlreadyExist = true;
          }
        });
        if (!isAlreadyExist) {
          result.actions = "";
          this.selectedResults.push(result);
        }

        this.showResult = true;
      });
    },
    receivingInstitutionChanged(val) {
      if (val) {
        apiClient
          .call("GET", "institutions/" + val.id + "/institutionSites", null)
          .then((response) => {
            console.log("receivingInstitutionSites:::", response);
            this.receivingInstitutionSites = response;
          })
          .catch((err) => console.log(err));
      } else {
        this.receivingInstitutionSites = [];
      }
    },
    deleteSelectedResult(item) {
      console.log("deleteSelectedResult:: ", item);
    },
    showFirstPage() {
      this.e1 = 1;
    },
    tabSelected(val) {
      this.searchResults = [];
      console.log("val", val);
    },
    searchCollectedSamples() {
      this.menu3 = false;
      this.showResult = false;
      console.log("search...");
      // return;
      var patientId = null;
      var collectedSamplesEndpoint =
        "institutions/" +
        this.currentInstitution.id +
        "/collectionProtocols/" +
        this.currentCollectionProtocol.id +
        "/collectedSamples";
      console.log("collectedSamplesEndpoint<><>", collectedSamplesEndpoint);
      if (this.selectedTab == 0) {
        console.log("searching by sample Id...");
        collectedSamplesEndpoint =
          collectedSamplesEndpoint + "?SampleID=" + this.SampleId;
        this.getCollectedSamples(collectedSamplesEndpoint);
      } else if (this.selectedTab == 1) {
        if (this.PatientMRN == "") {
          console.log("this.PatientMRN", this.PatientMRN);
          this.$store.commit("setSelectedLimsCollectedSamples", []);
        } else {
          apiClient
            .call(
              "GET",
              "institutions/patientMRNs?institutionId=" +
                this.currentInstitution.id +
                "&mrn=" +
                this.PatientMRN,
              null
              // patients%E2%80%8B/a274052a-9e22-4ca8-baf8-08d99c5355ea%E2%80%8B/patientMRNs
            )
            .then((patientMRNs) => {
              console.log("_________________________________________");
              console.log("patientMRNs:", patientMRNs);
              console.log("patientMRNs.length:", patientMRNs.length);
              if (patientMRNs.length > 0) {
                console.log(patientMRNs[0].patient.firstName);
                collectedSamplesEndpoint =
                  collectedSamplesEndpoint +
                  "?patientId=" +
                  patientMRNs[0].patientID;
                console.log("PatientMRNs::", patientMRNs);
                this.getCollectedSamples(collectedSamplesEndpoint);
              }
              console.log("_________________________________________");

              // response[0].patient.PatientMRNs = response;
            })
            .catch((err) => console.log(err));
        }

        console.log("searching by patient MRN...");
      } else {
        console.log("searching by range...");
        console.log(this.DateRange);
        if (this.DateRange.length > 1) {
          collectedSamplesEndpoint =
            collectedSamplesEndpoint +
            "?StartDate=" +
            this.DateRange[0] +
            "&EndDate=" +
            this.DateRange[1];
          this.getCollectedSamples(collectedSamplesEndpoint);
        }
        // return;
      }
      console.log("collectedSamplesEndpoint:", collectedSamplesEndpoint);
    },
    getCollectedSamples(CollectedSamplesEndpoint) {
      console.log("CollectedSamplesEndpoint::", CollectedSamplesEndpoint);
      apiClient
        .call("GET", CollectedSamplesEndpoint, null)
        .then((collectedSamples) => {
          console.log("collectedSamples", collectedSamples);
          this.searchResults = collectedSamples;
        })
        .catch((err) => console.log(err));
    },
    submit() {
      if (this.e1 == 1 && this.$refs.form.validate()) {
        this.e1 = 2;
        console.log("it is the first page");
      } else {
        if (this.$refs.form.validate()) {
          console.log(
            "ReceivingInstitution",
            this.requestData.ReceivingInstitution
          );
          console.log(
            "ReceivingInstitutionSite",
            this.requestData.ReceivingInstitutionSite
          );
          // return;
          this.requestData.SendingInstitutionId = this.currentInstitution.id;
          this.requestData.SendingInstitutionSiteId =
            this.requestData.SendingInstitutionSite.id;
          this.requestData.ReceivingInstitutionId =
            this.requestData.ReceivingInstitution.id;
          this.requestData.ReceivingInstitutionSiteId =
            this.requestData.ReceivingInstitutionSite.id;
          this.requestData.CollectionProtocolId =
            this.currentCollectionProtocol.id;
          // console.log(
          //   "SelectedInstitutionSite",
          //   this.requestData.SelectedInstitutionSite
          // );
          // this.requestData.InstitutionId = this.currentInstitution.id;
          // this.requestData.InstitutionSiteId =
          //   this.requestData.SelectedInstitutionSite.id;
          apiClient
            .call(
              "POST",
              "institutions/" +
                this.currentInstitution.id +
                "/collectedSampleShipments",
              this.requestData
            )
            .then((collectedSampleShipment) => {
              console.log(collectedSampleShipment);
              this.selectedResults.forEach((result) => {
                var newCollectedSampleShipment = {
                  CollectedSampleShipmentID: collectedSampleShipment.id,
                  CollectedSampleID: result.id,
                  Rejected: false,
                  Received: false,
                };
                apiClient
                  .call(
                    "POST",
                    "institutions/" +
                      this.currentInstitution.id +
                      "/collectedSampleShipments/" +
                      collectedSampleShipment.id +
                      "/collectedSampleShipmentItems",
                    newCollectedSampleShipment
                  )
                  .then((collectedSampleShipmentItem) => {
                    console.log(collectedSampleShipmentItem);

                    this.toggleForm();
                  })
                  .catch((err) => console.log(err));
              });
              this.$store.dispatch("getCollectionProtocolShipments");
              // this.$router
              //   .push({
              //     name: "CollectionProtocolShipments",
              //     params: { id: response.id },
              //   })
              //   .catch(() => {});
              // this.toggleForm();
            })
            .catch((err) => console.log(err));
        }
      }
    },
    getTodaysTime() {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes();
      return time;
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "institutionSites",
      "institutions",
      "currentCollectionProtocol",
      "collectionProtocolEventUnitOfMeasurements",
    ]),
  },
};
</script>