<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="[
          (v) => (v || '').length <= 4 || 'Code must be 4 characters or less',
        ]"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Address"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.Address"
      ></v-textarea>
      <!-- <v-select
        :items="institutions"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedInstitution"
        :rules="[(v) => !!v || '*Required']"
        label="Institution"
      ></v-select> -->
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-institution-site",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      requestData: {
        Title: "",
        Abbreviation: "",
        Address: "",
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call(
            "POST",
            "institutions/" + this.currentInstitution.id + "/institutionSites",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getAllInstitutionSites");
          })
          .catch((err) => console.log(err));
      }
    },
    validate() {
      this.$refs.form.validate();
      console.log("this.$refs.form.validate(): " + this.$refs.form.validate());
      console.log("Valid: " + this.valid);
      if (this.valid) {
        console.log("seems about right");
        // console.log(this.requestData);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>