<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="items" :rowCount="25" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../components/TablePage.vue";
import { mapGetters } from "vuex";
export default {
  name: "test-result-report",
  components: {
    TablePage,
  },
  created() {},
  data() {
    return {
      headers: [
        { text: "Patient MRN", value: "patientMRN" },
        {
          text: "Patient Name",
          align: "start",
          value: "patientName",
        },
        { text: "Date Of Birth", value: "dateOfBirth" },
        { text: "Gender", value: "render" },
        { text: "SampleId", value: "sampleId" },
        { text: "Requested Date", value: "requested Date" },
        { text: "Panel Name", value: "panelName" },
        { text: "Test Name", value: "testName" },
        { text: "Result", value: "result" },
        { text: "Result Comment", value: "resultComment" },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>