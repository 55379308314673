<template>
  <v-app>
    <v-main>
      <div
        class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
      >
        <form novalidate="novalidate" style="width: 100%; height: 100%">
          <div
            class="FullscreenHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-right--20 Padding-left--8 Padding-vertical--20 Flex-flex Flex-justifyContent--spaceBetween"
            style="flex: 0 0 auto"
          >
            <div class="Box-root Flex-flex Flex-alignItems--center">
              <div
                class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Box-root Flex-inlineFlex"
              >
                <div class="PressableCore-base Box-root">
                  <v-btn icon color="black" @click="toggleForm()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div
                  class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                ></div>
              </div>
              <div
                class="FullscreenHeader-separator Box-root Box-background--gray200 Margin-right--20 Margin-left--8"
              ></div>
              <span
                class="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                ><span>Barcode Generator</span></span
              >
            </div>
            <div class="Box-root Flex-flex">
              <div class="Box-root" style="pointer-events: none">
                <div
                  class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                  style="margin-left: -20px; margin-top: -20px"
                >
                  <div
                    class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                    style="pointer-events: auto"
                  >
                    <div class="Box-root Margin-top--4">
                      <div class="Box-root">
                        <button
                          data-db-analytics-name="dashboard_create_payment_feedback_link"
                          type="button"
                          class="UnstyledLink ButtonLink Flex-flex"
                        >
                          <div
                            class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                            style="position: relative"
                          >
                            <div
                              class="TextAligner Box-root"
                              style="
                                line-height: 20px;
                                font-size: 14px;
                                flex: 0 0 auto;
                              "
                            ></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                    style="pointer-events: auto"
                  >
                    <div
                      class="ButtonGroup Box-root"
                      style="pointer-events: none"
                    >
                      <div
                        class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                        style="margin-left: -8px; margin-top: -8px"
                      >
                        <div
                          class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                          style="pointer-events: auto"
                        >
                          <div
                            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex"
                            style="background-color: rgb(255, 255, 255)"
                          >
                            <!-- <div class="PressableCore-base Box-root">
                              <v-btn small elevation="0"
                                >Submit and create another</v-btn
                              >
                            </div> -->
                            <div
                              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            ></div>
                          </div>
                        </div>
                        <div
                          class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                          style="pointer-events: auto"
                        >
                          <div
                            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                          >
                            <div
                              class="PressableCore-base Box-root"
                              @click="submit()"
                            >
                              <v-btn
                                color="primary"
                                small
                                :loading="isLoading"
                                elevation="0"
                                >Print
                              </v-btn>

                              <!-- <div>{{ SelectedPatients.length }}</div>
                                <div>
                                  {{ selectedCollectedSampleResults.length }}
                                </div> -->
                            </div>
                            <div
                              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                            ></div>
                          </div>
                          <!-- <div
                              class="
                                PressableCore
                                PressableCore--cursor--pointer
                                PressableCore--height--medium
                                PressableCore--radius--all
                                PressableCore--width
                                PressableCore--width--auto
                                PressableButton
                                Button Button--color--blue
                                Box-root
                                Flex-inlineFlex
                              "
                              style="background-color: rgb(84, 105, 212)"
                            >
                              <div
                                class="PressableCore-base Box-root"
                                @click="submit()"
                              >
                                <v-btn color="primary" dark small elevation="0"
                                  >Submit</v-btn
                                >
                              </div>
                              <div
                                class="
                                  PressableCore-overlay
                                  PressableCore-overlay--extendBy1
                                  Box-root
                                  Box-background--white
                                "
                              ></div>
                            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="FullscreenContent FullscreenContent--scroll Box-root Box-background--white"
            style="flex: 1 1 100%; height: calc(100% - 76px)"
          >
            <div class="FormLayout Box-root Box-background--white">
              <div class="Box-root">
                <div
                  class="Box-root"
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    width: 500px;
                    margin-top: 20px;
                    min-height: 500px;
                  "
                >
                  <div>
                    <div class="w-full grid grid-cols-2 gap-2">
                      <div>
                        <v-text-field
                          dense
                          outlined
                          v-model="prefix"
                          label="Prefix"
                          required
                        ></v-text-field>
                      </div>
                      <v-text-field
                        dense
                        outlined
                        label="Start Index"
                        v-model="startIndex"
                        type="number"
                        required
                      ></v-text-field>
                      <v-text-field
                        dense
                        type="number"
                        outlined
                        v-model="quantity"
                        label="Quantity"
                        required
                      ></v-text-field>
                      <v-text-field
                        dense
                        type="number"
                        outlined
                        v-model="copies"
                        label="Copies"
                        required
                      ></v-text-field>
                      <!-- <div class="flex">
                        
                        <div class="w-1/2">
                          
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/2">
                          
                        </div>
                        <div class="w-1/2">
                          
                        </div>
                      </div> -->
                      <!-- <v-btn @click="printZPL()">Print</v-btn> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- <add-page
        selectedNavItem="Patient"
        :toggleForm="toggleForm"
        @form-submited="submit()"
      >
        
      </add-page> -->
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AddPage from "./AddPage.vue";
import apiClient from "../API/apiClient";
export default {
  components: { AddPage },
  name: "add-new-order",
  props: ["selectedNavItem", "toggleForm"],
  created() {},
  data() {
    return {
      isLoading: false,
      prefix: "",
      startIndex: 1,
      quantity: 1,
      copies: 1,
    };
  },
  watch: {
    searchPanel(val) {
      if (this.searchedPanel != val) {
        this.$store.commit("setPanels", []);
        console.log(val);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/panels?SearchTerm=" +
              val,
            null
          )
          .then((response) => {
            this.$store.commit("setPanels", response);
            this.searchedPanel = val;
          })
          .catch((err) => {});
      }
    },
    PhoneNumber(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PatientMRN = "";
      }
    },
    PatientMRN(val) {
      console.log("PhoneNumber", val);
      if (val || val.length != 0) {
        this.PhoneNumber = "";
      }
    },
  },
  methods: {
    submit() {
      console.log("Printing...");
      // window.postMessage({
      //   type: "zpl",
      //   cmd: "print",
      //   printer: "http://10.21.3.241",
      //   raw: "^XA^FO50,50^B8N,100,Y,N^FD1234567^FS^XZ",
      // });

      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;

      var endIndex = this.startIndex + this.quantity;
      var ipAddress = localStorage.getItem("barcodePrinterIP");

      for (let i = 0; i < this.quantity; i++) {
        var sampleId = parseInt(this.startIndex) + i;
        var date = formattedDate;
        // for (let j = 0; j < this.copies; j++) {
        //   console.log(this.prefix + "" + sampleId, date);

        // }
        window.postMessage(
          {
            type: "zebra_print_label",
            zpl: `^XA
          ^FX Second section with recipient address and permit information.
          ^CFA,20
          ^FO10,20^AQN^FD${this.prefix + "" + sampleId}^FS
          ^FO265,20^AQN^FD${date}^FS
          ^FX Third section with bar code.
          ^BY2,2,70
          ^FO10,45^BC,N,N,N,N^FD${sampleId}^FS

          ^PQ${this.copies}
          ^XZ`,
            url: "http://" + ipAddress + "/pstprnt",
          },
          "*"
        );
      }

      //       window.postMessage(
      //         {
      //           type: "zebra_print_label",
      //           zpl: `^XA
      // ^FX Second section with recipient address and permit information.
      // ^CFA,20
      // ^FO10,20^FD${sampleId}^FS
      // ^FO265,20^FD${date}^FS

      // ^FX Third section with bar code.
      // ^BY2,2,70
      // ^FO10,45^BC,N,N,N,N^FD${sampleId}^FS

      // ^CFA,20
      // ^FO10,125^FDMRN:${mrn}^FS
      // ^FO145,125^FD${Name}^FS

      // ^XZ`,
      //           url: "http://"+ipAddress+"/pstprnt",
      //         },
      //         "*"
      //       );
    },
  },
  computed: {},
};
</script>
