<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <div>
        <div>Email</div>
        <div>
          {{ account.userName }}
        </div>
      </div>
      <v-text-field
        dense
        autocomplete="false"
        label="Admin Password"
        :rules="NoEmptyRule"
        filled
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show1 = !show1"
        :type="show1 ? 'text' : 'password'"
        required
        v-model="requestData.adminPassword"
      ></v-text-field>
      <v-text-field
        dense
        autocomplete="false"
        label="Old Password"
        :rules="NoEmptyRule"
        filled
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show1 = !show1"
        :type="show1 ? 'text' : 'password'"
        required
        v-model="requestData.oldPassword"
      ></v-text-field>

      <v-text-field
        dense
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show2 = !show2"
        :type="show2 ? 'text' : 'password'"
        label="New Password"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.newPassword"
      ></v-text-field>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-account",
  props: ["selectedNavItem", "toggleForm", "account"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      show1: false,
      show2: true,
      departments: [],
      requestData: {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        ConfirmPassword: "",
        PhoneNumber: "",
        AllRoles: [
          "Administrator",
          "Bench Scientist",
          "Clinician",
          "Coordinator",
          "Institution Administrator",
          "Principal Investigator",
          "Researcher",
          "Site Administrator",
          "Super Administrator",
          "Technician",
          "Tissue Banker",
        ],
        Roles: [],
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log("this.requestData", this.requestData);
        // return;
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call("POST", "users", this.requestData)
          .then((response) => {
            console.log(response);
            console.log(
              "created: ",
              this.requestData.Email,
              this.requestData.password
            );
            this.toggleForm();
            this.$store.dispatch("getAccounts");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "selectedAccount"]),
  },
};
</script>
