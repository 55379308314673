<template>
  <v-app>
    <v-main>
      <div
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <form novalidate="novalidate" style="width: 100%; height: 100%">
          <div
            class="
              FullscreenHeader
              Box-root
              Box-background--white
              Box-divider--light-bottom-1
              Padding-right--20
              Padding-left--8
              Padding-vertical--20
              Flex-flex
              Flex-justifyContent--spaceBetween
            "
            style="flex: 0 0 auto"
          >
            <div class="Box-root Flex-flex Flex-alignItems--center">
              <div
                class="
                  PressableCore
                  PressableCore--cursor--pointer
                  PressableCore--height--medium
                  PressableCore--radius--all
                  PressableCore--width
                  PressableCore--width--auto
                  PressableButton
                  Box-root
                  Flex-inlineFlex
                "
              >
                <div class="PressableCore-base Box-root">
                  <v-btn icon color="black" @click="toggleForm()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div
                  class="
                    PressableCore-overlay PressableCore-overlay--extendBy1
                    Box-root
                    Box-background--white
                  "
                ></div>
              </div>
              <div
                class="
                  FullscreenHeader-separator
                  Box-root
                  Box-background--gray200
                  Margin-right--20
                  Margin-left--8
                "
              ></div>
              <span
                class="
                  Text-color--default
                  Text-fontSize--14
                  Text-fontWeight--medium
                  Text-lineHeight--20
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Create a Participant</span></span
              >
            </div>
            <div class="Box-root Flex-flex">
              <div class="Box-root" style="pointer-events: none">
                <div
                  class="
                    Box-root
                    Flex-flex
                    Flex-alignItems--center
                    Flex-direction--row
                    Flex-justifyContent--flexStart
                    Flex-wrap--nowrap
                  "
                  style="margin-left: -20px; margin-top: -20px"
                >
                  <div
                    class="
                      Box-root Box-hideIfEmpty
                      Margin-top--20
                      Margin-left--20
                    "
                    style="pointer-events: auto"
                  >
                    <div class="Box-root Margin-top--4">
                      <div class="Box-root">
                        <button
                          data-db-analytics-name="dashboard_create_payment_feedback_link"
                          type="button"
                          class="UnstyledLink ButtonLink Flex-flex"
                        >
                          <div
                            class="
                              Box-root
                              Flex-flex
                              Flex-alignItems--baseline
                              Flex-direction--row
                            "
                            style="position: relative"
                          >
                            <div
                              class="TextAligner Box-root"
                              style="
                                line-height: 20px;
                                font-size: 14px;
                                flex: 0 0 auto;
                              "
                            ></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      Box-root Box-hideIfEmpty
                      Margin-top--20
                      Margin-left--20
                    "
                    style="pointer-events: auto"
                  >
                    <div
                      class="ButtonGroup Box-root"
                      style="pointer-events: none"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--center
                          Flex-direction--row
                          Flex-justifyContent--flexStart
                          Flex-wrap--nowrap
                        "
                        style="margin-left: -8px; margin-top: -8px"
                      >
                        <div
                          class="
                            Box-root Box-hideIfEmpty
                            Margin-top--8
                            Margin-left--8
                          "
                          style="pointer-events: auto"
                        >
                          <div
                            class="
                              PressableCore
                              PressableCore--cursor--pointer
                              PressableCore--height--medium
                              PressableCore--radius--all
                              PressableCore--width
                              PressableCore--width--auto
                              PressableButton
                              Button Button--color--white
                              Box-root
                              Flex-inlineFlex
                            "
                            style="background-color: rgb(255, 255, 255)"
                          >
                            <div class="PressableCore-base Box-root">
                              <v-btn small elevation="0"
                                >Submit and create another</v-btn
                              >
                            </div>
                            <div
                              class="
                                PressableCore-overlay
                                PressableCore-overlay--extendBy1
                                Box-root
                                Box-background--white
                              "
                            ></div>
                          </div>
                        </div>
                        <div
                          class="
                            Box-root Box-hideIfEmpty
                            Margin-top--8
                            Margin-left--8
                          "
                          style="pointer-events: auto"
                        >
                          <div
                            class="
                              PressableCore
                              PressableCore--cursor--pointer
                              PressableCore--height--medium
                              PressableCore--radius--all
                              PressableCore--width
                              PressableCore--width--auto
                              PressableButton
                              Button Button--color--blue
                              Box-root
                              Flex-inlineFlex
                            "
                            style="background-color: rgb(84, 105, 212)"
                          >
                            <div
                              class="PressableCore-base Box-root"
                              @click="submit()"
                            >
                              <v-btn color="primary" dark small elevation="0"
                                >Submit</v-btn
                              >
                            </div>
                            <div
                              class="
                                PressableCore-overlay
                                PressableCore-overlay--extendBy1
                                Box-root
                                Box-background--white
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              FullscreenContent FullscreenContent--scroll
              Box-root
              Box-background--white
            "
            style="flex: 1 1 100%; height: calc(100% - 76px)"
          >
            <div class="FormLayout Box-root Box-background--white">
              <div class="Box-root">
                <div
                  class="Box-root"
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    width: 650px;
                    margin-top: 20px;
                    min-height: 500px;
                  "
                >
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        label="MRN"
                        filled
                        required
                        v-model="requestData.PatientMRN"
                      ></v-text-field>
                      <v-text-field
                        dense
                        label="Social Security Number"
                        filled
                        class="ml-3"
                        required
                        v-model="requestData.SocialSecurityNumber"
                      ></v-text-field>
                      <!-- <v-text-field
                        dense
                        label="Phone Number"
                        ref="myfield"
                        filled
                        :rules="phoneNumberRules"
                        required
                        v-model="requestData.PhoneNumber"
                      ></v-text-field> -->
                    </div>

                    <div class="d-flex">
                      <v-select
                        :items="patientTypes"
                        item-text="title"
                        filled
                        style="width: 200px"
                        class="mr-3"
                        return-object
                        v-model="requestData.SelectedPatientType"
                        :rules="[(v) => !!v || '*Required']"
                        label="Participant Type"
                      ></v-select>
                      <v-text-field
                        label="External Subject ID"
                        filled
                        class="mr-3"
                        required
                        v-model="requestData.ExternalSubjectID"
                      ></v-text-field>
                    </div>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        label="First Name"
                        :rules="NoEmptyRule"
                        filled
                        class="mr-3"
                        required
                        v-model="requestData.FirstName"
                      ></v-text-field>

                      <v-text-field
                        dense
                        label="Father Name"
                        :rules="NoEmptyRule"
                        filled
                        class="mr-3"
                        required
                        v-model="requestData.FatherName"
                      ></v-text-field>
                      <v-text-field
                        dense
                        label="Grand Father Name"
                        :rules="NoEmptyRule"
                        filled
                        required
                        v-model="requestData.GrandFatherName"
                      ></v-text-field>
                    </div>

                    <v-chip
                      class="m-2 ml-0"
                      close
                      v-for="(
                        phoneNumber, index
                      ) in requestData.SelectedPhoneNumbers"
                      :key="index"
                      close-icon="mdi-close"
                      filter
                      @click:close="removePhoneNumber(phoneNumber)"
                      outlined
                      >{{ phoneNumber.phoneNumber }}</v-chip
                    >
                    <v-btn
                      depressed
                      text
                      class="mb-2"
                      @click="showPhoneNumberAdder()"
                      ><v-icon>mdi-plus</v-icon> Add Phone Number</v-btn
                    >
                    <div v-if="requestData.isPhoneNumberAdderVisible">
                      <div class="d-flex">
                        <v-select
                          :items="phoneNumberTypeCategories"
                          item-text="title"
                          filled
                          @change="phoneNumberTypeCategorySelected"
                          class="mr-3"
                          style="max-width: 200px"
                          return-object
                          v-model="
                            newPhoneNumber.selectedPhoneNumberTypeCategory
                          "
                          label="Phone Number Type Category"
                        ></v-select>
                        <v-select
                          :items="phoneNumberTypes"
                          item-text="title"
                          filled
                          style="max-width: 170px"
                          return-object
                          v-model="newPhoneNumber.selectedPhoneNumberType"
                          label="Phone Number Type"
                        ></v-select>
                        <v-text-field
                          outlined
                          class="ml-3"
                          v-model="newPhoneNumber.phoneNumber"
                          label="Phone Number"
                        ></v-text-field>
                      </div>
                      <v-btn
                        depressed
                        color="secondary"
                        small
                        class="mt-0 mb-2"
                        @click="addPhoneNumber()"
                        >ADD</v-btn
                      >
                    </div>
                    <div class="d-flex">
                      <v-select
                        :items="genders"
                        item-text="title"
                        filled
                        style="width: 200px"
                        class="mr-3"
                        return-object
                        v-model="requestData.SelectedGender"
                        :rules="[(v) => !!v || '*Required']"
                        label="Gender"
                      ></v-select>
                      <v-select
                        :items="maritalStatuses"
                        item-text="title"
                        filled
                        return-object
                        v-model="requestData.SelectedMaritalStatus"
                        :rules="[(v) => !!v || '*Required']"
                        label="Marital Status"
                      ></v-select>
                    </div>
                    <div class="d-flex align-end">
                      <v-select
                        :items="vitalStatuses"
                        item-text="title"
                        filled
                        class="mr-3"
                        return-object
                        v-model="requestData.SelectedVitalStatus"
                        :rules="[(v) => !!v || '*Required']"
                        label="Vital Status"
                      ></v-select>
                      <v-menu
                        v-if="
                          requestData.SelectedVitalStatus &&
                          requestData.SelectedVitalStatus.title.toLowerCase() ==
                            'dead'
                        "
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            class="mr-3"
                            v-model="requestData.DeathDate"
                            label="Death Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="NoEmptyRule"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="requestData.DeathDate"
                          @input="menu4 = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <!-- <v-autocomplete
                  label="Races"
                  :items="specimenTypes"
                  :search-input.sync="searchRace"
                  return-object
                  filled
                  v-model="requestData.SelectedSpecimenType"
                  item-text="title"
                  :rules="[(v) => !!v || '*Required']"
                  clearable
                ></v-autocomplete> -->
                    <v-chip
                      close
                      close-icon="mdi-close"
                      v-for="race in requestData.SelectedRaces"
                      :key="race.id"
                      label
                      class="mr-2"
                      outlined
                      @click:close="removeRace(race)"
                      >{{ race.title }}</v-chip
                    >
                    <v-autocomplete
                      label="Races"
                      :items="races"
                      :search-input.sync="searchRace"
                      return-object
                      v-model="requestData.SelectedRace"
                      item-text="title"
                      :rules="[
                        requestData.SelectedRaces.length > 0 || '*Required',
                      ]"
                      clearable
                      @change="raceSelected()"
                    ></v-autocomplete>
                    <!-- <v-autocomplete
                      label="Ethnicities"
                      :items="ethnicities"
                      chips
                      :search-input.sync="searchEthnicity"
                      return-object
                      v-model="requestData.SelectedEthnicities"
                      multiple
                      item-text="title"
                      :rules="[(v) => !!v || '*Required']"
                      clearable
                      deletable-chips
                    ></v-autocomplete> -->
                    <v-chip
                      close
                      close-icon="mdi-close"
                      v-for="race in requestData.SelectedEthnicities"
                      :key="race.id"
                      label
                      class="mr-2"
                      outlined
                      @click:close="removeEthnicity(race)"
                      >{{ race.title }}</v-chip
                    >
                    <v-autocomplete
                      label="Ethnicities"
                      :items="ethnicities"
                      :search-input.sync="searchEthnicity"
                      return-object
                      v-model="requestData.SelectedEthnicity"
                      item-text="title"
                      :rules="[
                        requestData.SelectedEthnicities.length > 0 ||
                          '*Required',
                      ]"
                      clearable
                      @change="ethnicitySelected()"
                    ></v-autocomplete>
                    <div class="d-flex">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            class="mr-3"
                            v-model="requestData.BirthDate"
                            label="Birth Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="NoEmptyRule"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="requestData.BirthDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="requestData.BirthTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            v-model="requestData.BirthTime"
                            label="Birth Time"
                            :rules="NoEmptyRule"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="requestData.BirthTime"
                          full-width
                          @click:minute="$refs.menu.save(requestData.BirthTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <!-- <v-select
                      :items="institutionSites"
                      item-text="title"
                      filled
                      clearable
                      return-object
                      v-model="requestData.SelectedInstitutionSite"
                      :rules="[(v) => !!v || '*Required']"
                      label="Institution Site"
                    ></v-select> -->
                    <div>
                      <div
                        class="d-flex"
                        v-for="(
                          institutionSiteMRN, index
                        ) in institutionSiteMRNs"
                        :key="index"
                      >
                        <v-select
                          :items="institutionSites"
                          item-text="title"
                          filled
                          style="width: 60%"
                          clearable
                          return-object
                          v-model="institutionSiteMRN.InstitutionSite"
                          :rules="[
                            (v) =>
                              isInstitutionSiteValid(institutionSiteMRN) ||
                              '*Required',
                          ]"
                          label="Institution Site"
                        ></v-select>
                        <v-text-field
                          dense
                          class="ml-3"
                          label="MRN"
                          filled
                          :rules="[
                            (v) =>
                              isInstitutionSiteMRNValid(institutionSiteMRN) ||
                              '*Required',
                          ]"
                          required
                          v-model="institutionSiteMRN.MRN"
                        ></v-text-field>
                        <v-btn
                          icon
                          color="red"
                          @click="removeInstitutionSiteMRN(institutionSiteMRN)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <div class="d-flex justify-center">
                        <v-btn @click="addInstitutionSiteMRN()" depressed small
                          ><v-icon>mdi-plus</v-icon> Add Institution Site
                          MRN</v-btn
                        >
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- <add-page
      selectedNavItem="Patient"
      :toggleForm="toggleForm"
      @form-submited="submit()"
    >
      
    </add-page> -->
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
import { v4 as uuid_v4 } from "uuid";
export default {
  components: { AddPage },
  name: "add-patient",
  props: ["selectedNavItem", "toggleForm"],
  mounted() {
    // const inputElement = this.$refs.myfield.$refs.input;
    // inputElement.min = 0;
    // inputElement.max = 2;
  },
  created() {
    console.log("add-patient created!");
    this.getInstitutionSites();
    this.getPatientTypes();
    this.getGenders();
    this.getMaritalStatuses();
    this.getVitalStatuses();
    this.getPhoneNumberTypeCategories();
    // this.getRaces();
    // this.getEthnicities();
    this.institutionSiteMRNs.push({
      MRN: "",
      InstitutionSite: null,
    });

    this.patientTypes.forEach((patientType) => {
      // console.log("patientType::", patientType);
      if (patientType.isDefault) {
        this.requestData.PatientType = patientType;
      }
      // this.SelectedPatientType=patientType;
    });
  },
  data() {
    return {
      valid: false,
      menu1: false,
      menu2: false,
      menu4: false,
      min: 0,
      max: 2,
      newPhoneNumber: {
        phoneNumber: "",
        selectedPhoneNumberTypeCategory: null,
        selectedPhoneNumberType: null,
      },
      institutionSiteMRNs: [],
      searchRace: null,
      searchedRace: null,
      searchEthnicity: null,
      searchedEthnicity: null,
      requestData: {
        DeathDate: null,
        PatientMRN: "",
        FirstName: "",
        FatherName: "",
        ExternalSubjectID: "",
        GrandFatherName: "",
        SocialSecurityNumber: "",
        DeathDate: null,
        PhoneNumber: "",
        BirthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        BirthTime: this.getTodaysTime(),
        SelectedPatientType: null,
        PatientTypeId: null,
        SelectedGender: null,
        GenderId: null,
        SelectedMaritalStatus: null,
        MaritalStatusId: null,
        SelectedInstitutionSite: null,
        InstitutionSiteId: null,
        SelectedVitalStatus: null,
        VitalStatusId: null,
        SelectedRaces: [],
        SelectedRace: [],
        RaceId: null,
        SelectedEthnicities: [],
        selectedEthnicity: null,
        EthnicityId: null,
        SelectedPhoneNumbers: [],
        isPhoneNumberAdderVisible: false,
        // phone number validation
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  watch: {
    searchRace(val) {
      if (this.searchedRace != val) {
        this.$store.commit("setRaces", []);
        console.log(val);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/races?SearchTerm=" +
              val,
            null
          )
          .then((response) => {
            this.$store.commit("setRaces", response);
            this.searchedRace = val;
          })
          .catch((err) => {});
      }
    },
    searchEthnicity(val) {
      if (this.searchedEthnicity != val) {
        this.$store.commit("setEthnicities", []);
        console.log(val);
        apiClient
          .call(
            "GET",
            "institutions/" +
              this.currentInstitution.id +
              "/ethnicities?SearchTerm=" +
              val,
            null
          )
          .then((response) => {
            this.$store.commit("setEthnicities", response);
            this.searchedEthnicity = val;
          })
          .catch((err) => {});
      }
    },
  },
  methods: {
    ...mapActions([
      "getPatientTypes",
      "getGenders",
      "getMaritalStatuses",
      "getVitalStatuses",
      "getRaces",
      "getEthnicities",
      "getInstitutionSites",
      "getPhoneNumberTypeCategories",
      "getPhoneNumberTypesByPhoneNumberTypeCategory",
    ]),
    showPhoneNumberAdder() {
      this.requestData.isPhoneNumberAdderVisible = true;
    },
    hidePhoneNumberAdder() {
      this.requestData.isPhoneNumberAdderVisible = false;
    },
    addPhoneNumber() {
      console.log("::newPhoneNumber::", this.newPhoneNumber);
      this.requestData.SelectedPhoneNumbers.push(this.newPhoneNumber);
      this.hidePhoneNumberAdder();
      this.newPhoneNumber = {
        id: uuid_v4(),
        phoneNumber: "",
        selectedPhoneNumberTypeCategory: null,
        selectedPhoneNumberType: null,
      };
    },
    phoneNumberTypeCategorySelected(item) {
      if (item) {
        this.getPhoneNumberTypesByPhoneNumberTypeCategory(item);
      }
    },
    //remove phone number from list by phone number id
    removePhoneNumber(item) {
      this.requestData.SelectedPhoneNumbers =
        this.requestData.SelectedPhoneNumbers.filter((phoneNumber) => {
          return phoneNumber.id != item.id;
        });
    },

    isNewInstitutionSiteMRN(InstitutionSiteMRN) {
      var result = true;
      var isMRNEmpty = false;
      var isInstitutionSite = InstitutionSiteMRN.InstitutionSite == null;
      if (!InstitutionSiteMRN.MRN || InstitutionSiteMRN.MRN.length === 0) {
        isMRNEmpty = true;
      }
      console.log("validation");
      if (!(isMRNEmpty && isInstitutionSite)) {
        result = false;
      }
      return result;
    },
    isInstitutionSiteValid(InstitutionSiteMRN) {
      var result = true;
      var isMRNEmpty = true;
      if (InstitutionSiteMRN.MRN || InstitutionSiteMRN.MRN.length > 0) {
        isMRNEmpty = false;
      }
      var isInstitutionSiteEmpty = InstitutionSiteMRN.InstitutionSite == null;
      if (!isMRNEmpty && !isInstitutionSiteEmpty) {
        result = true;
      } else if (!isMRNEmpty && isInstitutionSiteEmpty) {
        result = false;
      }

      return result;
    },
    isInstitutionSiteMRNValid(InstitutionSiteMRN) {
      var result = true;
      var isMRNEmpty = true;
      if (InstitutionSiteMRN.MRN || InstitutionSiteMRN.MRN.length > 0) {
        isMRNEmpty = false;
      }
      var isInstitutionSiteEmpty = InstitutionSiteMRN.InstitutionSite == null;
      if (!isMRNEmpty && !isInstitutionSiteEmpty) {
        result = true;
      } else if (isMRNEmpty && !isInstitutionSiteEmpty) {
        result = false;
      }
      // console.log(
      //   "isMRNEmpty, isInstitutionSiteEmpty",
      //   isMRNEmpty,
      //   isInstitutionSiteEmpty
      // );
      // console.log("result:", result);
      return result;
    },
    getTodaysTime() {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes();
      return time;
    },
    submit() {
      if (this.$refs.form.validate()) {
        // console.log(
        //   this.requestData.SelectedPatientType,
        //   this.requestData.SelectedGender,
        //   this.requestData.SelectedMaritalStatus,
        //   this.requestData.SelectedVitalStatus
        // );
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.PatientTypeId =
          this.requestData.SelectedPatientType.id;
        this.requestData.GenderId = this.requestData.SelectedGender.id;
        // this.requestData.RaceId = this.requestData.SelectedRace.id;
        // this.requestData.EthnicityId = this.requestData.SelectedEthnicity.id;

        this.requestData.MaritalStatusId =
          this.requestData.SelectedMaritalStatus.id;
        this.requestData.VitalStatusId =
          this.requestData.SelectedVitalStatus.id;
        let that = this;
        apiClient
          .call(
            "POST",
            "institutions/" + this.currentInstitution.id + "/patients",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            // this.toggleForm();
            // this.$store.dispatch("getDepartments");
            var newPatientMRN = {
              institutionId: this.currentInstitution.id,
              patientId: response.id,
              mrn: this.requestData.PatientMRN,
            };
            apiClient
              .call(
                "POST",
                "institutions/" +
                  this.currentInstitution.id +
                  "/patients/" +
                  response.id +
                  "/patientMRNs",
                newPatientMRN
              )
              .then((response) => {
                console.log(response);
                // this.toggleForm();
                // this.$store.dispatch("getDepartments");
              })
              .catch((err) => console.log(err));
            this.institutionSiteMRNs.forEach((institutionSiteMRN) => {
              if (
                !institutionSiteMRN.InstitutionSite &&
                institutionSiteMRN.MRN
              ) {
                var newInstitutionSiteMRN = {
                  institutionId: this.currentInstitution.id,
                  patientId: response.id,
                  mrn: institutionSiteMRN.MRN,
                  InstitutionSiteID: institutionSiteMRN.InstitutionSite.id,
                };
                apiClient
                  .call(
                    "POST",
                    "institutions/" +
                      this.currentInstitution.id +
                      "/patients/" +
                      response.id +
                      "/patientMRNs",
                    newInstitutionSiteMRN
                  )
                  .then((institutionSiteMRNResponse) => {
                    console.log(institutionSiteMRNResponse);
                    // this.toggleForm();
                    // this.$store.dispatch("getDepartments");
                  })
                  .catch((err) => console.log(err));
              }
            });
            this.requestData.SelectedRaces.forEach((race) => {
              var newPatientRace = {
                institutionSiteId: null,
                patientId: response.id,
                raceId: race.id,
              };
              apiClient
                .call(
                  "POST",
                  "institutions/" +
                    this.currentInstitution.id +
                    "/patients/" +
                    response.id +
                    "/patientRaces",
                  newPatientRace
                )
                .then((response) => {
                  console.log(response);
                  // this.toggleForm();
                  // this.$store.dispatch("getDepartments");
                })
                .catch((err) => console.log(err));
            });

            this.requestData.SelectedPhoneNumbers.forEach((phoneNumber) => {
              var newPatientPhoneNumber = {
                institutionSiteId: null,
                patientId: response.id,
                phoneNumberTypeId: phoneNumber.selectedPhoneNumberType.id,
                phoneNumber: phoneNumber.phoneNumber,
              };
              apiClient
                .call(
                  "POST",
                  "institutions/" +
                    this.currentInstitution.id +
                    "/patients/" +
                    response.id +
                    "/patientPhoneNumbers",
                  newPatientPhoneNumber
                )
                .then((response) => {
                  console.log(response);
                  // this.toggleForm();
                  // this.$store.dispatch("getDepartments");
                })
                .catch((err) => console.log(err));
            });

            this.requestData.SelectedEthnicities.forEach((ethnicity) => {
              var newPatientEthnicity = {
                institutionSiteId: null,
                patientId: response.id,
                ethnicityId: ethnicity.id,
              };

              apiClient
                .call(
                  "POST",
                  "institutions/" +
                    this.currentInstitution.id +
                    "/patients/" +
                    response.id +
                    "/patientEthnicities",
                  newPatientEthnicity
                )
                .then((response) => {
                  console.log(response);
                  // this.toggleForm();
                  // this.$store.dispatch("getDepartments");
                })
                .catch((err) => console.log(err));
            });
            this.toggleForm();
            this.$store.dispatch("getPatients");
          })
          .catch((err) => console.log(err));
      }
    },
    validate() {
      this.$refs.form.validate();
      console.log("this.$refs.form.validate(): " + this.$refs.form.validate());
      console.log("Valid: " + this.valid);
      if (this.valid) {
        console.log("seems about right");
        // console.log(this.requestData);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addInstitutionSiteMRN() {
      this.institutionSiteMRNs.push({
        institutionSite: null,
        MRN: null,
        id: uuid_v4(),
      });
    },
    removeInstitutionSiteMRN(institutionSiteMRN) {
      let filteredInstitutionSiteMRN = this.institutionSiteMRNs.filter(
        (id) => id == institutionSiteMRN.id
      );
      // var tempInstitutionSiteMRN =
      //   this.institutionSites.indexOf(institutionSiteMRN);
      this.institutionSiteMRNs.splice(filteredInstitutionSiteMRN[0], 1);
    },
    raceSelected() {
      if (this.requestData.SelectedRace) {
        console.log(
          "this.requestData.SelectedRace",
          this.requestData.SelectedRace
        );
        var doesExist = false;
        this.requestData.SelectedRaces.forEach((rc) => {
          if (rc.id == this.requestData.SelectedRace.id) {
            doesExist = true;
          }
        });

        if (!doesExist) {
          this.requestData.SelectedRaces.push(this.requestData.SelectedRace);
        }

        // this.requestData.SelectedRace = null;
        this.$set(this.requestData, "SelectedRace", null);
        console.log(
          "this.requestData.SelectedRaces",
          this.requestData.SelectedRaces
        );
      }
    },
    removeRace(item) {
      this.requestData.SelectedRaces.splice(
        this.requestData.SelectedRaces.indexOf(item),
        1
      );
      this.requestData.SelectedRaces = [...this.requestData.SelectedRaces];
    },
    ethnicitySelected() {
      if (this.requestData.SelectedEthnicity) {
        console.log(
          "this.requestData.SelectedEthnicity",
          this.requestData.SelectedEthnicity
        );
        var doesExist = false;
        this.requestData.SelectedEthnicities.forEach((rc) => {
          if (rc.id == this.requestData.SelectedEthnicity.id) {
            doesExist = true;
          }
        });

        if (!doesExist) {
          this.requestData.SelectedEthnicities.push(
            this.requestData.SelectedEthnicity
          );
        }

        // this.requestData.SelectedEthnicity = null;
        this.$set(this.requestData, "SelectedEthnicity", null);
        console.log(
          "this.requestData.SelectedEthnicities",
          this.requestData.SelectedEthnicities
        );
      }
    },
    removeEthnicity(item) {
      this.requestData.SelectedEthnicities.splice(
        this.requestData.SelectedEthnicities.indexOf(item),
        1
      );
      this.requestData.SelectedEthnicities = [
        ...this.requestData.SelectedEthnicities,
      ];
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "patientTypes",
      "genders",
      "maritalStatuses",
      "vitalStatuses",
      "races",
      "ethnicities",
      "institutionSites",
      "phoneNumberTypeCategories",
      "phoneNumberTypes",
    ]),
    phoneNumberRules() {
      const rules = [];
      var maxLength = 10;
      var allowSpaces = false;

      if (maxLength) {
        const rule = (v) =>
          (v || "").length <= maxLength ||
          `A maximum of ${maxLength} characters is allowed`;

        rules.push(rule);
      }

      if (!allowSpaces) {
        const rule = (v) =>
          (v || "").indexOf(" ") < 0 || "No spaces are allowed";

        rules.push(rule);
      }

      if (this.requestData.PhoneNumber) {
        const rule = (v) => v.startsWith("09") || "Invalid";
        rules.push(rule);
      }

      if (this.requestData.PhoneNumber) {
        const rule = (v) => !isNaN(v) || "Invalid character";
        rules.push(rule);
      }

      return rules;
    },
  },
};
</script>