<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>
      <v-text-field
        dense
        label="ICD Code"
        filled
        v-model="requestData.ICDCode"
      ></v-text-field>
      <v-checkbox
        v-model="requestData.HasUnitOfMeasurement"
        label="Has Unit Of Measurement"
      ></v-checkbox>
      <v-select
        v-if="requestData.HasUnitOfMeasurement"
        :items="unitOfMeasurementTypes"
        item-text="title"
        filled
        return-object
        @change="getUnitOfMeasurements()"
        v-model="requestData.SelectedUnitOfMeasurementType"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement Type"
      ></v-select>
      <v-select
        v-if="requestData.HasUnitOfMeasurement"
        :items="unitOfMeasurements"
        item-text="unitName"
        filled
        return-object
        v-model="requestData.SelectedUnitOfMeasurement"
        :rules="[(v) => !!v || '*Required']"
        label="Unit Of Measurement"
      ></v-select>
      <v-checkbox v-model="requestData.HasRange" label="Has Range"></v-checkbox>
      <v-checkbox
        v-model="requestData.HasAcceptedValue"
        label="Has Accepted Value"
      ></v-checkbox>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    console.log("getting unit of measurments");
    this.getUnitOfMeasurementTypes();
  },
  data() {
    return {
      valid: false,
      unitOfMeasurements: [],
      requestData: {
        Title: "",
        Abbreviation: "",
        Description: "",
        HasRange: false,
        HasUnitOfMeasurement: false,
        HasAcceptedValue: false,
        SelectedInstitution: null,
        InstitutionId: null,
        SelectedUnitOfMeasurementType: null,
        UnitOfMeasurementId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getUnitOfMeasurementTypes"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        if (this.requestData.HasUnitOfMeasurement) {
          this.requestData.UnitOfMeasurementId =
            this.requestData.SelectedUnitOfMeasurement.id;
        }

        apiClient
          .call(
            "POST",
            "institutions/" + this.currentInstitution.id + "/tests",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getTests");
          })
          .catch((err) => console.log(err));
      }
    },
    getUnitOfMeasurements() {
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/unitOfMeasurementTypes/" +
            this.requestData.SelectedUnitOfMeasurementType.id +
            "/unitOfMeasurements",
          null
        )
        .then((response) => {
          this.unitOfMeasurements = response;
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "unitOfMeasurementTypes"]),
  },
};
</script>