<template>
  <div>
    <div>Barcode Printer IP Address</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div>
        <v-text-field
          dense
          class="mt-3"
          label="IP Address"
          :rules="NoEmptyRule"
          outlined
          required
          v-model="ipAddress"
        ></v-text-field>
      </div>
      <v-btn @click="submit()">Update</v-btn>
    </v-form>
    <div class="my-3">
      <a
        class="mt-2"
        target="_blank"
        href="https://chrome.google.com/webstore/detail/zebra-printing/ndikjdigobmbieacjcgomahigeiobhbo"
        >Install Printer extention</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "printer",
  created() {
    this.ipAddress = localStorage.getItem("barcodePrinterIP");
  },
  data() {
    return {
      ipAddress: "",
      valid: false,
      NoEmptyRule: [
        (v) => !!v || "*required",
        (v) =>
          v.match(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
          ) || "Invalid IP address",
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("barcodePrinterIP", this.ipAddress);
        this.$store.commit("setShowErrorMessage", true);
        this.$store.commit("setErrorMessage", "IP Address have been updated");
        // console.log(localStorage.getItem("barcodePrinterIP"));
      }
    },
  },
};
</script>
>
