<template>
  <div class="db-ChromeMainContent">
    <left-nav :leftNavItems="leftNavItems" title="Setting" />
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div class="Box-root">
        <div
          class="Card-root Card--radius--all Section Box-root Box-background--white"
          style="overflow: visible"
        >
          <div class="ListView ListView--withoutFooter">
            <div
              class="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween"
            >
              <span
                class="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                ><span>{{ selectedNavItem }}</span></span
              >
              <div class="Box-root" style="pointer-events: none">
                <div
                  class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                  style="margin-left: -8px; margin-top: -8px"
                >
                  <div
                    class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                    style="pointer-events: auto"
                  >
                    <div
                      class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                      style="
                        background-color: rgb(84, 105, 212);
                        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
                      "
                    >
                      <div class="PressableCore-base Box-root"></div>
                      <div
                        class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table class="tablex">
              <tbody class="tablex-body"></tbody>
            </table>
            <div class="db-DashboardEmptyState Box-root Flex-flex">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftNav from "../../components/LeftNav.vue";
import { uuid, v4 } from "uuidv4";
export default {
  components: { LeftNav },
  name: "configuration",
  created() {
    this.$router.push({ name: this.leftNavItems[0].pathName }).catch(() => {});
  },
  data() {
    return {
      leftNavItems: [
        {
          id: uuid(),
          title: "Printer",
          pathName: "Printer",
          showChildren: false,
          selected: true,
          children: [],
        },
        // {
        //   id: uuid(),
        //   title: "Inistitution",
        //   pathName: "Inistitution",
        //   showChildren: false,
        //   selected: false,
        //   children: [],
        // },
        // {
        //   id: uuid(),
        //   title: "Accounts",
        //   pathName: "Accounts",
        //   showChildren: false,
        //   selected: false,
        //   children: [],
        // },
      ],
    };
  },
  computed: {
    selectedNavItem() {
      var title = "none";
      this.leftNavItems.forEach((leftNavItem) => {
        if (leftNavItem.selected) {
          title = leftNavItem.title;
        }
        leftNavItem.children.forEach((leftNav) => {
          if (leftNav.selected) {
            title = leftNav.title;
          }
        });
      });
      return title;
    },
  },
};
</script>
