<template>
  <form novalidate="novalidate" style="width: 100%; height: 100%">
    <div
      class="
        FullscreenHeader
        Box-root
        Box-background--white
        Box-divider--light-bottom-1
        Padding-right--20
        Padding-left--8
        Padding-vertical--20
        Flex-flex
        Flex-justifyContent--spaceBetween
      "
      style="flex: 0 0 auto"
    >
      <div class="Box-root Flex-flex Flex-alignItems--center">
        <div
          class="
            PressableCore
            PressableCore--cursor--pointer
            PressableCore--height--medium
            PressableCore--radius--all
            PressableCore--width
            PressableCore--width--auto
            PressableButton
            Box-root
            Flex-inlineFlex
          "
        >
          <div class="PressableCore-base Box-root">
            <v-btn icon color="black" @click="toggleForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div
            class="
              PressableCore-overlay PressableCore-overlay--extendBy1
              Box-root
              Box-background--white
            "
          ></div>
        </div>
        <div
          class="
            FullscreenHeader-separator
            Box-root
            Box-background--gray200
            Margin-right--20
            Margin-left--8
          "
        ></div>
        <span
          class="
            Text-color--default
            Text-fontSize--14
            Text-fontWeight--medium
            Text-lineHeight--20
            Text-numericSpacing--proportional
            Text-typeface--base
            Text-wrap--wrap
            Text-display--inline
          "
          ><span>Create a {{ selectedNavItem }}</span></span
        >
      </div>
      <div class="Box-root Flex-flex">
        <div class="Box-root" style="pointer-events: none">
          <div
            class="
              Box-root
              Flex-flex
              Flex-alignItems--center
              Flex-direction--row
              Flex-justifyContent--flexStart
              Flex-wrap--nowrap
            "
            style="margin-left: -20px; margin-top: -20px"
          >
            <div
              class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
              style="pointer-events: auto"
            >
              <div class="Box-root Margin-top--4">
                <div class="Box-root">
                  <button
                    data-db-analytics-name="dashboard_create_payment_feedback_link"
                    type="button"
                    class="UnstyledLink ButtonLink Flex-flex"
                  >
                    <div
                      class="
                        Box-root
                        Flex-flex
                        Flex-alignItems--baseline
                        Flex-direction--row
                      "
                      style="position: relative"
                    >
                      <div
                        class="TextAligner Box-root"
                        style="
                          line-height: 20px;
                          font-size: 14px;
                          flex: 0 0 auto;
                        "
                      ></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
              style="pointer-events: auto"
            >
              <div class="ButtonGroup Box-root" style="pointer-events: none">
                <div
                  class="
                    Box-root
                    Flex-flex
                    Flex-alignItems--center
                    Flex-direction--row
                    Flex-justifyContent--flexStart
                    Flex-wrap--nowrap
                  "
                  style="margin-left: -8px; margin-top: -8px"
                >
                  <div
                    class="
                      Box-root Box-hideIfEmpty
                      Margin-top--8
                      Margin-left--8
                    "
                    style="pointer-events: auto"
                  >
                    <div
                      class="
                        PressableCore
                        PressableCore--cursor--pointer
                        PressableCore--height--medium
                        PressableCore--radius--all
                        PressableCore--width
                        PressableCore--width--auto
                        PressableButton
                        Button Button--color--white
                        Box-root
                        Flex-inlineFlex
                      "
                      style="background-color: rgb(255, 255, 255)"
                    >
                      <div class="PressableCore-base Box-root">
                        <v-btn small elevation="0"
                          >Submit and create another</v-btn
                        >
                      </div>
                      <div
                        class="
                          PressableCore-overlay PressableCore-overlay--extendBy1
                          Box-root
                          Box-background--white
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    class="
                      Box-root Box-hideIfEmpty
                      Margin-top--8
                      Margin-left--8
                    "
                    style="pointer-events: auto"
                  >
                    <div
                      class="
                        PressableCore
                        PressableCore--cursor--pointer
                        PressableCore--height--medium
                        PressableCore--radius--all
                        PressableCore--width
                        PressableCore--width--auto
                        PressableButton
                        Button Button--color--blue
                        Box-root
                        Flex-inlineFlex
                      "
                      style="background-color: rgb(84, 105, 212)"
                    >
                      <div
                        class="PressableCore-base Box-root"
                        @click="formSubmit()"
                      >
                        <v-btn color="primary" small elevation="0"
                          >Submit</v-btn
                        >
                      </div>
                      <div
                        class="
                          PressableCore-overlay PressableCore-overlay--extendBy1
                          Box-root
                          Box-background--white
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        FullscreenContent FullscreenContent--scroll
        Box-root
        Box-background--white
      "
      style="flex: 1 1 100%; height: calc(100% - 76px)"
    >
      <div class="FormLayout Box-root Box-background--white">
        <div class="Box-root">
          <div
            class="Box-root"
            style="
              margin-left: auto;
              margin-right: auto;
              width: 500px;
              margin-top: 20px;
              min-height: 500px;
            "
          >
            <slot> </slot>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "add-page",
  props: ["selectedNavItem", "toggleForm"],
  methods: {
    formSubmit() {
      // console.log("emitting form-submited");
      this.$emit("form-submited");
    },
  },
};
</script>