<template>
  <div>
    <div>
      <router-link
        :to="
          '/collection-protocols/' + currentCollectionProtocol.id + '/overview'
        "
        >{{ currentCollectionProtocol.title }}</router-link
      ><span style="padding: 5px">/</span
      ><router-link
        :to="
          '/collection-protocols/' +
          currentCollectionProtocol.id +
          '/participants'
        "
        >Participants</router-link
      ><span style="padding: 5px">/</span
      >{{ currentCollectionProtocolParticipant.patient.firstName }}
      {{ currentCollectionProtocolParticipant.patient.fatherName
      }}<span style="padding: 5px">/</span>collected samples
    </div>
    <v-app>
      <div class="Box-root">
        <div
          class="
            Card-root Card--radius--all
            Section
            Box-root
            Box-background--white
          "
          style="overflow: visible"
        >
          <div class="ListView ListView--withoutFooter">
            <div
              class="
                Box-root
                Padding-top--16
                Padding-bottom--8
                Flex-flex
                Flex-alignItems--center
                Flex-justifyContent--spaceBetween
              "
            >
              <span
                class="
                  Text-color--dark
                  Text-fontSize--28
                  Text-fontWeight--bold
                  Text-lineHeight--32
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>Collected Samples</span></span
              >
              <v-btn color="primary" elevation="0" small @click="toggleForm()"
                ><v-icon style="margin-right: 5px" right dark>
                  mdi-plus
                </v-icon>
                Collect Sample</v-btn
              >
            </div>
            <v-data-table
              style="margin-top: 15px"
              :headers="headers"
              :items="filteredCollectionProtocolCollectedSamples"
            ></v-data-table>
          </div>
        </div>
      </div>
      <div
        v-if="showAddForm"
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <add-collection-protocol-CollectedSample
          selectedNavItem="CollectedSample"
          :toggleForm="toggleForm"
          :hasParticipant="true"
        />
      </div>
    </v-app>
  </div>
</template>
<script>
import AddCollectionProtocolCollectedSample from "../../components/AddForms/AddCollectionProtocolCollectedSample.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { AddCollectionProtocolCollectedSample },
  name: "collection-protocol-CollectedSamples",
  created() {
    this.getCollectionProtocolCollectedSamples();
  },
  data() {
    return {
      showAddForm: false,
      headers: [
        {
          text: "Event",
          align: "start",
          value: "collectionProtocolEvent.label",
        },
        {
          text: "Sample Id",
          align: "start",
          value: "sampleID",
        },
        {
          text: "Specimen Type",
          align: "start",
          value: "specimenType.title",
        },
        {
          text: "Collected Amount",
          align: "start",
          value: "collectedAmount",
        },
        {
          text: "Remaining Amount",
          align: "start",
          value: "remainingAmount",
        },
        {
          text: "Unit of Measurment",
          align: "start",
          value: "unitOfMeasurement.unitName",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolCollectedSamples"]),
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolCollectedSamples",
      "currentCollectionProtocolParticipant",
      "currentCollectionProtocol",
    ]),
    filteredCollectionProtocolCollectedSamples() {
      var result = [];
      this.collectionProtocolCollectedSamples.forEach(
        (collectionProtocolCollectedSample) => {
          console.log(collectionProtocolCollectedSample);
          console.log(
            "currentCollectionProtocolParticipant",
            this.currentCollectionProtocolParticipant
          );
          if (
            this.currentCollectionProtocolParticipant.patientID ==
            collectionProtocolCollectedSample.patientID
          ) {
            result.push(collectionProtocolCollectedSample);
            console.log("Got him...");
          }
        }
      );
      return result;
    },
  },
};
</script>