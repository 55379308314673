<template>
  <div style="display: flex">
    <div>
      <div class="d-flex justify-center p-3">
        <v-btn @click="showAdd(false)" color="primary" small depressed>
          <v-icon dense>mdi-plus</v-icon>
          Create Container</v-btn
        >
      </div>
      <div
        class="d-flex align-center justify-center"
        v-if="isLoading"
        style="width: 300px"
      >
        <v-progress-circular indeterminate color="blue"></v-progress-circular>
      </div>
      <v-treeview
        v-else
        style="width: 300px"
        v-model="tree"
        :open="initiallyOpen"
        :items="items"
        activatable
        item-key="id"
        open-on-click
      >
        <!-- <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!item.file">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else>
          {{ files[item.file] }}
        </v-icon>
      </template> -->
        <template slot="label" slot-scope="{ item, open }">
          <div class="d-flex align-center">
            <img
              :src="require('../../../assets/' + getIconName(item.type, open))"
              alt=""
              height="17.5"
              width="17.5"
              srcset=""
              style="margin-right: 10px"
            />
            <div
              style="height: 50px; display: flex; align-items: center"
              @click="onclicka(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </template>
      </v-treeview>
    </div>

    <div style="width: 100%">
      <div v-if="selectedStorage" style="display: flex; align-items: center">
        <div class="d-flex align-center">
          <div
            style="
              background: #e3e3e3;
              height: 30px;
              width: 30px;
              border-radius: 7.5px;
              margin-right: 5px;
            "
          >
            <img
              :src="
                require('../../../assets/' +
                  getIconName(selectedStorage.type, true))
              "
              alt=""
              height="17.5"
              width="17.5"
              srcset=""
              style="margin: auto; display: block; margin-top: 7px"
            />
          </div>
          <div class="text-h4">{{ selectedStorage.title }}</div>
          <div
            style="
              background: #bbdefb;
              border-radius: 15px;
              font-weight: bold;
              font-size: 12px;
              padding: 2.5px 10px;
              margin: 10px;
              color: #1976d2;
            "
          >
            {{ selectedType }}
          </div>
        </div>
        <v-spacer />
        <!-- v-if="addType != 'BoxPosition'" -->
        <div>
          <v-btn
            depressed
            v-if="isAddButtonVisible"
            small
            color="primary"
            @click="showAdd()"
            >Add {{ addType }}</v-btn
          >
        </div>
      </div>
      <div
        v-if="!items"
        class="
          db-DashboardEmptyState
          Box-root
          Flex-flex
          Flex-alignItems--center
          Flex-justifyContent--center
        "
      >
        <div class="db-DashboardEmptyState--inner Box-root">
          <div
            class="
              Card-root Card--radius--all
              Box-root Box-hideIfEmpty
              Box-background--white
            "
            style="display: inline-block"
          >
            <div class="Box-root Box-background--offset Padding-all--12">
              <div
                aria-hidden="true"
                class="
                  SVGInline SVGInline--cleaned
                  SVG
                  Icon Icon--configurationItem Icon-color Icon-color--gray
                  Box-root
                  Flex-flex
                "
              >
                <svg
                  aria-hidden="true"
                  height="24"
                  width="24"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="
                    SVGInline-svg SVGInline--cleaned-svg
                    SVG-svg
                    Icon-svg Icon--configurationItem-svg Icon-color-svg
                    Icon-color--gray-svg
                  "
                >
                  <path
                    d="M2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm9.57 4.5h.68a.75.75 0 1 0 0-1.5h-3.5a.75.75 0 0 0-.75.75v2.5a.75.75 0 0 0 1.5 0V4.837a3.5 3.5 0 0 1 .5 6.036v1.711A5.001 5.001 0 0 0 11.57 4.5zm-7.14 7.084h-.68a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-1.5 0v1.413A3.5 3.5 0 0 1 6 5.211V3.5a5.001 5.001 0 0 0-1.57 8.084z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="Box-root Padding-top--24">
            <span
              class="
                Text-color--dark
                Text-fontSize--20
                Text-fontWeight--bold
                Text-lineHeight--28
                Text-numericSpacing--proportional
                Text-typeface--base
                Text-wrap--wrap
                Text-display--inline
              "
              ><span>Create your first Container</span></span
            >
          </div>
          <div class="Box-root Padding-top--4">
            <span
              class="
                Text-color--gray
                Text-fontSize--14
                Text-lineHeight--20
                Text-numericSpacing--proportional
                Text-typeface--base
                Text-wrap--wrap
                Text-display--inline
              "
              ><div class="Box-root" style="pointer-events: none">
                <div
                  class="
                    Box-root
                    Flex-flex
                    Flex-direction--column
                    Flex-justifyContent--flexStart
                    Flex-wrap--nowrap
                  "
                  style="margin-left: -4px; margin-top: -4px"
                >
                  <div
                    class="
                      Box-root Box-hideIfEmpty
                      Margin-top--4
                      Margin-left--4
                    "
                    style="pointer-events: auto"
                  >
                    <span
                      ><span
                        >This page let's you manage storage containers</span
                      ></span
                    >
                  </div>
                </div>
              </div></span
            >
          </div>
          <div class="Box-root Padding-top--24">
            <span
              class="
                Text-color--default
                Text-fontSize--14
                Text-lineHeight--20
                Text-numericSpacing--proportional
                Text-typeface--base
                Text-wrap--wrap
                Text-display--inline
              "
              ><div
                class="
                  PressableCore
                  PressableCore--cursor--pointer
                  PressableCore--height--medium
                  PressableCore--radius--all
                  PressableCore--width
                  PressableCore--width--auto
                  PressableButton
                  Button Button--color--blue
                  Box-root
                  Flex-inlineFlex
                "
                style="
                  background-color: rgb(84, 105, 212);
                  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
                "
              >
                <div class="PressableCore-base Box-root">
                  <v-btn @click="showAdd()" color="primary" small depressed>
                    <v-icon dense>mdi-plus</v-icon>
                    Create Container</v-btn
                  >
                </div>
                <div
                  class="
                    PressableCore-overlay PressableCore-overlay--extendBy1
                    Box-root
                    Box-background--white
                  "
                ></div></div
            ></span>
          </div>
        </div>
      </div>
      <div
        v-if="selectedType.toLowerCase() != 'box'"
        style="
          max-width: 100%;
          display: flex;
          align-items: start;
          flex-wrap: wrap;
        "
      >
        <!-- {{ selectedType }} -->
        <div
          class="storage-card"
          v-for="(child, index) in selectedChildren"
          :key="index"
        >
          <div style="display: flex; align-items: center">
            <div
              style="
                background: #e3e3e3;
                height: 30px;
                width: 30px;
                border-radius: 7.5px;
                margin-right: 5px;
              "
            >
              <img
                :src="
                  require('../../../assets/' + getIconName(child.type, true))
                "
                alt=""
                height="15"
                width="15"
                srcset=""
                style="margin: auto; display: block; margin-top: 8px"
              />
            </div>
            <div>
              <div>{{ child.title }}</div>
              <div style="font-size: 13px">{{ child.code }}</div>
            </div>
          </div>
          <div>{{ child.rows }} x {{ child.columns }}</div>
        </div>
      </div>
      <div
        v-else
        style="
          max-width: 100%;
          display: flex;
          align-items: start;
          flex-wrap: wrap;
        "
      >
        <!-- {{ selectedType }} -->
        <div
          class="storage-card"
          v-for="(boxPosition, index) in selectedBoxPositions"
          :key="index"
        >
          <div>
            <div
              v-if="!boxPosition.occupied"
              style="
                background: #9ccc65;
                min-height: 25px;
                border-radius: 7.5px;
                margin-right: 5px;
              "
              class="d-flex align-center justify-center"
            >
              <div>AVAILABLE</div>
              <!-- <img
                :src="
                  require('../../../assets/' + getIconName(boxPosition.type, true))
                "
                alt=""
                height="15"
                width="15"
                srcset=""
                style="margin: auto; display: block; margin-top: 8px"
              /> -->
            </div>
            <div
              v-else
              style="
                background: #e57373;
                min-height: 25px;
                border-radius: 7.5px;
                margin-right: 5px;
              "
              class="d-flex align-center justify-center"
            >
              <div>AVAILABLE</div>
              <!-- <img
                :src="
                  require('../../../assets/' + getIconName(boxPosition.type, true))
                "
                alt=""
                height="15"
                width="15"
                srcset=""
                style="margin: auto; display: block; margin-top: 8px"
              /> -->
            </div>
            <div class="d-flex align-center mt-2">
              <!-- <div>{{ boxPosition.title }}</div> -->
              <div style="font-size: 13px">{{ boxPosition.code }}</div>
              <v-spacer />
              <div
                style="
                  background: #e3e3e3;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                class="ml-2 pl-2 pr-2"
              >
                {{ boxPosition.rowIndex }} , {{ boxPosition.columnIndex }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showAddForm"
      class="
        FullscreenView FullscreenView--isLayer
        Box-root
        Box-background--white
        Flex-flex
        Flex-alignItems--center
        Flex-direction--column
        Flex-justifyContent--center
      "
    >
      <component
        :is="component"
        :selectedNavItem="addTitle"
        :toggleForm="toggleForm"
        :selectedStorage="selectedStorage"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import apiClient from "../../../API/apiClient";
import AddStorageContainer from "../../../components/AddForms/AddStorageContainer.vue";
import AddStorageContainerBox from "../../../components/AddForms/AddStorageContainerBox.vue";
import AddStorageContainerDrawer from "../../../components/AddForms/AddStorageContainerDrawer.vue";
import AddStorageContainerRack from "../../../components/AddForms/AddStorageContainerRack.vue";
import AddStorageContainerShelf from "../../../components/AddForms/AddStorageContainerShelf.vue";

export default {
  created() {
    this.getStorageContainers();
  },
  components: {
    AddStorageContainer,
    AddStorageContainerBox,
    AddStorageContainerDrawer,
    AddStorageContainerRack,
    AddStorageContainerShelf,
  },
  data: () => ({
    showAddForm: false,
    component: "",
    addTitle: "",
    initiallyOpen: ["f7c1640b-f7aa-46b9-724a-08d99dfb1dc9"],
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    tree: [],
    items: [],
    selectedType: "",
    selectedChildren: [],
    selectedBoxPositions: [],
    selectedStorage: null,
    addType: "",
  }),
  methods: {
    onclicka(item) {
      console.log(item.title);
      this.selectedBoxPositions = null;
      this.selectedType = item.type;
      this.selectedChildren = item.children;
      this.selectedStorage = item;
      if (this.selectedType.toLowerCase() == "container") {
        this.addType = "Shelf";
      } else if (this.selectedType.toLowerCase() == "shelf") {
        this.addType = "Rack";
      } else if (this.selectedType.toLowerCase() == "rack") {
        this.addType = "Drawer";
      } else if (this.selectedType.toLowerCase() == "drawer") {
        this.addType = "Box";
      } else if (this.selectedType.toLowerCase() == "box") {
        this.selectedBoxPositions = item.positions;
        this.addType = "BoxPosition";
      }
    },
    getStorageContainers() {
      console.log("getting storage containers...");
      this.$store.commit("setIsLoading", true);
      apiClient
        .call(
          "GET",
          "institutions/" + this.currentInstitution.id + "/storageContainers",
          null
        )
        .then((containers) => {
          var cs = [];
          containers.forEach((container) => {
            container.type = "container";
            container.ids = {
              containerId: container.id,
            };
            container.children = [];
            apiClient
              .call(
                "GET",
                "institutions/" +
                  this.currentInstitution.id +
                  "/storageContainers/" +
                  container.id +
                  "/storageContainerShelfs",
                null
              )
              .then((shelfs) => {
                shelfs.forEach((shelf) => {
                  shelf.type = "shelf";
                  shelf.ids = {
                    containerId: container.id,
                    shelfId: shelf.id,
                  };
                  shelf.children = [];
                  apiClient
                    .call(
                      "GET",
                      "institutions/" +
                        this.currentInstitution.id +
                        "/storageContainers/" +
                        container.id +
                        "/storageContainerShelfs/" +
                        shelf.id +
                        "/storageContainerRacks",
                      null
                    )
                    .then((racks) => {
                      racks.forEach((rack) => {
                        rack.type = "rack";
                        rack.ids = {
                          containerId: container.id,
                          shelfId: shelf.id,
                          rackId: rack.id,
                        };
                        rack.children = [];
                        apiClient
                          .call(
                            "GET",
                            "institutions/" +
                              this.currentInstitution.id +
                              "/storageContainers/" +
                              container.id +
                              "/storageContainerShelfs/" +
                              shelf.id +
                              "/storageContainerRacks/" +
                              rack.id +
                              "/storageContainerDrawers",
                            null
                          )
                          .then((drawers) => {
                            drawers.forEach((drawer) => {
                              drawer.type = "drawer";
                              drawer.ids = {
                                containerId: container.id,
                                shelfId: shelf.id,
                                rackId: rack.id,
                                drawerId: drawer.id,
                              };
                              drawer.children = [];
                              apiClient
                                .call(
                                  "GET",
                                  "institutions/" +
                                    this.currentInstitution.id +
                                    "/storageContainers/" +
                                    container.id +
                                    "/storageContainerShelfs/" +
                                    shelf.id +
                                    "/storageContainerRacks/" +
                                    rack.id +
                                    "/storageContainerDrawers/" +
                                    drawer.id +
                                    "/storageContainerBoxes",
                                  null
                                )
                                .then((boxs) => {
                                  boxs.forEach((box) => {
                                    box.type = "box";
                                    box.ids = {
                                      containerId: container.id,
                                      shelfId: shelf.id,
                                      rackId: rack.id,
                                      drawerId: drawer.id,
                                      boxId: box.id,
                                    };
                                    box.positions = [];
                                    apiClient
                                      .call(
                                        "GET",
                                        "institutions/" +
                                          this.currentInstitution.id +
                                          "/storageContainers/" +
                                          container.id +
                                          "/storageContainerShelfs/" +
                                          shelf.id +
                                          "/storageContainerRacks/" +
                                          rack.id +
                                          "/storageContainerDrawers/" +
                                          drawer.id +
                                          "/storageContainerBoxes/" +
                                          box.id +
                                          "/StorageContainerBoxPositions",
                                        null
                                      )
                                      .then((boxPositions) => {
                                        boxPositions.forEach((boxPosition) => {
                                          boxPosition.type = "boxPosition";
                                          boxPosition.ids = {
                                            containerId: container.id,
                                            shelfId: shelf.id,
                                            rackId: rack.id,
                                            drawerId: drawer.id,
                                            boxId: box.id,
                                            boxPositionId: boxPosition.id,
                                          };
                                          boxPosition.children = [];

                                          box.positions.push(boxPosition);
                                        });
                                        this.items = cs;
                                        console.log(
                                          "boxPositions::",
                                          boxPositions
                                        );
                                      })
                                      .catch((err) => console.log(err));
                                    drawer.children.push(box);
                                  });
                                  this.items = cs;
                                  console.log("boxs::", boxs);
                                })
                                .catch((err) => console.log(err));
                              rack.children.push(drawer);
                            });
                            this.items = cs;
                            console.log("drawers::", drawers);
                          })
                          .catch((err) => console.log(err));
                        shelf.children.push(rack);
                      });
                      this.items = cs;
                      console.log("racks::", racks);
                    })
                    .catch((err) => console.log(err));
                  container.children.push(shelf);
                });
                this.items = cs;
                console.log("shelfs::", shelfs);
              })
              .catch((err) => console.log(err));
            cs.push(container);
          });
          this.items = cs;
          console.log("containers::", containers);
          this.$store.commit("setIsLoading", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setIsLoading", false);
        });
    },
    showAdd(isNotContaner = true) {
      if (isNotContaner) {
        console.log(this.selectedStorage.ids);
        console.log("AddStorageContainer" + this.addType);

        this.component = "AddStorageContainer" + this.addType;
        this.addTitle = "Storage Container " + this.addType;
      } else {
        this.component = "AddStorageContainer";
        this.addTitle = "Storage Container ";
      }
      this.showAddForm = true;
    },
    toggleForm() {
      this.showAddForm = false;
      this.getStorageContainers();
    },
    getIconName(type, open) {
      // return "bb_logo.png";  ../../../assets/
      var path = "";
      if (type.toLowerCase() == "container") {
        if (open) {
          return path + "cube.png";
        }
        return path + "cube (1).png";
      } else if (type.toLowerCase() == "shelf") {
        if (open) {
          return path + "layers.png";
        }
        return path + "layers (1).png";
      } else if (type.toLowerCase() == "rack") {
        if (open) {
          return path + "database.png";
        }
        return path + "database (1).png";
      } else if (type.toLowerCase() == "drawer") {
        if (open) {
          return path + "box.png";
        }
        return path + "box (1).png";
      } else if (type.toLowerCase() == "box") {
        if (open) {
          return path + "apps.png";
        }
        return path + "apps (1).png";
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "isLoading"]),
    isAddButtonVisible() {
      var result = true;
      if (this.addType == "BoxPosition") {
        console.log("this.addType == 'BoxPosition'");
        result = false;
      } else if (this.selectedChildren) {
        var totalChildCount = 0;
        if (this.selectedStorage) {
          var totalChildCount =
            this.selectedStorage.rows * this.selectedStorage.columns;
        }
        if (this.selectedChildren.length > 0) {
          if (this.selectedChildren.length == totalChildCount) {
            result = false;
          }
        }
      }
      return result;
    },
  },
};
</script>
<style>
.storage-card {
  width: 160px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 7.5px;
  border: 2px solid #64b5f6;
}
.storage-card:hover {
  border: 2px solid #1976d2;
  background: #e3f2fd;
}
</style>