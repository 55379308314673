<template>
  <v-app>
    <v-main>
      <div class="db-ChromeMainContent">
        <left-nav
          :leftNavItems="leftNavItems"
          title="
Shipments
"
        />
        <div class="db-ChromeMainContent-content" tabindex="-1">
          <div class="Box-root">
            <div
              class="
                Card-root Card--radius--all
                Section
                Box-root
                Box-background--white
              "
              style="overflow: visible"
            >
              <div class="ListView ListView--withoutFooter">
                <div
                  class="
                    Box-root
                    Padding-top--16
                    Padding-bottom--8
                    Flex-flex
                    Flex-alignItems--center
                    Flex-justifyContent--spaceBetween
                  "
                >
                  <span
                    class="
                      Text-color--dark
                      Text-fontSize--28
                      Text-fontWeight--bold
                      Text-lineHeight--32
                      Text-numericSpacing--proportional
                      Text-typeface--base
                      Text-wrap--wrap
                      Text-display--inline
                    "
                    ><span>{{ selectedNavItem }} Shipments</span></span
                  >
                  <v-btn color="primary" small depressed>Create Shipment</v-btn>
                </div>

                <table class="tablex">
                  <tbody class="tablex-body"></tbody>
                </table>
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="shipments"
                    :items-per-page="10"
                    class="elevation-0"
                  ></v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import LeftNav from "../../components/LeftNav.vue";
import { v4 as uuid_v4 } from "uuid";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { LeftNav },
  name: "configuration",
  created() {
    this.getShipments();
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Tracking Number",
          align: "start",
          value: "trackingNumber",
        },
        {
          text: "Tracking URL",
          align: "start",
          value: "trackingURL",
        },
        {
          text: "Sending Institution Site",
          align: "start",
          value: "sendingInstitutionSite",
        },
        {
          text: "Sending Institution",
          align: "start",
          value: "sendingInstitution",
        },
        {
          text: "Receiving Institution",
          align: "start",
          value: "receivingInstitution",
        },
        {
          text: "ReceivingInstitutionSite",
          align: "start",
          value: "receiving Institution Site",
        },
        {
          text: "Shipped Date",
          align: "start",
          value: "shippedDate",
        },
        {
          text: "Shipped Time",
          align: "start",
          value: "shippedTime",
        },
        {
          text: "Status",
          align: "start",
          value: "status",
        },
        {
          text: "ReceivedDate",
          align: "start",
          value: "receivedDate",
        },
        {
          text: "ReceivedTime",
          align: "start",
          value: "receivedTime",
        },
      ],
      leftNavItems: [
        {
          id: uuid_v4(),
          title: "Requests",
          pathName: "Requests",
          showChildren: false,
          selected: true,
          children: [],
        },
        {
          id: uuid_v4(),
          title: "Approved",
          pathName: "Approved",
          showChildren: false,
          selected: false,
          children: [],
        },
        {
          id: uuid_v4(),
          title: "Issued",
          pathName: "Issued",
          showChildren: false,
          selected: false,
          children: [],
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getShipments"]),
  },
  computed: {
    ...mapGetters(["shipments"]),
    selectedNavItem() {
      var title = "none";
      this.leftNavItems.forEach((leftNavItem) => {
        if (leftNavItem.selected) {
          title = leftNavItem.title;
        }
        leftNavItem.children.forEach((leftNav) => {
          if (leftNav.selected) {
            title = leftNav.title;
          }
        });
      });
      return title;
    },
  },
};
</script>