<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="ethnicities" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../../components/TablePage.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TablePage,
  },
  methods: {
    ...mapActions(["getEthnicities"]),
  },
  created() {
    this.$store.commit("setSearchTerm", "");
    this.getEthnicities();
  },
  computed: {
    ...mapGetters(["currentInstitution", "ethnicities"]),
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Description", value: "description" },
        { text: "Created Date", value: "createdDate" },
        { text: "Created By", value: "creatorAccount" },
      ],
      items: [],
    };
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>