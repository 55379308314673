<template>
  <div class="db-ChromeMainContent">
    <div style="" class="db-ChromeMainContent-content">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Events
      </div>
      <div style="display: flex">
        <div style="width: 320px">
          <div class="d-flex pt-2 pb-1">
            <div style="font-weight: bold">Events</div>
            <v-spacer />
            <div>
              <v-btn small elevation="0" @click="toggleForm()"
                >Create Event
              </v-btn>
            </div>
          </div>
          <div
            v-for="(collectionProtocolEvent, index) in collectionProtocolEvents"
            :key="index"
            @click="selectEvent(collectionProtocolEvent.id)"
            :class="{
              selected: collectionProtocolEvent.selected,
              'not-selected': !collectionProtocolEvent.selected,
            }"
          >
            <span style="font-weight: bold"
              >{{ collectionProtocolEvent.label }}
            </span>
            <div style="display: flex">
              <span style="font-weight: bold">Code:</span
              ><span style="padding-left: 10px">{{
                collectionProtocolEvent.eventCode
              }}</span>
            </div>
          </div>
          <!-- <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
            "
          >
            <v-btn small elevation="0" @click="toggleForm()"
              >Create Event
            </v-btn>
          </div> -->
        </div>
        <div
          style="width: 100%; margin: 10px; margin-top: 15px"
          v-if="selectedCollectionProtocolEvent"
        >
          <div style="display: flex">
            <span
              >{{ selectedCollectionProtocolEvent.label }} Specimen
              Requirement</span
            ><v-spacer /><v-btn
              small
              elevation="0"
              @click="toggleSpecimenRequirementForm"
              color="primary"
              >Create Event Specimen Requirement
            </v-btn>
          </div>
          <!-- <v-data-table
            style="margin-top: 15px"
            :headers="headers"
            :items="collectionProtocolEventSpecimenRequirements"
          ></v-data-table> -->
          <div class="d-flex align-center justify-center" v-if="isLoading">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-treeview
            v-else
            v-model="tree"
            :open="initiallyOpen"
            :items="collectionProtocolEventSpecimenRequirements"
            activatable
            item-key="id"
            open-on-click
          >
            <template slot="label" slot-scope="{ item, open }">
              <div>
                <div
                  v-if="!item.addView"
                  class="d-flex align-center"
                  style="
                    min-width: 600px;
                    max-width: 800px;
                    border-bottom: 1px solid #e3e3e3;
                  "
                >
                  <div
                    class="mr-3"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 5px;
                      background: #afb42b;
                    "
                  ></div>
                  <div
                    style="
                      width: 150px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <!-- @click="onclicka(item)" -->
                    <span v-if="item.collectionProtocolEventID">
                      {{ item.title }}
                    </span>
                    <span v-else
                      ><span v-if="item.subSampleLineage.title == 'Derived'"
                        >{{ item.subSampleLineage.title }}
                        {{ item.specimenType.title }}</span
                      >
                      <span v-else>{{ item.subSampleLineage.title }}</span>
                    </span>
                  </div>
                  <v-spacer />
                  <div
                    style="
                      width: 250px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <!-- @click="onclicka(item)" -->
                    {{ item.specimenType.title }}
                  </div>

                  <div
                    style="
                      width: 150px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <!-- @click="onclicka(item)" -->
                    {{ item.initialQuantity }}
                    {{ item.unitOfMeasurement.unitSymbol }}
                  </div>

                  <div
                    style="
                      width: 150px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <!-- @click="onclicka(item)" -->
                    {{ item.createdDate }}
                  </div>
                  <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                  <div>
                    <v-btn @click="toggleAddView(item)" icon small
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                    <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                  </div>
                </div>
                <div v-else style="border: 1px solid #e3e3e3">
                  <div
                    class="d-flex align-center"
                    style="
                      min-width: 600px;
                      max-width: 800px;
                      background: #e3e3e3;
                    "
                  >
                    <div
                      class="mr-3"
                      style="
                        height: 10px;
                        width: 10px;
                        border-radius: 5px;
                        background: #afb42b;
                      "
                    ></div>
                    <div
                      style="
                        width: 150px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <!-- @click="onclicka(item)" -->
                      <span v-if="item.collectionProtocolEventID">
                        {{ item.title }}
                      </span>
                      <span v-else
                        ><span v-if="item.subSampleLineage.title == 'Derived'"
                          >{{ item.subSampleLineage.title }}
                          {{ item.specimenType.title }}</span
                        >
                        <span v-else>{{ item.subSampleLineage.title }}</span>
                      </span>
                    </div>
                    <v-spacer />
                    <div
                      style="
                        width: 250px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <!-- @click="onclicka(item)" -->
                      {{ item.specimenType.title }}
                    </div>

                    <div
                      style="
                        width: 150px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <!-- @click="onclicka(item)" -->
                      <!-- {{ item }} -->
                      {{ item.initialQuantity }}
                      {{ item.unitOfMeasurement.unitSymbol }}
                    </div>

                    <div
                      style="
                        width: 150px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <!-- @click="onclicka(item)" -->
                      <!-- {{ item.createdDate | moment("YYYY-MM-DD") }} -->
                    </div>
                    <!-- <div @click="toggleAddView(item)">
                        {{ item.addView }}
                      </div> -->
                    <div>
                      <v-btn @click="toggleAddView(item)" icon small
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                      <!-- <v-btn
                          @click="
                            createSubCollectionProtocolCollectedSample(item)
                          "
                          icon
                          small
                          ><v-icon>mdi-plus</v-icon></v-btn
                        > -->
                    </div>
                  </div>
                  <div>
                    <div v-if="requestData">
                      <v-form
                        v-if="requestData"
                        ref="specimenRequirementItemForm"
                        v-model="valid"
                        lazy-validation
                        style="min-height: 50px; max-width: 800px"
                        class="d-flex p-2 pb-0"
                        v-on:submit.prevent
                      >
                        <!-- <v-select
                          :items="specimenTypes"
                          item-text="title"
                          filled
                          return-object
                          v-model="requestData.specimenType"
                          :rules="[(v) => !!v || '*Required']"
                          label="SpecimenType"
                        ></v-select> -->
                        <v-autocomplete
                          label="Specimen Type"
                          :items="specimenTypes"
                          :search-input.sync="SearchSpecimenType"
                          return-object
                          filled
                          v-model="requestData.specimenType"
                          item-text="title"
                          :rules="[(v) => !!v || '*Required']"
                          clearable
                        ></v-autocomplete>
                        <v-select
                          :items="subSampleLineages"
                          item-text="title"
                          filled
                          class="ml-2"
                          return-object
                          v-model="requestData.subSampleLineage"
                          :rules="[(v) => !!v || '*Required']"
                          label="Sub Sample Lineage"
                        ></v-select>
                        <v-text-field
                          class="ml-2"
                          label="Initial Quantity"
                          :rules="NoEmptyRule"
                          filled
                          type="number"
                          required
                          v-model="requestData.initialQuantity"
                        ></v-text-field>
                      </v-form>
                    </div>
                    <v-btn
                      @click="createSpecimenRequirementItem(item)"
                      class="ml-2"
                      color="primary"
                      depressed
                      small
                      >Create</v-btn
                    >
                    <v-btn
                      @click="closeAdder(item)"
                      class="ml-2"
                      color="secondary"
                      depressed
                      small
                      >Cancel</v-btn
                    >
                  </div>
                </div>
              </div>
            </template>
          </v-treeview>
        </div>
      </div>
      <add-collection-protocol-event
        v-if="showAddForm"
        :toggleForm="toggleForm"
      />
      <add-collection-protocol-event-specimen-requirement
        :toggleForm="toggleSpecimenRequirementForm"
        v-if="showAddSpecimenRequirementForm"
      />
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolEvent from "../../components/AddForms/AddCollectionProtocolEvent.vue";
import { mapGetters, mapActions } from "vuex";
import AddCollectionProtocolEventSpecimenRequirement from "../../components/AddForms/AddCollectionProtocolEventSpecimenRequirement.vue";
import apiClient from "../../API/apiClient";
export default {
  components: {
    AddCollectionProtocolEvent,
    AddCollectionProtocolEventSpecimenRequirement,
  },
  name: "collection-protocol-event",
  created() {
    this.getCollectionProtocolEvents();
    this.getSubSampleLineages();
    // this.getAllSpecimenTypes();
  },
  data() {
    return {
      valid: false,
      tree: [],
      initiallyOpen: [],
      showAddForm: false,
      SearchSpecimenType: null,
      SearchUnitOfMeasurement: null,
      showAddSpecimenRequirementForm: false,
      events: [],
      requestData: null,
      NoEmptyRule: [(v) => !!v || "*required"],
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Code", value: "code" },
        { text: "Anatomic Site", value: "anatomicSite.title" },
        { text: "Pathological Status", value: "pathologicalStatus.title" },
        { text: "InitialQuantity", value: "initialQuantity" },
        { text: "Unit Of Measurement", value: "unitOfMeasurement.unitName" },
        {
          text: "Specimen Collection Procedure",
          value: "specimenCollectionProcedure.title",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "collectionProtocolEvents",
      "collectionProtocolEventSpecimenRequirements",
      "selectedCollectionProtocolEvent",
      "currentCollectionProtocol",
      "isLoading",
      "subSampleLineages",
      "specimenTypes",
      "currentInstitution",
    ]),
    allEvents() {
      this.events.forEach((event) => {
        console.log("Event: " + event.label + event.selected);
      });

      return this.events;
    },
  },
  methods: {
    ...mapActions([
      "getCollectionProtocolEvents",
      "getCollectionProtocolEventSpecimenRequirements",
      "getSubSampleLineages",
    ]),
    toggleForm() {
      this.showAddForm = !this.showAddForm;
    },
    toggleSpecimenRequirementForm() {
      this.showAddSpecimenRequirementForm =
        !this.showAddSpecimenRequirementForm;
    },
    selectEvent(id) {
      this.$store.commit("setSelectedCollectionProtocolEvent", id);
      this.getCollectionProtocolEventSpecimenRequirements();
    },
    toggleAddView(item) {
      console.log("toggleAddView", item);
      console.log(
        "item.collectionProtocolEventSpecimenRequirement == null",
        item.collectionProtocolEventSpecimenRequirementID == null
      );
      console.log(
        "item.collectionProtocolEventSpecimenRequirement",
        item,
        item.collectionProtocolEventSpecimenRequirement
      );
      // console.log(
      //   "collectionProtocolEventSpecimenRequirement",
      //   item.collectionProtocolEventSpecimenRequirement == null
      //     ? item
      //     : item.collectionProtocolEventSpecimenRequirement
      // );
      // return;
      item.addView = !item.addView;
      if (item.addView) {
        this.requestData = {
          collectionProtocolEventSpecimenRequirement:
            item.collectionProtocolEventSpecimenRequirementID == null
              ? item
              : item.collectionProtocolEventSpecimenRequirement,
          parentCollectionProtocolEventSpecimenRequirementItem:
            item.collectionProtocolEventID != null ? null : item,
          initialQuantity: "",
          specimenType: item.specimenType,
          subSampleLineage: item.subSampleLineage,
          unitOfMeasurement: item.unitOfMeasurement,
        };
        // this.$set(this.requestData, "subSampleLineage", true);
        // this.collectedSample.subSampleLineage = null;
      } else {
        this.requestData = null;
      }
    },
    createSpecimenRequirementItem(item) {
      console.log("Create Specimen Requirement Item", item);
      if (this.$refs.specimenRequirementItemForm.validate()) {
        this.requestData.specimenTypeID = this.requestData.specimenType.id;
        this.requestData.subSampleLineageID =
          this.requestData.subSampleLineage.id;
        if (
          this.requestData.parentCollectionProtocolEventSpecimenRequirementItem
        ) {
          this.requestData.parentCollectionProtocolEventSpecimenRequirementItemID =
            this.requestData.parentCollectionProtocolEventSpecimenRequirementItem.id;
        }

        if (this.requestData.collectionProtocolEventSpecimenRequirement) {
          this.requestData.collectionProtocolEventSpecimenRequirementID =
            this.requestData.collectionProtocolEventSpecimenRequirement.id;
        }
        if (this.requestData.unitOfMeasurement) {
          this.requestData.unitOfMeasurementID =
            this.requestData.unitOfMeasurement.id;
        }
        console.log("__________________________________");
        console.log(this.requestData);
        // return;
        // console.log(
        //   "this.selectedCollectionProtocolEvent",
        //   this.selectedCollectionProtocolEvent
        // );
        console.log(
          "endpoint",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${this.selectedCollectionProtocolEvent.id}/collectionProtocolEventSpecimenRequirements/${this.requestData.collectionProtocolEventSpecimenRequirementID}/collectionProtocolEventSpecimenRequirementItems`
        );
        // return;
        apiClient
          .call(
            "POST",
            `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${this.selectedCollectionProtocolEvent.id}/collectionProtocolEventSpecimenRequirements/${this.requestData.collectionProtocolEventSpecimenRequirementID}/collectionProtocolEventSpecimenRequirementItems`,
            this.requestData
          )
          .then((response) => {
            console.log(response);
            // this.$store.dispatch("getCollectionProtocolCollectedSamples");
            // this.toggleForm();
            this.getCollectionProtocolEventSpecimenRequirements();
            item.addView = false;
          })
          .catch((err) => console.log(err));
      }
    },
    closeAdder(item) {
      item.addView = false;
    },
  },
  watch: {
    SearchUnitOfMeasurement(val) {
      if (val) {
        if (this.SearchedUnitOfMeasurement != val) {
          this.$store.commit("setUnitOfMeasurements", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/unitOfMeasurements?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedUnitOfMeasurement = val;
              this.$store.commit("setUnitOfMeasurements", response);
            })
            .catch((err) => {});
        }
      }
    },
    SearchSpecimenType(val) {
      if (val) {
        if (this.SearchedSpecimenType != val) {
          this.$store.commit("setSpecimenTypes", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/specimenTypes?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedSpecimenType = val;
              this.$store.commit("setSpecimenTypes", response);
            })
            .catch((err) => {});
        }
      }
    },
  },
};
</script>
<style scoped>
.selected {
  padding: 10px;
  background: #5469d4;
  min-height: 60px;
  border-radius: 7.5px;
  color: white;
  margin-top: 10px;
}
.not-selected {
  padding: 10px;
  border: 1px solid #5469d4;
  min-height: 60px;
  border-radius: 7.5px;
  margin-top: 10px;
}
</style>