<template>
  <div
    class="
      FullscreenView FullscreenView--isLayer
      Box-root
      Box-background--white
      Flex-flex
      Flex-alignItems--center
      Flex-direction--column
      Flex-justifyContent--center
    "
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="
          FullscreenHeader
          Box-root
          Box-background--white
          Box-divider--light-bottom-1
          Padding-right--20
          Padding-left--8
          Padding-vertical--20
          Flex-flex
          Flex-justifyContent--spaceBetween
        "
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="
              PressableCore
              PressableCore--cursor--pointer
              PressableCore--height--medium
              PressableCore--radius--all
              PressableCore--width
              PressableCore--width--auto
              PressableButton
              Box-root
              Flex-inlineFlex
            "
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="
                PressableCore-overlay PressableCore-overlay--extendBy1
                Box-root
                Box-background--white
              "
            ></div>
          </div>
          <div
            class="
              FullscreenHeader-separator
              Box-root
              Box-background--gray200
              Margin-right--20
              Margin-left--8
            "
          ></div>
          <span
            class="
              Text-color--default
              Text-fontSize--14
              Text-fontWeight--medium
              Text-lineHeight--20
              Text-numericSpacing--proportional
              Text-typeface--base
              Text-wrap--wrap
              Text-display--inline
            "
            ><span>Create a Collection Protocol Participant Consent</span></span
          >
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="
                Box-root
                Flex-flex
                Flex-alignItems--center
                Flex-direction--row
                Flex-justifyContent--flexStart
                Flex-wrap--nowrap
              "
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="
                          Box-root
                          Flex-flex
                          Flex-alignItems--baseline
                          Flex-direction--row
                        "
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="
                      Box-root
                      Flex-flex
                      Flex-alignItems--center
                      Flex-direction--row
                      Flex-justifyContent--flexStart
                      Flex-wrap--nowrap
                    "
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--white
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="
                        Box-root Box-hideIfEmpty
                        Margin-top--8
                        Margin-left--8
                      "
                      style="pointer-events: auto"
                    >
                      <div
                        class="
                          PressableCore
                          PressableCore--cursor--pointer
                          PressableCore--height--medium
                          PressableCore--radius--all
                          PressableCore--width
                          PressableCore--width--auto
                          PressableButton
                          Button Button--color--blue
                          Box-root
                          Flex-inlineFlex
                        "
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="
                            PressableCore-overlay
                            PressableCore-overlay--extendBy1
                            Box-root
                            Box-background--white
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          FullscreenContent FullscreenContent--scroll
          Box-root
          Box-background--white
        "
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-select
                  :items="collectionProtocolConsents"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedCollectionProtocolConsent"
                  :rules="[(v) => !!v || '*Required']"
                  label="Select Consent"
                ></v-select>
                <div v-if="requestData.SelectedCollectionProtocolConsent">
                  <div
                    style="
                      border: 2px solid #1976d2;
                      border-radius: 5px;
                      padding: 5px;
                    "
                    class="mb-2"
                    v-html="
                      requestData.SelectedCollectionProtocolConsent.consent
                    "
                  ></div>
                  <!-- {{ requestData.SelectedCollectionProtocolConsent.consent }} -->
                </div>
                <div class="d-flex align-center">
                  <div class="m-3 ml-0 h5">Signature</div>
                  <v-btn depressed small @click="clearSignature()">
                    clear
                  </v-btn>
                </div>
                <div style="border: 2px solid #1976d2; border-radius: 5px">
                  <VueSignaturePad
                    width="100%"
                    height="90px"
                    v-model="requestData.Signature"
                    ref="SignaturePad"
                  />
                </div>

                <!-- <v-textarea
                  filled
                  name="input-7-1"
                  rows="20"
                  label="Consent"
                  v-model="requestData.consent"
                ></v-textarea> -->
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-collection-protocol-participant-consent",
  props: ["toggleForm"],
  created() {
    console.log("The id is: " + this.$route.params.id);
    this.getCollectionProtocolConsents();
  },
  data() {
    return {
      consents: [],
      valid: false,
      departments: [],
      requestData: {
        SelectedCollectionProtocolConsent: null,
        CollectionProtocolConsentId: null,
        PatientId: null,
        Consent: "",
        Signature: null,
        InstitutionId: null,
        CollectionProtocolId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolConsents"]),
    submit() {
      if (this.$refs.form.validate()) {
        console.log("this.$refs.SignaturePad", this.$refs.SignaturePad);
        const { isEmpty, data } = this.$refs.SignaturePad.saveSignature();
        console.log(isEmpty);
        console.log(data);
        console.log(
          "submit",
          this.requestData.SelectedCollectionProtocolConsent,
          this.currentCollectionProtocolParticipant
        );
        console.log(this.currentInstitution, this.currentCollectionProtocol);
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.Signature = data.replace("data:image/png;base64,", "");
        this.requestData.CollectionProtocolConsentId =
          this.requestData.SelectedCollectionProtocolConsent.id;
        this.requestData.PatientId =
          this.currentCollectionProtocolParticipant.patientID;
        this.requestData.Consent =
          this.requestData.SelectedCollectionProtocolConsent.consent;
        console.log("requestData", this.requestData);
        // "/collectionProtocolConsents/" +
        //       this.requestData.CollectionProtocolConsentId +
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocols/" +
              this.currentCollectionProtocol.id +
              "/collectionProtocolParticipantConsents",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.$store.dispatch("getCollectionProtocolParticipantConsents");
            this.toggleForm();
          })
          .catch((err) => console.log(err));
      }
    },
    clearSignature() {
      this.$refs.SignaturePad.clearSignature();
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "institutionSites",
      "currentCollectionProtocol",
      "collectionProtocolConsents",
      "currentCollectionProtocolParticipant",
    ]),
  },
};
</script>