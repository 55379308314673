<template>
  <div
    class="db-ChromeMainContent-content"
    tabindex="-1"
    v-on:send-result-values="showResultData"
  >
    <v-app>
      <div class="Box-root">
        <div
          class="
            Card-root Card--radius--all
            Section
            Box-root
            Box-background--white
          "
          style="overflow: visible"
        >
          <div class="ListView ListView--withoutFooter">
            <div
              v-if="selectedNavItem != 'Storage Container'"
              class="
                Box-root
                Padding-top--16
                Padding-bottom--8
                Flex-flex
                Flex-alignItems--center
                Flex-justifyContent--spaceBetween
              "
            >
              <span
                class="
                  Text-color--dark
                  Text-fontSize--28
                  Text-fontWeight--bold
                  Text-lineHeight--32
                  Text-numericSpacing--proportional
                  Text-typeface--base
                  Text-wrap--wrap
                  Text-display--inline
                "
                ><span>{{ selectedNavItem }}</span></span
              >
              <v-btn color="primary" elevation="0" small @click="toggleForm()"
                ><v-icon style="margin-right: 5px" right dark>
                  mdi-plus
                </v-icon>
                Create {{ selectedNavItem }}</v-btn
              >
            </div>
            <slot></slot>
          </div>
        </div>
      </div>
      <div
        v-if="showAddForm"
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <component
          :is="component"
          :selectedNavItem="selectedNavItem"
          :toggleForm="toggleForm"
        />
      </div>

      <div
        v-if="showEditForm"
        class="
          FullscreenView FullscreenView--isLayer
          Box-root
          Box-background--white
          Flex-flex
          Flex-alignItems--center
          Flex-direction--column
          Flex-justifyContent--center
        "
      >
        <component
          :is="editComponent"
          :selectedNavItem="selectedNavItem"
          :toggleForm="toggleEditForm"
        />
      </div>
    </v-app>
  </div>
</template>
<script>
import AddPage from "./AddPage.vue";
import EditPage from "./EditPage.vue";
import AddPanel from "./AddForms/AddPanel.vue";
import AddTest from "./AddForms/AddTest.vue";
import AddDepartment from "./AddForms/AddDepartment.vue";
import AddInstitution from "./AddForms/AddInstitution.vue";
import AddInstitutionSite from "./AddForms/AddInstitutionSite.vue";
import AddEthnicity from "./AddForms/AddEthnicity.vue";
import AddGender from "./AddForms/AddGender.vue";
import AddUnitOfMeasurement from "./AddForms/AddUnitOfMeasurement.vue";
import AddLabelingSchema from "./AddForms/AddLabelingSchema.vue";
import AddMaritalStatus from "./AddForms/AddMaritalStatus.vue";
import AddPatientType from "./AddForms/AddPatientType.vue";
import AddPositionLabelingOption from "./AddForms/AddPositionLabelingOption.vue";
import AddPriority from "./AddForms/AddPriority.vue";
import AddRace from "./AddForms/AddRace.vue";
import AddVitalStatus from "./AddForms/AddVitalStatus.vue";
import AddSpecimenTypeCategory from "./AddForms/AddSpecimenTypeCategory.vue";
import AddSpecimenType from "./AddForms/AddSpecimenType.vue";
import AddUnitOfMeasurementType from "./AddForms/AddUnitOfMeasurementType.vue";
import AddPanelTest from "./AddForms/AddPanelTest.vue";
import AddTestAcceptedValue from "./AddForms/AddTestAcceptedValue.vue";
import AddTestResultRange from "./AddForms/AddTestResultRange.vue";
import AddAnatomicSite from "./AddForms/AddAnatomicSite.vue";
import AddPathologicalStatus from "./AddForms/AddPathologicalStatus.vue";
import AddSpecimenCollectionProcedure from "./AddForms/AddSpecimenCollectionProcedure.vue";
import AddCollectionProtocolEventUnitOfMeasurement from "./AddForms/AddCollectionProtocolEventUnitOfMeasurement.vue";
import AddOrderCommentType from "./AddForms/AddOrderCommentType.vue";
import AddStorageContainer from "./AddForms/AddStorageContainer.vue";
import AddStorageContainerBox from "./AddForms/AddStorageContainerBox.vue";
import AddStorageContainerDrawer from "./AddForms/AddStorageContainerDrawer.vue";
import AddStorageContainerRack from "./AddForms/AddStorageContainerRack.vue";
import AddStorageContainerShelf from "./AddForms/AddStorageContainerShelf.vue";
import AddSubSampleLineage from "./AddForms/AddSubSampleLineage.vue";

import EditPanel from "./EditForms/EditPanel.vue";
import EditTest from "./EditForms/EditTest.vue";
import EditDepartment from "./EditForms/EditDepartment.vue";
import EditInstitution from "./EditForms/EditInstitution.vue";
import EditInstitutionSite from "./EditForms/EditInstitutionSite.vue";
import EditEthnicity from "./EditForms/EditEthnicity.vue";
import EditGender from "./EditForms/EditGender.vue";
import EditLabelingSchema from "./EditForms/EditLabelingSchema.vue";
import EditMaritalStatus from "./EditForms/EditMaritalStatus.vue";
import EditPatientType from "./EditForms/EditPatientType.vue";
import EditPositionLabelingOption from "./EditForms/EditPositionLabelingOption.vue";
import EditPriority from "./EditForms/EditPriority.vue";
import EditRace from "./EditForms/EditRace.vue";
import EditVitalStatus from "./EditForms/EditVitalStatus.vue";
import EditSpecimenTypeCategory from "./EditForms/EditSpecimenTypeCategory.vue";
import EditAnatomicSite from "./EditForms/EditAnatomicSite.vue";
import EditPathologicalStatus from "./EditForms/EditPathologicalStatus.vue";
import EditSpecimenCollectionProcedure from "./EditForms/EditSpecimenCollectionProcedure.vue";
import EditSubSampleLineage from "./EditForms/EditSubSampleLineage.vue";
import EditSpecimenType from "./EditForms/EditSpecimenType.vue";
import EditUnitOfMeasurementType from "./EditForms/EditUnitOfMeasurementType.vue";
import EditUnitOfMeasurement from "./EditForms/EditUnitOfMeasurement.vue";
import EditOrderCommentType from "./EditForms/EditOrderCommentType.vue";
import EditCollectionProtocolEventUnitOfMeasurement from "./EditForms/EditCollectionProtocolEventUnitOfMeasurement.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AddPage,
    AddPanel,
    AddTest,
    AddDepartment,
    AddInstitution,
    AddInstitutionSite,
    AddEthnicity,
    AddGender,
    AddUnitOfMeasurement,
    AddLabelingSchema,
    AddMaritalStatus,
    AddPatientType,
    AddPositionLabelingOption,
    AddPriority,
    AddRace,
    AddVitalStatus,
    AddSpecimenTypeCategory,
    AddSpecimenType,
    AddUnitOfMeasurementType,
    AddPanelTest,
    AddTestAcceptedValue,
    AddTestResultRange,
    AddAnatomicSite,
    AddPathologicalStatus,
    AddSpecimenCollectionProcedure,
    AddCollectionProtocolEventUnitOfMeasurement,
    AddOrderCommentType,
    AddStorageContainer,
    AddStorageContainerBox,
    AddStorageContainerDrawer,
    AddStorageContainerRack,
    AddStorageContainerShelf,
    AddSubSampleLineage,
    EditPage,
    EditPanel,
    EditTest,
    EditDepartment,
    EditInstitution,
    EditInstitutionSite,
    EditEthnicity,
    EditGender,
    EditLabelingSchema,
    EditMaritalStatus,
    EditPatientType,
    EditPositionLabelingOption,
    EditPriority,
    EditRace,
    EditVitalStatus,
    EditSpecimenTypeCategory,
    EditAnatomicSite,
    EditPathologicalStatus,
    EditSpecimenCollectionProcedure,
    EditSubSampleLineage,
    EditSpecimenType,
    EditUnitOfMeasurementType,
    EditUnitOfMeasurement,
    EditOrderCommentType,
    EditCollectionProtocolEventUnitOfMeasurement,
  },
  props: ["selectedNavItem"],
  name: "configuration-detail",
  data() {
    return {
      showAddForm: false,
    };
  },
  methods: {
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    toggleEditForm() {
      this.$store.commit("toggleEditView");
      this.$store.commit("setSelectedEditObject", null);
    },
    showResultData: function () {
      console.log("Data received from child: ");
    },
  },
  computed: {
    ...mapGetters(["showEditForm"]),
    component() {
      // console.log(
      //   "component: " + "Add" + this.selectedNavItem.replaceAll(" ", "")
      // );
      return "Add" + this.selectedNavItem.replaceAll(" ", "");
    },
    editComponent() {
      // console.log(
      //   "component: " + "Add" + this.selectedNavItem.replaceAll(" ", "")
      // );
      return "Edit" + this.selectedNavItem.replaceAll(" ", "");
    },
  },
};
</script>