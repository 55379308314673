<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <router-view v-if="currentCollectionProtocolParticipant" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import apiClient from "../../API/apiClient";
export default {
  created() {
    // console.log(this.$route.params.collectionProtocolParticipantId);
    console.log("loading...");
    this.getCollectionProtocolParticipantById(
      this.$route.params.collectionProtocolParticipantId
    );
  },
  methods: {
    ...mapMutations(["setSelectedCollectionProtocolParticipant"]),
    getCollectionProtocolParticipantById(id) {
      // console.log("getting CollectionProtocolParticipantById::", id);
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/collectionProtocols/" +
            this.currentCollectionProtocol.id +
            "/collectionProtocolParticipants/" +
            id,
          null
        )
        .then((response) => {
          // console.log(response);
          this.setSelectedCollectionProtocolParticipant(response);
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "currentCollectionProtocol",
      "currentCollectionProtocolParticipant",
    ]),
  },
};
</script>