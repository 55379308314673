import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Patients from "../views/Patients.vue";
import Configuration from "../views/Configuration/Configuration.vue";
import Report from "../views/Report/Report.vue";
import Setting from "../views/Setting/Setting.vue";
import Forms from "../views/Forms/Forms.vue";
import FormEditor from "../views/Forms/FormEditor.vue";
import Try from "../views/Try.vue";

import Orders from "../views/Orders/Orders.vue";
import SearchOrder from "../views/Orders/SearchOrder.vue";
import OrderTestResult from "../views/Orders/OrderTestResult.vue";

import CollectionProtocols from "../views/CollectionProtocols/CollectionProtocols.vue";
import CollectionProtocolDetail from "../views/CollectionProtocols/CollectionProtocolDetail.vue";
import CollectionProtocolsList from "../views/CollectionProtocols/CollectionProtocolsList.vue";
import CollectionProtocolOverview from "../views/CollectionProtocols/CollectionProtocolOverview.vue";
import CollectionProtocolConsents from "../views/CollectionProtocols/CollectionProtocolConsents.vue";
import CollectionProtocolSites from "../views/CollectionProtocols/CollectionProtocolSites.vue";
import CollectionProtocolEvents from "../views/CollectionProtocols/CollectionProtocolEvents.vue";
import CollectionProtocolSettings from "../views/CollectionProtocols/CollectionProtocolSettings.vue";
import CollectionProtocolForms from "../views/CollectionProtocols/CollectionProtocolForms.vue";
import CollectionProtocolFormsList from "../views/CollectionProtocols/CollectionProtocolFormsList.vue";
import CollectionProtocolFormEditor from "../views/CollectionProtocols/CollectionProtocolFormEditor.vue";
import CollectionProtocolCollectedSamples from "../views/CollectionProtocols/CollectionProtocolCollectedSamples.vue";
import CollectionProtocolCollectedQuestioners from "../views/CollectionProtocols/CollectionProtocolCollectedQuestioners.vue";
import CollectionProtocolParticipants from "../views/CollectionProtocols/CollectionProtocolParticipants.vue";
import CollectionProtocolParticipantsList from "../views/CollectionProtocols/CollectionProtocolParticipantsList.vue";
import CollectionProtocolParticipantDetail from "../views/CollectionProtocols/CollectionProtocolParticipantDetail.vue";
import CollectionProtocolParticipantOverview from "../views/CollectionProtocols/CollectionProtocolParticipantOverview.vue";
import CollectionProtocolParticipantConsents from "../views/CollectionProtocols/CollectionProtocolParticipantConsents.vue";
import CollectionProtocolParticipantForms from "../views/CollectionProtocols/CollectionProtocolParticipantForms.vue";
import CollectionProtocolParticipantCollectedSamples from "../views/CollectionProtocols/CollectionProtocolParticipantCollectedSamples.vue";
import CollectionProtocolShipments from "../views/CollectionProtocols/CollectionProtocolShipments.vue";

import Shipment from "../views/Shipments/Shipment.vue";

import PanelConfiguration from "../views/Configuration/PanelAndTest/PanelConfiguration.vue";
import TestConfiguration from "../views/Configuration/PanelAndTest/TestConfiguration.vue";
import PanelTestConfiguration from "../views/Configuration/PanelAndTest/PanelTestConfiguration.vue";
import TestAcceptedValueConfiguration from "../views/Configuration/PanelAndTest/TestAcceptedValueConfiguration.vue";
import TestResultRangeConfiguration from "../views/Configuration/PanelAndTest/TestResultRangeConfiguration.vue";
import InstitutionsConfiguration from "../views/Configuration/InstitutionsConfiguration.vue";
import FacilityConfiguration from "../views/Configuration/Container/FacilityConfiguration.vue";
import BuildingConfiguration from "../views/Configuration/Container/BuildingConfiguration.vue";
import FloorConfiguration from "../views/Configuration/Container/FloorConfiguration.vue";
import WingConfiguration from "../views/Configuration/Container/WingConfiguration.vue";
import RoomConfiguration from "../views/Configuration/Container/RoomConfiguration.vue";
import ShelfConfiguration from "../views/Configuration/Container/ShelfConfiguration.vue";
import PositionConfiguration from "../views/Configuration/Container/PositionConfiguration.vue";
import DeviceConfiguration from "../views/Configuration/Container/DeviceConfiguration.vue";
import AnatomicSiteConfiguration from "../views/Configuration/Advanced/AnatomicSiteConfiguration.vue";
import PathologicalStatusConfiguration from "../views/Configuration/Advanced/PathologicalStatusConfiguration.vue";
import SpecimenCollectionProcedureConfiguration from "../views/Configuration/Advanced/SpecimenCollectionProcedureConfiguration.vue";
import CollectionProtocolEventUnitOfMeasurementConfiguration from "../views/Configuration/Advanced/CollectionProtocolEventUnitOfMeasurementConfiguration.vue";

import StorageContainerConfiguration from "../views/Configuration/StorageContainer/StorageContainerConfiguration.vue";
import StorageContainerBoxConfiguration from "../views/Configuration/StorageContainer/StorageContainerBoxConfiguration.vue";
import StorageContainerDrawerConfiguration from "../views/Configuration/StorageContainer/StorageContainerDrawerConfiguration.vue";
import StorageContainerRackConfiguration from "../views/Configuration/StorageContainer/StorageContainerRackConfiguration.vue";
import StorageContainerShelfConfiguration from "../views/Configuration/StorageContainer/StorageContainerShelfConfiguration.vue";

import TestResultReport from "../views/Report/TestResultReport.vue";
import TestUsageReport from "../views/Report/Test/TestUsageReport.vue";
import TestSummaryReport from "../views/Report/Test/TestSummaryReport.vue";
import PanelUsageReport from "../views/Report/Panel/PanelUsageReport.vue";
import PanelSummaryReport from "../views/Report/Panel/PanelSummaryReport.vue";
import UnreleasedPanelsReport from "../views/Report/UnreleasedPanelsReport.vue";
import WeeklyBreastCancerReport from "../views/Report/WeeklyBreastCancerReport.vue";
import WeeklyCervicalCancerReport from "../views/Report/WeeklyCervicalCancerReport.vue";

import Printer from "../views/Setting/Printer.vue";
import Inistitution from "../views/Setting/Inistitution.vue";
import Accounts from "../views/Accounts.vue";

import ShipmentRequests from "../views/Shipments/ShipmentRequests.vue";
import ApprovedShipment from "../views/Shipments/ApprovedShipment.vue";
import IssuedShipment from "../views/Shipments/IssuedShipment.vue";

import DepartmentConfiguration from "../views/Configuration/Advanced/DepartmentConfiguration.vue";
import EthnicityConfiguration from "../views/Configuration/Advanced/EthnicityConfiguration.vue";
import GenderConfiguration from "../views/Configuration/Advanced/GenderConfiguration.vue";
import SubSampleLineageConfiguration from "../views/Configuration/Advanced/SubSampleLineageConfiguration.vue";
import InstitutionConfiguration from "../views/Configuration/Advanced/InstitutionConfiguration.vue";
import InstitutionSiteConfiguration from "../views/Configuration/Advanced/InstitutionSiteConfiguration.vue";
import LabelingSchemaConfiguration from "../views/Configuration/Advanced/LabelingSchemaConfiguration.vue";
import MaritalStatusConfiguration from "../views/Configuration/Advanced/MaritalStatusConfiguration.vue";
import PatientTypeConfiguration from "../views/Configuration/Advanced/PatientTypeConfiguration.vue";
import PositionLabelingOptionConfiguration from "../views/Configuration/Advanced/PositionLabelingOptionConfiguration.vue";
import PriorityConfiguration from "../views/Configuration/Advanced/PriorityConfiguration.vue";
import RaceConfiguration from "../views/Configuration/Advanced/RaceConfiguration.vue";
import SpecimenTypeCategoryConfiguration from "../views/Configuration/Advanced/SpecimenTypeCategoryConfiguration.vue";
import SpecimenTypeConfiguration from "../views/Configuration/Advanced/SpecimenTypeConfiguration.vue";
import UnitOfMeasurementConfiguration from "../views/Configuration/Advanced/UnitOfMeasurementConfiguration.vue";
import UnitOfMeasurementTypeConfiguration from "../views/Configuration/Advanced/UnitOfMeasurementTypeConfiguration.vue";
import VitalStatusConfiguration from "../views/Configuration/Advanced/VitalStatusConfiguration.vue";
import OrderCommentTypeConfiguration from "../views/Configuration/Advanced/OrderCommentTypeConfiguration.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/try",
  //   name: "Try",
  //   component: Try,
  //   meta: {
  //     guest: true,
  //   },
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/",
    component: Home,
    alias: "/home",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Dashboard,
      },
      {
        path: "orders",
        component: Orders,
        children: [
          {
            path: "",
            name: "Orders",
            component: SearchOrder,
          },
          {
            path: "test-result",
            name: "OrderTestResult",
            component: OrderTestResult,
          },
        ],
      },
      {
        path: "collection-protocols",
        // name: "CollectionProtocols",
        component: CollectionProtocols,
        children: [
          {
            path: "",
            name: "CollectionProtocols",
            component: CollectionProtocolsList,
          },
          {
            path: ":collectionProtocolId",
            component: CollectionProtocolDetail,
            children: [
              {
                path: "overview",
                name: "CollectionProtocolOverview",
                component: CollectionProtocolOverview,
              },
              {
                path: "consents",
                name: "CollectionProtocolConsents",
                component: CollectionProtocolConsents,
              },
              {
                path: "sites",
                name: "CollectionProtocolSites",
                component: CollectionProtocolSites,
              },
              {
                path: "events",
                name: "CollectionProtocolEvents",
                component: CollectionProtocolEvents,
              },
              {
                path: "participants",
                component: CollectionProtocolParticipants,
                children: [
                  {
                    path: "",
                    name: "CollectionProtocolParticipants",
                    component: CollectionProtocolParticipantsList,
                  },
                  {
                    path: ":collectionProtocolParticipantId",
                    component: CollectionProtocolParticipantDetail,
                    children: [
                      {
                        path: "participant-overview",
                        name: "CollectionProtocolParticipantOverview",
                        component: CollectionProtocolParticipantOverview,
                      },
                      {
                        path: "participant-consents",
                        name: "CollectionProtocolParticipantConsents",
                        component: CollectionProtocolParticipantConsents,
                      },
                      {
                        path: "participant-forms",
                        name: "CollectionProtocolParticipantForms",
                        component: CollectionProtocolParticipantForms,
                      },
                      {
                        path: "collected-samples",
                        name: "CollectionProtocolParticipantCollectedSamples",
                        component:
                          CollectionProtocolParticipantCollectedSamples,
                      },
                    ],
                  },
                ],
              },
              {
                path: "collected-samples",
                name: "CollectionProtocolCollectedSamples",
                component: CollectionProtocolCollectedSamples,
              },
              {
                path: "collected-questioners",
                name: "CollectionProtocolCollectedQuestioners",
                component: CollectionProtocolCollectedQuestioners,
              },
              {
                path: "settings",
                name: "CollectionProtocolSettings",
                component: CollectionProtocolSettings,
              },
              {
                path: "shipments",
                name: "CollectionProtocolShipments",
                component: CollectionProtocolShipments,
              },
              {
                path: "collectio-protocol-forms",
                component: CollectionProtocolForms,
                children: [
                  {
                    path: "",
                    name: "CollectionProtocolForms",
                    component: CollectionProtocolFormsList,
                  },
                  {
                    path: ":formId",
                    component: CollectionProtocolFormEditor,
                    name: "CollectionProtocolFormEditor",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "configuration",
        name: "Configuration",
        component: Configuration,
        redirect: "/configuration/institution",
        children: [
          {
            path: "panel",
            name: "Panel",
            component: PanelConfiguration,
          },
          {
            path: "test",
            name: "Test",
            component: TestConfiguration,
          },
          {
            path: "panel-test",
            name: "PanelTest",
            component: PanelTestConfiguration,
          },
          {
            path: "test-accepted-value",
            name: "TestAcceptedValue",
            component: TestAcceptedValueConfiguration,
          },
          {
            path: "test-result-range",
            name: "TestResultRange",
            component: TestResultRangeConfiguration,
          },
          {
            path: "service-location",
            name: "Institutions",
            component: InstitutionsConfiguration,
          },
          {
            path: "facility",
            name: "Facility",
            component: FacilityConfiguration,
          },
          {
            path: "building",
            name: "Building",
            component: BuildingConfiguration,
          },
          {
            path: "floor",
            name: "Floor",
            component: FloorConfiguration,
          },
          {
            path: "wing",
            name: "Wing",
            component: WingConfiguration,
          },
          {
            path: "room",
            name: "Room",
            component: RoomConfiguration,
          },
          {
            path: "device",
            name: "Device",
            component: DeviceConfiguration,
          },
          {
            path: "shelf",
            name: "Shelf",
            component: ShelfConfiguration,
          },
          {
            path: "position",
            name: "Position",
            component: PositionConfiguration,
          },
          {
            path: "order-comment-type",
            name: "OrderCommentType",
            component: OrderCommentTypeConfiguration,
          },
          {
            path: "department",
            name: "Department",
            component: DepartmentConfiguration,
          },
          {
            path: "ethnicity",
            name: "Ethnicity",
            component: EthnicityConfiguration,
          },
          { path: "gender", name: "Gender", component: GenderConfiguration },
          {
            path: "subSampleLineage",
            name: "SubSampleLineage",
            component: SubSampleLineageConfiguration,
          },
          {
            path: "institution",
            name: "Institution",
            component: InstitutionConfiguration,
          },
          {
            path: "institution-site",
            name: "InstitutionSite",
            component: InstitutionSiteConfiguration,
          },
          {
            path: "labeling-schema",
            name: "LabelingSchema",
            component: LabelingSchemaConfiguration,
          },
          {
            path: "marital-status",
            name: "maritalStatuses",
            component: MaritalStatusConfiguration,
          },
          {
            path: "patient-type",
            name: "PatientType",
            component: PatientTypeConfiguration,
          },
          {
            path: "position-labeling-option",
            name: "PositionLabelingOption",
            component: PositionLabelingOptionConfiguration,
          },
          {
            path: "priority",
            name: "Priority",
            component: PriorityConfiguration,
          },
          {
            path: "storage-container",
            name: "StorageContainer",
            component: StorageContainerConfiguration,
          },
          {
            path: "storage-container-box",
            name: "StorageContainerBox",
            component: StorageContainerBoxConfiguration,
          },
          {
            path: "storage-container-drawer",
            name: "StorageContainerDrawer",
            component: StorageContainerDrawerConfiguration,
          },
          {
            path: "storage-container-rack",
            name: "StorageContainerRack",
            component: StorageContainerRackConfiguration,
          },
          {
            path: "storage-container-shelf",
            name: "StorageContainerShelf",
            component: StorageContainerShelfConfiguration,
          },
          {
            path: "race",
            name: "Race",
            component: RaceConfiguration,
          },
          {
            path: "specimen-type-category",
            name: "SpecimenTypeCategory",
            component: SpecimenTypeCategoryConfiguration,
          },
          {
            path: "specimen-type",
            name: "SpecimenType",
            component: SpecimenTypeConfiguration,
          },
          {
            path: "unit-of-measurement",
            name: "UnitOfMeasurement",
            component: UnitOfMeasurementConfiguration,
          },
          {
            path: "unit-of-measurement-type",
            name: "UnitOfMeasurementType",
            component: UnitOfMeasurementTypeConfiguration,
          },
          {
            path: "vital-status",
            name: "VitalStatus",
            component: VitalStatusConfiguration,
          },
          {
            path: "anatomic-site",
            name: "AnatomicSite",
            component: AnatomicSiteConfiguration,
          },
          {
            path: "pathological-status",
            name: "PathologicalStatus",
            component: PathologicalStatusConfiguration,
          },
          {
            path: "specimen-collection-procedure",
            name: "SpecimenCollectionProcedure",
            component: SpecimenCollectionProcedureConfiguration,
          },
          {
            path: "collection-protocol-event-unit-of-measurement",
            name: "CollectionProtocolEventUnitOfMeasurement",
            component: CollectionProtocolEventUnitOfMeasurementConfiguration,
          },
        ],
      },
      {
        path: "report",
        name: "Report",
        component: Report,
        children: [
          {
            path: "test-result-report",
            name: "TestResultReport",
            component: TestResultReport,
          },
          {
            path: "test-summary-report",
            name: "TestSummaryReport",
            component: TestSummaryReport,
          },
          {
            path: "test-usage-report",
            name: "TestUsageReport",
            component: TestUsageReport,
          },
          {
            path: "panel-summary-report",
            name: "PanelSummaryReport",
            component: PanelSummaryReport,
          },
          {
            path: "panel-usage-report",
            name: "PanelUsageReport",
            component: PanelUsageReport,
          },
          {
            path: "unreleased-panels-report",
            name: "UnreleasedPanelsReport",
            component: UnreleasedPanelsReport,
          },
          {
            path: "breast-cancer-report-per-date-range",
            name: "WeeklyBreastCancerReport",
            component: WeeklyBreastCancerReport,
          },
          {
            path: "cervical-cancer-report-per-date-range",
            name: "WeeklyCervicalCancerReport",
            component: WeeklyCervicalCancerReport,
          },
        ],
      },
      {
        path: "patients",
        name: "Patients",
        component: Patients,
      },
      {
        path: "setting",
        name: "Setting",
        component: Setting,
        children: [
          {
            path: "printer",
            name: "Printer",
            component: Printer,
          },
          {
            path: "inistitution",
            name: "Inistitution",
            component: Inistitution,
          },
        ],
      },
      {
        path: "accounts",
        name: "Accounts",
        component: Accounts,
      },
      // {
      //   path: "settings",
      //   name: "Settings",
      //   component: Settings,
      // },
      {
        path: "forms",
        name: "Forms",
        component: FormEditor,
      },
      {
        path: "shipment",
        name: "Shipment",
        component: Shipment,
        // children: [
        //   {
        //     path: "",
        //     name: "Requests",
        //     component: ShipmentRequests,
        //   },
        //   {
        //     path: "approved",
        //     name: "Approved",
        //     component: ApprovedShipment,
        //   },
        //   {
        //     path: "issued",
        //     name: "Issued",
        //     component: IssuedShipment,
        //   },
        // ],
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("auth_token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
      // let user = JSON.parse(localStorage.getItem('user'))
      // if (to.matched.some(record => record.meta.is_admin)) {
      //   if (user.is_admin == 1) {
      //     next()
      //   } else {
      //     next({ name: 'userboard' })
      //   }
      // } else {
      //   next()
      // }
    }
  } else {
    next();
  }
});

export default router;
