<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div class="d-flex align-center">
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Overview
        <v-spacer />
        <v-btn small depressed @click="editItem()"
          ><v-icon left dark> mdi-pencil </v-icon> Update</v-btn
        >
      </div>
      <div class="h2 font-weight-light">
        {{ currentCollectionProtocol.title }}
      </div>
      <div class="d-flex">
        <div class="d-flex flex-column justify-start">
          <div class="font-weight-bold text-right">Abbreviation</div>
          <div class="font-weight-bold text-right">Code</div>
          <div
            v-if="currentCollectionProtocol.startDate"
            class="font-weight-bold text-right"
          >
            Start Date
          </div>
          <div
            v-if="currentCollectionProtocol.endDate"
            class="font-weight-bold text-right"
          >
            End Date:
          </div>
          <div class="font-weight-bold text-right">Expected Participants</div>
        </div>
        <div class="d-flex flex-column justify-start">
          <div class="ml-2 font-weight-light text-left">
            : {{ currentCollectionProtocol.abbreviation }}
          </div>
          <div class="ml-2 font-weight-light text-left">
            : {{ currentCollectionProtocol.code }}
          </div>
          <div
            v-if="currentCollectionProtocol.startDate"
            class="ml-2 font-weight-light text-left"
          >
            : {{ currentCollectionProtocol.startDate | moment("YYYY-MM-DD") }}
          </div>
          <div
            v-if="currentCollectionProtocol.endDate"
            class="ml-2 font-weight-light text-left"
          >
            : {{ currentCollectionProtocol.endDate | moment("YYYY-MM-DD") }}
          </div>
          <div class="ml-2 font-weight-light text-left">
            : {{ currentCollectionProtocol.expectedParticipants }}
          </div>
        </div>
      </div>
    </div>
    <edit-collection-protocol
      :toggleForm="toggleForm"
      v-if="showEditForm"
      :collectionProtocol="currentCollectionProtocol"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditCollectionProtocol from "../../components/EditForms/EditCollectionProtocol.vue";
export default {
  components: { EditCollectionProtocol },
  computed: {
    ...mapGetters(["currentCollectionProtocol"]),
  },
  data() {
    return {
      showEditForm: false,
    };
  },
  methods: {
    editItem: function () {
      this.showEditForm = true;
      // console.log("item:::", item);
    },
    toggleForm() {
      console.log("toggleForm:::");
      this.showAddForm = !this.showAddForm;
      this.showEditForm = !this.showEditForm;
    },
  },
};
</script>