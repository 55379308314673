<template>
  <div>
    <div
      class="Card-root Card--radius--all Card--shadow--keyline Box-root Box-hideIfEmpty Box-background--white"
      style="margin-bottom: 8px"
    ></div>
    <div class="db-CardlessUIPpanelsWrapper db-CustomerDetailPpanels">
      <div style="display: flex; max-width: 1380px">
        <div
          class="db-TwoColumnLayout--leftColumn"
          style="min-width: 250px; max-width: 520px; flex: 0 0 25%"
        >
          <div style="align-self: flex-start; position: sticky">
            <div class="Box-root Padding-bottom--48">
              <div class="Box-root Padding-bottom--20">
                <div
                  class="Card-root Card--radius--all Section Box-root Box-background--white"
                  style="overflow: visible"
                >
                  <div
                    class="Box-root Padding-top--20 Padding-bottom--12 Padding-horizontal--0"
                  >
                    <div
                      class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                      style="position: relative; min-width: 0px"
                    >
                      <div
                        class="TextAligner Box-root"
                        style="
                          line-height: 32px;
                          font-size: 28px;
                          flex: 0 0 auto;
                        "
                      ></div>
                      <div style="min-height: 50px; width: 100%">
                        <!-- <div
                          class="
                            Box-root
                            Padding-top--16
                            Flex-flex
                            Flex-direction--row
                          "
                        >
                          <v-text-field
                            dense
                            v-on:keyup.enter="searchOrder()"
                            label="Sample Id"
                            style="width: 90%; margin: 0px"
                            v-model="SampleId"
                            filled
                          ></v-text-field>
                        </div> -->
                        <div
                          style="width: 100%"
                          v-if="order ? order.patient : false"
                        >
                          <v-card
                            elevation="0"
                            style="border: 2px solid #bbdefb"
                          >
                            <v-card-title>
                              <div>
                                {{ order.patient.firstName }}
                                {{ order.patient.fatherName }}
                                {{ order.patient.grandFatherName }}
                              </div>
                            </v-card-title>
                            <v-card-subtitle>
                              <div>
                                MRN:
                                <!-- {{ order }} -->
                                <v-chip
                                  style="height: 20px; margin-left: 5px"
                                  v-for="(patientMRN,
                                  index) in order.PatientMRNs"
                                  :key="index"
                                  >{{ patientMRN.mrn }}</v-chip
                                >
                              </div>
                            </v-card-subtitle>
                            <div style="padding-left: 15px">
                              <div style="display: flex">
                                <span class="font-weight-bold mr-2 mb-1"
                                  >Birth Date:</span
                                >
                                {{
                                  order.patient.birthDate
                                    | moment("from", "now", true)
                                }}
                              </div>
                              <div
                                style="display: flex"
                                v-if="order.patient.gender"
                              >
                                <span class="font-weight-bold mr-2 mb-1"
                                  >Gender:</span
                                >
                                {{ order.patient.gender.title }}
                              </div>
                              <div
                                style="display: flex"
                                v-if="order.patient.maritalStatus"
                              >
                                <span class="font-weight-bold mr-2 mb-1"
                                  >Martial Status:</span
                                >
                                {{ order.patient.maritalStatus.title }}
                              </div>
                              <div
                                style="display: flex"
                                v-if="order.patient.vitalStatus"
                              >
                                <span class="font-weight-bold mr-2 mb-1"
                                  >Vital Status:</span
                                >
                                {{ order.patient.vitalStatus.title }}
                              </div>
                            </div>
                          </v-card>

                          <div class="flex">
                            <span></span>
                          </div>
                        </div>
                        <div style="margin-top: 5px; width: 100%" v-if="order">
                          <v-card
                            elevation="0"
                            style="
                              border: 2px solid #bbdefb;
                              padding-top: 10px;
                              margin-top: 10px;
                              padding-bottom: 10px;
                            "
                          >
                            <div
                              class="ml-4"
                              style="display: flex"
                              v-if="order.institutionSite"
                            >
                              <span class="font-weight-bold mr-2 mb-1"
                                >Institution Site:</span
                              >
                              {{ order.institutionSite.title }}
                            </div>
                            <div
                              class="ml-4"
                              style="display: flex"
                              v-if="order.priority"
                            >
                              <span class="font-weight-bold mr-2 mb-1"
                                >Priority:</span
                              >
                              {{ order.priority.title }}
                            </div>
                            <div
                              class="ml-4"
                              style="display: flex"
                              v-if="order.sampleID"
                            >
                              <span class="font-weight-bold mr-2 mb-1"
                                >Sample Id:</span
                              >
                              {{ order.sampleID }}
                            </div>
                            <div
                              class="ml-4"
                              style="display: flex"
                              v-if="order.priority"
                            >
                              <span class="font-weight-bold mr-2 mb-1"
                                >Ordered Date:</span
                              >
                              {{
                                order.createdDate | moment("YYYY-MM-DD hh:mm A")
                              }}
                            </div>

                            <div
                              class="ml-4"
                              style="display: flex"
                              v-if="order.creatorAccount"
                            >
                              <span class="font-weight-bold mr-2 mb-1"
                                >Ordered By:</span
                              >
                              {{ order.creatorAccount.firstName }}
                              {{ order.creatorAccount.lastName }}
                            </div>
                          </v-card>
                          <!-- <v-card>
                                  <v-card-title>
                                    Collection Protocol</v-card-title
                                  >
                                </v-card> -->
                        </div>
                        <!-- <v-data-table
                            :headers="headers"
                            :items="columns"
                            :search="search"
                          ></v-data-table> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="db-TwoColumnLayout--rightColumn Box-root"
          style="flex-grow: 1"
        >
          <div class="Box-root Padding-top--24 Padding-bottom--48">
            <div class="StatefulPpanelsTabs--section" style="display: initial">
              <div class="Box-root">
                <div>
                  <v-card
                    elevation="0"
                    class="mt-2 pl-4 pb-3 pt-3"
                    v-if="false"
                  >
                    <div style="display: flex">
                      <!-- <div
                        class="ml-4"
                        style="display: flex"
                        v-if="order.institutionSite"
                      >
                        <span class="font-weight-bold mr-2 mb-1"
                          >Institution Site:</span
                        >
                        {{ order.institutionSite.title }}
                      </div>
                      <div
                        class="ml-4"
                        style="display: flex"
                        v-if="order.priority"
                      >
                        <span class="font-weight-bold mr-2 mb-1"
                          >Priority:</span
                        >
                        {{ order.priority.title }}
                      </div> -->
                      <v-spacer />
                      <v-btn
                        style="margin-right: 10px"
                        color="primary"
                        depressed
                        @click="saveTestResults()"
                        small
                        >Save</v-btn
                      >
                      <!-- <div class="ml-4" style="display: flex">
                          <span class="font-weight-bold mr-2 mb-1"
                            >Ordered By:</span
                          >
                          {{ order.orderedBy }}
                        </div> -->
                    </div>
                  </v-card>
                  <!-- {{ order.orderedPanels }} -->
                  <div class="d-flex">
                    <v-spacer />
                    <v-btn
                      class="mr-3"
                      color="primary"
                      depressed
                      icon
                      @click="printBarcode()"
                      small
                    >
                      <v-icon> mdi-barcode </v-icon>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      depressed
                      small
                      @click="saveTestResults()"
                      >Save</v-btn
                    >
                  </div>
                  <v-snackbar v-model="snackbar" :timeout="timeout">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                  <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span>Comment</span>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-textarea
                        style="padding: 15px"
                        outlined
                        v-model="testComment.comment"
                      ></v-textarea>
                      <v-card-actions>
                        <v-btn color="primary" text @click="dialog = false">
                          Close
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="saveTestResultComment()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <div
                    class="card-columns mt-2"
                    v-if="order ? order.orderedPanels : false"
                  >
                    <div
                      class="card"
                      v-for="(orderedPanel, index) in order.orderedPanels"
                      :key="index"
                      style="
                        min-width: 100px;
                        padding-bottom: 20px;
                        border: 1px solid #64b5f6;
                      "
                    >
                      <div
                        @contextmenu="show"
                        style="
                          min-height: 20px;
                          padding: 10px 15px;
                          font-weight: bold;
                        "
                        v-if="orderedPanel.panel"
                      >
                        {{ orderedPanel.panel.title }}
                      </div>
                      <hr style="margin: 0px; background: #64b5f6" />

                      <div
                        v-for="(orderedTest,
                        index) in orderedPanel.orderedTests"
                        :key="index"
                      >
                        <!-- <v-menu
                          v-model="showMenu"
                          :position-x="x"
                          :position-y="y"
                          absolute
                          offset-y
                        >
                          <v-list>
                            <v-list-item
                              @click="releaseTest(orderedPanel, orderedTest)"
                            >
                              Release
                            </v-list-item>
                            <v-list-item> Comment </v-list-item>
                          </v-list>
                        </v-menu> -->
                        <hr style="margin: 0px" />
                        <panel-test-row
                          :commentTest="commentTest"
                          :orderedPanel="orderedPanel"
                          :releaseTest="releaseTest"
                          v-if="orderedTest.testResult"
                          :orderedTest="orderedTest"
                        ></panel-test-row>

                        <!-- <div
                          v-if="orderedTest.testResult"
                          @contextmenu="show"
                          style="
                            min-height: 40px;
                            height: 40px;
                            display: flex;
                            margin-top: 10px;
                          "
                        >
                          <div
                            style="
                              height: 40px;
                              width: 30%;
                              text-align: center;
                              vertical-align: middle;
                              line-height: 40px;
                            "
                          >
                            {{ orderedTest.test.title }}
                          </div>
                          <div
                            style="
                              min-height: 40px;
                              min-width: 25%;
                              text-align: center;
                              vertical-align: middle;
                              line-height: 40px;
                            "
                          >
                            <span v-if="orderedTest.testAcceptedValues">
                              <v-select
                                v-if="orderedTest.testAcceptedValues.length > 0"
                                style="width: 100px"
                                dense
                                :items="orderedTest.testAcceptedValues"
                                item-text="title"
                                filled
                                v-model="orderedTest.testResult.result"
                                :rules="[(v) => !!v || '*Required']"
                              ></v-select>
                              <v-text-field
                                style="width: 100px"
                                v-else
                                :disabled="
                                  orderedTest.testResultRelease
                                    ? orderedTest.testResultRelease.isReleased
                                    : false
                                "
                                v-model="orderedTest.testResult.result"
                                dense
                                filled
                              ></v-text-field>
                            </span>
                          </div>
                          <div
                            style="
                              min-height: 40px;
                              width: 45%;
                              text-align: center;
                              vertical-align: middle;
                              line-height: 40px;
                            "
                          >
                            <span v-if="orderedTest.testResultRange">
                              <span
                                >{{ orderedTest.testResultRange.minimumValue }}
                                -
                                {{
                                  orderedTest.testResultRange.maximumValue
                                }}</span
                              >
                            </span>
                            <span v-if="orderedTest.test">
                              <span v-if="orderedTest.test.unitOfMeasurement"
                                >{{ test.unitOfMeasurement.unitSymbol }}
                              </span>
                            </span>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="StatefulPpanelsTabs--section"
              style="display: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import apiClient from "../../API/apiClient";
import VueContext from "vue-context";
import PanelTestRow from "../../components/CustomComponents/PanelTestRow.vue";
export default {
  name: "order-test-result",
  components: {
    VueContext,
    PanelTestRow,
  },
  created() {
    this.getGenders();
    this.getVitalStatuses();
    this.getMaritalStatuses();
    this.getInstitutionSites();
    this.SampleId = this.$route.query.sampleId;
    console.log(this.$route.query.sampleId);
    this.searchOrder();
  },
  data() {
    return {
      snackbar: false,
      text: "Saved successfuly!",
      timeout: 2000,
      dialog: false,
      showMenu: false,
      x: 0,
      y: 0,
      items: [
        {
          title: "Release",
        },
        {
          title: "Comment",
        },
      ],
      testComment: { comment: "", orderedPanel: null, orderedTest: null },
      SampleId: "",
      orderedPanels: [],
      order: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      headers: [
        {
          text: "Sample Id",
          value: "sampleId",
        },
        { text: "Panels", value: "panels" },
      ],
      columns: [
        {
          sampleId: "ADF1000",
          panels: "PAN1, PAN2, PAN3,",
        },
        {
          sampleId: "GLU1000",
          panels: "PAN4, PAN5, PAN6,",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getGenders",
      "getVitalStatuses",
      "getMaritalStatuses",
      "getInstitutionSites",
    ]),
    printBarcode() {
      var ipAddress = localStorage.getItem("barcodePrinterIP");
      var date = this.order.createdDate;
      var name =
        this.order.patient.firstName + " " + this.order.patient.fatherName;
      var mrn = "";
      if (this.order.PatientMRNs) {
        if (this.order.PatientMRNs.length > 0) {
          mrn = this.order.PatientMRNs[0].mrn;
        }
      }
      window.postMessage(
        {
          type: "zebra_print_label",
          zpl: `^XA
      ^FX Second section with recipient address and permit information.
      ^CFA,20
      ^FO10,20^AQN^FD${this.order.sampleID}^FS
      ^FO265,20^AQN^FD${date}^FS

      ^FX Third section with bar code.
      ^BY2,2,70
      ^FO10,45^BC,N,N,N,N^FD${this.order.sampleID}^FS

      ^CFA,20
      ^FO10,125^AQN^FDMRN:${mrn}^FS
      ^FO145,125^AQN^FD${name}^FS

      ^XZ`,
          url: "http://" + ipAddress + "/pstprnt",
        },
        "*"
      );
    },
    searchOrder() {
      console.log(this.SampleId);
      apiClient
        .call(
          "GET",
          "institutions/" +
            this.currentInstitution.id +
            "/LIMSOrders?SampleID=" +
            this.SampleId,
          null
        )
        .then((response) => {
          console.log("search", response);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/patients/" +
                response[0].patientID +
                "/patientMRNs",
              null
              // patients%E2%80%8B/a274052a-9e22-4ca8-baf8-08d99c5355ea%E2%80%8B/patientMRNs
            )
            .then((patientMRNs) => {
              console.log("PatientMRNs", patientMRNs);
              this.$set(response[0], "PatientMRNs", patientMRNs);
              // response[0].patient.PatientMRNs = response;
            })
            .catch((err) => console.log(err));
          if (response == 0) {
            console.log("no response");
            this.order = null;
            return;
          }
          // console.log(response);
          // console.log("genders", this.genders[0]);
          this.genders.forEach((element) => {
            if (response[0].patient.genderID == element.id) {
              response[0].patient.gender = element;
            }
          });
          // this.genders.forEach((gender) => {
          //   console.log("gender", this.gender);
          //   if (response[0].patient.genderID == gender.id) {
          //     this.response[0].patient.gender = gender;
          //   }
          // });
          this.vitalStatuses.forEach((vitalStatus) => {
            if (response[0].patient.vitalStatusID == vitalStatus.id) {
              response[0].patient.vitalStatus = vitalStatus;
            }
          });
          this.maritalStatuses.forEach((maritalStatus) => {
            if (response[0].patient.maritalStatusID == maritalStatus.id) {
              response[0].patient.maritalStatus = maritalStatus;
            }
          });
          // console.log("institutionSites", this.institutionSites);
          this.institutionSites.forEach((institutionSite) => {
            if (response[0].institutionSiteID == institutionSite.id) {
              response[0].institutionSite = institutionSite;
            }
          });
          var order = response[0];
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/LIMSOrders/" +
                response[0].id +
                "/limsOrderedPanels?PageSize=5000",
              null
            )
            .then((orderedPanels) => {
              orderedPanels.forEach((orderedPanel) => {
                apiClient
                  .call(
                    "GET",
                    "institutions/" +
                      this.currentInstitution.id +
                      "/LIMSOrders/" +
                      response[0].id +
                      "/limsOrderedPanels/" +
                      orderedPanel.id +
                      "/limsOrderedTests?PageSize=5000",
                    null
                  )
                  .then((orderedTests) => {
                    orderedTests.forEach((orderedTest) => {
                      apiClient
                        .call(
                          "GET",
                          "institutions/" +
                            this.currentInstitution.id +
                            "/tests/" +
                            orderedTest.test.id +
                            "/testAcceptedValues",
                          null
                        )
                        .then((testAcceptedValues) => {
                          // console.log("testAcceptedValues", testAcceptedValues);
                          this.$set(
                            orderedTest,
                            "testAcceptedValues",
                            testAcceptedValues
                          );
                        })
                        .catch((err) => console.log(err));

                      // this.getTestResultsByTestId(orderedTest.test.id);
                      apiClient
                        .call(
                          "GET",
                          "institutions/" +
                            this.currentInstitution.id +
                            "/LIMSOrders/" +
                            response[0].id +
                            "/limsOrderedPanels/" +
                            orderedPanel.id +
                            "/limsOrderedTests/" +
                            orderedTest.id +
                            "/limsOrderedTestResults",
                          null
                        )
                        .then((testResults) => {
                          testResults.forEach((tr) => {
                            tr.previousResult = tr.result;
                          });
                          // console.log("testResults", testResults[0]);
                          this.$set(orderedTest, "testResult", testResults[0]);
                          if (testResults[0].testResultRangeID) {
                            apiClient
                              .call(
                                "GET",
                                "institutions/" +
                                  this.currentInstitution.id +
                                  "/tests/" +
                                  orderedTest.test.id +
                                  "/testResultRanges/" +
                                  testResults[0].testResultRangeID,
                                null
                              )
                              .then((testResultRange) => {
                                // console.log("testResultRange", testResultRange);
                                this.$set(
                                  orderedTest,
                                  "testResultRange",
                                  testResultRange
                                );
                              })
                              .catch((err) => console.log(err));
                          }

                          if (testResults[0]) {
                            apiClient
                              .call(
                                "GET",
                                `institutions/${this.currentInstitution.id}/LIMSOrders/${response[0].id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${testResults[0].id}/limsOrderedTestResultReleases`,
                                null
                              )
                              .then((testResultRelease) => {
                                // console.log("test result releases");
                                // console.log(
                                //   "testResultRelease",
                                //   testResultRelease[0]
                                // );
                                this.$set(
                                  orderedTest,
                                  "testResultRelease",
                                  testResultRelease[0]
                                );
                              })
                              .catch((err) => console.log(err));
                          }
                        })
                        .catch((err) => console.log(err));
                    });
                    this.$set(orderedPanel, "orderedTests", orderedTests);
                    // orderedPanel.orderedTests = orderedTests;
                  })
                  .catch((err) => console.log(err));
              });
              // console.log("orderedPanels", orderedPanels);
              // this.orderedPanels = orderedPanels;
              this.$set(order, "orderedPanels", orderedPanels);
            })
            .catch((err) => console.log(err));
          this.order = order;
          // console.log("order", order);
        })
        .catch((err) => console.log(err));
    },
    saveTestResults() {
      if (this.order.orderedPanels) {
        console.log(
          "this.order.orderedPanels",
          this.order.orderedPanels.length
        );
        for (const index in this.order.orderedPanels) {
          var orderedPanel = this.order.orderedPanels[index];
          // console.log("orderedPanel", orderedPanel);
          // console.log(orderedPanel.orderedTests);
          for (const index in orderedPanel.orderedTests) {
            var orderedTest = orderedPanel.orderedTests[index];
            // console.log("orderedTest", orderedTest);
            if (
              orderedTest.testResult.result !=
              orderedTest.testResult.previousResult
            ) {
              console.log(
                orderedTest.test.title + " changed",
                orderedTest.testResult.previousResult,
                orderedTest.testResult.result
              );
              var updatedTestResult = {
                result: orderedTest.testResult.result,
                // testResultRangeID: orderedTest.testResult.testResultRangeID,
              };
              console.log(
                `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${orderedTest.testResult.id}`
              );
              apiClient
                .call(
                  "PUT",
                  `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${orderedTest.testResult.id}`,
                  updatedTestResult
                )
                .then((response) => {
                  console.log("saved: ", response);
                  this.snackbar = true;
                })
                .catch((err) => console.log(err));
            }
          }
          // orderedPanel.panel.orderedTests.forEach((orderedTest) => {

          // });
        }
      }
    },
    releaseTest(orderedPanel, orderedTest) {
      // console.log("releaseTest", orderedPanel, orderedTest);
      this.saveTestResults();
      console.log("releasing: ", orderedPanel, orderedTest);
      var newTestResultRelease = {
        id: orderedTest.testResultRelease.id,
        limsOrderedTestResultID: orderedTest.testResult.id,
      };
      console.log(
        `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${orderedTest.testResult.id}/limsOrderedTestResultReleases/${orderedTest.testResultRelease.id}/release`
      );
      apiClient
        .call(
          "POST",
          `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${orderedTest.testResult.id}/limsOrderedTestResultReleases/${orderedTest.testResultRelease.id}/release`,
          newTestResultRelease
        )
        .then((response) => {
          console.log("released: ", orderedTest.test.title);
          this.searchOrder();
        })
        .catch((err) => console.log(err));
    },
    releasePanel(orderedPanel) {
      orderedPanel.orderedTests.forEach((orderedTest) => {
        console.log("releasing: ", orderedPanel, orderedTest);
        var newTestResultRelease = {
          isReleased: true,
          releasedDate: Date.now(),
          id: orderedTest.testResultRelease.id,
          limsOrderedTestResultID: orderedTest.testResult.id,
        };
        apiClient
          .call(
            "POST",
            `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}/limsOrderedTestResults/${orderedTest.testResult.id}/limsOrderedTestResultReleases/${orderedTest.testResultRelease.id}/release`,
            newTestResultRelease
          )
          .then((response) => {
            console.log("saved: ", response);
          })
          .catch((err) => console.log(err));
      });
    },
    commentTest(orderedPanel, orderedTest) {
      this.testComment.comment = orderedTest.comment;
      this.testComment.orderedPanel = orderedPanel;
      this.testComment.orderedTest = orderedTest;
      this.dialog = true;
    },
    saveTestResultComment() {
      console.log("Save comment", this.testComment);
      this.order.orderedPanels.forEach((orderedPanel) => {
        if (orderedPanel.id == this.testComment.orderedPanel.id) {
          orderedPanel.orderedTests.forEach((orderedTest) => {
            if (orderedTest.id == this.testComment.orderedTest.id) {
              this.$set(orderedTest, "comment", this.testComment.comment);
              apiClient
                .call(
                  "PUT",
                  `institutions/${this.currentInstitution.id}/LIMSOrders/${this.order.id}/limsOrderedPanels/${orderedPanel.id}/limsOrderedTests/${orderedTest.id}`,
                  orderedTest
                )
                .then((response) => {
                  console.log("commented: ", response);
                })
                .catch((err) => console.log(err));
              // console.log("Comment on this one!", orderedTest.test.title);
            }
          });
        }
      });
      this.dialog = false;
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "genders",
      "vitalStatuses",
      "maritalStatuses",
      "institutionSites",
    ]),
  },
};
</script>
<style>
.v-card__subtitle,
.v-card__text {
  font-size: unset !important;
}
</style>
