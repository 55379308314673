<template>
  <div
    class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="FullscreenHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-right--20 Padding-left--8 Padding-vertical--20 Flex-flex Flex-justifyContent--spaceBetween"
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Box-root Flex-inlineFlex"
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
            ></div>
          </div>
          <div
            class="FullscreenHeader-separator Box-root Box-background--gray200 Margin-right--20 Margin-left--8"
          ></div>
          <span
            class="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
            ><span>Create a Collection Protocol Participant Form</span></span
          >
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                      style="pointer-events: auto"
                    >
                      <div
                        class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex"
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                        ></div>
                      </div>
                    </div>
                    <div
                      class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                      style="pointer-events: auto"
                    >
                      <div
                        class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="FullscreenContent FullscreenContent--scroll Box-root Box-background--white"
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-select
                  :items="collectionProtocolForms"
                  item-text="name"
                  filled
                  @input="getFormFields()"
                  clearable
                  return-object
                  v-model="requestData.SelectedCollectionProtocolForm"
                  :rules="[(v) => !!v || '*Required']"
                  label="Select Form"
                ></v-select>
                <v-select
                  :items="institutionSites"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedInstitutionSite"
                  :rules="[(v) => !!v || '*Required']"
                  label="Institution Site"
                ></v-select>
                <v-text-field
                  dense
                  label="Sample ID"
                  filled
                  required
                  v-model="requestData.SampleID"
                ></v-text-field>
                <div
                  v-for="(formfield, index) in collectionProtocolFormFields"
                  style=""
                  :key="index"
                >
                  <div
                    v-if="formfield.dataType"
                    class="top"
                    style="margin: 0px 00px; padding: 0px 0px"
                  >
                    <div style="padding: 10px 0px">
                      {{ formfield.prompt }}
                    </div>

                    <v-text-field
                      dense
                      v-if="formfield.dataType.abbreviation == 'TB'"
                      :placeholder="formfield.placeholder"
                      :label="formfield.prompt"
                      v-model="formfield.value"
                      outlined
                    ></v-text-field>
                    <v-textarea
                      outlined
                      v-model="formfield.value"
                      :placeholder="formfield.placeholder"
                      :label="formfield.prompt"
                      rows="5"
                      v-if="formfield.dataType.abbreviation == 'TA'"
                    ></v-textarea>
                    <custom-time-picker
                      v-model="formfield.value"
                      v-if="formfield.dataType.abbreviation == 'TP'"
                      :formfield="formfield"
                    />

                    <custom-date-picker
                      v-if="formfield.dataType.abbreviation == 'DP'"
                      :formfield="formfield"
                    />

                    <custom-date-picker
                      v-if="formfield.dataType.abbreviation == 'DTP'"
                      :formfield="formfield"
                    />

                    <v-container
                      class="px-0"
                      fluid
                      v-if="formfield.dataType.abbreviation == 'CB'"
                    >
                      <v-checkbox
                        v-model="formfield.value"
                        v-if="formfield.dataType.abbreviation == 'CB'"
                        :label="formfield.prompt"
                      ></v-checkbox>
                    </v-container>
                    <div
                      v-if="formfield.dataType.abbreviation == 'CBL'"
                      :class="{
                        'd-flex': formfield.horizontalView,
                      }"
                    >
                      <div
                        v-for="(option, index) in formfield.Options"
                        :key="index"
                      >
                        <v-checkbox
                          style="margin: 0px; padding: 0px"
                          v-model="option.selected"
                          :label="option.value"
                        ></v-checkbox>
                      </div>
                    </div>
                    <!-- <div
                      v-if="formfield.dataType.abbreviation == 'CBL'"
                      :class="{
                        'd-flex': formfield.horizontalView,
                      }"
                    >
                      <div
                        v-for="(option, index) in formfield.Options"
                        :key="index"
                      >
                        <v-checkbox
                          v-model="formfield.value"
                          style="margin: 0px; padding: 0px"
                          :label="option.value"
                        ></v-checkbox>
                      </div>
                    </div> -->
                    <div v-if="formfield.dataType.abbreviation == 'RL'">
                      <v-radio-group
                        v-model="formfield.value"
                        :rules="
                          formfield.required ? [(v) => !!v || '*Required'] : []
                        "
                      >
                        <v-radio
                          v-for="(option, index) in formfield.Options"
                          :key="index"
                          :value="option.value"
                          :label="option.value"
                        ></v-radio>
                      </v-radio-group>
                      <!-- <a-radio-group :options="formfield.Options" /> -->
                    </div>
                    <v-select
                      v-if="formfield.dataType.abbreviation == 'SL'"
                      :items="formfield.Options"
                      :label="formfield.prompt"
                      item-text="value"
                      item-value="value"
                      v-model="formfield.value"
                      :rules="
                        formfield.required ? [(v) => !!v || '*Required'] : []
                      "
                      outlined
                      dense
                    ></v-select>
                  </div>

                  <!-- <div>{{ formfield }}</div> -->
                  <!-- <div>{{ index }}</div> -->
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomDatePicker from "../../components/CustomComponents/CustomDatePicker.vue";
import CustomTimePicker from "../../components/CustomComponents/CustomTimePicker.vue";
export default {
  components: { CustomTimePicker, CustomDatePicker },
  name: "add-collection-protocol-participant-consent",
  props: ["toggleForm"],
  created() {
    console.log("The id is: " + this.$route.params.id);
    this.$store.dispatch("getCollectionProtocolFormFields", null);
    this.getInstitutionSites();
    this.getCollectionProtocolForms();
  },
  data() {
    return {
      valid: false,
      requestData: {
        SelectedCollectionProtocolForm: null,
        FormFields: [],
        CollectionProtocolFormId: null,
        PatientId: null,
        InstitutionId: null,
        CollectionProtocolId: null,
        InstitutionSiteId: null,
        SelectedInstitutionSite: null,
        SampleID: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getCollectionProtocolForms", "getInstitutionSites"]),
    getFormFields(a) {
      // console.log("Form", this.requestData.SelectedCollectionProtocolForm);
      if (!!this.requestData.SelectedCollectionProtocolForm) {
        console.log(
          "Form Id",
          this.requestData.SelectedCollectionProtocolForm.id
        );
        this.$store.dispatch(
          "getCollectionProtocolFormFields",
          this.requestData.SelectedCollectionProtocolForm.id
        );
      } else {
        this.$store.dispatch("getCollectionProtocolFormFields", null);
      }
    },
    submit() {
      console.log("submitted!");
      if (this.$refs.form.validate()) {
        console.log("is valid!");
        console.log(this.currentCollectionProtocolParticipant);
        // return;
        // console.log(
        //   "submit",
        //   this.requestData.SelectedCollectionProtocolForm,
        //   this.currentCollectionProtocolParticipant
        // );
        console.log(this.currentInstitution, this.currentCollectionProtocol);
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.InstitutionSiteId = this.requestData.SelectedInstitutionSite.id;
        // this.requestData.Signature = data;
        this.requestData.CollectionProtocolFormId = this.requestData.SelectedCollectionProtocolForm.id;
        this.requestData.PatientId = this.currentCollectionProtocolParticipant.patientID;
        // this.requestData.Form =
        //   this.requestData.SelectedCollectionProtocolForm.consent;
        console.log("requestData", this.requestData);
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/submittedCollectionProtocolForms",
            this.requestData
          )
          .then((submittedCollectionProtocolForm) => {
            console.log(submittedCollectionProtocolForm);
            this.collectionProtocolFormFields.forEach(
              (collectionProtocolFormField) => {
                console.log(
                  "collectionProtocolFormField:: ",
                  collectionProtocolFormField
                );

                var value = collectionProtocolFormField.value;
                var values = [];
                if (collectionProtocolFormField.Options) {
                  if (collectionProtocolFormField.Options.length > 0) {
                    collectionProtocolFormField.Options.forEach((option) => {
                      if (option.selected) {
                        values.push(option.value);
                      }
                    });
                  }
                }

                if (values.length > 0) {
                  value = JSON.stringify(values);
                }

                var submittedCollectionProtocolFormValue = {
                  collectionProtocolFormFieldID: collectionProtocolFormField.id,
                  value: value,
                };
                apiClient
                  .call(
                    "POST",
                    "institutions/" +
                      this.currentInstitution.id +
                      "/submittedCollectionProtocolForms/" +
                      submittedCollectionProtocolForm.id +
                      "/submittedCollectionProtocolFormValues",
                    submittedCollectionProtocolFormValue
                  )
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((err) => console.log(err));
              }
            );
            this.$store.dispatch("getSubmittedCollectionProtocolForms");
            this.toggleForm();
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "institutionSites",
      "currentCollectionProtocol",
      "collectionProtocolForms",
      "collectionProtocolFormFields",
      "currentCollectionProtocolParticipant",
    ]),
  },
};
</script>
