<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="items" :rowCount="25" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../components/TablePage.vue";
import { mapGetters } from "vuex";
export default {
  name: "unreleased-panels-report",
  components: {
    TablePage,
  },
  created() {},
  data() {
    return {
      headers: [
        {
          text: "Patient Name",
          align: "start",
          value: "patientName",
        },
        { text: "Patient Id", value: "patientId" },
        { text: "SampleId", value: "sampleId" },
        { text: "Ordered On", value: "orderedOn" },
        { text: "Ordered By", value: "orderedBy" },
        { text: "Panel Name", value: "panelName" },
        { text: "Department Name", value: "departmentName" },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>