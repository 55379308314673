<template>
  <div id="dashboardRoot" class="db-DashboardRoot">
    <span
      ><span tabindex="-1"></span><span tabindex="-1"></span>
      <div>
        <div class="db-World-root" data-testid="world-root">
          <div
            class="db-World-wrapper Box-root Flex-flex Flex-direction--column"
          >
            <v-app id="inspire" v-if="!currentInstitution">
              <v-main>
                <v-container fluid fill-height>
                  <v-layout align-center justify-center>
                    <div class="flex flex-col max-w-lg">
                      <div style="padding: 10px; margin: 10px; display: flex">
                        <img
                          src="../assets/bb_logo.png"
                          alt=""
                          @click="selectNav(0)"
                          srcset=""
                          class="w-16 h-16"
                        />
                        <div style="padding: 5px 10px">
                          <div style="font-size: 15px; font-weight: bold">
                            Biobanking LIMS
                          </div>
                          <div>
                            Remember, with great power comes great
                            responsibility.
                          </div>
                        </div>
                      </div>
                      <v-card style="padding: 10px" elevation="0">
                        <div style="margin: 10px">INSTITUTIONS</div>
                        <v-data-table
                          @click:row="insitutionSelected"
                          :headers="headers"
                          :items="institutions"
                          loading-text="Loading Institutions..."
                          :loading="isLoading"
                          hide-default-header
                          hide-default-footer
                          class="elevation-0"
                        ></v-data-table>
                      </v-card>
                    </div>
                  </v-layout>
                </v-container>
              </v-main>
            </v-app>
            <div style="min-width: 1000px; position: relative">
              <nav-bar v-if="currentInstitution" />
              <router-view v-if="currentInstitution"></router-view>
              <!-- <configuration /> -->
              <!-- <configuration /> -->
            </div>
          </div>
        </div>
      </div></span
    >
  </div>
  <!-- <router-view></router-view> -->
  <!-- <div class="home">
    <a-button type="primary">Button</a-button>
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapActions } from "vuex";
import apiClient from "../API/apiClient";

export default {
  name: "Home",
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title",
      },
      // { text: "Abbreviation", value: "abbreviation" },
      // { text: "Description", value: "description" },
    ],
  }),
  methods: {
    ...mapActions(["getInstitutions"]),
    insitutionSelected(value) {
      localStorage.setItem("selectedInstitutionId", value.id);
      this.$store.dispatch("setCurrentInstitution", value);
      // this.$router.push({ name: "Home" }).catch(() => {});
      this.menu = false;
    },
  },
  beforeCreate() {
    console.log("app prepared!");
    this.$store.dispatch("getInstitutions");

    if (localStorage.getItem("selectedInstitutionId")) {
      apiClient
        .call(
          "GET",
          "institutions/" + localStorage.getItem("selectedInstitutionId"),
          null
        )
        .then((response) => {
          this.$store.dispatch("setCurrentInstitution", response);
        })
        .catch((err) => console.log(err));

      console.log(localStorage.getItem("selectedInstitutionId"));
    } else {
      this.$store.dispatch("getInstitutions");
    }
    console.log(this.$route.params.id);
    // this.$store.dispatch("getCollectionProtocols");
    if (
      this.$route.path.includes("collection-protocols") &&
      !!this.$route.params.id
    ) {
      this.$store.state.collectionProtocolId = this.$route.params.id;
    } else {
      this.$store.state.collectionProtocolId = null;
    }
    // this.$store.state.collectionProtocols.forEach((collectionProtocol) => {
    //   console.log(collectionProtocol);
    // });
    console.log(
      this.$route.path.includes("collection-protocols") &&
        !!this.$route.params.id
    );
    // console.log(!!this.$route.params.id);
    console.log("The id is: " + this.$route.params.id);
    // apiClient
    //   .call("GET", "institutions", null)
    //   .then((response) => {
    //     this.items = response;
    //     if (!this.currentInstitution) {
    //       this.$store.commit("setSelectedInstitution", this.items[0]);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  },
  components: {
    HelloWorld,
    NavBar,
  },
  computed: {
    ...mapGetters(["currentInstitution", "institutions", "isLoading"]),
  },
};
</script>
