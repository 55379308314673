<template>
  <div
    class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
  >
    <form novalidate="novalidate" style="width: 100%; height: 100%">
      <div
        class="FullscreenHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-right--20 Padding-left--8 Padding-vertical--20 Flex-flex Flex-justifyContent--spaceBetween"
        style="flex: 0 0 auto"
      >
        <div class="Box-root Flex-flex Flex-alignItems--center">
          <div
            class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Box-root Flex-inlineFlex"
          >
            <div class="PressableCore-base Box-root">
              <v-btn icon color="black" @click="toggleForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
            ></div>
          </div>
          <div
            class="FullscreenHeader-separator Box-root Box-background--gray200 Margin-right--20 Margin-left--8"
          ></div>
          <div>
            <div>
              <span
                class="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
                ><span>Collect Specimen</span></span
              >
            </div>
            <div>{{ currentCollectionProtocol.title }}</div>
          </div>
        </div>
        <div class="Box-root Flex-flex">
          <div class="Box-root" style="pointer-events: none">
            <div
              class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
              style="margin-left: -20px; margin-top: -20px"
            >
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="Box-root Margin-top--4">
                  <div class="Box-root">
                    <button
                      data-db-analytics-name="dashboard_create_payment_feedback_link"
                      type="button"
                      class="UnstyledLink ButtonLink Flex-flex"
                    >
                      <div
                        class="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                        style="position: relative"
                      >
                        <div
                          class="TextAligner Box-root"
                          style="
                            line-height: 20px;
                            font-size: 14px;
                            flex: 0 0 auto;
                          "
                        ></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--20"
                style="pointer-events: auto"
              >
                <div class="ButtonGroup Box-root" style="pointer-events: none">
                  <div
                    class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                    style="margin-left: -8px; margin-top: -8px"
                  >
                    <div
                      class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                      style="pointer-events: auto"
                    >
                      <div
                        class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex"
                        style="background-color: rgb(255, 255, 255)"
                      >
                        <div class="PressableCore-base Box-root">
                          <v-btn small elevation="0"
                            >Submit and create another</v-btn
                          >
                        </div>
                        <div
                          class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                        ></div>
                      </div>
                    </div>
                    <div
                      class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                      style="pointer-events: auto"
                    >
                      <div
                        class="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--blue Box-root Flex-inlineFlex"
                        style="background-color: rgb(84, 105, 212)"
                      >
                        <div
                          class="PressableCore-base Box-root"
                          @click="submit()"
                        >
                          <v-btn color="primary" small elevation="0"
                            >Submit</v-btn
                          >
                        </div>
                        <div
                          class="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="FullscreenContent FullscreenContent--scroll Box-root Box-background--white"
        style="flex: 1 1 100%; height: calc(100% - 76px)"
      >
        <div class="FormLayout Box-root Box-background--white">
          <div class="Box-root">
            <div
              class="Box-root"
              style="
                margin-left: auto;
                margin-right: auto;
                width: 500px;
                margin-top: 20px;
                min-height: 500px;
              "
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-on:submit.prevent
              >
                <v-text-field
                  dense
                  v-if="!hasParticipant"
                  label="Patient MRN"
                  :rules="[requestData.SelectedPatient != null || '*Required']"
                  filled
                  hint="Write patient MRN and hit enter to search for patient."
                  required
                  v-on:keyup.enter="searchPatient()"
                  v-model="requestData.PatientMRN"
                ></v-text-field>

                <div
                  v-if="requestData.SelectedPatient"
                  style="
                    min-height: 60px;
                    border-radius: 5px;
                    border: 2px solid #5469d4;
                    border-left: 5px solid #5469d4;
                    margin-bottom: 10px;
                    padding: 10px 20px;
                  "
                >
                  <div class="h3">
                    {{ requestData.SelectedPatient.firstName }}
                    {{ requestData.SelectedPatient.fatherName }}
                    {{ requestData.SelectedPatient.grandFatherName }}
                  </div>
                  <div class="d-flex">
                    <div>Date of Birth:</div>
                    <div>{{ requestData.SelectedPatient.birthDate }}</div>
                  </div>
                  <div class="d-flex">
                    <div>Gender:</div>
                    <div>
                      {{ requestData.SelectedPatient.gender.title }}
                    </div>
                  </div>
                </div>
                <v-text-field
                  dense
                  label="Sample Id"
                  filled
                  required
                  v-on:keyup.enter="searchPatient()"
                  v-model="requestData.SampleID"
                ></v-text-field>
                <v-select
                  :items="institutionSites"
                  item-text="title"
                  filled
                  clearable
                  return-object
                  v-model="requestData.SelectedInstitutionSite"
                  label="InstitutionSite"
                ></v-select>
                <v-select
                  :items="collectionProtocolEvents"
                  item-text="label"
                  filled
                  clearable
                  @change="eventChanged"
                  return-object
                  v-model="requestData.SelectedCollectionProtocolEvent"
                  label="Event"
                ></v-select>
                <v-autocomplete
                  label="Specimen Type"
                  :items="specimenTypes"
                  :search-input.sync="SearchSpecimenType"
                  return-object
                  filled
                  v-model="requestData.SelectedSpecimenType"
                  item-text="title"
                  :rules="[(v) => !!v || '*Required']"
                  clearable
                ></v-autocomplete>
                <!-- <v-select
                  :items="specimenTypes"
                  item-text="title"
                  filled
                  return-object
                  v-model="requestData.SelectedSpecimenType"
                  :rules="[(v) => !!v || '*Required']"
                  label="SpecimenType"
                ></v-select> -->
                <div class="d-flex">
                  <v-text-field
                    label="Collected Amount"
                    :rules="NoEmptyRule"
                    filled
                    type="number"
                    required
                    v-model="requestData.CollectedAmount"
                  ></v-text-field>
                  <v-autocomplete
                    label="Unit Of Measurement"
                    :items="unitOfMeasurements"
                    :search-input.sync="SearchUnitOfMeasurement"
                    return-object
                    filled
                    class="ml-3"
                    v-model="requestData.SelectedUnitOfMeasurement"
                    item-text="unitSymbol"
                    :rules="[(v) => !!v || '*Required']"
                    clearable
                  ></v-autocomplete>
                  <!-- <v-select
                    :items="unitOfMeasurements"
                    item-text="unitName"
                    filled
                    class="ml-3"
                    return-object
                    v-model="requestData.SelectedUnitOfMeasurement"
                    :rules="[(v) => !!v || '*Required']"
                    label="Unit Of Measurement"
                  ></v-select> -->
                </div>
                <div class="d-flex">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="requestData.CollectedDate"
                        label="Collected Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="NoEmptyRule"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestData.CollectedDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="requestData.CollectedTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        class="ml-3"
                        v-model="requestData.CollectedTime"
                        label="Collected Time"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        :rules="NoEmptyRule"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="requestData.BirthTime"
                      full-width
                      @click:minute="$refs.menu.save(requestData.BirthTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  name: "add-collection-protocol-collected-samples",
  props: ["toggleForm", "hasParticipant"],
  created() {
    // console.log("The id is: " + this.$route.params.id);
    this.getInstitutionSites();
    this.getCollectionProtocolEvents();
    this.getAllUnitOfMeasurements();
  },
  data() {
    return {
      valid: false,
      departments: [],
      menu1: false,
      menu2: false,
      SearchSpecimenType: null,
      SearchUnitOfMeasurement: null,
      requestData: {
        SelectedInstitutionSite: null,
        InstitutionSiteId: null,
        SelectedCollectionProtocolEvent: null,
        CollectionProtocolEventId: null,
        SelectedPatient: null,
        PatientId: null,
        PatientMRN: "",
        SampleID: "",
        SelectedSpecimenType: null,
        SpecimenTypeId: null,
        CollectedAmount: 0,
        RemainingAmount: 0,
        SelectedUnitOfMeasurement: null,
        UnitOfMeasurementId: null,
        CollectedDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        CollectedTime: this.getTodaysTime(),
        // CollectedDate: null,
        // CollectedTime: null,
        InstitutionId: null,
        CollectionProtocolId: null,
        PatientId: null,
        IsSubSample: false,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions([
      "getInstitutionSites",
      "getCollectionProtocolEvents",
      "getAllUnitOfMeasurements",
    ]),
    eventChanged(event) {
      console.log("event", event.label);
      apiClient
        .call(
          "GET",
          `institutions/${this.currentInstitution.id}/collectionProtocols/${this.currentCollectionProtocol.id}/collectionProtocolEvents/${event.id}`,
          null
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    searchPatient() {
      console.log("searching now...");
      apiClient
        .call(
          "GET",
          "institutions/PatientMRNs?institutionId=" +
            this.currentInstitution.id +
            "&mrn=" +
            this.requestData.PatientMRN,
          null
        )
        .then((response) => {
          if (response.length > 0) {
            console.log(response[0].patient);
            this.requestData.SelectedPatient = response[0].patient;
          } else {
            this.requestData.SelectedPatient = null;
          }

          // this.toggleForm();
          // this.$store.dispatch("getDepartments");
        })
        .catch((err) => console.log(err));
    },
    submit() {
      if (this.$refs.form.validate()) {
        // console.log(this.currentInstitution, this.currentCollectionProtocol);
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.CollectionProtocolId =
          this.currentCollectionProtocol.id;
        if (!this.hasParticipant) {
          this.requestData.PatientId = this.requestData.SelectedPatient.id;
        } else {
          this.requestData.PatientId =
            this.currentCollectionProtocolParticipant.patientID;
        }
        // console.log();
        if (this.requestData.SelectedCollectionProtocolEvent) {
          this.requestData.CollectionProtocolEventId =
            this.requestData.SelectedCollectionProtocolEvent.id;
        }

        if (this.requestData.SelectedInstitutionSite) {
          this.requestData.InstitutionSiteId =
            this.requestData.SelectedInstitutionSite.id;
        }

        this.requestData.SpecimenTypeId =
          this.requestData.SelectedSpecimenType.id;
        this.requestData.UnitOfMeasurementId =
          this.requestData.SelectedUnitOfMeasurement.id;
        this.requestData.RemainingAmount = this.requestData.CollectedAmount;
        // return;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/collectionProtocols/" +
              this.currentCollectionProtocol.id +
              "/collectedSamples",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.$store.dispatch("getCollectionProtocolCollectedSamples");
            this.toggleForm();
          })
          .catch((err) => console.log(err));
      }
    },
    getTodaysTime() {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes();
      return time;
    },
  },
  watch: {
    SearchUnitOfMeasurement(val) {
      if (val) {
        if (this.SearchedUnitOfMeasurement != val) {
          this.$store.commit("setUnitOfMeasurements", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/unitOfMeasurements?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedUnitOfMeasurement = val;
              this.$store.commit("setUnitOfMeasurements", response);
            })
            .catch((err) => {});
        }
      }
    },
    SearchSpecimenType(val) {
      if (val) {
        if (this.SearchedSpecimenType != val) {
          this.$store.commit("setSpecimenTypes", []);
          console.log("searching...", val);
          apiClient
            .call(
              "GET",
              "institutions/" +
                this.currentInstitution.id +
                "/specimenTypes?SearchTerm=" +
                val,
              null
            )
            .then((response) => {
              this.SearchedSpecimenType = val;
              this.$store.commit("setSpecimenTypes", response);
            })
            .catch((err) => {});
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "currentCollectionProtocol",
      "institutionSites",
      "collectionProtocolEvents",
      "specimenTypes",
      "unitOfMeasurements",
      "currentCollectionProtocolParticipant",
    ]),
  },
};
</script>
