<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="tests"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedTest"
        :rules="[(v) => !!v || '*Required']"
        label="Test"
      ></v-select>
      <v-select
        :items="panels"
        item-text="title"
        filled
        return-object
        v-model="requestData.SelectedPanel"
        :rules="[(v) => !!v || '*Required']"
        label="Panel"
      ></v-select>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-panel-test",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    this.getTests();
    this.getPanels();
  },
  data() {
    return {
      valid: false,
      unitOfMeasurements: [],
      requestData: {
        SelectedTest: null,
        TestId: null,
        SelectedPanel: null,
        PanelId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    ...mapActions(["getTests", "getPanels"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        this.requestData.TestId = this.requestData.SelectedTest.id;
        this.requestData.PanelId = this.requestData.SelectedPanel.id;
        apiClient
          .call(
            "POST",
            "institutions/" + this.currentInstitution.id + "/panelTests",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getPanelTests");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution", "tests", "panels"]),
  },
};
</script>