<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Field Lable"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.prompt"
      ></v-text-field>

      <v-textarea
        dense
        label="Response Title"
        :rules="NoEmptyRule"
        filled
        rows="2"
        required
        v-model="requestData.responseTitle"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.required"
        label="Is Required"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.required"
        dense
        label="Required Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.requiredMessage"
      ></v-text-field>

      <v-checkbox
        v-model="requestData.hasValidation"
        label="Has Validation"
      ></v-checkbox>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpression"
      ></v-text-field>
      <v-text-field
        v-if="requestData.hasValidation"
        dense
        label="Regular Expression Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.regularExpressionMessage"
      ></v-text-field>
      <v-text-field
        dense
        label="Ordinal"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.ordinal"
        type="number"
      ></v-text-field>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  props: ["selectedNavItem", "toggleForm", "addFormField", "dataTypes"],
  created() {
    this.requestData.ordinal = this.currentOrdinal;
    //console.log("dataTypes", this.dataTypes);
    this.dataTypes.forEach((DT) => {
      //console.log(DT.abbreviation);
      if (DT.abbreviation.toLowerCase() === "tp") {
        this.requestData.dataType = DT;
        //console.log(this.requestData.dataType.title);
      }
    });
  },
  data() {
    return {
      text: "vertical",
      valid: false,
      NoEmptyRule: [(v) => !!v || "*required"],
      search: null,
      requestData: {
        prompt: "",
        placeholder: "none",
        ordinal: 0,
        responseTitle: "",
        onNewLine: true,
        required: false,
        requiredMessage: "",
        verticalView: false,
        horizontalView: false,
        hasValidation: false,
        regularExpression: "",
        regularExpressionMessage: "",
        value: "",
        dataType: {
          dataTypeName: "TimePicker",
          dataTypeAbbreviation: "TP",
          dataTypeDescription: "TimePicker",
          hasValidation: false,
          dataTypeValidation: "",
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // console.log(this.requestData);
        this.addFormField(this.requestData);
      }
    },
  },
  computed: { ...mapGetters(["currentOrdinal"]) },
};
</script>