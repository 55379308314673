<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="testResultRanges" :rowCount="25" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../../components/TablePage.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TablePage,
  },
  methods: {
    ...mapActions(["getAllTestResultRanges"]),
  },
  created() {
    this.$store.commit("setSearchTerm", "");
    this.getAllTestResultRanges();
    console.log(this.testResultRanges.length);
  },
  computed: {
    ...mapGetters(["currentInstitution", "testResultRanges"]),
  },
  data() {
    return {
      headers: [
        { text: "Test", value: "test.title" },
        // { text: "Panel", value: "panel" },
        {
          text: "Minimum",
          value: "minimumValue",
        },
        { text: "Maximum", value: "maximumValue" },
        // { text: "Age/Gender Group", value: "ageOrGenderGroup" },
        // { text: "Description", value: "description" },
        { text: "Created Date", value: "enabledDate" },
        { text: "Created By", value: "enablerAccount" },
      ],
      items: [],
    };
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>