import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style.css";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import DatetimePicker from "vuetify-datetime-picker";
// import VueHtml2Canvas from "vue-html2canvas";

// Vue.use(VueHtml2Canvas);

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);
import VueSignaturePad from "vue-signature-pad";
Vue.use(DatetimePicker);
Vue.use(VueSignaturePad);

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";

// import Antd from 'ant-design-vue';
// import "ant-design-vue/dist/antd.css";
// Vue.use(Antd);
import "./assets/dashboard.1fb16ffd58386e26d960.css";
import vuetify from "./plugins/vuetify";

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdiSvg",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
