<template>
  <v-app>
    <v-main>
      <table-page :headers="headers" :items="items" :rowCount="25" />
    </v-main>
  </v-app>
</template>
<script>
import TablePage from "../../../components/TablePage.vue";
export default {
  components: {
    TablePage,
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Description", value: "description" },
        { text: "Created Date", value: "createdDate" },
        { text: "Created By", value: "creatorAccount" },
      ],
      items: [
        {
          title: "Wing 1",
          abbreviation: "W1",
          description: "wing 1 description",
        },
        {
          title: "Wing 2",
          abbreviation: "W2",
          description: "wing 2 description",
        },
        {
          title: "Wing 3",
          abbreviation: "W3",
          description: "wing 3 description",
        },
      ],
    };
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 13px !important;
  height: 48px;
}
</style>