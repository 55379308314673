<template>
  <v-app>
    <v-main>
      <div class="db-ChromeMainContent">
        <div class="db-ChromeMainContent-content" tabindex="-1">
          <div style="margin: 10px 0px">
            <span
              class="Text-color--dark Text-fontSize--16 Text-fontWeight--bold Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"
              ><span>Accounts</span></span
            >
          </div>
          <div style="display: flex; align-items: center">
            <v-text-field
              outlined
              style="max-width: 400px"
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn @click="toggleForm()" color="primary" elevation="0" small
              >Create</v-btn
            >
          </div>
          <v-data-table
            style="margin-top: 15px"
            :headers="headers"
            :items="accounts"
            @click:row="handleClick"
            :search="search"
          ></v-data-table>
        </div>
        <div
          v-if="showAddForm"
          class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
        >
          <add-account
            selectedNavItem="Account"
            :toggleForm="toggleForm"
          ></add-account>
        </div>
        <div
          v-if="isEditFormVisible"
          class="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center"
        >
          <edit-account
            selectedNavItem="Account"
            :toggleForm="toggleEditForm"
            :account="selectedAccount"
          ></edit-account>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AddAccount from "../components/AddForms/AddAccount.vue";
import EditAccount from "../components/EditForms/EditAccount.vue";
import { v4 as uuid_v4 } from "uuid";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      search: "",
      showAddForm: false,
      headers: [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "PhoneNumber", value: "phoneNumber" },
      ],
      isEditFormVisible: false,
      selectedAccount: null,
    };
  },
  created() {
    this.getAccounts();
  },
  computed: {
    ...mapGetters(["accounts"]),
  },
  methods: {
    ...mapMutations(["setSelectedAccount"]),
    ...mapActions(["getAccounts"]),
    handleClick(value) {
      this.setSelectedAccount(value);
      this.selectedAccount = value;
      console.log("currentAccount: list clicked", value);
      // this.$router
      //   .push({
      //     name: "AccountOverview",
      //     params: { collectionProtocolId: value.id },
      //   })
      //   .catch(() => {});
      this.toggleEditForm();
      console.log(value);
    },
    toggleForm() {
      this.showAddForm = !this.showAddForm;
    },
    toggleEditForm() {
      this.isEditFormVisible = !this.isEditFormVisible;
    },
  },
  components: {
    AddAccount,
    EditAccount,
  },
};
</script>
