<template>
  <add-page
    :selectedNavItem="selectedNavItem"
    :toggleForm="toggleForm"
    @form-submited="submit()"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        label="Title"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Title"
      ></v-text-field>

      <v-text-field
        dense
        label="Abbreviation"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.Abbreviation"
      ></v-text-field>

      <v-textarea
        dense
        label="Description"
        :rules="NoEmptyRule"
        filled
        rows="10"
        required
        v-model="requestData.Description"
      ></v-textarea>
      <v-checkbox
        v-model="requestData.HasValidation"
        label="Has Validation"
      ></v-checkbox>
      <v-text-field
        dense
        v-if="requestData.HasValidation"
        label="Regular Expression"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.RegularExpression"
      ></v-text-field>
      <v-text-field
        dense
        v-if="requestData.HasValidation"
        label="Regular Expression Message"
        :rules="NoEmptyRule"
        filled
        required
        v-model="requestData.RegularExpressionMessage"
      ></v-text-field>
    </v-form>
  </add-page>
</template>
<script>
import AddPage from "../AddPage.vue";
import apiClient from "../../API/apiClient";
import { mapGetters } from "vuex";
export default {
  components: { AddPage },
  name: "add-order-comment-type",
  props: ["selectedNavItem", "toggleForm"],
  created() {
    apiClient
      .call("GET", "institutions", null)
      .then((response) => {
        this.institutions = response;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      valid: false,
      departments: [],
      requestData: {
        Title: "",
        Abbreviation: "",
        Description: "",
        HasValidation: false,
        RegularExpression: "",
        RegularExpressionMessage: "",
        SelectedInstitution: null,
        InstitutionId: null,
      },
      institutions: [],
      name: "",
      NoEmptyRule: [(v) => !!v || "*required"],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.requestData.InstitutionId = this.currentInstitution.id;
        apiClient
          .call(
            "POST",
            "institutions/" +
              this.currentInstitution.id +
              "/limsorderCommentTypes",
            this.requestData
          )
          .then((response) => {
            console.log(response);
            this.toggleForm();
            this.$store.dispatch("getOrderCommentTypes");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
};
</script>