<template>
  <div id="app">
    <div class="db-ChromeMainContent">
      <div class="db-ChromeMainContent-content" tabindex="-1">
        <div
          class="
            Box-root
            Padding-top--16
            Padding-bottom--8
            Flex-flex
            Flex-alignItems--center
            Flex-justifyContent--spaceBetween
          "
        >
          <span
            class="
              Text-color--dark
              Text-fontSize--28
              Text-fontWeight--bold
              Text-lineHeight--32
              Text-numericSpacing--proportional
              Text-typeface--base
              Text-wrap--wrap
              Text-display--inline
            "
            ><span class="pl-3 mb-3">Dashboard</span></span
          >
          <!-- <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Participant</v-btn
                > -->
        </div>
        <!-- Dashboard -->
        <div class="content container-fluid">
          <!-- Stats -->
          <div class="row gx-2 gx-lg-3">
            <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <!-- Card -->
              <span class="card card-hover-shadow h-100">
                <div class="card-body">
                  <h6 class="card-subtitle">Total Participants</h6>

                  <div class="row align-items-center gx-2 mb-1">
                    <div class="col-6">
                      <span class="card-title h2">{{ participantCount }}</span>
                    </div>

                    <div class="col-6">
                      <!-- Chart -->
                      <div class="chartjs-custom" style="height: 3rem">
                        <div class="chartjs-size-monitor">
                          <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                          </div>
                          <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                          </div>
                        </div>
                      </div>
                      <!-- End Chart -->
                    </div>
                  </div>
                  <!-- End Row -->

                  <!-- <span class="badge badge-soft-success">
                    <i class="tio-trending-up"></i> 12.5%
                  </span>
                  <span class="text-body font-size-sm ml-1">from 70,104</span> -->
                </div>
              </span>
              <!-- End Card -->
            </div>

            <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <!-- Card -->
              <span class="card card-hover-shadow h-100">
                <div class="card-body">
                  <h6 class="card-subtitle">Collection Protocols</h6>

                  <div class="row align-items-center gx-2 mb-1">
                    <div class="col-6">
                      <span class="card-title h2">{{
                        collectionProtocolCount
                      }}</span>
                    </div>

                    <div class="col-6">
                      <!-- Chart -->
                      <div class="chartjs-custom" style="height: 3rem">
                        <div class="chartjs-size-monitor">
                          <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                          </div>
                          <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                          </div>
                        </div>
                      </div>
                      <!-- End Chart -->
                    </div>
                  </div>
                  <!-- End Row -->

                  <!-- <span class="badge badge-soft-success">
                    <i class="tio-trending-up"></i> 1.7%
                  </span>
                  <span class="text-body font-size-sm ml-1">from 29.1%</span> -->
                </div>
              </span>
              <!-- End Card -->
            </div>

            <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <!-- Card -->
              <span class="card card-hover-shadow h-100">
                <div class="card-body">
                  <h6 class="card-subtitle">LIMS Orders</h6>

                  <div class="row align-items-center gx-2 mb-1">
                    <div class="col-6">
                      <span class="card-title h2">{{ orderCount }}</span>
                    </div>

                    <div class="col-6">
                      <!-- Chart -->
                      <div class="chartjs-custom" style="height: 3rem">
                        <div class="chartjs-size-monitor">
                          <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                          </div>
                          <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                          </div>
                        </div>
                      </div>
                      <!-- End Chart -->
                    </div>
                  </div>
                  <!-- End Row -->

                  <!-- <span class="badge badge-soft-danger">
                    <i class="tio-trending-down"></i> 4.4%
                  </span>
                  <span class="text-body font-size-sm ml-1">from 61.2%</span> -->
                </div>
              </span>
              <!-- End Card -->
            </div>

            <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              <!-- Card -->
              <span class="card card-hover-shadow h-100">
                <div class="card-body">
                  <h6 class="card-subtitle">Collected Samples</h6>

                  <div class="row align-items-center gx-2 mb-1">
                    <div class="col-6">
                      <span class="card-title h2">{{
                        collectedSampleCount
                      }}</span>
                    </div>

                    <div class="col-6">
                      <!-- Chart -->
                      <div class="chartjs-custom" style="height: 3rem">
                        <div class="chartjs-size-monitor">
                          <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                          </div>
                          <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                          </div>
                        </div>
                      </div>
                      <!-- End Chart -->
                    </div>
                  </div>
                  <!-- End Row -->

                  <!-- <span class="badge badge-soft-secondary">0.0%</span>
                  <span class="text-body font-size-sm ml-1">from 2,913</span> -->
                </div>
              </span>
              <!-- End Card -->
            </div>
          </div>
          <!-- End Stats -->

          <div class="row gx-2 gx-lg-3">
            <div class="col-lg-5 mb-3 mb-lg-5">
              <!-- Card -->
              <div class="card h-100">
                <!-- Header -->
                <div class="card-header">
                  <h5 class="card-header-title">
                    Pending Collection Protocols
                  </h5>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush list-group-no-gutters">
                    <li class="list-group-item py-3">
                      <h5 class="modal-title">Recent Collection Protocols:</h5>
                    </li>

                    <!-- List Group Item -->
                    <li
                      class="list-group-item py-3"
                      v-for="(collectionProtocol, index) in collectionProtocols"
                      :key="index"
                    >
                      <div class="media">
                        <div class="media-body">
                          <div class="row align-items-center">
                            <div class="col">
                              <h5 class="mb-0">
                                {{ collectionProtocol.title }}
                              </h5>
                              <span class="d-block font-size-sm"
                                >Start Date:
                                {{
                                  collectionProtocol.startDate
                                    | moment("YYYY-MM-DD")
                                }}</span
                              >
                            </div>

                            <div class="col-auto">
                              <span
                                class="btn btn-sm btn-primary"
                                @click="handleClick(collectionProtocol)"
                              >
                                Launch

                                <i class="tio-open-in-new ml-1"></i>
                              </span>
                            </div>
                          </div>
                          <!-- End Row -->
                        </div>
                      </div>
                    </li>
                    <!-- End List Group Item -->

                    <li class="list-group-item">
                      <small
                        >View More
                        <router-link to="/collection-protocols"
                          >more</router-link
                        >
                      </small>
                    </li>
                  </ul>
                </div>
                <!-- End Body -->
              </div>
              <!-- End Card -->
            </div>

            <div class="col-lg-7 mb-3 mb-lg-5">
              <!-- Card -->
              <div class="card h-100">
                <!-- Header -->
                <div class="card-header">
                  <h5 class="card-header-title">Participants</h5>
                </div>
                <!-- End Header -->

                <!-- Body -->
                <div class="card-body">
                  <v-data-table
                    style="margin-top: 15px"
                    :headers="headers"
                    hide-default-footer
                    :loading="isLoading"
                    :items="patients"
                  >
                    <template v-slot:item.name="{ item }">
                      {{ item.firstName }} {{ item.fatherName }}
                      {{ item.grandFatherName }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <template v-slot:item.birthDate="{ item }">
                      {{ item.birthDate | moment("YYYY-MM-DD") }}
                    </template></v-data-table
                  >

                  <div class="m-2">
                    <small
                      >View More
                      <router-link to="/patients">more</router-link>
                    </small>
                  </div>
                  <!-- End Bar Chart -->
                </div>
                <!-- End Body -->
              </div>
              <!-- End Card -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddPage from "../components/AddPage.vue";
import LeftNav from "../components/LeftNav.vue";
import NavBar from "../components/NavBar.vue";
import Configuration from "./Configuration/Configuration.vue";
import TestResult from "../views/TestResult.vue";
import { mapActions, mapGetters } from "vuex";
import apiClient from "../API/apiClient";
export default {
  name: "app",
  created() {
    this.getCollectionProtocols();
    this.getPatients(undefined);
    console.log("Dashboard created", this.currentInstitution);
    apiClient
      .call(
        "GET",
        "institutions/" + this.currentInstitution.id + "/patients/count",
        null
      )
      .then((response) => {
        // console.log(response);
        this.participantCount = response;
      })
      .catch((err) => {
        console.log(err);
      });

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/collectionProtocols/count",
        null
      )
      .then((response) => {
        // console.log(response);
        this.collectionProtocolCount = response;
      })
      .catch((err) => {
        console.log(err);
      });

    apiClient
      .call(
        "GET",
        "institutions/" +
          this.currentInstitution.id +
          "/collectedSamples/count",
        null
      )
      .then((response) => {
        // console.log(response);
        this.collectedSampleCount = response;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      participantCount: 0,
      collectionProtocolCount: 0,
      collectedSampleCount: 0,
      orderCount: 0,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Birth Date",
          align: "start",
          value: "birthDate",
        },
        {
          text: "Gender",
          align: "start",
          value: "gender.title",
        },
        {
          text: "Marital Status",
          align: "start",
          value: "maritalStatus.title",
        },
        {
          text: "Vital Status",
          align: "start",
          value: "vitalStatus.title",
        },
        {
          text: "Patient Type",
          align: "start",
          value: "patientType.title",
        },
        {
          text: "External Subject ID",
          align: "start",
          value: "externalSubjectID",
        },
      ],
    };
  },
  components: {
    NavBar,
    LeftNav,
    Configuration,
    TestResult,
    AddPage,
  },
  methods: {
    ...mapActions(["getCollectionProtocols", "getPatients"]),
    handleClick(value) {
      this.$store.commit("setSelectedCollectionProtocol", value);
      this.$router
        .push({
          name: "CollectionProtocolOverview",
          params: { collectionProtocolId: value.id },
        })
        .catch(() => {});
      console.log(value);
    },
  },
  computed: {
    ...mapGetters([
      "currentInstitution",
      "collectionProtocols",
      "patients",
      "isLoading",
    ]),
  },
};
</script>,
    TestResult
<style>
* {
  font-family: "Roboto", "Inter", "Nunito", "Roboto" !important;
}
</style>