<template>
  <div class="db-ChromeMainContent">
    <div class="db-ChromeMainContent-content" tabindex="-1">
      <div>
        <router-link to="/collection-protocols">Collection Protocol</router-link
        ><span style="padding: 5px">/</span
        ><span>{{ currentCollectionProtocol.title }}</span
        ><span style="padding: 5px">/</span>Shipments
      </div>
      <v-app>
        <div class="Box-root">
          <div
            class="
              Card-root Card--radius--all
              Section
              Box-root
              Box-background--white
            "
            style="overflow: visible"
          >
            <div class="ListView ListView--withoutFooter">
              <div
                class="
                  Box-root
                  Padding-top--16
                  Padding-bottom--8
                  Flex-flex
                  Flex-alignItems--center
                  Flex-justifyContent--spaceBetween
                "
              >
                <span
                  class="
                    Text-color--dark
                    Text-fontSize--28
                    Text-fontWeight--bold
                    Text-lineHeight--32
                    Text-numericSpacing--proportional
                    Text-typeface--base
                    Text-wrap--wrap
                    Text-display--inline
                  "
                  ><span>Shipments</span></span
                >
                <v-btn color="primary" elevation="0" small @click="toggleForm()"
                  ><v-icon style="margin-right: 5px" right dark>
                    mdi-plus
                  </v-icon>
                  Create Shipment</v-btn
                >
              </div>
              <v-chip-group
                v-model="selection"
                active-class="deep-purple--text text--accent-4"
                mandatory
              >
                <v-chip v-for="size in options" :key="size" :value="size">
                  {{ size }}
                </v-chip>
              </v-chip-group>
              <v-data-table
                :loading="isLoading"
                style="margin-top: 15px"
                :headers="headers"
                @click:row="handleClick"
                :items="collectionProtocolShipments"
              ></v-data-table>
            </div>
          </div>
        </div>
        <div
          v-if="showAddForm"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <add-collection-protocol-Shipment
            selectedNavItem="Shipment"
            :toggleForm="toggleForm"
          />
        </div>
        <div
          v-if="showCollectionProtocolShipmentDetail"
          class="
            FullscreenView FullscreenView--isLayer
            Box-root
            Box-background--white
            Flex-flex
            Flex-alignItems--center
            Flex-direction--column
            Flex-justifyContent--center
          "
        >
          <collection-protocol-shipment-detail
            selectedNavItem="Shipment"
            :toggleForm="toggleShipmentDetail"
            :selectedCollectionProtocolShipment="
              selectedCollectionProtocolShipment
            "
            :isSent="selection == options[0]"
          />
        </div>
      </v-app>
    </div>
  </div>
</template>
<script>
import AddCollectionProtocolShipment from "../../components/AddForms/AddCollectionProtocolShipment.vue";
import { mapGetters, mapActions } from "vuex";
import CollectionProtocolShipmentDetail from "../../components/AddForms/CollectionProtocolShipmentDetail.vue";
export default {
  components: {
    AddCollectionProtocolShipment,
    CollectionProtocolShipmentDetail,
  },
  name: "collection-protocol-shipments",
  created() {
    this.getCollectionProtocolShipments(true);
  },
  data() {
    return {
      selection: "SENT",
      options: ["SENT", "RECEIVED"],
      showAddForm: false,
      showCollectionProtocolShipmentDetail: false,
      selectedCollectionProtocolShipment: null,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Tracking Number",
          align: "start",
          value: "trackingNumber",
        },

        {
          text: "Sending Institution Site",
          align: "start",
          value: "sendingInstitutionSite.title",
        },
        {
          text: "Sending Institution",
          align: "start",
          value: "sendingInstitution.title",
        },
        {
          text: "Receiving Institution",
          align: "start",
          value: "receivingInstitution.title",
        },
        {
          text: "Receiving Institution Site",
          align: "start",
          value: "receivingInstitutionSite.title",
        },
        {
          text: "Shipped Date",
          align: "start",
          value: "shippedDate",
        },
        {
          text: "Shipped Time",
          align: "start",
          value: "shippedTime",
        },
        {
          text: "Status",
          align: "start",
          value: "status",
        },
        {
          text: "ReceivedDate",
          align: "start",
          value: "receivedDate",
        },
        {
          text: "ReceivedTime",
          align: "start",
          value: "receivedTime",
        },
      ],
    };
  },
  watch: {
    selection(val) {
      console.log(":::::::::::::::::::::::::::::::::::::::", val);
      this.getCollectionProtocolShipments(val == this.options[0]);
    },
  },
  methods: {
    ...mapActions(["getCollectionProtocolShipments"]),
    toggleForm: function () {
      this.showAddForm = !this.showAddForm;
    },
    toggleShipmentDetail: function () {
      this.showCollectionProtocolShipmentDetail =
        !this.showCollectionProtocolShipmentDetail;
    },
    handleClick(val) {
      console.log("selected collected shipment");
      this.selectedCollectionProtocolShipment = val;
      this.showCollectionProtocolShipmentDetail = true;
    },
  },
  computed: {
    ...mapGetters([
      "collectionProtocolShipments",
      "currentCollectionProtocol",
      "isLoading",
    ]),
  },
};
</script>