<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      @update:options="updateOptions"
      class="elevation-0"
      :loading="isLoading"
      :page="1"
      :pageCount="180"
      :server-items-length="totalCount"
      :options="options"
      @update:pagination="updatePagination"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
      }"
      :hide-default-footer="hasActions"
    >
      <template v-slot:top>
        <v-text-field
          @keyup.enter="search()"
          v-model="searchTerm"
          label="Search"
          outlined
          hint="press 'Enter' to search"
          dense
          style="width: 450px"
          class="mt-4"
        ></v-text-field>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ item.createdDate | moment("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.enabledDate="{ item }">
        {{ item.enabledDate | moment("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.creatorAccount="{ item }">
        <span v-if="item.creatorAccount"
          >{{ item.creatorAccount.firstName }}
          {{ item.creatorAccount.lastName }}</span
        >
      </template>
      <template v-slot:item.enablerAccount="{ item }">
        <span v-if="item.enablerAccount"
          >{{ item.enablerAccount.firstName }}
          {{ item.enablerAccount.lastName }}</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="dblclickg(item)" style="cursor: pointer">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Disable</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- {{ item.creatorAccount }} -->
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "table-page",
  props: ["headers", "items", "rowCount", "hasActions"],
  beforeCreate() {
    this.$store.commit("setOptions", {
      page: 1,
      itemsPerPage: 10,
      pageCount: 15,
    });
  },
  created() {
    if (!this.hasActions) {
      this.headers.push({ text: "Actions", value: "actions" });
    }
    // console.log("this.$parent:", this.$parent.$options.name);
    this.noEditModels.forEach((element) => {
      if (this.$route.path.includes(element)) {
        this.hasNoEdit = true;
      }
    });
    // console.log("this.$route", this.$route.path);
  },
  data() {
    return {
      noEditModels: ["panel-test", "test-accepted-value", "test-result-range"],
      hasNoEdit: false,
      isFirstTime: true,
      searchTerm: "",
    };
  },
  watch: {
    "options.page"(val) {
      console.log("Page changed to: " + val);
    },
    page(val) {
      console.log("Page changed to:: " + val);
    },
    options: {
      handler(val) {
        console.log("something is happening...");
      },
      deep: true,
    },
  },
  methods: {
    updatePage(val) {
      console.log("Page changed to: " + val);
    },
    updatePageSize(val) {
      console.log("Page size changed to: " + val);
    },
    updateItemsPerPage(val) {
      console.log("Items per page changed to: " + val);
    },
    updateOptions(val) {
      console.log("Options changed to: ", val);
      this.$store.commit("setOptions", val);
      console.log("this.selectedConfiguration", this.selectedConfiguration);
      if (!this.isFirstTime) {
        this.$store.dispatch(this.selectedConfiguration);
      } else {
        this.isFirstTime = false;
      }
    },
    search() {
      console.log("Searching...");
      this.$store.commit("setSearchTerm", this.searchTerm);
      console.log("this.selectedConfiguration", this.selectedConfiguration);
      this.$store.dispatch(this.selectedConfiguration);
    },
    updatePagination(val) {
      console.log("Pagination changed to: ", val);
      // this.$store.commit("setPagination", val);
    },
    dblclickg(val) {
      // console.log("dblclicked!", val);
      if (this.hasNoEdit) {
        // console.log("has no edit");
        return;
      }
      this.$store.commit("toggleEditView");
      this.$store.commit("setSelectedEditObject", val);
    },
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "selectedEditObject",
      "selectedConfiguration",
      "totalCount",
    ]),
    ...mapState(["options"]),
  },
};
</script>
<style>
.text-start {
  font-size: 14px !important;
}
.td.text-start {
  font-size: 14px !important;
}
.v-data-footer {
  font-size: 14px !important;
}
.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 14px !important;
}
</style>
